import React from "react"
import {Button, ButtonGroup, Media} from "reactstrap";

class MultiMediaItem extends React.Component {
    render() {
        let {attributes} = this.props
        if (typeof attributes != "object")
            attributes = {}

        return <div className={"p-3 border rounded multimedia-item-single"}>
            <img src={attributes.public_url} width={125} height={125}/>
            <div className={"media-actions"}>
                <Button
                    onClick={() => {
                        if (typeof this.props.preview === "function")
                            this.props.preview(attributes)
                    }}
                    size={"sm"} color={"primary"} className={"rounded-circle mr-1"}><i
                    className={"icon-feather-zoom-in"}/></Button>
                <Button
                    onClick={() => {
                        if (typeof this.props.copy === "function")
                            this.props.copy(attributes)
                    }}
                    size={"sm"} color={"dark"} className={"rounded-circle mr-1"}><i
                    className={" icon-material-outline-filter-none"}/></Button>
                <Button
                    onClick={() => {
                        if (typeof this.props.copy === "function")
                            this.props.delete(attributes)
                    }}
                    size={"sm"} color={"danger"} className={"rounded-circle"}><i className={"icon-feather-trash"}/></Button>
            </div>
        </div>;
    }
}

export default MultiMediaItem
import React from "react"
import {get_main_config} from "../index";
import {matchPath} from "react-router";
import {Spinner} from "reactstrap";

const getParams = (pathname) => {
    const matchProfile = matchPath(pathname, {
        path: `/page/:slug`,
    });
    return (matchProfile && matchProfile.params) || {};
};

const LoadingPage = () => {
    return <div className={"padding-top-100 w-100 text-center"}>
        <Spinner color={"dark"}/>
    </div>
}

class HomeSinglePage extends React.Component {
    state = {
        title: "",
        content: "",
        loading: true
    }
    slug = ""

    constructor(props) {
        super(props);
        let params = getParams(props.location.pathname)
        this.slug = params.slug
    }

    loadPageData = (slug) => {
        let self = this
        this.slug = slug
        this.setState({loading: true})
        setTimeout(function () {
            let pageContent = get_main_config("pages", slug)
            self.setState({
                title: pageContent.name,
                content: pageContent.content
            }, () => {
                self.setState({loading:false})
            })
        }, 250);
    }

    componentDidMount() {
        this.loadPageData(this.slug)
    }

    componentWillReceiveProps(nextProps) {
        let params = nextProps.computedMatch.params
        if (params.slug !== this.slug) {
            this.loadPageData(params.slug)
        }
    }

    render() {
        let {title, content, loading} = this.state
        return loading ? <LoadingPage/> : <div className={"padding-top-20 text-left"}>
            <h1>{title}</h1>
            <div dangerouslySetInnerHTML={{__html: content}}/>
        </div>
    };

}

export default HomeSinglePage
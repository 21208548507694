import React from "react"
import {Col, Row} from "reactstrap";
import CarouselReady from "../../../Components/CarouselReady";
import ContentModel from "../../../Models/ContentModel";

class Announcements extends React.Component {
    state = {
        data: []
    }

    load_data = () => {
        ContentModel.getAnnouncements({
            sort: "-created",
            include: "content-field",
            status: "1",
            field: {
                featured: "yes"
            }
        })
            .then(r => r.json())
            .then(r => {
                let {data, included} = r
                Object.keys(data).forEach(i => {
                    if (typeof included === "object" && typeof included[i] === "object") {
                        let th = included[i].find(item => item.data.field_name === "thumbnail")
                        data[i]['image'] = th.data.value
                    }
                })
                this.setState({data: data})
            })
    }

    componentDidMount() {
        this.load_data()
    }

    render() {
        let {data} = this.state
        data = data.map(item => {
            return {
                caption: item.attributes.name,
                src: item.image || "",
                id: item.id
            }
        })
        return <><Row>
            <Col className={"pb-3"} sm={{size: 10, offset: 1}}>
                <CarouselReady history={this.props.history} items={data}/>
            </Col>
        </Row></>;
    }
}

export default Announcements
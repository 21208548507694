import React from "react"
import {Button, Col, Modal, ModalBody, ModalHeader, Row, Spinner} from "reactstrap";
import ConfirmationModalAlert from "./ModalAlerts/ConfirmationModalAlert";
import AddressModel from "../Models/AddressModel";
import {ApiErrorThrow} from "../Config";
import SmallAddressBox from "./SmallAddressBox";
import BillingProfileForm from "../Elements/BillingProfileForm";
import {defaultAddressFields} from "../Pages/Admin/Clients/Widgets/ClientProfileBillingProfile";
import {FormattedMessage} from "react-intl";
import {react_intl} from "../index";

export const NoAddressEntered = (props) => {
    return <div className={"text-center w-100"}>
        <h3><i className={"icon-line-awesome-warning"}/> <FormattedMessage id={"components.Invoice_Details_Manager_invoice_data"} /></h3>
        <p><FormattedMessage id={"components.Invoice_Details_Manager_account_related_invoice_data"} /></p>
        <Button color={"primary"} onClick={() => {
            props.toggle()
        }}><FormattedMessage id={"components.Invoice_Details_Manager_add_new"} /> <i className={"icon-material-outline-add"}/></Button>
    </div>
}

class InvoiceDetailsManager extends React.Component {
    state = {
        delete_modal: false,
        current_address_data: {},
        modal2: false,
        editData: null,
        invoice_details: {},
        addresses: [],
        loadingModalAddress: false,
        edit_modal: false
    }


    constructor(props) {
        super(props);
        this.delete_modal_toggle = this.delete_modal_toggle.bind(this)
        this.invoice_details_change = this.invoice_details_change.bind(this)
    }

    invoice_details_change = (e, callback) => {
        let invoice_details = {...this.state.invoice_details}
        invoice_details[e.target.name] = e.target.value;
        this.setState({invoice_details}, () => {
            if (typeof callback === "function") {
                callback()
            }
        })
    }

    delete_modal_toggle = (details) => {
        if (!details)
            details = {}
        this.setState({
            current_address_data: details,
            delete_modal: !this.state.delete_modal
        })
    }
    edit_modal_toggle = (details) => {
        if (!details)
            details = null
        this.setState({
            invoice_details: details,
        }, () => this.modal2_toggle())
    }
    modal2_toggle = () => {
        this.setState({modal2: !this.state.modal2})
        if (this.state.modal2) {
            this.setState({
                invoice_details: {}
            })
        }
    }

    componentDidMount() {
        this.loadUserAddress()
    }

    save = (data) => {
        let invoice_details = {...this.state.invoice_details}
        if (typeof data !== "undefined") {
            invoice_details = data
            Object.keys(data).forEach(item => {
                invoice_details[item] = data[item]
            })
        }
        invoice_details = Object.assign(defaultAddressFields, invoice_details);
        let process
        if (typeof invoice_details.id !== "number") {
            process = AddressModel.create(this.props.user_id, invoice_details)
        } else {
            process = AddressModel.modify(this.props.user_id, invoice_details.id, invoice_details)
        }

        process.then(r => r.json())
            .then(r => {
                this.loadUserAddress()
                this.modal2_toggle()
            })
            .catch(r => ApiErrorThrow(r))
    }

    loadUserAddress() {
        this.setState({loadingModalAddress: true})
        AddressModel.getClientAddress(this.props.user_id)
            .then(r => r.json())
            .then(r => {
                this.setState({
                    addresses: r.data,
                    loadingModalAddress: false
                })
            }).catch(r => ApiErrorThrow(r))
    }

    render() {
        let {editData, delete_modal, current_address_data, loadingModalAddress, addresses} = this.state
        return <>
            <ConfirmationModalAlert
                isOpen={delete_modal}
                toggle={this.delete_modal_toggle}
                color={"error"}
                title={ react_intl.formatMessage({id:"components.Invoice_Details_Manager_are_you_sure"})}
                description={react_intl.formatMessage({id:"components.Invoice_Details_Manager_deleted_title"}, {
                    title: current_address_data.title
                })}
                buttonAction={() => {
                    AddressModel.delete(current_address_data.user_id.id, current_address_data.id)
                        .then(r => {
                            this.loadUserAddress()
                            this.delete_modal_toggle()
                        }).catch(r => ApiErrorThrow(r))
                }}
            />
            <Row>
                {
                    loadingModalAddress ? <div className={"padding-top-50 w-100 text-center"}>
                        <Spinner color={"dark"} size={"lg"}/>
                    </div> : addresses.length ? <>{addresses.map(item =>
                        <Col sm={12} md={4} className={"mb-2"}>
                            <SmallAddressBox
                                delete_toggle={this.delete_modal_toggle}
                                edit_toggle={this.edit_modal_toggle}
                                useButton={this.props.useButton}
                                onChange={(e) => {
                                    if (typeof this.props.onChange === "function")
                                        this.props.onChange(e)
                                    if (typeof this.props.toggleModal === "function")
                                        this.props.toggleModal()

                                }}
                                {...item.attributes}
                            />
                        </Col>)}

                        <div className={"w-100 text-center p-3"}>
                            <Button color={"primary"} onClick={() => {
                                this.modal2_toggle()
                            }}><FormattedMessage id={"components.Invoice_Details_Manager_second_add_new"} /> <i className={"icon-material-outline-add"}/></Button>
                        </div>
                    </> : <>
                        <NoAddressEntered self={this} toggle={this.modal2_toggle}/></>
                }
            </Row>
            <Modal
                isOpen={this.state.modal2}
                toggle={this.modal2_toggle}
                centered={true}
            >
                <ModalHeader
                    toggle={this.modal2_toggle}
                >{editData === null ? react_intl.formatMessage({id:"components.Invoice_Details_Manager_add_invoice"}) : react_intl.formatMessage({id:"components.Invoice_Details_Manager_edit_invoice"})}</ModalHeader>
                <ModalBody>
                    {<BillingProfileForm
                        ref={this.billingFormRef}
                        key={"BillingProfileForm"}
                        data={this.state.invoice_details || defaultAddressFields}
                        address_id={null}
                        onChange={this.invoice_details_change}
                        save={this.save}
                    />}
                </ModalBody>
            </Modal>
        </>;
    }
}

export default InvoiceDetailsManager

import {ApiErrorThrow, defaultSnackbarOptions} from "../Config";
import {withSnackbar} from "react-simple-snackbar";
import React from "react"
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import ConfirmationModalAlert from "./ModalAlerts/ConfirmationModalAlert";
import ClientModel from "../Models/ClientModel";
import {get_user_id} from "../Conts";
import {BiMailSend} from "react-icons/all";
import {Alert} from "@material-ui/lab";
import {react_intl} from "../index";
import {FormattedMessage} from "react-intl";

const emailSendLimit = 30

class EmailVerification extends React.Component {
    state = {
        show: false,
        show2: false,
        code: "",
        resendTime: -1,
        step2Error: ""
    }
    reSendTimer = null

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this)
        this.toggle2 = this.toggle2.bind(this)
        this.toggle3 = this.toggle3.bind(this)
        this.inputChanged = this.inputChanged.bind(this)
    }

    toggle = () => {
        this.setState({show: !this.state.show})
    }

    toggle2 = () => {
        this.setState({show2: !this.state.show2})
    }

    toggle3 = () => {
        this.setState({show3: !this.state.show3})
    }

    inputChanged = (e) => {
        this.setState({
            code: e.target.value
        })
    }

    sendCode = () => {
        let self = this
        let {openSnackbar} = this.props
        this.setState({resendTime: 0})
        ClientModel.emailVerifyRequest(get_user_id())
            .then(r => r.json())
            .then(r => {
                this.step1to2()
                this.reSendTimer = setInterval(function () {
                    self.setState({resendTime: self.state.resendTime + 1}, () => {
                        if (self.state.resendTime === emailSendLimit) {
                            clearInterval(self.reSendTimer)
                        }
                    })
                }, 1000)
            }).catch(r => ApiErrorThrow(r, openSnackbar))
    }

    step1to2 = () => {
        this.setState({code: "", show: false, show2: true})
    }

    step2to3 = () => {
        this.setState({code: "", show2: false, show3: true})
    }

    render() {
        let {buttonColor, buttontext, openSnackbar} = this.props
        let {show, show2, show3, resendTime, step2Error} = this.state
        return <>
            <ConfirmationModalAlert
                color={"success"}
                title={react_intl.formatMessage({id: "components.email_verification_email_verification"})}
                description={react_intl.formatMessage({id: "components.email_verification_system_email_record"})}
                icon={" icon-line-awesome-hourglass-half"}
                centered={true} isOpen={show} toggle={this.toggle}
                buttonAction={() => {
                    this.sendCode()
                }}
            />

            <ConfirmationModalAlert
                color={"success"}
                title={react_intl.formatMessage({id: "components.email_verification_code"})}
                description={react_intl.formatMessage({id: "components.email_verification_system_verification_code"})}
                icon={"icon-line-awesome-hourglass-half"}
                centered={true} isOpen={show2} toggle={this.toggle2}
                buttonAction={() => {
                    ClientModel.emailVerifyComplete(get_user_id(), this.state.code)
                        .then(r => r.json())
                        .then(r => {
                            if (typeof r.status === "boolean" && r.status) {
                                this.step2to3()
                            } else {
                                this.setState({step2Error: react_intl.formatMessage({id: "components.email_verification_code_control"})})
                            }
                        }).catch(r => ApiErrorThrow(r, openSnackbar))
                }}
            >
                {step2Error ? <Alert severity={"error"} onClose={() => {
                    this.setState({step2Error: ""})
                }
                }>{step2Error}</Alert> : ""}
                <input type="text"
                       value={this.state.code}
                       onChange={this.inputChanged}
                       className={"form-control verification-code-input"}/>
                {resendTime > -1 && <Button
                    size={"sm"}
                    onClick={() => {
                        if (emailSendLimit - resendTime === 0) {
                            this.sendCode()
                        }
                    }}
                    color={"link"}>{emailSendLimit - resendTime === 0 ? <><FormattedMessage
                    id={"components.email_verification_send_again"}/>
                    <BiMailSend/></> : react_intl.formatMessage({id: "components.email_verification_resend"}, {left: (emailSendLimit - resendTime)})}
                </Button>}
            </ConfirmationModalAlert>

            <ConfirmationModalAlert
                isOpen={show3}
                toggle={this.toggle3}
                color={"success"}
                title={react_intl.formatMessage({id: "components.email_verification_successful_verification"})}
                icon={" icon-material-outline-check"}
                description={react_intl.formatMessage({id: "components.email_verification_congratulations"})}
                buttonAction={() => {
                    window.location.reload()
                }}
                buttontext={react_intl.formatMessage({id: "components.email_verification_close"})}
                canCancel={false}
            />

            <Button size={"sm"} color={buttonColor || "secondary"}
                    onClick={this.toggle}>{buttontext || react_intl.formatMessage({id: "components.email_verification_verify"})}</Button>
        </>
    }
}

export default withSnackbar(EmailVerification, defaultSnackbarOptions)
import GlRequest from "../Lib/GlRequest";

class ContentModel {
    delete(id) {
        return GlRequest.delete(`/api/v2/content/${id}`)
    }

    getById(id, include) {
        let incSuffix = ""
        if (typeof include === "object") {
            include = include.join(",")
            incSuffix = "?include=" + include
        }
        let url = '/content/' + id + incSuffix
        return GlRequest.get(url)
    }

    modify(id, post) {
        post = {
            data: {
                attributes: post
            }
        }
        return GlRequest.patch('/api/v2/content/' + id, post, 'json')
    }

    create(post) {
        post = {
            data: {attributes: post}
        }
        return GlRequest.post('/api/v2/content', post, 'json')
    }

    getMenus(slug) {
        return GlRequest.get('/content?type=menu&slug=' + slug)
    }

    getPages(name) {
        return GlRequest.get('/content?type=page&status=1&name=' + name)
    }

    addField(postId, field_name, value) {
        let data = {
            data: {
                attributes: [
                    {field_name, value}
                ]
            }
        }
        return GlRequest.post(`/api/v2/content-field/${postId}`, data, 'json')
    }

    addFieldMulti(postId, group) {
        let data = {
            data: {
                attributes: group.map(item => {
                    return {
                        field_name: item.name,
                        value: item.value
                    }
                })
            }
        }
        return GlRequest.post(`/api/v2/content-field/${postId}`, data, 'json')
    }

    editField(postId, field_name, value) {
        let data = {
            data: {
                attributes: [
                    {field_name, value}
                ]
            }
        }
        return GlRequest.patch(`/api/v2/content-field/${postId}`, data, 'json')
    }


    editFieldMulti(postId, group) {
        let data = {
            data: {
                attributes: group
            }
        }
        return GlRequest.patch(`/api/v2/content-field/${postId}`, data, 'json')
    }

    deleteField = (postId, fieldId) => {
        if (typeof fieldId === "object")
            fieldId = fieldId.join(",")

        return GlRequest.delete(`/api/v2/content-field/${postId}/${fieldId}`)
    }

    getAnnouncements(params) {
        if(typeof params === "undefined")
            params = {}
        params.type = "announcement"
        return GlRequest.get('/content', {
            params
        })
    }
}

export default ContentModel = new ContentModel()
import React from "react"
import {Card, CardHeader} from "@material-ui/core";
import PaymentMethodModel from "../Models/PaymentMethodModel";
import {Badge, Button, CardBody, CardFooter, Col, Row, Spinner} from "reactstrap";
import {Banks, getBankObject} from "../Banks";
import {Link} from "react-router-dom";
import CurrencyModel from "../Models/CurrencyModel";
import ShowPrice from "../Elements/ShowPrice";
import classnames from "classnames";
import {Skeleton} from "@material-ui/lab";
import {FormattedMessage} from "react-intl";
import {react_intl} from "../index";

const SingleCurrencyItem = (props) => {
    let {item, default_currency} = props
    return <div className={"single-currency border m-1 rounded bg-light p-1 float-none d-inline-block width-auto"}>
        <h4>{item.attributes.code}</h4>
        <div className={classnames({
            "d-none": item.attributes.code === default_currency.attributes.code
        })}>
            <ShowPrice currencyprefix={default_currency.attributes.prefix}
                       amount={item.attributes.rate}
                       currencysuffix={default_currency.attributes.suffix}/> = <ShowPrice
            amount={1}
            currencyprefix={item.attributes.prefix} currencysuffix={item.attributes.suffix}/></div>
        <div className={classnames({
            "d-none": item.attributes.code !== default_currency.attributes.code
        })}>
            <Badge color={"success"}><FormattedMessage id={"components.currencies_widget_default"} /></Badge>
        </div>
    </div>
}
const CurrenciesWidgetSkeleton = (props) => {
    return <>
        <div className={"d-flex"}>
            <Skeleton variant={"rect"} width={150} height={36} className={"mr-2 rounded"}/>
            <Skeleton variant={"rect"} width={150} height={36} className={"mr-2 rounded"}/>
        </div>
    </>
}

class CurrenciesWidget extends React.Component {
    state = {
        currencies: [],
        loading: true,
        default_currency: {}
    }

    loadCurrencies = () => {
        CurrencyModel.getAll({})
            .then(r => r.json())
            .then(r => {
                let {data} = r

                let default_currency = data.find(item => item.attributes.is_default)

                this.setState({
                    currencies: data,
                    loading: false,
                    default_currency
                })
            })
    }

    componentDidMount() {
        this.loadCurrencies()
    }

    render() {
        let {currencies, loading} = this.state
        return <Card>
            <CardHeader
                disableTypography
                className={"border-bottom paymendo-grad text-white rounded-0 m-0"}
                title={react_intl.formatMessage({id:"components.currencies_widget_active_currencies"})}
            >
            </CardHeader>
            <CardBody className={"text-center p-2 active-currencies"} style={{height: 250}}>
                {loading ? <Row><Col sm={12} className={"pt-3 pb-3"}>
                    <CurrenciesWidgetSkeleton/>
                </Col></Row> : currencies.map((item, i) => {
                    return <SingleCurrencyItem
                        key={`currency-item-${i}`}
                        item={item}
                        default_currency={this.state.default_currency}
                    />
                })}
            </CardBody>
            <CardFooter className={"text-center bg-light"}>
                <Link to={'/admin/currencies'}
                      className={"btn btn-default btn-sm button-sliding-icon"}><FormattedMessage id={"components.currencies_widget_manage"} /> <i
                    className={"icon-feather-arrow-right"}/></Link>
            </CardFooter>
        </Card>;
    }
}

export default CurrenciesWidget
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ContentModel from "../../Models/ContentModel";
import {Grid} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import {Col, Row} from "reactstrap";
import {FormattedMessage} from "react-intl";
import {react_intl} from "../../index";

const useStyles = makeStyles({
    root: {
        width: "100%",
        marginBottom: 20
    },
});

const AnnouncementSkeleton = (props) => {
    return <div className={"p-2 mb-3"}>
        <Skeleton variant={"rect"} className={"rounded mb-2"} width={"100%"} height={90}/>
        <Skeleton variant={"text"} width={"100%"} height={10} className={"mb-2"}/>
        <Skeleton variant={"text"} width={"100%"} height={40}/>
        <Skeleton variant={"rect"} className={"rounded mt-2"} width={110} height={25}/>
    </div>
}

const SingleAnnouncement = (props) => {
    const classes = useStyles();
    let data = props.item.attributes
    let fields = props.item.fields

    let image = fields.find(item => item.data.field_name === "thumbnail")
    if (typeof image === "object")
        image = image.data.value

    if (!image)
        image = "defaultImage"

    let {name, content} = data
    let summary = content.replace(/(<([^>]+)>)/gi, "").substr(0, 100)


    return (
        <Col lg={4} sm={12}>
            <Card className={classes.root}>
                <CardActionArea onClick={() => {
                    props.history.push(`/cp/announcements/${props.item.id}`)
                }}>
                    <CardMedia
                        component="img"
                        alt={name}
                        height="140"
                        image={image}
                        title={name}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2" className={"text-excepted"}>
                            {name}
                        </Typography>
                        <Typography style={{minHeight: 60}} variant="body2" color="textSecondary" component="p">
                            {summary}...
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <Button size="small" color="primary" onClick={() => {
                        props.history.push(`/cp/announcements/${props.item.id}`)
                    }}>
                        {react_intl.formatMessage({id: "pages.announcements.details"})}
                    </Button>
                </CardActions>
            </Card>
        </Col>
    );
}


class CpAnnouncements extends React.Component {
    state = {
        data: [],
        loading: true
    }

    loadAnnouncements = () => {
        ContentModel.getAnnouncements({
            include: "content-field",
            status: "1",
            sort: "-id"
        }).then(r => r.json())
            .then(r => {
                let {data} = r
                data.map((item, i) => {
                    data[i]['fields'] = r.included[i]
                })
                let loading = false
                this.setState({data, loading})
            })
    }

    componentDidMount() {
        this.loadAnnouncements()
    }

    render() {
        let {data, loading} = this.state
        return <div>
            <div className={"w-100 pl-3 pr-3"}>
                <h3 className={"margin-bottom-25"}><FormattedMessage
                    id={"client.cp_announcements.campaign_announcements_txt"}/></h3>
                {loading ? <><Row>{[0, 1, 2, 3, 4, 5].map(item => <Col sm={6} lg={4}
                                                                       className={""}><AnnouncementSkeleton/></Col>)}</Row></> :
                    <Row>
                        {data.map(item => {
                            return <SingleAnnouncement history={this.props.history} item={item}/>
                        })
                        }
                    </Row>
                }
            </div>
        </div>;
    }
}

export default CpAnnouncements
import React from "react"
import {withSnackbar} from "react-simple-snackbar";
import QuickPaymentBase from "./QuickPaymentBase";
import {injectIntl} from "react-intl";
import {defaultSnackbarOptions} from "../../../Config";

class OpenPayment extends QuickPaymentBase {
    payment_type = "open_payment"
}

export default injectIntl(withSnackbar(OpenPayment, defaultSnackbarOptions))
import React from "react"
import {Button, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import {defaultSnackbarOptions, log_types} from "../Config";
import {Switch} from "@material-ui/core";
import SettingModel from "../Models/SettingModel";
import {FormattedMessage, injectIntl} from "react-intl";
import {withSnackbar} from "react-simple-snackbar";

class LogSettings extends React.Component {
    state = {
        isOpen: false,
        log_settings: {},
        saving: false
    }

    modalToggle = () => {
        this.setState({isOpen: !this.state.isOpen})
    }


    componentDidMount() {
        SettingModel.getByGroup('log_config').then(r => r.json()).then(r => {
            let obj = {};
            (r.data || []).forEach(x => {
                obj[x.attributes.name] = x.attributes.value;
            });
            this.setState({
                log_settings: obj
            });
        });
    }

    constructor(props) {
        super(props);
        this.modalToggle = this.modalToggle.bind(this);
    }

    render() {
        let {isOpen, log_settings} = this.state
        let self = this;
        return <>
            <Modal isOpen={isOpen} toggle={this.modalToggle}>
                <ModalHeader toggle={this.modalToggle}>
                    <FormattedMessage id={"admin.log_settings.modal_title"} />
                </ModalHeader>
                <ModalBody>
                    <p><FormattedMessage id={"admin.log_settings.modal_description"} /></p>
                    <ListGroup>
                        {log_types().filter(i => i.value !== '').map((i, c) => {
                            return <ListGroupItem color={c % 2 ? "light" : ""}>
                                <Switch checked={typeof log_settings[i.value] && log_settings[i.value] === "yes"}
                                        onClick={() => {
                                            let obj = Object.assign({}, log_settings);
                                            obj[i.value] = typeof log_settings[i.value] && log_settings[i.value] === "yes" ? "no" : "yes";
                                            this.setState({
                                                log_settings: obj
                                            });
                                        }} name={"status"}/> {i.label}
                            </ListGroupItem>
                        })}
                    </ListGroup>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => {
                        let group = {
                            log_config: log_settings
                        };
                        self.setState({saving: true})
                        SettingModel.bulkEdit(group).then(r => {
                            self.setState({saving: false});
                            self.props.openSnackbar(<FormattedMessage id={"admin.log_settings.saved"} />);
                            self.modalToggle();
                        });
                    }}><FormattedMessage id={"admin.log_settings.save_button"} /> {this.state.saving && <Spinner size={"sm"}/>}</Button>
                </ModalFooter>
            </Modal>
            <Button className={"mt-2"} onClick={this.modalToggle} color={"primary"}><FormattedMessage id={"admin.log_settings.button_label"} /> <i className={"icon-feather-settings"}></i></Button>
        </>;
    }
}

export default injectIntl(withSnackbar(LogSettings, defaultSnackbarOptions))
import React from "react"
import {GetTitle} from "../../Routes/AdminRoute";
import {Alert, Col, Row} from "reactstrap";
import {get_user_id} from "../../Conts";
import AddressModel from "../../Models/AddressModel";
import InvoiceDetailsManager from "../../Components/InvoiceDetailsManager";
import {FormattedMessage} from "react-intl";
import {injectIntl} from "react-intl"

class BillingInformation extends React.Component{
    state = {
        billing_information: [],
        modal2: false,
        editData: null
    }

    invoice_details_change = (e, callback) => {
        let invoice_details = {...this.state.invoice_details}
        invoice_details[e.target.name] = e.target.value
        this.setState({invoice_details}, () => {
            if (typeof callback === "function")
                callback()
        })
    }

    modal2_toggle = () => {
        this.setState({modal2: !this.state.modal2})
        if (this.state.modal2) {
            this.setState({
                invoice_details: {}
            })
        }
    }

    componentDidMount() {
        let {formatMessage}=this.props.intl
        document.title = GetTitle(formatMessage({id:"client.billing_information.invoice_info_title"}))
        AddressModel.getClientAddress(get_user_id()).then( r => r.json() )
            .then(r => {
                this.setState({billing_information: r.data})
            })
    }

    render() {
        return <div className={"w-100 pl-3 pr-3"}>
            <Alert color={"light"} className={"border"}>
                <Row>
                    <Col sm={12} md={6}>
                        <h3><FormattedMessage id={"client.billing_information.invoice_info"}/> </h3>
                        <p className={"font-italic text-muted"}><FormattedMessage id={"client.billing_information.define_invoice_info_txt"}/></p>
                    </Col>
                </Row>
            </Alert>
            <InvoiceDetailsManager user_id={get_user_id()} useButton={false} />
        </div>
    }
}

export default injectIntl(BillingInformation)
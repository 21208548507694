import React from "react"
import StaticPanelTopBar from "../../Components/StaticPanelTopBar";
import moment from "moment";
import DataTable from "../../Components/DataTable";
import {log_types} from "../../Config";
import SystemLogs from "../Admin/Settings/SystemLogs";
import {react_intl} from "../../index";

class PaymentLogs extends React.Component{
    render() {
        return <>
            <StaticPanelTopBar title={react_intl.formatMessage({id:"client.payment_logs.payment_records"})} />
            <div className={"bg-white p-3 margin-top-100"}>
                <SystemLogs
                    filterType={"payment"}
                    filterIp={false}
                    typeColumn={false}
                    filterDate={true}
                    purge={true}
                    viewType={"inline"}
                />
            </div>
        </>;
    }
}
export default PaymentLogs
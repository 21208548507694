import {defaultSnackbarOptions} from "../../../../Config";
import {withSnackbar} from "react-simple-snackbar";
import {react_intl} from "../../../../index";
import ListAll from "../MainType/ListAll";

class List extends ListAll {
    title = react_intl.formatMessage({id: "admin.content.announcements.list.text"})
    description = react_intl.formatMessage({id: "admin.content.announcements.list.description"})
    content_type = "announcement"
    create_link = "/admin/new-announcement"
    detail_link = "/admin/announcements/:id"
}

export default withSnackbar(List, defaultSnackbarOptions)
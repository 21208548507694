import React from "react"
import {Link} from "react-router-dom";
import {UncontrolledTooltip} from "reactstrap";

class OrderLink extends React.Component {
    render() {
        let {order} = this.props
        let notes = ""
        if (order.notes) {
            notes = <div className={"d-inline-block"}>
                <UncontrolledTooltip target={`order-${order.id}-note`}>{order.notes}</UncontrolledTooltip>
                <i id={`order-${order.id}-note`} className={"icon-line-awesome-comment"}/>
            </div>
        }
        return <Link to={`/admin/orders/${order.id}`}>#{order.order_num} {notes}</Link>
    }
}

export default OrderLink
import GlRequest from "../Lib/GlRequest";

class LogModel {
    base = '/api/v2/log'
    purge = (details) => {
        details = {
            data: {
                attributes: details
            }
        }
        return GlRequest.post(this.base + '/purge', details, "json")
    }
}
export default LogModel = new LogModel()
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import SystemSettingGroup from "../Pages/Admin/Settings/SystemSettings/SystemSettingGroup";
import React from "react";
import {successSnackbarOptions} from "../Config";
import {withSnackbar} from "react-simple-snackbar";
import {react_intl} from "../index";
import {FormattedMessage} from "react-intl";

class SettingsSeparated extends React.Component {
    constructor(props) {
        super(props);
        this.settingModalToggle = this.settingModalToggle.bind(this)
    }

    state = {
        settingModal: false,
    }

    settingModalToggle = () => {
        this.setState({
            settingModal: !this.state.settingModal
        })
    }

    render() {
        let {settings, group, buttontext, title, buttonColor, viewType, openSnackbar} = this.props
        if (typeof viewType === "undefined")
            viewType = "modal"
        let selected_settings = settings().settings.find(item => item.slug === group)

        if(typeof selected_settings === "undefined")
            return false

        const settingItem = <SystemSettingGroup
            key={`setting-group-currency`}
            selectHandler={() => {
            }}
            values={() => {
            }}
            column={2}
            single={true}
            label={react_intl.formatMessage({id:"components.settings_separated_currency_settings"})}
            handler={this.inputHandle}
            shortcodeButtonToggle={() => {
            }}
            settings={selected_settings.settings || []}
            group_slug={selected_settings.slug}
            onSave={() => {
                if(viewType === "modal")
                    this.settingModalToggle()
                openSnackbar(react_intl.formatMessage({id:"components.settings_separated_settings_saved"}))
            }}
        />
        if (viewType === "modal")
            return <>
                <Modal isOpen={this.state.settingModal} size={"lg"} toggle={this.settingModalToggle}>
                    <ModalHeader toggle={this.settingModalToggle}>{title || react_intl.formatMessage({id:"components.settings_separated_settings"})}</ModalHeader>
                    <ModalBody>
                        {settingItem}
                    </ModalBody>
                </Modal>
                <Button color={buttonColor || "primary"}
                        onClick={this.settingModalToggle}>{buttontext || <><FormattedMessage id={"components.settings_separated_second_settings"} /></>}</Button>
            </>;
        else
            return <>
                {settingItem}
            </>

    }

}
export default withSnackbar(SettingsSeparated, successSnackbarOptions)
import React from "react"
import {FaCheck, FaTimes} from "react-icons/all";
import {Badge, Card} from "reactstrap";
import {Link} from "react-router-dom";
import {Skeleton} from "@material-ui/lab";
import {react_intl} from "../../index";

const VerificationBoxLoading = (props) => {
    return <Card
        className={"padding-top-5 padding-bottom-5 padding-right-10 padding-left-10 align-items-center text-center"}>
        <Skeleton variant={"circle"} width={60} height={42} className={"d-inline-block mb-1"}/>
        <Skeleton variant={"text"} width={90} height={24} className={"d-inline-block"}/>
        <Skeleton variant={"text"} width={105} height={32} className={"d-inline-block mb-1 mt-n2"}/>
    </Card>
}

class VerificationBox extends React.Component {
    render() {
        let {icon, status, type, title, loading} = this.props

        if (loading)
            return <VerificationBoxLoading/>

        return <Link to={"/cp/settings"}>
            <Card className={"padding-top-10 padding-bottom-5 padding-right-10 padding-left-10"}>
                                <span>
                                    {icon}
                                </span>
                <h5 className={"margin-top-5"}>{title}</h5>
                <Badge color={status ? "success" : "danger"} className={"mt-2"} style={{lineHeight: "20px"}}>
                    {status ? <>{react_intl.formatMessage({id:"client_dashboard.completed"})} <FaCheck/></> : <>{react_intl.formatMessage({id:"client_dashboard.pending"})} <FaTimes/></>}
                </Badge>
            </Card>
        </Link>;
    }
}

export default VerificationBox
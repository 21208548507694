import React from "react"
import classnames from "classnames";
import {injectIntl} from "react-intl";

class InstallmentBox extends React.Component {
    render() {
        let {total, count, currency, selected, extraInstallment} = this.props
        let {formatMessage}=this.props.intl
        let totalCount = count + extraInstallment;
        return (
            <label onClick={this.props.onClick} className={classnames({active: selected})}>
                <span className="installment-big">
                    <strong>{count === 1 ? formatMessage({id:"client.installment_box.cash"}) : `${count} `+ (extraInstallment > 0 ? " +"+extraInstallment+" ":"") +formatMessage({id:"client.installment_box.installment"})}</strong>
                </span>
                <span className={"installment-amounts"}>{currency.prefix}{(total/totalCount).toFixed(2)}{currency.suffix} x {totalCount}</span>
            </label>
        );
    }
}

export default injectIntl(InstallmentBox)

import React from "react"
import {
    email_shortcodes_base, email_shortcodes_company_info, email_shortcodes_system_config
} from "../../../EmailTemplateVariables";
import {ApiErrorThrow, default_lang, languages} from "../../../Config";
import {Button, Card, CardBody, CardFooter, Col, Input, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Link} from "react-router-dom";
import InputWithIcon from "../../../Elements/InputWithIcon";
import Select from "react-select";
import TemplateShortCodes from "../../../Components/TemplateShortCodes";
import {CKEditor} from 'ckeditor4-react';
import {matchPath} from "react-router";
import SettingModel from "../../../Models/SettingModel";
import {Select38Px} from "../../../Conts";
import {GetTitle} from "../../../Routes/AdminRoute";
import {Skeleton} from "@material-ui/lab";
import {react_intl} from "../../../index";
import {FormattedMessage} from "react-intl";

const getParamsEmail = (pathname) => {
    const matchProfile = matchPath(pathname, {
        path: `/admin/email-templates/:templateId`,
    });
    return (matchProfile && matchProfile.params) || {};
};
const getParamsSms = (pathname) => {
    const matchProfile = matchPath(pathname, {
        path: `/admin/sms-templates/:templateId`,
    });
    return (matchProfile && matchProfile.params) || {};
};

class EmailSmsTemplateSingle extends React.Component {
    template_type = ""
    url_slug = ""
    single_type_title = ""
    template_model = null
    templateId = null
    editor = null
    all_link = ""
    state = {
        data: {},
        shortCodeInsertTo: "content",
        custom_short_codes: [],
        global_shortcodes: [],
        loading: true
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.title = GetTitle(react_intl.formatMessage({id:"admin.email_sms_template_single.edit_template_title"}))
        let params

        if (this.template_type === "email")
            params = getParamsEmail(this.props.location.pathname)
        else
            params = getParamsSms(this.props.location.pathname)

        this.templateId = params.templateId
        if (this.templateId !== null)
            this.loadData()
    }

    loadData = () => {
        let {openSnackbar} = this.props
        if (typeof this.templateId === "undefined")
            return false;
        this.template_model.getById(this.templateId).then(r => r.json())
            .then(r => {
                if (typeof r.status === "boolean" && !r.status) {
                    if (typeof r.message === "string")
                        openSnackbar(r.message);
                    this.props.history.push(`/admin/${this.url_slug}`)
                    return false;
                }
                let _find_template = this.templates.find(item => item.key === r.data.attributes.type)
                let state = {}
                state['data'] = r.data.attributes
                if (typeof _find_template === "object")
                    state['custom_short_codes'] = _find_template.shortcodes
                else
                    state['custom_short_codes'] = []
                state['loading'] = false
                this.setState(state)
            })

        SettingModel.getByGroup('mail_global_short_codes').then(r => r.json())
            .then(r => {
                let {data} = r
                data = data.map(item => {
                    item = item.attributes
                    let _value = item.value
                    if (_value.length > 30)
                        _value = _value.substr(0, 27) + '...'
                    return {
                        slug: item.name,
                        title: react_intl.formatMessage({id:"admin.email_sms_template_single.edit_template_title"}, {value:_value})
                    }
                })
                this.setState({global_shortcodes: data})
            })
    }

    toggle = tab => {
        this.setState({
            shortCodeTab: tab
        })
    }

    render() {
        let {data} = this.state
        if (typeof data.language === "undefined")
            data.language = default_lang

        let shortCodes = [
            {
                label: react_intl.formatMessage({id:"admin.email_sms_template_single.basic"}),
                codes: email_shortcodes_base,
                icon: <i className={" icon-material-outline-reorder"}/>
            },
            {
                label: react_intl.formatMessage({id:"admin.email_sms_template_single.system"}),
                codes: email_shortcodes_system_config,
                icon: <i className={"icon-line-awesome-newspaper-o"}/>
            },
            {
                label: react_intl.formatMessage({id:"admin.email_sms_template_single.corporate"}),
                codes: email_shortcodes_company_info,
                icon: <i className={"icon-line-awesome-newspaper-o"}/>
            },
            {
                label: react_intl.formatMessage({id:"admin.email_sms_template_single.special_for_template"}),
                codes: this.state.custom_short_codes,
                icon: <i className={"icon-line-awesome-newspaper-o"}/>
            },
            {
                label: react_intl.formatMessage({id:"admin.email_sms_template_single.special_for_you"}),
                codes: this.state.global_shortcodes,
                icon: <i className={" icon-material-outline-account-circle"}/>
            }
        ]

        return <div>
            <Card>
                <div className={"card-header"}>
                    <Row>
                        <Col>
                            <div className="card-title">
                                <Link className={"btn btn-sm btn-dark mr-2"} to={`/admin/${this.all_link}`}><FormattedMessage id={"admin.email_sms_template_single.all_templates"}/></Link>
                                <h4 className={"mt-2"}><FormattedMessage id={"admin.email_sms_template_single.all_templates"} values={{type_title:this.single_type_title}}/></h4>
                            </div>
                        </Col>
                        <Col className={"text-right"}>
                            <TemplateShortCodes codes={shortCodes}/>
                            <p>
                                <small className={"font-italic"}><FormattedMessage id={"admin.email_sms_template_single.short_codes_msg"}/></small>
                            </p>
                        </Col>
                    </Row>
                </div>
                <CardBody>
                    <Row>
                        <Col>
                            <Label><FormattedMessage id={"admin.email_sms_template_single.title"}/></Label>
                            <InputWithIcon
                                icon={"icon-line-awesome-header"}
                                onChange={(e) => {
                                    let {data} = this.state
                                    data.title = e.target.value
                                    this.setState({data})
                                }}
                                type={"text"} value={data.title}/>
                        </Col>
                        <Col>
                            <Label><FormattedMessage id={"admin.email_sms_template_single.language"}/></Label>
                            <Select
                                styles={Select38Px}
                                onChange={(value) => {
                                    let {data} = this.state
                                    data.language = value.value
                                    this.setState({data})
                                }}
                                options={languages()}
                                value={languages().find(item => item.value === data.language)}/>
                        </Col>
                    </Row>

                    {this.template_type === "email" && <>
                        <p>&nbsp;</p>
                        <Row>
                            <Col sm={6}>
                                <Label><FormattedMessage id={"admin.email_sms_template_single.e_mail_subject"}/></Label>
                            </Col>
                            <Col sm={12}>
                                <Input type={"text"} name={"subject"}
                                       value={this.state.data.subject}
                                       onChange={(e) => {
                                           let data = this.state.data
                                           data.subject = e.target.value
                                           this.setState({
                                               data
                                           })
                                       }}/>
                            </Col>
                        </Row>
                    </>}


                    <>
                        <p>&nbsp;</p>
                        <Label><FormattedMessage id={"admin.email_sms_template_single.content"}/></Label>
                        {this.template_type === "sms" && <textarea className={"form-control"}
                                                                   key={this.editor}
                                                                   onChange={(event, editor) => {
                                                                       data.message = event.target.value
                                                                       this.setState({data})
                                                                   }}
                                                                   defaultValue={data.message}
                        />}
                        {this.template_type === "email" && <div className="page-content-editor">
                            {this.state.loading ? <Skeleton height={300}/> : <CKEditor
                                initData={data.mail_body || ""}
                                onInstanceReady={ckThis => {
                                    this.editor = ckThis.editor
                                }}
                                onChange={(event, editor) => {
                                    if (this.editor === null || Object.keys(data).length < 2)
                                        return;
                                    const editor_data = this.editor.getData();
                                    data.mail_body = editor_data
                                    this.setState({data})
                                }}
                            />}
                        </div>}
                    </>
                </CardBody>
                <CardFooter className={"text-right"}>
                    <Button onClick={() => this.save()} color={"primary"}><FormattedMessage id={"admin.email_sms_template_single.save"}/></Button>
                </CardFooter>
            </Card>
        </div>;
    }

    save = () => {
        let save_data = {...this.state.data}
        let {openSnackbar} = this.props
        delete save_data.id
        delete save_data.created
        delete save_data.updated
        this.template_model.modify(this.templateId, save_data).then(r => r.json())
            .then(r => {
                openSnackbar(<FormattedMessage id={"admin.email_sms_template_single.template_edited_msg"}/>)
            }).catch(r => ApiErrorThrow(r, openSnackbar))
    }
}

export default EmailSmsTemplateSingle
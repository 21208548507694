import React from 'react';
import Notifications from "./Notifications";
import {frontendBaseUrl} from "../../../Config";
import {FaUserAlt} from "react-icons/all";
import moment from "moment";
import {react_intl} from "../../../index";

class SmsNotifications extends Notifications {
    logsEnabled = true
    templatesEnabled = true
    settingsEnabled = true
    typeName = react_intl.formatMessage({id:"admin.notifications.sms.type_name"})
    typeSlug = "sms"
    title = react_intl.formatMessage({id:"admin.notifications.sms.title"})
    columns = [
        {
            Header: react_intl.formatMessage({id:"admin.notifications.sms.table_cols.sms_to"}),
            width: "25%",
            mobileWidth: "40%",
            Cell: data => {
                let row = data.row.original
                row = row.attributes
                return <div className={"d-flex"}>
                    <span className={"pr-2"}>{row.sms_to.join(", ")}</span>
                    {typeof row.user_id === "object" &&
                    <a target={"_blank"}
                       title={`${row.user_id.firstname} ${row.user_id.lastname}`}
                       href={`${frontendBaseUrl}/admin/clients/${row.user_id.id}`}><FaUserAlt/></a>}
                </div>
            }
        },
        {
            Header: react_intl.formatMessage({id:"admin.notifications.sms.table_cols.message"}),
            width: "50%",
            Cell: data => {
                let row = data.row.original
                row = row.attributes
                return <div>
                    {row.message}
                </div>
            }
        },
        {
            Header: react_intl.formatMessage({id:"admin.notifications.sms.table_cols.sent_time"}),
            width: "20%",
            Cell: data => {
                let row = data.row.original
                row = row.attributes
                return <div>
                    {moment(row.sent_date).format("DD.MM.YYYY HH:mm")}
                </div>
            }
        }
    ]
}

export default SmsNotifications
import GlRequest from "../Lib/GlRequest";

class AddressModel {
    create(clientId, post) {
        post = {
            data: {
                attributes: post
            }
        }
        return GlRequest.post(`/api/v2/user/${clientId}/address`, post, 'json')
    }

    modify(clientId, addressId, post) {
        post = {
            data: {
                attributes: post
            }
        }
        return GlRequest.patch(`/api/v2/user/${clientId}/address/${addressId}`, post, 'json')
    }

    getClientAddress(clientId) {
        return GlRequest.get(`/api/v2/user/${clientId}/address`)
    }

    delete(clientId, addressId) {
        return GlRequest.delete(`/api/v2/user/${clientId}/address/${addressId}`)
    }
}

export default AddressModel = new AddressModel()
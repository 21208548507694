import React from "react"
import {ApiErrorThrow, defaultSnackbarOptions} from "../../../Config";
import {withSnackbar} from "react-simple-snackbar";
import DataTable from "../../../Components/DataTable";
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Label, Modal,
    ModalBody, ModalFooter, ModalHeader,
    Row,
    Spinner
} from "reactstrap";
import InputWithIcon from "../../../Elements/InputWithIcon";
import {Alert} from "@material-ui/lab";
import CurrencyModel from "../../../Models/CurrencyModel";
import ConfirmationModalAlert from "../../../Components/ModalAlerts/ConfirmationModalAlert";
import {GetTitle} from "../../../Routes/AdminRoute";
import Switch from "@material-ui/core/Switch";
import {FormControlLabel} from "@material-ui/core";
import classnames from "classnames";
import moment from "moment";
import {AiFillInfoCircle, BiLinkExternal, GiEarrings, GoGear, GrClose, GrFormClose} from "react-icons/all";
import SystemSettingGroup from "../Settings/SystemSettings/SystemSettingGroup";
import {order_settings, settings} from "../Settings/SystemSettings";
import StaticPanelTopBar from "../../../Components/StaticPanelTopBar";
import SettingsSeparated from "../../../Components/SettingsSeparated";
import {react_intl} from "../../../index";
import {FormattedMessage} from "react-intl";


class Currencies extends React.Component {
    tableRef = React.createRef()
    columns = [
        {
            accessor: "attributes.code",
            Header: react_intl.formatMessage({id: "admin.payment.currencies.table_cols.code"}),
            width: "10%"
        },
        {
            accessor: "attributes.prefix",
            Header: react_intl.formatMessage({id: "admin.payment.currencies.table_cols.prefix"}),
            width: "15%"
        },
        {
            accessor: "attributes.suffix",
            Header: react_intl.formatMessage({id: "admin.payment.currencies.table_cols.suffix"}),
            width: "15%"
        },
        {
            accessor: null,
            Header: react_intl.formatMessage({id: "admin.payment.currencies.table_cols.rate"}),
            width: "25%",
            Cell: (data) => {
                let row = data.row.original, updated = ""
                row = row.attributes
                if (!row.is_default)
                    updated = <small
                        className={"d-block w-100 font-italic"}>
                        <FormattedMessage id={"admin.payment.currencies.table_cols.updated"}
                                          values={{human_time: moment(row.updated).fromNow()}}
                        />
                    </small>
                return <span>{row.rate}{updated}</span>
            }
        },
        {
            accessor: null,
            Header: react_intl.formatMessage({id: "admin.payment.currencies.table_cols.actions"}),
            Cell: (data) => {
                let row = data.row.original
                row = row.attributes
                return <ButtonGroup size={"sm"}>
                    <Button
                        onClick={() => {
                            this.setState({
                                new_currency: row
                            })
                        }}
                        color={"primary"}><FormattedMessage
                        id={"admin.payment.currencies.table_cols.actions.edit"}/></Button>
                    <Button onClick={() => {
                        this.setState({
                            new_currency: row
                        }, this.deleteModalToggle)
                    }} color={"danger"}><FormattedMessage
                        id={"admin.payment.currencies.table_cols.actions.delete"}/></Button>
                    {!row.is_default ? <Button
                        onClick={() => {
                            this.setState({
                                new_currency: row
                            }, this.defaultModalToggle)
                        }}
                        color={"success"}><FormattedMessage
                        id={"admin.payment.currencies.table_cols.actions.set_default"}/></Button> : ""}
                </ButtonGroup>
            }
        }
    ]

    emptyCurrency = {
        code: "",
        prefix: "",
        suffix: "",
        rate: "",
        currency_decimal: "",
        is_default: false
    }

    constructor(props) {
        super(props);
        this.handleNewCurrencyField = this.handleNewCurrencyField.bind(this)
        this.deleteModalToggle = this.deleteModalToggle.bind(this)
        this.defaultModalToggle = this.defaultModalToggle.bind(this)

    }

    state = {
        new_currency: {...this.emptyCurrency},
        editingCurrency: null,
        deleteModal: false,
        defaultModal: false,
        rateUpdating: false
    }

    handleNewCurrencyField = (e) => {
        let new_currency = this.state.new_currency
        new_currency[e.target.name] = e.target.value
        this.setState({
            new_currency
        })
    }


    saveCurrency = () => {
        let data = {...this.state.new_currency}
        let _id = data.id + ''
        delete data.id
        delete data.updated
        let {openSnackbar} = this.props
        CurrencyModel.modify(_id, data).then(r => r.json())
            .then(r => {
                this.tableRef.current.loadData()
                openSnackbar(react_intl.formatMessage({id: "admin.payment.currencies.edited"}))
                this.discardEditing()
            })
            .catch(r => ApiErrorThrow(r, openSnackbar))
    }

    addNewCurrency = () => {
        if (typeof this.state.new_currency.id !== "undefined")
            return this.saveCurrency()
        let {openSnackbar} = this.props
        CurrencyModel.create(this.state.new_currency).then(r => r.json())
            .then(r => {
                this.tableRef.current.loadData()
                this.setState({new_currency: this.emptyCurrency})
                openSnackbar(react_intl.formatMessage({id: "admin.payment.currencies.added"}))
            })
            .catch(r => ApiErrorThrow(r, openSnackbar))
    }

    deleteModalToggle = () => {
        this.setState({deleteModal: !this.state.deleteModal})
        if (this.state.deleteModal)
            this.discardEditing()
    }

    componentDidMount() {

        document.title = GetTitle(react_intl.formatMessage({id: "admin.payment.currencies.title"}))
    }

    render() {
        let {new_currency} = this.state
        let {openSnackbar} = this.props

        return <div>
            <ConfirmationModalAlert
                isOpen={this.state.defaultModal}
                toggle={this.defaultModalToggle}
                title={react_intl.formatMessage({id: "admin.payment.currencies.default_modal_title"})}
                description={react_intl.formatMessage({id: "admin.payment.currencies.default_modal_desc"}, {
                    code: new_currency.code
                })}
                icon={"icon-feather-alert-triangle"}
                buttonActionCancel={() => {
                    this.defaultModalToggle()
                    this.discardEditing()
                }}
                buttonAction={() => {
                    CurrencyModel.modify(new_currency.id, {
                        is_default: true
                    })
                        .then(r => r.json())
                        .then(r => {
                            this.tableRef.current.loadData()
                            this.discardEditing()
                            this.defaultModalToggle()
                            openSnackbar(react_intl.formatMessage({id: "admin.payment.currencies.default_changed"}))
                        })
                }}
            />

            <ConfirmationModalAlert
                isOpen={this.state.deleteModal}
                toggle={this.deleteModalToggle}
                title={react_intl.formatMessage({id: "admin.payment.currencies.delete_modal_title"})}
                icon={"icon-line-awesome-trash-o"}
                description={react_intl.formatMessage({id: "admin.payment.currencies.delete_modal_desc"},{
                    code: new_currency.code
                })}
                buttonActionCancel={() => {
                    this.deleteModalToggle()
                    this.discardEditing()
                }}
                buttonAction={() => {
                    CurrencyModel.delete(new_currency.id)
                        .then(r => {
                            this.tableRef.current.loadData()
                            this.discardEditing()
                            this.deleteModalToggle()
                            openSnackbar(react_intl.formatMessage({id: "admin.payment.currencies.deleted"}))
                        })
                        .catch(r => ApiErrorThrow(r, openSnackbar))
                }}
            />

            <StaticPanelTopBar
                title={react_intl.formatMessage({id: "admin.payment.currencies.top_title"})}
                extra={<div className={"padding-top-25 text-right"}>
                    <SettingsSeparated
                        settings={order_settings}
                        group={"currency_config"}
                        title={react_intl.formatMessage({id: "admin.payment.currencies.settings"})}
                    />
                </div>}
            />

            <Row className={"padding-top-20 padding-bottom-20 margin-top-100"}>
                <Col>
                    <Alert color={"info"} icon={<i className={"icon-feather-info"}/>}
                           action={<Button disabled={this.state.rateUpdating} color="dark" size="sm" onClick={() => {
                               this.setState({rateUpdating: true})
                               CurrencyModel.updateRates().then(r => r.json())
                                   .then(r => {
                                       openSnackbar(react_intl.formatMessage({id: "admin.payment.currencies.updated"}))
                                       this.setState({rateUpdating: false}, () => {
                                           this.tableRef.current.loadData()
                                       })
                                   })
                           }}>
                               <FormattedMessage id={"admin.payment.currencies.update_now"}/>
                           </Button>}>

                        <div dangerouslySetInnerHTML={{
                            __html: react_intl.formatMessage({id: "admin.payment.currencies.update_description"}, {
                                icon: "<a href='https://www.tcmb.gov.tr/kurlar/today.xml' target='_blank'><i class='icon-material-outline-visibility'></i></a>"
                            })
                        }}/>

                        {this.state.rateUpdating && <Spinner size={"sm"}/>}
                    </Alert>
                </Col>
            </Row>
            <Row>
                <Col sm={12} lg={4}>
                    <Card className={new_currency.id ? "bg-gray-300 shadow" : ""}>
                        <CardHeader
                            className={"bg-dark text-left text-white"}>{new_currency.id ?
                            <div className={"float-left"}><i className={"icon-line-awesome-pencil"}/> <FormattedMessage
                                id={"admin.payment.currencies.edit"}/>
                            </div> : react_intl.formatMessage({id: "admin.payment.currencies.add"})}
                            <Button className={classnames("float-right", {
                                "d-none": this.state.new_currency.code === ""
                            })} color={"dark"} size={"sm"}
                                    onClick={() => this.discardEditing()}>
                                <i className={"icon-line-awesome-times-circle-o"}/>
                            </Button>
                        </CardHeader>
                        <CardBody>
                            <form>
                                <InputWithIcon
                                    placeholder={react_intl.formatMessage({id: "admin.payment.currencies.currency_code"})}
                                    icon={"icon-material-outline-fingerprint"} name={"code"}
                                    value={new_currency.code}
                                    onChange={this.handleNewCurrencyField}
                                />
                                <InputWithIcon
                                    placeholder={react_intl.formatMessage({id: "admin.payment.currencies.prefix"})}
                                    icon={"icon-material-outline-keyboard-arrow-right"} name={"prefix"}
                                    value={new_currency.prefix}
                                    onChange={this.handleNewCurrencyField}
                                />
                                <InputWithIcon
                                    placeholder={react_intl.formatMessage({id: "admin.payment.currencies.suffix"})}
                                    value={new_currency.suffix}
                                    icon={"icon-material-outline-keyboard-arrow-left"} name={"suffix"}
                                    onChange={this.handleNewCurrencyField}
                                />
                                <InputWithIcon
                                    placeholder={react_intl.formatMessage({id: "admin.payment.currencies.rate"})}
                                    value={new_currency.rate}
                                    icon={"icon-material-outline-compare-arrows"} name={"rate"}
                                    onChange={this.handleNewCurrencyField}
                                />
                                <InputWithIcon
                                    type={"number"}
                                    placeholder={react_intl.formatMessage({id: "admin.payment.currencies.decimal"})}
                                    min={1}
                                    max={6}
                                    value={new_currency.currency_decimal}
                                    icon={"icon-line-awesome-calculator"} name={"currency_decimal"}
                                    onChange={this.handleNewCurrencyField}
                                />
                            </form>
                        </CardBody>
                        <CardFooter className={"text-center"}>
                            <Button color={"primary"} onClick={() => this.addNewCurrency()}><FormattedMessage
                                id={"admin.payment.currencies.save"}/></Button>
                        </CardFooter>
                    </Card>
                </Col>
                <Col sm={12} lg={8} style={{
                    opacity: new_currency.id ? "0.3" : "1",
                    userSelect: new_currency.id ? "none" : ""
                }}
                     onClick={() => {
                         if (new_currency.id) {
                             openSnackbar(react_intl.formatMessage({id: "admin.payment.currencies.save_first"}))
                             return;
                         }
                     }}
                >
                    <div
                        style={{
                            pointerEvents: new_currency.id ? "none" : ""
                        }}
                    >
                        <DataTable
                            sortable={true}
                            pageSize={25}
                            order={"asc"}
                            ref={this.tableRef}
                            dataQuery={{
                                url: "/currency",
                                filters: {}
                            }}
                            columns={this.columns}
                        />
                    </div>
                </Col>
            </Row>
        </div>;
    }

    discardEditing() {
        this.setState({
            new_currency: this.emptyCurrency,
            editingCurrency: null
        });
    }

    defaultModalToggle() {
        this.setState({
            defaultModal: !this.state.defaultModal
        })
        if (this.state.defaultModal)
            this.discardEditing()
    }

}

export default withSnackbar(Currencies, defaultSnackbarOptions)
import classnames from "classnames";
import {Link} from "react-router-dom";
import React from "react";
import {AdminMenu} from "../../../Config";
import {injectIntl} from "react-intl";

class AdminMenuComp extends React.Component {
    render() {
        let {changeMenu, openedMenu, selectedMenu, changeSelectedMenu} = this.props
        let {formatMessage} = this.props.intl
        return AdminMenu().map(item => {
            return <ul
                key={`submenu-${item.label}`}
                data-submenu-title={typeof item.label === "string" ? item.label : formatMessage({id:(item.label).props.id})}>
                {item.items.map(item_sub1 => {
                    let sub_items = (typeof item_sub1.items === "object" && item_sub1.items.length > 0) ? item_sub1.items : [];
                    return <li key={`menu-${item_sub1.slug}`} className={classnames({
                        "active-submenu": (openedMenu === item_sub1.slug) || (sub_items.find(subItem => subItem.slug === openedMenu)),
                        "active": openedMenu === item_sub1.slug
                    })}>
                        <Link
                            to={(typeof item_sub1.href === "string" ? item_sub1.href : "#")}
                            onClick={() => {
                                changeMenu(item_sub1.slug, this.props.menuChanged)
                            }}>
                            {typeof item_sub1.icon === "string" ? <i className={item_sub1.icon}/> : typeof item_sub1.icon === "object" ? item_sub1.icon : ""}
                            {item_sub1.label}
                        </Link>
                        {sub_items.length ? <ul>
                            {sub_items.map(item_sub2 => {
                                return <li
                                    key={`li-${item_sub2.label}`}
                                ><Link
                                    key={`link-${item_sub2.slug}`}
                                    className={selectedMenu.href === item_sub2.href ? "active" : ""}
                                    onClick={() => {
                                        changeSelectedMenu(item_sub2, this.props.menuChanged)
                                    }}
                                    to={(typeof item_sub2.href === "string" ? item_sub2.href : "#")}>{item_sub2.label}</Link>
                                </li>
                            })}
                        </ul> : ""}
                    </li>
                })}
            </ul>
        })
    }
}

export default injectIntl(AdminMenuComp)
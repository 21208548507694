import React from "react";

class StaticPanelTopBar extends React.Component{
    render() {
        let {title, extra} = this.props
        return <div className="page-filter-bar pl-3 pr-3 pt-2 h100px">
            <div className="row">
                <div className="d-block d-md-none text-right col-sm-12">
                    <button type="button" className="btn-rounded-circle btn btn-danger btn-sm"><i
                        className="icon-line-awesome-close" /></button>
                </div>
                <div className="col-sm-12">
                    <div className="title-box">
                        <div className="row">
                            <div className="col-sm-12 col-lg-6"><h2 className="rounded pt-3">{title}</h2></div>
                            <div className="col-sm-12 col-lg-6">{extra || <small className="w-100 text-right float-left pr-1"></small>}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}
export default StaticPanelTopBar
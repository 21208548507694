import React from "react"
import SmsTemplateModel from "../../../../Models/SmsTemplateModel";
import {
    Alert,
    Button,
    Card,
    CardBody,
    Modal,
    ModalBody,
    ModalHeader, Table,
    UncontrolledTooltip
} from "reactstrap";
import moment from "moment"
import ConfirmationModalAlert from "../../../../Components/ModalAlerts/ConfirmationModalAlert";
import {withSnackbar} from "react-simple-snackbar";

export const SingleSmsLog = props => {
    let {icon, attributes, show, repeat, msgprop} = props
    if(typeof msgprop === "undefined")
        msgprop = "message"
    let msg = attributes[msgprop]
    let l = 70
    if (msg.length > l)
        msg = msg.substr(0, l - 3) + '...'

    return <>
        <Card
            onClick={() => {
                props.show(attributes)
            }}
            className={"mb-1 single-sms-history cursor-pointer"}>
            <CardBody className={"p-2"}>
                <div className={"d-flex"}>
                    <i className={icon + " text-dark rounded text-center p-2"}
                       style={{fontSize: 27}}/>
                    <div className={"w-100 pl-1 ml-1 border-left"}>
                        <span className={"text-muted d-inline-block w-100"} style={{height: 40}}>{msg}</span>
                        <p className={"mb-0"} style={{opacity: 0.5, fontSize: 11}}><i
                            className={"icon-feather-calendar"}/> {moment(attributes.sent_date).format("DD/MM/YYYY HH:mm")}
                        </p>
                    </div>
                    <small className={"pt-3 text-info text-center"}>
                        {typeof show === "function" && <>
                            <UncontrolledTooltip target={`message-${props.id}-show`}>Detaylar</UncontrolledTooltip>
                            <a onClick={() => {
                                props.show(attributes)
                            }} id={`message-${props.id}-show`}
                               className={"rounded mr-2 bg-dark text-white p-1"}><i
                                className={"icon-feather-zoom-in"}/></a>
                        </>}
                        {typeof repeat === "function" && <>
                            <UncontrolledTooltip target={`message-${props.id}-reply`}>Tekrar
                                Gönder</UncontrolledTooltip>
                            <a onClick={() => props.repeat(attributes)} id={`message-${props.id}-reply`}
                               className={"rounded mr-2 bg-info text-white p-1"}><i className={" icon-feather-repeat"}/></a>
                        </>}
                    </small>
                </div>
            </CardBody>
        </Card>
    </>
}
const SmsHistoryNotFound = () => {
    return <div className={"text-center"}>
        <i style={{fontSize: 21}} className={"icon-feather-phone-off"}></i>
        <span className={"d-block"} style={{fontSize: 18}}>SMS Bulunmuyor</span>
    </div>
}

class SmsHistoryWidget extends React.Component {
    state = {
        sms_history: [],
        active_history: {},
        repeat_modal: false,
        show_modal: false
    }

    repeatToggle = (details) => {
        let _state = {}

        if (typeof details === "object")
            _state['active_history'] = details
        else _state['active_history'] = {}
        _state['repeat_modal'] = typeof details === "object" && Object.keys(details).length
        this.setState(_state)
    }

    loadData = () => {
        let user_id = this.props.user_id
        SmsTemplateModel.history({
            user_id
        })
            .then(r => r.json())
            .then(r => {
                this.setState({
                    sms_history: r.data
                })
            })
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let {sms_history, repeat_modal, show_modal, active_history} = this.state
        if (typeof active_history !== "object")
            active_history = {}
        let {openSnackbar} = this.props
        return <>

            <Modal isOpen={this.state.show_modal} toggle={() => this.showMessage()}>
                <ModalHeader toggle={() => this.showMessage()}>Mesaj Görüntüleme</ModalHeader>
                <ModalBody>
                    <Table>
                        <tbody>
                        <tr>
                            <th>Alıcı:</th>
                            <td>{typeof active_history.sms_to === "object" && (active_history.sms_to).join(", ")}</td>
                        </tr>
                        <tr>
                            <th>Gönderilme Tarihi:</th>
                            <td>{typeof active_history.sent_date === "string" && moment(active_history.sent_date).format("DD/MM/YYYY HH:mm")}</td>
                        </tr>
                        <tr>
                            <th>Mesaj:</th>
                            <td>{typeof active_history.message === "string" && active_history.message}</td>
                        </tr>
                        </tbody>
                    </Table>
                    <div className={"text-center"}>
                        <Button size={"sm"} onClick={() => {
                            this.repeatToggle(active_history)
                            this.setState({show_modal: false})
                        }}>Tekrar Gönder</Button>
                    </div>
                </ModalBody>
            </Modal>

            <ConfirmationModalAlert
                isOpen={repeat_modal}
                toggle={() => this.repeatToggle()}
                title={`Mesajı Tekrar Gönder`}
                color={"warning"}
                buttonAction={() => {
                    SmsTemplateModel.repeat(this.state.active_history.id)
                        .then(r => r.json())
                        .then(r => {
                            openSnackbar("Mesaj gönderildi!")
                            this.repeatToggle()
                            this.loadData()
                        })
                }}
            >
                {`Mesaj tekrar gönderilecektir. Onaylıyor musunuz?`}
                <Alert color={"primary"}>
                    <i className={"icon-feather-info"}/> <strong>İÇERİK:</strong>{" "}
                    {active_history.message}
                </Alert>
            </ConfirmationModalAlert>

            <div style={{backgroundColor: "#e4f2f0"}} className={"pt-2"}>
                <div className={"pl-2 pr-2 pb-2 w-100"}>
                    {sms_history.length === 0 ? <SmsHistoryNotFound/> : sms_history.map(item => <SingleSmsLog
                        icon={"icon-feather-message-square"}
                        show={() => {
                            this.showMessage(item.attributes)
                        }}
                        repeat={() => {
                            this.repeatToggle(item.attributes)
                        }} {...item} />)}
                </div>
            </div>
        </>;
    }

    showMessage(attributes) {
        this.setState({
            active_history: attributes,
            show_modal: typeof attributes === "object"
        })
    }
}

export default withSnackbar(SmsHistoryWidget)
import React from "react";
import {defaultSnackbarOptions} from "../../../../Config";
import {withSnackbar} from "react-simple-snackbar";
import Detail from "../MainType/Detail";
import {react_intl} from "../../../../index";

class Create extends Detail {
    title = react_intl.formatMessage({id:"admin.content.pages.create.title"})
    thumbnail = false
    action = "create"
    content_type = "page"
    redirect_url = "/admin/pages/:id"
    pageId = null
    back_url = "/admin/pages"
    back_text = react_intl.formatMessage({id:"admin.content.pages.create.back_text"})
    save_button = react_intl.formatMessage({id:"admin.content.pages.create.add"})
    post_loading = false
}
export default withSnackbar(Create, defaultSnackbarOptions)
import React from "react";
import {Badge, Button, Col, Row, UncontrolledTooltip} from "reactstrap";
import SystemSettingItem from "./SystemSettingItem";
import SettingModel from "../../../../Models/SettingModel";
import {func} from "prop-types";
import ButtonLoad from "../../../../Elements/ButtonLoad";
import {FormattedMessage} from "react-intl";
import {react_intl} from "../../../../index";

class SystemSettingGroup extends React.Component {

    state = {
        settings: [],
        saving: false
    }

    save = () => {
        this.setState({
            saving: true
        })
        let save_data = {}
        save_data[this.props.group_slug] = {}
        save_data[this.props.group_slug] = this.state.settings
        SettingModel.bulkEdit(save_data)
            .then(r => r.json())
            .then(r => {
                this.setState({saving: false}, () => {
                    if (typeof this.props.onSave === "function")
                        this.props.onSave();
                })
            });
    }


    loadSettings = async () => {
        let {group_slug} = this.props
        let saved_settings = {}
        await SettingModel.getByGroup(group_slug).then(r => r.json())
            .then(r => {
                (r.data).map(item => {
                    let {attributes} = item
                    saved_settings[attributes.name] = attributes.value
                })
                this.setState({
                    settings: saved_settings
                })
            })
    }

    componentDidMount() {
        if (typeof this.props.single === "boolean" && this.props.single) {
            this.loadSettings().then(r => "")
        }
    }

    inputHandle = (e) => {
        let settings = Object.assign({}, this.state.settings)
        let {name, value, type, checked} = e.target
        if (type === "checkbox")
            value = checked ? "yes" : "no"
        settings[name] = value

        this.setState({
            settings
        })
    }

    render() {
        let {single, handler, values, label, settings, description, slug, column} = this.props
        if (typeof column !== "number")
            column = 2

        if (single) {
            values = this.state.settings
            handler = this.inputHandle
        }

        return <div className={"mb-3"}>
            {!single && <Row>
                <Col>
                    <div className={"bg-white text-dark rounded border pl-2 pr-2 p-3 mb-1"}>
                        <h4 className={"d-inline"}>{label}</h4>
                        <div className={"d-inline"}>
                            <UncontrolledTooltip target={`reset-settings-${slug}`}><FormattedMessage
                                id={"admin.system_setting_group.reset_group"}/></UncontrolledTooltip>
                            <Button size={"sm"} color={"link"} className={""} id={`reset-settings-${slug}`}
                                    onClick={() => {
                                        if (typeof this.props.selectHandler === "function") {
                                            this.props.selectHandler(slug)
                                        }
                                    }}
                            >
                                <i style={{fontSize: 13, marginTop: "-10px"}} className={"icon-feather-rotate-ccw"}/>
                            </Button>
                            {(slug === "mailer_config" || slug === "sms_config") &&
                            <Button
                                onClick={this.props.shortcodeButtonToggle}
                                size={"sm"} className={"float-right"} color={"primary"}><FormattedMessage
                                id={"admin.system_setting_group.external_short_codes"}/> <i
                                className={"icon-line-awesome-star"}/></Button>}
                        </div>
                        {description && <div className={"w-100"}>
                            <small className={"font-italic text-muted"}>{description}</small>
                        </div>}
                    </div>
                </Col>
            </Row>}
            <div className={"border rounded p-2 bg-light"}>
                <table className={"w-100"} cellPadding={5}>
                    <tbody>
                    {settings.map(item => {
                        return <SystemSettingItem
                            column={column}
                            key={`setting-item-${item.slug}`}
                            group={slug}
                            value={values[item.slug]}
                            {...item}
                            handler={handler}
                        />
                    })}
                    </tbody>
                </table>
            </div>
            {this.props.single && <div className={"text-right mt-2 mb-2"}>
                <ButtonLoad
                    onClick={this.save}
                    color={"dark"} text={react_intl.formatMessage({id: "admin.system_setting_group.save"})}
                    loading={this.state.saving}/>
            </div>}
        </div>;
    }
}

export default SystemSettingGroup
import React from "react"
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {FixedSizeList} from 'react-window';
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Col,
    Collapse,
    Label,
    Row,
    Spinner, Table
} from "reactstrap";
import Select from "react-select";
import {AccountStatuses, AccountTypes, Select38Px} from "../../../Conts";
import MultiRadio from "../../../Elements/MultiRadio";
import ClientGroups from "../Settings/ClientGroups";
import ClientModel from "../../../Models/ClientModel";
import GlSelect, {react_select_zIndex2_style, react_select_zIndex99_style} from "../../../Components/GlSelect";
import InputWithIcon from "../../../Elements/InputWithIcon";
import CustomCheckbox from "../../../Elements/CustomCheckbox";
import GlRequest from "../../../Lib/GlRequest";
import FilterResult from "./FilterResult";
import classnames from "classnames";
import TemplateModel from "../../../Models/TemplateModel";
import EmailTemplateModel from "../../../Models/EmailTemplateModel";
import CurrencyModel from "../../../Models/CurrencyModel";
import SmsTemplateModel from "../../../Models/SmsTemplateModel";
import {Link} from "react-router-dom";
import {withSnackbar} from "react-simple-snackbar";
import {ApiErrorThrow, defaultSnackbarOptions, languages} from "../../../Config";
import {Sms} from "@material-ui/icons";
import SendSmsEmailModel from "../../../Models/SendSmsEmailModel";
import CircularProgress from '@material-ui/core/CircularProgress';
import {Box, List, ListItem, ListItemText, Typography} from "@material-ui/core";
import LinearProgress from '@material-ui/core/LinearProgress';
import {Alert, AlertTitle} from "@material-ui/lab";
import ButtonLoad from "../../../Elements/ButtonLoad";
import StaticPanelTopBar from "../../../Components/StaticPanelTopBar";
import {GetTitle} from "../../../Routes/AdminRoute";
import {FormattedMessage} from "react-intl";
import {react_intl} from "../../../index";

const load_template__by_id = (id, self) => {
    self.setState({
        loadingTemplate: true
    })

    let model = EmailTemplateModel
    if (self.state.sendType === "sms")
        model = SmsTemplateModel
    model.getById(id).then(r => r.json())
        .then(r => {
            self.setState({
                loadingTemplate: false,
                selectedTemplateBody: (self.state.sendType === "email") ? r.data.attributes.mail_body : r.data.attributes.message,
                selectedTemplateSubject: r.data.attributes.subject,
                selectedTemplate: r.data.attributes.type,
                selectedTemplateId: r.data.attributes.id
            })
        })
}

const NoUserFound = (props) => {
    return <>
        <Alert color={"alert"}>
            <h1>
                <i className={"icon-feather-user-minus"}/> <FormattedMessage id={"admin.mailer.user_not_found"}/>
            </h1>
            <p><FormattedMessage id={"admin.mailer.user_not_found_desc"}/></p>
        </Alert>
    </>
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 400,
        maxWidth: 300,
        backgroundColor: theme.palette.background.paper,
    },
}));

renderRow.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
};

const CompletedStep = (props) => {
    return <>
        <Alert>
            <i className={"icon-box-check"}/>
            <FormattedMessage id={"admin.mailer.sending_started"}/></Alert>
    </>
}

const CheckStep = (props) => {
    let {self} = props
    let {selectedTemplateSubject, selectedTemplateBody} = self.state
    return <>
        <Table>
            <tbody>
            {self.state.sendType === "email" && <tr>
                <th><FormattedMessage id={"admin.mailer.email_subject"}/></th>
                <td>{selectedTemplateSubject}</td>
            </tr>}
            <tr>
                <th><FormattedMessage
                    id={self.state.sendType !== "sms" ? "admin.mailer.email_content" : "admin.mailer.sms_content"}/>
                </th>
                <td style={{pointerEvents: "none"}}>
                    <div className={"w-100 bg-light border rounded p-3"}
                         dangerouslySetInnerHTML={{__html: selectedTemplateBody}}/>
                </td>
            </tr>
            <tr>
                <th><FormattedMessage id={"admin.mailer.send_list"}/></th>
                <td>
                    <div className={"p-3 border rounded bg-light"}
                         style={{overflow: "scroll", overflowX: "hidden", height: 400, width: "100%"}}>
                        <ResultStep preview={true} self={self}/>
                    </div>
                </td>
            </tr>
            </tbody>
        </Table>
    </>
}

const SelectTemplate = (props) => {
    let {self} = props
    let {templates, loadingTemplate} = self.state
    return <>
        <table width={"100%"} border={0} cellPadding={10} cellSpacing={0}>
            <tbody>
            <tr>
                <th width={"40%"}>
                    <FormattedMessage id={"admin.mailer.email_template_select"}/>
                    {loadingTemplate ? <Spinner className={"ml-1"} size={"sm"}/> : ""}
                </th>
                <td>
                    {<GlSelect
                        url={'/api/v2/template/' + self.state.sendType + '?language=tr&status=1'}
                        placeholder={react_intl.formatMessage({id: "admin.mailer.select_placeholder"})}
                        closeMenuOnSelect={true}
                        onChange={async (value) => {
                            load_template__by_id(value.value, self)
                        }}
                    />}
                </td>
            </tr>
            {self.state.selectedTemplateBody ? <tr>
                <th width={"40%"}><FormattedMessage id={ self.state.sendType === "email" ? "admin.mailer.email_content" : "admin.mailer.sms_content"}/> {self.state.loadingTemplate &&
                <Spinner size={"sm"} color={"dark"}/>}</th>
                <td>
                    <div className={"w-100 bg-light border rounded p-3"}
                         dangerouslySetInnerHTML={{__html: self.state.selectedTemplateBody}}/>
                    <div className={"text-center p-2"}>
                        <Link target={"_blank"}
                              className={"btn btn-sm btn-primary"}
                              to={`/admin/${self.state.sendType}-templates/${self.state.selectedTemplateId}`}><i
                            className={"icon-feather-edit"}/> <FormattedMessage
                            id={"admin.mailer.edit_template"}/></Link>
                        {" "}
                        <Button
                            onClick={() => {
                                load_template__by_id(self.state.selectedTemplateId, self)
                            }}
                            color={"primary"} size={"sm"}>
                            <i className={"icon-feather-refresh-ccw"}/>
                            <FormattedMessage id={"admin.mailer.reload_template"}/></Button>
                    </div>
                </td>
            </tr> : null}</tbody>
        </table>
    </>
}

const ResultStep = (props) => {
    let {self, preview} = props
    let {results, selected_ids} = self.state
    if (typeof preview !== "boolean")
        preview = false

    let selectedAll = selected_ids.length === results.length

    return <div>
        {!preview && results.length > 0 &&
        <Label className={classnames("bg-light p-2 ml-n2 rounded", {"border": selectedAll})}>
            <CustomCheckbox
                style={{pointerEvents: "none"}}
                checked={selectedAll}
                onChange={(e) => {
                    self.setState({selected_ids: !e.target.checked ? [] : results.map(item => item.id)});
                }}/>
            {" "}
            {selectedAll ? react_intl.formatMessage({id: "admin.mailer.uncheck_all"}) : react_intl.formatMessage({id: "admin.mailer.check_all"})}
        </Label>}
        {results.length === 0 && <NoUserFound/>}
        {
            results.map((item, i) => (!preview || (preview && selected_ids.indexOf(item.id) > -1)) && <FilterResult
                key={`Result-${i}`}
                preview={preview}
                selected={selected_ids.indexOf(item.id) > -1}
                onSelect={(e) => {
                    if (preview)
                        return false
                    let selected = [...selected_ids]
                    if (e.target.checked)
                        selected.push(item.id)
                    else {
                        const index = selected.indexOf(item.id);
                        if (index > -1) {
                            selected.splice(index, 1);
                        }
                    }
                    self.setState({selected_ids: selected})
                }}
                {...item} />)
        }</div>
}
const FilterForm = (props) => {
    let {filters, handle, self} = props
    return <div>
        <div className={classnames("p-3 rounded bg-light border", {
            "border-dark": self.state.sendType === "email",
            "border-success": self.state.sendType === "sms"
        })}>
            <Row>
                <Col className={"text-center"}>
                    <h3 className={"mb-2"}><FormattedMessage id={"admin.mailer.send_type"}/></h3>
                    <MultiRadio
                        options={sendTypes()}
                        value={self.state.sendType}
                        onChange={(e) => {
                            self.setState({sendType: e.target.value});
                        }}
                    />
                </Col>
            </Row>
        </div>

        <Table className={"mailer-filter-table"}>
            <tbody>
            <tr>
                <th><i className={" icon-material-outline-account-circle"}/> <FormattedMessage
                    id={"admin.mailer.account_type"}/></th>
                <td><Select
                    // menuIsOpen={true}
                    onChange={(e) => {
                        handle({
                            target: {
                                name: "account_type",
                                value: e.value
                            }
                        })
                    }} options={AccountTypes}
                    value={AccountTypes.find(item => item.value === filters.account_type)}/></td>
                <th>
                    <i className={" icon-feather-toggle-left"}/>
                    {" "}<FormattedMessage id={"admin.mailer.account_status"}/>
                </th>
                <td>
                    <MultiRadio
                        name={"enabled"}
                        onChange={(e) => {
                            self.handle(e)
                        }}
                        options={AccountStatuses}
                        value={filters.enabled}
                    />
                </td>
            </tr>
            <tr>
                <th><i className={" icon-material-outline-group"}/> <FormattedMessage id={"admin.mailer.user_group"}/>
                </th>
                <td>
                    <GlSelect
                        // menuIsOpen={true}
                        isMulti={true}
                        url={'/api/v2/user-group'}
                        placeholder={react_intl.formatMessage({id: "admin.mailer.user_group_placeholder"})}
                        onChange={(e) => {
                            handle({
                                target: {
                                    name: "user_group",
                                    value: e.map(item => item.value).join(",")
                                }
                            })
                        }}
                        isClearable={true}
                        searchKey={"name"}
                    /></td>
                <th><i className={" icon-line-awesome-language"}/> <FormattedMessage id={"admin.mailer.user_lang"}/>
                </th>
                <td>
                    <Select
                        styles={Select38Px}
                        onChange={(e) => {
                            if (e === null)
                                e = {
                                    value: ""
                                }
                            let filters = {...self.state.filters}
                            filters['language'] = e.value;
                            self.setState({filters})
                        }}
                        options={languages()}
                        isClearable={true}
                        searchKey={"name"}
                    />
                </td>
            </tr>
            <tr>
                <th><i className={" icon-line-awesome-sort-asc"}/> <FormattedMessage
                    id={"admin.mailer.payment_total_bigger"}/></th>
                <td><InputWithIcon type="number" name={"payment_total_bigger"} onChange={handle}/></td>
                <th><i className={" icon-line-awesome-sort-desc"}/> <FormattedMessage
                    id={"admin.mailer.payment_total_smaller"}/></th>
                <td><InputWithIcon type="number" name={"payment_total_smaller"} onChange={handle}/></td>
            </tr>
            <tr>
                <th><i className={" icon-line-awesome-ban"}/> <FormattedMessage
                    id={"admin.mailer.have_canceled_order"}/></th>
                <td><CustomCheckbox value={1} name={"have_canceled_order"} onChange={handle}/></td>
                <th><i className={" icon-material-outline-undo"}/> <FormattedMessage
                    id={"admin.mailer.have_refunded_order"}/></th>
                <td><CustomCheckbox value={1} name={"have_refunded_order"} onChange={handle}/></td>
            </tr>
            <tr>
                <th><i className={" icon-line-awesome-flag-checkered"}/> <FormattedMessage
                    id={"admin.mailer.have_fully_paid_order"}/></th>
                <td><CustomCheckbox value={1} name={"have_fully_paid_order"} onChange={handle}/></td>
                <th><i className={" icon-line-awesome-hourglass"}/> <FormattedMessage
                    id={"admin.mailer.have_unpaid_order"}/></th>
                <td><CustomCheckbox value={1} name={"have_unpaid_order"} onChange={handle}/></td>
            </tr>
            </tbody>
        </Table>
    </div>
}

function renderRow(props) {
    const {index, style, data} = props;
    let d = data[index]
    return (
        <ListItem button style={style} key={index} className={""}>
            <ListItemText primary={`#${d.id} ${d.firstname} ${d.lastname} <${d.email}>`}/>
        </ListItem>
    );
}

const defaultSettings = {
    "language": "",
    "enabled": "1",
    "user_group": "",
    "role": "ROLE_USER",
    "account_type": "",
    "sms_verification": "",
    "email_verification": "",
    "payment_total_bigger": "",
    "payment_total_smaller": "",
    "have_canceled_order": "",
    "have_refunded_order": "",
    "have_fully_paid_order": "",
    "have_unpaid_order": ""
}

const sendTypes = () => {
    return [
        {
            label: react_intl.formatMessage({id: "admin.mailer.send_type.sms"}),
            value: "sms",
            color: "success"
        },
        {
            label: react_intl.formatMessage({id: "admin.mailer.send_type.email"}),
            value: "email",
            color: "dark"
        }
    ]
}

const LinearProgressWithLabel = (props) => {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const CircularProgressWithLabel = (props) => {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

class Mailer extends React.Component {
    locked = false
    state = {
        loadingTemplate: false,
        sendType: "email",
        templates: [],
        selectedTemplate: "",
        selectedTemplateId: "",
        filters: {...defaultSettings},
        filtering: false,
        results: [],
        selected_ids: [],
        showResult: false,
        activeStep: 0,
        selectedTemplateBody: "",
        selectedTemplateSubject: "",
        busInQuery: 0,
        busTotalFailed: 0,
        busTotalCompleted: 0,
        busTotal: 0,
        clearLoading: false,
        failedList: []
    }

    busInterval = null

    handle = (e) => {
        let filters = {...this.state.filters}

        if (e.target.type === "checkbox" && typeof e.target.checked === "boolean" && !e.target.checked)
            e.target.value = ""

        filters[e.target.name] = e.target.value
        this.setState({filters: filters})
    }

    constructor(props) {
        super(props);
        this.handle = this.handle.bind(this)
    }

    getSteps = () => {
        return [
            {
                title: react_intl.formatMessage({id: "admin.mailer.user_filter"}),
                content: <FilterForm
                    self={this}
                    handle={this.handle}
                    filters={this.state.filters}
                />,
                next_action: function (self) {
                    self.applyFilter()
                }
            },
            {
                title: react_intl.formatMessage({id: "admin.mailer.select_users"}),
                content: <ResultStep
                    results={this.state.results}
                    self={this}
                    preview={false}
                    selected_ids={this.state.selected_ids}
                />,
                prev_action: function (self) {
                    self.firstStep()
                },
                next_action: function (self) {
                    if (typeof self.state.selected_ids === "object" && self.state.selected_ids.length === 0) {
                        alert(react_intl.formatMessage({id: "admin.mailer.set_template"}))
                        return false;
                    }
                    self.loadTemplates()
                }
            },
            {
                title: <FormattedMessage id={"admin.mailer.template_select"}/>,
                content: <SelectTemplate self={this}/>,
                next_action: function (self) {
                    if (self.state.selectedTemplate === "") {
                        alert(react_intl.formatMessage({id: "admin.mailer.select_template_to_next_step"}))
                    } else {
                        self.nextStep();
                    }
                }
            },
            {
                title: react_intl.formatMessage({id: "admin.mailer.approve_sending"}),
                content: <CheckStep self={this}/>,
                next_action: function (self) {
                    let _ids = self.state.selected_ids
                    SendSmsEmailModel.sendBulk(self.state.sendType, self.state.selectedTemplate, _ids)
                        .then(r => r.json())
                        .then(r => {
                            if (r.status) {
                                self.nextStep()
                                setTimeout(function () {
                                    self.getBusStatus()
                                }, 1000);
                            }
                        }).catch(r => ApiErrorThrow(r))
                }
            },
            {
                title: react_intl.formatMessage({id: "admin.mailer.sent_step"}),
                content: <CompletedStep/>
            }
        ]
    }

    resetFilter = () => {
        this.setState({
            filtering: false,
            results: [],
            selected_ids: []
        }, this.prevStep)
    }

    applyFilter = () => {
        this.setState({filtering: true})
        let filters = {...this.state.filters}
        let filterParams = {}

        Object.keys(filters).forEach(key => {
            if (filters[key] !== "" && filters[key] !== "all")
                filterParams[key] = filters[key]
        })

        SendSmsEmailModel.getMailingList(filterParams).then(r => r.json())
            .then(r => {
                this.setState({
                    filtering: false,
                    results: r.data.attributes,
                    showResult: true,
                    selected_ids: (r.data.attributes).map(item => item.id)
                }, this.nextStep)
            })
    }

    componentDidMount() {
        document.title = GetTitle(react_intl.formatMessage({id: "admin.mailer.title"}))
        this.getBusStatus()
        let self = this
        this.busInterval = setInterval(function () {
            self.getBusStatus()
        }, 7.5 * 1000)
    }

    componentWillUnmount() {
        clearInterval(this.busInterval)
    }

    getBusStatus = () => {
        SendSmsEmailModel.busStatus().then(r => r.json())
            .then(r => {
                let data = r.data.attributes
                this.setState({
                    busInQuery: parseInt(data.in_query),
                    busTotalFailed: parseInt(data.total_failed),
                    busTotal: parseInt(data.total),
                    busCompleted: parseInt(data.completed),
                    failedList: data.failed_list || [],
                    clearLoading: false
                })
            })
    }

    sendBulkMail = () => {
        let {results, selected_ids, activeStep} = this.state
        let buttontext = react_intl.formatMessage({id: "admin.mailer.next"})
        if (activeStep === this.getSteps().length - 2)
            buttontext = react_intl.formatMessage({id: "admin.mailer.send_label"})
        return <div className={""}>
            {this.getSteps().map((item, key) => <Collapse
                key={`Step-${key}`}
                isOpen={key === this.state.activeStep}
            >
                <Card className={"border-0 shadow"}>
                    <CardBody className={"border-0"}>

                        <h3 className={"pb-5 border-bottom mb-3"}>{item.title} {results.length > 0 &&
                        <div className={"float-right text-right"}>
                            <div className={"d-flex"}>
                                <div className={"p-2 rounded bg-success text-white"}>
                                    <i className={" icon-feather-check-circle"}/>
                                    <FormattedMessage id={"admin.mailer.x_selected"}
                                                      values={{number: selected_ids.length}}/>
                                </div>
                                <div className="p-2 bg-light text-dark">
                                    <FormattedMessage id={"admin.mailer.total_result"}
                                                      values={{number: results.length}}/>
                                </div>
                            </div>
                        </div>}</h3>

                        {key === this.state.activeStep && item.content}
                    </CardBody>
                    <CardFooter className={"text-right"}>
                        <Row>
                            <Col className={"text-left"}>
                                <Button
                                    className={classnames({
                                        "d-none": this.state.activeStep === 0
                                    })}
                                    onClick={() => {
                                        let current_step = this.getSteps()[this.state.activeStep]
                                        if (typeof current_step.prev_action === "function")
                                            current_step.prev_action(this)
                                        else
                                            this.prevStep()
                                    }}
                                    size={"sm"} color={"danger"}><FormattedMessage id={"admin.mailer.back_button"}/>
                                </Button>
                            </Col>
                            <Col className={"text-right"}>
                                {activeStep + 1 < this.getSteps().length && <Button
                                    onClick={() => {
                                        let current_step = this.getSteps()[this.state.activeStep]
                                        if (typeof current_step.next_action === "function")
                                            current_step.next_action(this)
                                        else
                                            this.nextStep()
                                    }}
                                    size={"sm"} color={"dark"}>{buttontext}{" "}
                                    {this.state.filtering ? <Spinner size={"sm"}/> :
                                        <i className={"icon-feather-arrow-right-circle"}/>}
                                </Button>}
                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
            </Collapse>)}
        </div>
    }

    clearBus = () => {
        this.setState({
            filters: {...defaultSettings},
            results: [],
            selected_ids: [],
            activeStep: 0,
            filtering: false
        })
        SendSmsEmailModel.purgeStatus().then(r => {
            this.getBusStatus()
        })
    }

    duringProcess = () => {
        let {busTotal, busInQuery, busCompleted, busTotalFailed, failedList} = this.state
        let totalCompleted = busTotal - busInQuery
        let val = (totalCompleted / busTotal) * 100
        return <div className={"mt-5"}>
            <div className={"bg-light border rounded"}>
                <div className={"text-center pt-3 pl-3 pr-3"}>
                    <h3 className={"text-left mb-3 mt-3"}>{val === 100 &&
                    <i className={"icon-feather-check-circle"}/>} <FormattedMessage
                        id={"admin.mailer.progress_status"}/></h3>
                    <LinearProgressWithLabel variant="determinate" value={val}/>
                    <Row>
                        <Col>
                            {val < 100 && <Alert className={"mt-1 border"} icon={false} severity={"info"}>
                                <Spinner color={"dark"} size={"sm"} className={"mr-2"}/>
                                <FormattedMessage id={"admin.mailer.progress_status.during"}/>
                            </Alert>}
                            {val === 100 && <Alert
                                severity={"success"}
                            ><FormattedMessage id={"admin.mailer.progress_status.completed"}/></Alert>}
                        </Col>
                        <Col className={"text-right p-2"}>
                            <ButtonLoad
                                onClick={() => this.clearBus()}
                                color={"dark"}
                                text={react_intl.formatMessage({id: "admin.mailer.clear_label"})}
                                icon={"icon-feather-trash-2"}
                                loading={this.state.clearLoading}
                            />
                        </Col>
                    </Row>
                </div>

                <hr className={"mb-0"}/>
                <div className={"p-3 bg-white"}>
                    <Row>
                        <Col>
                            <Alert severity="error">
                                <AlertTitle><FormattedMessage id={"admin.mailer.failed_processes"}/></AlertTitle>
                                <FormattedMessage id={"admin.mailer.failed_processes_desc"}
                                                  values={{number: busTotalFailed}}/>
                                {typeof failedList === "object" && Object.keys(failedList).length > 0 ?
                                    <div className={"border rounded bg-white"}>
                                        <FixedSizeList
                                            itemData={failedList}
                                            height={failedList.length * 46 < 250 ? failedList.length * 46 : 250}
                                            width={300} itemSize={46} itemCount={failedList.length}>
                                            {renderRow}
                                        </FixedSizeList>
                                    </div> : ""}
                            </Alert>
                        </Col>
                        <Col>
                            <Alert severity="success">
                                <AlertTitle><FormattedMessage id={"admin.mailer.success_processes"}/></AlertTitle>
                                <FormattedMessage id={"admin.mailer.success_processes_desc"}
                                                  values={{number: busCompleted}}/>
                            </Alert>
                        </Col>
                        <Col>
                            <Alert severity="info">
                                <AlertTitle><FormattedMessage id={"admin.mailer.pending_processes"}/></AlertTitle>
                                <FormattedMessage id={"admin.mailer.pending_processes_desc"}
                                                  values={{number: busInQuery}}/>
                            </Alert>
                        </Col>
                    </Row>
                </div>

            </div>
        </div>
    }

    render() {
        let {busTotal} = this.state

        return <>

            <StaticPanelTopBar title={react_intl.formatMessage({id: "admin.mailer.top_title"})}/>

            <div className={"margin-top-100"}>
                {busTotal > 0 ? <div className={"mb-3"}>
                    {this.duringProcess()}
                </div>: ""}
                {this.sendBulkMail()}
            </div>

        </>;
    }

    nextStep() {
        let {activeStep} = this.state
        let nextStep = activeStep + 1
        let max = this.getSteps().length - 1
        if (nextStep > max)
            nextStep = max
        this.setState({activeStep: nextStep, filtering: false})
    }

    loadTemplates = () => {
        EmailTemplateModel.get({page: {size: 99}}).then(r => r.json())
            .then(r => this.setState({templates: r.data, filtering: true}, this.nextStep))
    }

    firstStep = () => {
        this.setState({
            selected_ids: [],
            results: [],
            activeStep: 0,
            selectedTemplate: "",
            selectedTemplateId: ""
        })
    }

    prevStep = () => {
        let {activeStep} = this.state
        switch (activeStep) {
            case 1:
                this.setState({
                    selected_ids: [],
                    results: []
                })
                break;
        }

        let nextStep = activeStep - 1
        if (nextStep < 0)
            nextStep = 0
        this.setState({activeStep: nextStep})
    }

}

export default withSnackbar(Mailer, defaultSnackbarOptions)
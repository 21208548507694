import React, {Fragment} from "react"
import Header from "../Pages/Client/Header";
import Sidebar from "../Pages/Client/Sidebar";
import {pageTemplates} from "../Config";
import {Col, Container, Row} from "reactstrap";
import {Scrollbars} from 'react-custom-scrollbars';
import Footer from "../Pages/Client/Footer";
import {GlobalAuthorizationObject} from "../Lib/Authorization";

const {Route} = require("react-router-dom");

export class ClientRoute extends Route {
    render() {
        let {history} = this.props
        if (!GlobalAuthorizationObject.isLoggedIn()) {
            window.location.href = '/cp/login'
            return false
        }

        const {component: Component}: ClientRoute = this.props;
        const wrapperClass = ""
        let pageTemplate = (typeof this.props.template === "string" && typeof pageTemplates[this.props.template] === "object") ? this.props.template : "standard";
        const containerInnerClass = pageTemplates[pageTemplate].containerInnerClass;

        return <Fragment>
            <div className={"client-div"}>
                <Header{...this.props}/>
                <Container className={"border rounded bg-white pt-3 mt-3"}>
                    <div id="wrapper" className={wrapperClass}>
                        <div className="dashboard-container">
                            <Scrollbars style={{height: "calc(100vh - 170px)"}}>
                                <Component {...this.props} />
                            </Scrollbars>
                        </div>
                    </div>
                </Container>
                <Footer/>
            </div>
        </Fragment>
    }
}
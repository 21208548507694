import {Button, UncontrolledTooltip} from "reactstrap";
import React from "react";
import ConfirmationModalAlert from "./ModalAlerts/ConfirmationModalAlert";
import OrderModel from "../Models/OrderModel";
import {IoIosCopy} from "react-icons/all";
import {react_intl} from "../index";
import {FormattedMessage} from "react-intl";

class OrderDuplicate extends React.Component{
    state = {
        isOpen: false
    }

    toggle=() => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this)
    }

    render() {
        let {id, after_complete} = this.props
        return <>
            <UncontrolledTooltip target={`duplicate-${id}`}>
                <FormattedMessage id={"components.order_duplicate_duplicate"} />
            </UncontrolledTooltip>
            <Button
                id={`duplicate-${id}`}
                onClick={this.toggle}
                size={"sm"} color={"dark"} className={"mr-1 d-inline-block text-white"}><IoIosCopy /></Button>
            <ConfirmationModalAlert
                toggle={this.toggle}
                isOpen={this.state.isOpen}
                title={react_intl.formatMessage({id:"components.order_duplicate_duplicate_invoice"})}
                description={react_intl.formatMessage({id:"components.order_duplicate_duplicate_same_info_invoice"})}
                buttonAction={() => {
                    OrderModel.duplicate(id).then(r => r.json())
                        .then(r => {
                            if(typeof after_complete === "function")
                                after_complete()
                            this.toggle()
                        })
                }}
            />
        </>;
    }
}
export default OrderDuplicate
import React from "react"
import {ApiErrorThrow, debounce, defaultSnackbarOptions} from "../../../Config";
import {withSnackbar} from "react-simple-snackbar";
import {
    Badge,
    Button,
    ButtonGroup,
    Col,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import ConfirmationModalAlert from "../../../Components/ModalAlerts/ConfirmationModalAlert";
import ClientGroupModel from "../../../Models/ClientGroupModel";
import DataTable from "../../../Components/DataTable";
import InputWithIcon from "../../../Elements/InputWithIcon";
import TablePageFilterBar from "../../../Components/TablePageFilterBar";
import {GetTitle} from "../../../Routes/AdminRoute";
import {react_intl} from "../../../index";
import {FormattedMessage} from "react-intl";

export const empty_client_group = {
    name: "",
    color: "",
    discount_percent: 0
}

class ClientGroups extends React.Component {
    state = {
        nameFilter: '',
        emailFilter: '',
        groupEditModal: false,
        editingGroup: {},
        deleteModal: false,
        newPassword: ""
    }
    tableRef = React.createRef()

    groupEditModalToggle = () => {
        this.setState({groupEditModal: !this.state.groupEditModal})
        if (this.state.groupEditModal) {
            let self = this
            setTimeout(function () {
                self.setState({editingGroup: {}})
            }, 500)
        }
    }

    constructor(props) {
        super(props);
        this.groupEditModalToggle = this.groupEditModalToggle.bind(this)
        this.clientGroupHandler = this.clientGroupHandler.bind(this)
        this.deleteModalToggle = this.deleteModalToggle.bind(this)
    }

    columns = [
        {
            Header: react_intl.formatMessage({id:"admin.client_groups.id"}),
            accessor: 'id',
            sortKey: 'id',
            width: "10%",
            mobileWidth: "25%",
            Cell: row => {
                return <span className="id-cell">{"#" + row.value}</span>
            }
        },
        {
            Header: react_intl.formatMessage({id:"admin.client_groups.group_name_header"}),
            accessor: "attributes.name",
            width: "35%",
            mobileWidth: "40%"
        },
        {
            Header: react_intl.formatMessage({id:"admin.client_groups.color_header"}),
            accessor: "attributes.color",
            width: "20%",
            hideMobile: true,
            Cell: row => {
                return row && <Badge style={{backgroundColor: row.value}}>{row.value}</Badge>
            }
        },
        {
            Header: react_intl.formatMessage({id:"admin.client_groups.transactions"}),
            accessor: null,
            width: "30%",
            mobileWidth: "35%",
            Cell: (data) => {
                let row = data.row.original
                row = row.attributes
                return <ButtonGroup size={"sm"}>
                    <Button
                        onClick={() => {
                            this.setState({
                                editingGroup: {...row}
                            }, this.groupEditModalToggle)
                        }}
                        color={"primary"}><FormattedMessage id={"admin.client_groups.edit"}/></Button>
                    <Button color={"danger"} onClick={() => {
                        this.setState({
                            editingGroup: row
                        }, this.deleteModalToggle)
                    }}>Sil</Button>
                </ButtonGroup>
            }
        }
    ]

    clientGroupHandler = (e) => {
        let {editingGroup} = this.state
        editingGroup[e.target.name] = e.target.value
        this.setState({
            editingGroup
        })
    }

    onFilterChanged = debounce(() => {
        this.tableRef.current.loadData()
    }, 500);

    componentDidMount() {
        document.title = GetTitle(react_intl.formatMessage({id:"admin.client_groups.customer_groups"}))
    }

    render() {
        let {editingGroup} = this.state
        let {openSnackbar} = this.props
        return <div>

            <ConfirmationModalAlert
                icon={' icon-feather-trash-2'}
                isOpen={this.state.deleteModal}
                toggle={this.deleteModalToggle}
                title={react_intl.formatMessage({id:"admin.client_groups.sure_ask"})}
                description={react_intl.formatMessage({id:"admin.client_groups.customer_group_deleted_wrong"}, {
                    name: editingGroup.name
                })}
                buttonAction={() => {
                    ClientGroupModel.delete(editingGroup.id)
                        .then(r => {
                            this.setState({editingGroup: {}})
                            openSnackbar(<FormattedMessage id={"admin.client_groups.customer_group_deleted_msg"}/>)
                            this.deleteModalToggle()
                            this.onFilterChanged()
                        })
                        .catch(r => ApiErrorThrow(r,openSnackbar))
                }}
            />


            <TablePageFilterBar
                title={react_intl.formatMessage({id:"admin.client_groups.customer_groups"})}
                description={react_intl.formatMessage({id:"admin.client_groups.customers_followable_msg"})}
            >
                <Col sm={4}>
                    <Label><FormattedMessage id={"admin.client_groups.group_name"}/></Label>
                    <InputWithIcon icon={"icon-material-outline-search"} onChange={(e) => {
                        this.setState({nameFilter: e.target.value}, this.onFilterChanged)
                    }}/>
                </Col>
                <Col sm={4}>&nbsp;</Col>
                <Col sm={4} className={"text-right"}>
                    <Label className={"w-100"}>&nbsp;</Label>
                    <Button color={"primary"} onClick={this.groupEditModalToggle}><FormattedMessage id={"admin.client_groups.add_new_customer_group"}/></Button>
                </Col>
            </TablePageFilterBar>

            <div className={"container admin-table"}>
                <DataTable
                    pageSize={25}
                    order={"desc"}
                    orderBy={"id"}
                    ref={this.tableRef}
                    columns={this.columns} dataQuery={{
                    url: "/api/v2/user-group",
                    filters: {
                        name: this.state.nameFilter
                    }
                }}/>
            </div>

            <Modal isOpen={this.state.groupEditModal} toggle={this.groupEditModalToggle}
                   centered={true}
            >
                <ModalHeader toggle={this.groupEditModalToggle}>
                    {this.state.editingGroup.id ? react_intl.formatMessage({id:"admin.client_groups.edit_customer_group"}) : react_intl.formatMessage({id:"admin.client_groups.create_customer_group"})}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={12}>
                            <InputWithIcon name={"name"}
                                           value={this.state.editingGroup.name}
                                           placeholder={react_intl.formatMessage({id:"admin.client_groups.name"})}
                                           onChange={this.clientGroupHandler}
                            />
                        </Col>
                        <Col sm={12}>
                            <InputWithIcon name={"color"}
                                           value={this.state.editingGroup.color}
                                           placeholder={react_intl.formatMessage({id:"admin.client_groups.color"})}
                                           onChange={this.clientGroupHandler}
                            />
                        </Col>
                        {/*<Col sm={12}>*/}
                        {/*    <InputWithIcon*/}
                        {/*        icon={"icon-feather-percent"}*/}
                        {/*        name={"discount_percent"}*/}
                        {/*        value={this.state.editingGroup.discount_percent}*/}
                        {/*        placeholder={react_intl.formatMessage({id:"admin.client_groups.discount_rate"})}*/}
                        {/*        onChange={this.clientGroupHandler}*/}
                        {/*    />*/}
                        {/*</Col>*/}
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color={"primary"} onClick={() => this.saveGroup()}>
                        {this.state.editingGroup.id ? react_intl.formatMessage({id:"admin.client_groups.save"}) : react_intl.formatMessage({id:"admin.client_groups.add"})}
                    </Button>
                </ModalFooter>
            </Modal>

        </div>
            ;
    }

    saveGroup() {
        let {openSnackbar} = this.props
        let {editingGroup} = this.state
        let id
        let save_data = Object.assign(empty_client_group, editingGroup)
        if (typeof editingGroup !== "undefined") {
            id = editingGroup.id
            delete save_data.id
        }

        if (id) {
            ClientGroupModel.modify(id, save_data).then(r => r.json())
                .then(r => {
                    openSnackbar(<FormattedMessage id={"admin.client_groups.edited_customer_group"}/>)
                    this.groupEditModalToggle()
                    this.setState({editingGroup: {}})
                    this.tableRef.current.loadData()
                })
                .catch(r => ApiErrorThrow(r,openSnackbar))
        } else {
            ClientGroupModel.create(save_data).then(r => r.json())
                .then(r => {
                    openSnackbar(<FormattedMessage id={"admin.client_groups.added_customer_group"}/>)
                    this.groupEditModalToggle()
                    this.setState({editingGroup: {}})
                    this.tableRef.current.loadData()
                })
                .catch(r => ApiErrorThrow(r,openSnackbar))
        }
    }

    deleteModalToggle = () => {
        this.setState({deleteModal: !this.state.deleteModal})
    }
}

export default withSnackbar(ClientGroups, defaultSnackbarOptions)
import React from "react"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

class PhoneNumberInput extends React.Component {
    handleOnChange(value, data, event, formattedValue) {
        if(typeof this.props.onChange === "function")
            this.props.onChange({
                country : (data.countryCode).toUpperCase(),
                number: `+${value}`
            })
    }

    loadPhone = (e) => {
        if(e!==null) {
            if (typeof this.props.afterLoad === "function")
                this.props.afterLoad(e)
        }
    }

    render() {
        let {value} = this.props
        return <>
            <div className={"input-with-icon-left"}>
                <PhoneInput
                    ref={this.loadPhone}
                    inputClass={"w-100 input-text phonenumber-input"}
                    country="tr"
                    value={value}
                    autocomplete={"tel"}
                    countryCodeEditable={false}
                    {...this.props}
                    onChange={(v,d) => this.handleOnChange(v,d)}
                />
            </div>
        </>;
    }
}

export default PhoneNumberInput
import moment from "moment";
import {Button, Spinner, UncontrolledTooltip} from "reactstrap";
import React from "react";
import GlRequest from "../Lib/GlRequest";
import {FaFilePdf} from "react-icons/all";
import {FormattedMessage} from "react-intl";

class OrderPdfButton extends React.Component {
    state = {
        loading: false
    }
    fnGetFileNameFromContentDispostionHeader = function (header) {
        let contentDispostion = header.split(';');
        const fileNameToken = `filename*=UTF-8''`;

        let fileName = 'downloaded.pdf';
        for (let thisValue of contentDispostion) {
            if (thisValue.trim().indexOf(fileNameToken) === 0) {
                fileName = decodeURIComponent(thisValue.trim().replace(fileNameToken, ''));
                break;
            }
        }

        return fileName;
    };

    getPdfInvoice = async (orderId, forceDownloadPdf) => {
        this.setState({invoiceGetting: orderId})
        return new Promise(async function (resolve, reject) {
            await GlRequest.get(`/api/v2/invoice-pdf/${orderId}?force_download=1`)
                .then(async res => ({
                    filename: typeof res.headers === "object" && Object.keys(res.headers).length > 0 ? this.fnGetFileNameFromContentDispostionHeader(res.headers.get('Content-Disposition')) : `Invoice-${orderId}-${moment("YYYY-MM-DD")}.pdf`,
                    blob: await res.blob()
                }))
                .then(r => {
                    const newBlob = new Blob([r.blob], {type: 'application/pdf'});
                    resolve(newBlob)
                })
            reject("Error");
        });
    }

    render() {
        let id = this.props.id
        let buttontext = <FaFilePdf/>
        if (typeof this.props.buttontext !== "undefined")
            buttontext = this.props.buttontext

        return (
            <>
                <Button color={"danger"} style={{backgroundColor: "#f40f02"}} size={"sm"}
                        className={"mr-1"}
                        {...this.props}
                        id={`order-pdf-${id}`}
                        onClick={async () => {
                            this.setState({
                                loading: true
                            })
                            await this.getPdfInvoice(id).then(blob => {
                                let forceDownloadPdf = false
                                if (forceDownloadPdf) {
                                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                        window.navigator.msSaveOrOpenBlob(blob);
                                    } else {
                                        // For other browsers: create a link pointing to the ObjectURL containing the blob.
                                        const objUrl = window.URL.createObjectURL(blob);
                                        let link = document.createElement('a');
                                        link.href = objUrl;
                                        link.download = `Order-${id}-${moment().format('DD-MM-YYYY')}.pdf`;
                                        link.click();
                                        link.remove()
                                        // For Firefox it is necessary to delay revoking the ObjectURL.
                                        setTimeout(() => {
                                            window.URL.revokeObjectURL(objUrl);
                                        }, 250);
                                    }
                                } else {
                                    let blobUrl = URL.createObjectURL(blob);
                                    window.open(blobUrl);
                                }
                                this.setState({loading: false})
                            }).catch(r => {
                                this.setState({loading: false})
                            })
                        }}>
                    {
                        this.state.loading ? <Spinner size={"sm"}/> :
                            buttontext
                    }
                </Button>
                <UncontrolledTooltip target={`order-pdf-${id}`}>
                    <FormattedMessage id={"components.order_pdf_button_invoice_pdf"} />
                </UncontrolledTooltip>
            </>
        );
    }
}

export default OrderPdfButton
import GlRequest from "../Lib/GlRequest";

class PaymentRuleModel {
    modify(id, details)
    {
        details = {
            data: {
                attributes: details
            }
        }
        return GlRequest.patch('/api/v2/payment-forwarding/' + id, details, 'json')
    }

    create(details) {
        details = {
            data: {attributes: details}
        }
        return GlRequest.post('/api/v2/payment-forwarding', details, 'json')
    }

    delete(id) {
        return GlRequest.delete('/api/v2/payment-forwarding/' + id)
    }
}

export default PaymentRuleModel = new PaymentRuleModel()
import ConfirmationModalAlert from "./ModalAlerts/ConfirmationModalAlert";
import React from "react";
import {Button, Label, TabContent, TabPane} from "reactstrap";
import InputWithIcon from "../Elements/InputWithIcon";
import MultiRadio from "../Elements/MultiRadio";
import moment from "moment";
import {defaultSnackbarOptions, log_types} from "../Config";
import DayPicker from "./react-dates/DayPicker";
import LogModel from "../Models/LogModel";
import {withSnackbar} from "react-simple-snackbar";
import {react_intl} from "../index";
import {FormattedMessage} from "react-intl";

export const PurgeTypes = () => [
    {
        label: react_intl.formatMessage({id:"components.purge_log_all"}),
        value: "all"
    },
    {
        label: react_intl.formatMessage({id:"components.purge_log_log_type"}),
        value: "log_type"
    },
    {
        label: react_intl.formatMessage({id:"components.purge_log_ip"}),
        value: "ip"
    },
    {
        label: react_intl.formatMessage({id:"components.purge_log_date"}),
        value: "date"
    }
]

class PurgeLog extends React.Component {
    state = {
        purgeModal: false,
        purge_type: "date",
        log_type: "",
        ip: "",
        date: "",
        date_direction: "before"
    }

    purgeModalToggle = () => {
        this.setState({purgeModal: !this.state.purgeModal})
    }

    render() {
        let {purgeModal, purge_type} = this.state
        return <>
            <ConfirmationModalAlert
                isOpen={purgeModal}
                toggle={this.purgeModalToggle}
                title={react_intl.formatMessage({id:"components.purge_log_delete_records"})}
                buttonAction={() => {
                    let details = {}
                    switch (purge_type)
                    {
                        case 'all':
                            break;

                        case 'date':
                            details = {
                                date: moment(this.state.date).format("YYYY-MM-DD"),
                                date_direction: this.state.date_direction
                            }
                            break;
                        case 'ip':
                            details = {
                                ip: this.state.ip,
                            }
                            break;
                        case 'log_type':
                            details = {
                                type: this.state.purge_type
                            }
                            break;
                    }
                    let {openSnackbar} = this.props
                    LogModel.purge(details)
                        .then(r =>{
                            if(typeof this.props.reload === "function") {
                                this.purgeModalToggle()
                                this.props.reload()
                                openSnackbar(react_intl.formatMessage({id:"components.purge_log_records_deleted"}))
                            }
                        })
                }}
            >
                <p><FormattedMessage id={"components.purge_log_decide_deleted_records"} /></p>

                <MultiRadio
                    value={this.state.purge_type}
                    options={PurgeTypes()} onChange={(e) => {
                    this.setState({purge_type: e.target.value})
                }}/>

                <TabContent activeTab={this.state.purge_type} className={"mt-3 bg-light p-2 rounded"}>

                    <TabPane tabId={"log_type"}>
                        <Label><FormattedMessage id={"components.purge_log_choose_type"} /></Label>
                        <MultiRadio
                            value={this.state.log_type}
                            options={log_types()}
                            onChange={(e) => {
                                this.setState({
                                    log_type: e.target.value
                                })
                            }}
                        />

                    </TabPane>
                    <TabPane tabId={"ip"}>
                        <Label><FormattedMessage id={"components.purge_log_enter_ip"} /></Label>
                        <InputWithIcon onChange={(e) => {
                            this.setState({ip: e.target.value})
                        }}/>
                    </TabPane>
                    <TabPane tabId={"date"}>
                        <Label><FormattedMessage id={"components.purge_log_choose_date"} /></Label>
                        <DayPicker
                            horizontalCenter={true}
                            renderDayContents={(day) => {
                                return (day.format("DD-MM-YYYY") === moment(this.state.date).format("DD-MM-YYYY") ?
                                    <i className={"icon-line-awesome-check"}/> : day.format('D'))
                            }}
                            onDayClick={(day) => {
                                this.setState({date: day})
                            }}
                        />
                        {this.state.date ? <div className={"border bg-white p-2 mt-2"}>
                        <p>{moment(this.state.date).format("DD/MM/YYYY")}</p>
                        <MultiRadio options={[
                            {slug: "before", label: react_intl.formatMessage({id:"components.purge_log_before"})},
                            {slug: "after", label: react_intl.formatMessage({id:"components.purge_log_after"})}
                        ]}
                                    value={this.state.date_direction}
                                    onChange={(e) => {
                                        this.setState({date_direction: e.target.value})
                                    }}
                        />
                        </div> : ""}
                    </TabPane>
                    <TabPane tabId={"type"}>

                    </TabPane>
                </TabContent>
            </ConfirmationModalAlert>
            <Button color={""} onClick={this.purgeModalToggle} size={"info"}><FormattedMessage id={"components.purge_log_delete"} /> <i
                className={" icon-feather-trash-2"}/></Button>
        </>
    }
}

export default withSnackbar(PurgeLog,defaultSnackbarOptions)
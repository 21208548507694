import GlRequest from "../Lib/GlRequest";

class SendSmsEmailModel {
    sendEmail = (clientId, orderId, transactionId, templateType) => {
        let data = {
            data: {
                attributes: {
                    "user_id": clientId,
                    "order_id": orderId,
                    "transaction_id": transactionId,
                    "template_type": templateType
                }
            }
        }
        return GlRequest.post('/api/v2/send-mail', data, 'json')
    }
    sendEmailWithoutTemplate = (clientId, subject, content) => {
        let data = {
            data: {
                attributes: {
                    user_id: clientId,
                    subject: subject,
                    body: content
                }
            }
        }
        return GlRequest.post('/api/v2/send-mail-without-template', data, 'json')
    }
    sendSms = (clientId, orderId, transactionId, templateType) => {
        let data = {
            data: {
                attributes: {
                    "user_id": clientId,
                    "order_id": orderId,
                    "transaction_id": transactionId,
                    "template_type": templateType
                }
            }
        }
        return GlRequest.post('/api/v2/send-sms', data, 'json')
    }
    sendSmsWithoutTemplate = (clientId, text) => {
        let data = {
            data: {
                attributes: {
                    "user_id": clientId,
                    "message": text
                }
            }
        }
        return GlRequest.post('/api/v2/send-sms-without-template', data, 'json')
    }
    sendBulk = (smsOrEmail, templateName, userList) => {
        return GlRequest.post(`/api/v2/send/${smsOrEmail}`, {
            data:{
                attributes: {
                    template_type: templateName,
                    user_id: userList
                }
            }
        }, "json")
    }
    getMailingList = (attributes) => {
        let data = {
            data: {
                attributes: attributes
            }
        }
        return GlRequest.post('/api/v2/get-mailing-list', data, 'json')
    }
    busStatus = () => {
        return GlRequest.get('/api/v2/bus-status')
    }
    purgeStatus = () => {
        return GlRequest.get('/api/v2/purge-bus')
    }
}

export default SendSmsEmailModel = new SendSmsEmailModel()
import React from "react"
import {debounce, defaultSnackbarOptions, ipRuleTypes, languages} from "../../../Config";
import {withSnackbar} from "react-simple-snackbar";
import {Badge, Button, ButtonGroup, Col, Input, Label, Row, UncontrolledTooltip} from "reactstrap";
import Select from "react-select";
import DataTable from "../../../Components/DataTable";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import InputWithIcon from "../../../Elements/InputWithIcon";
import ConfirmationModalAlert from "../../../Components/ModalAlerts/ConfirmationModalAlert";
import IpAddressModel from "../../../Models/IpAddressModel";
import TablePageFilterBar from "../../../Components/TablePageFilterBar";
import {GetTitle} from "../../../Routes/AdminRoute";
import {Select38Px} from "../../../Conts";
import {react_intl} from "../../../index";
import {FormattedMessage} from "react-intl";

class AccessControl extends React.Component {
    defaultNewRecord = {
        type: "white",
        reason: "",
        ip_address: ""
    }
    tableRef = React.createRef()

    state = {
        createModal: false,
        new_record: this.defaultNewRecord,
        deleteModal: false,
        editing: false,
        reasonFilter: ""
    }

    onFilterChanged = debounce(() => {
        this.tableRef.current.loadData()
    }, 500);

    createModalToggle = () => {
        this.setState({createModal: !this.state.createModal})
        if (this.state.createModal) {
            this.setState({
                new_record: this.defaultNewRecord,
                createModal: false,
                editing: false
            })
        }
    }

    constructor(props) {
        super(props);
        this.createModalToggle = this.createModalToggle.bind(this)
        this.deleteModalToggle = this.deleteModalToggle.bind(this)
    }

    createHandle = (e) => {
        let new_record = this.state.new_record
        new_record[e.target.name] = e.target.value
        this.setState({
            new_record
        })
    }

    columns = [
        {
            Header: react_intl.formatMessage({id:"admin.access_control.id"}),
            accessor: 'id',
            sortKey: 'id',
            width: "10%",
            Cell: row => {
                return <span className="id-cell">{"#" + row.value}</span>
            }
        },
        {
            Header: react_intl.formatMessage({id:"admin.access_control.ip_address_header"}),
            accessor: 'ip_address',
            width: "30%",
            Cell: data => {
                let row = data.row.original
                row = row.attributes
                let {openSnackbar} = this.props
                return <div>
                    {row.ip_address}{' '}
                    <UncontrolledTooltip target={`whois-${row.id}`}><FormattedMessage id={"admin.access_control.who_is"}/></UncontrolledTooltip>
                    <a href={`https://ipinfo.io/${row.ip_address}`} target={"_blank"} id={`whois-${row.id}`}>
                        <i className={"icon-line-awesome-globe"}/>
                    </a>

                    <UncontrolledTooltip target={`copy-${row.id}`}><FormattedMessage id={"admin.access_control.copy"}/></UncontrolledTooltip>
                    <CopyToClipboard text={row.ip_address} id={`copy-${row.id}`} onCopy={() => {
                        openSnackbar(<FormattedMessage id={"admin.access_control.ip_copied_msg"}/>)
                    }}>
                        <i className={"icon-feather-copy"}/>
                    </CopyToClipboard>
                </div>
            }
        },
        {
            Header: react_intl.formatMessage({id:"admin.access_control.transaction"}),
            accessor: "attributes.type",
            width: "20%",
            Cell: row => {
                let color = row.value === "white" ? "success" : "danger"
                let text = row.value === "white" ? react_intl.formatMessage({id:"admin.access_control.ip_address_header"}) : react_intl.formatMessage({id:"admin.access_control.banned"})
                return <Badge color={color}>{text}</Badge>
            }
        },
        {
            Header: react_intl.formatMessage({id:"admin.access_control.description_header"}),
            accessor: "attributes.reason",
            width: "20%"
        },
        {
            Header: react_intl.formatMessage({id:"admin.access_control.transactions"}),
            width: "20%",
            Cell: data => {
                let row = data.row.original
                row = row.attributes
                return <ButtonGroup size={"sm"}>
                    <Button
                        color={"primary"} onClick={() => {
                        this.setState({editing: row.id, new_record: row}, this.createModalToggle)
                    }}><FormattedMessage id={"admin.access_control.edit"}/></Button>
                    <Button color={"danger"} onClick={() => {
                        this.setState({deleteId: row.id}, this.deleteModalToggle)
                    }}><FormattedMessage id={"admin.access_control.delete"}/></Button>
                </ButtonGroup>
            }
        }
    ]

    deleteModalToggle = () => {
        this.setState({
            deleteModal: !this.state.deleteModal
        })
    }

    componentDidMount() {
        document.title = GetTitle(react_intl.formatMessage({id:"admin.access_control.access_permissions_title"}))
    }

    render() {
        let {createModal, new_record, editing} = this.state
        let {openSnackbar, filterView} = this.props

        const filters = <>
            <Col sm={3}>
                <Label><FormattedMessage id={"admin.access_control.description"}/></Label>
                <InputWithIcon
                    icon={"icon-line-awesome-ellipsis-h"}
                    onChange={(e) => {
                        this.setState({reasonFilter: e.target.value}, this.onFilterChanged)
                    }}/>
            </Col>
            <Col sm={9} className={"text-right"}>
                <Button
                    className={"margin-top-25"}
                    color={"primary"}
                    onClick={this.createModalToggle}><FormattedMessage id={"admin.access_control.add_new_record"}/></Button>
            </Col>
        </>

        if (typeof filterView === "undefined")
            filterView = "pageTop"

        return <div>

            <ConfirmationModalAlert
                isOpen={createModal} toggle={this.createModalToggle}
                icon={"icon-material-outline-library-add"}
                title={editing ? react_intl.formatMessage({id:"admin.access_control.edit_record"}) : react_intl.formatMessage({id:"admin.access_control.add_new_ip_record"}) }
                buttonAction={() => {
                    let details = new_record
                    if (editing) {
                        let new_record_copy = {...new_record}
                        delete new_record_copy.id
                        delete new_record_copy.created
                        delete new_record_copy.updated
                        IpAddressModel.modify(editing, new_record_copy).then(r => r.json())
                            .then(r => {
                                this.tableRef.current.loadData()
                                this.createModalToggle()
                            })
                    } else {
                        IpAddressModel.create(details)
                            .then(r => r.json())
                            .then(r => {
                                this.tableRef.current.loadData()
                                this.createModalToggle()
                            })
                    }
                }}
            >
                <Row>
                    <Col sm={12}>
                        <Label><FormattedMessage id={"admin.access_control.ip_address"}/></Label>
                        <InputWithIcon
                            icon={"icon-feather-globe"}
                            value={new_record.ip_address}
                            type={"text"} onChange={this.createHandle} name={"ip_address"}/>
                    </Col>
                    <Col sm={12}>
                        <Label><FormattedMessage id={"admin.access_control.description2"}/></Label>
                        <InputWithIcon
                            value={new_record.reason}
                            icon={"icon-material-outline-keyboard-arrow-right"}
                            type={"text"} onChange={this.createHandle} name={"reason"}/>
                    </Col>
                    <Col sm={12}>
                        <Label><FormattedMessage id={"admin.access_control.transaction_type"}/></Label>
                        <div className={"text-left"}>
                            <Select
                                options={ipRuleTypes()}
                                styles={Select38Px}
                                onChange={(e) => {
                                    let new_record_edited = {...new_record}
                                    new_record_edited.type = e.value
                                    this.setState({
                                        new_record: new_record_edited
                                    })
                                }}
                                value={ipRuleTypes().find(item => item.value === new_record.type)}
                            />
                        </div>
                    </Col>
                </Row>
            </ConfirmationModalAlert>

            <ConfirmationModalAlert
                icon={"icon-feather-help-circle"}
                title={react_intl.formatMessage({id:"admin.access_control.sure_ask"})}
                toggle={this.deleteModalToggle}
                isOpen={this.state.deleteModal}
                description={react_intl.formatMessage({id:"admin.access_control.ip_deleted_msg"})}
                buttonAction={() => {
                    IpAddressModel.delete(this.state.deleteId).then(r => {
                        this.tableRef.current.loadData()
                        this.deleteModalToggle()
                    })
                }}
            />

            {filterView === "pageTop" && <TablePageFilterBar
                title={react_intl.formatMessage({id:"admin.access_control.access_permissions"})}
                description={react_intl.formatMessage({id:"admin.access_control.edit_access_permissions"})}
            >
                {filters}
            </TablePageFilterBar>}
            <div className={"container"}>

                {filterView === "inline" && <Row className={"border-bottom pb-3 mb-3"}>
                    {filters}
                </Row>}

                <DataTable
                    pageSize={25}
                    order={"desc"}
                    orderBy={"id"}
                    ref={this.tableRef}
                    columns={this.columns} dataQuery={{
                    url: "/api/v2/ip-rule",
                    filters: {
                        reason: this.state.reasonFilter
                    }
                }}/>
            </div>
        </div>;
    }
}

export default withSnackbar(AccessControl, defaultSnackbarOptions)
import moment from "moment";
import {Button, Spinner} from "reactstrap";
import React from "react";
import GlRequest from "../Lib/GlRequest";

const excelFileExtension = "xls"

class ExcelExportButton extends React.Component {
    state = {
        loading: false
    }

    fnGetFileNameFromContentDispostionHeader = function (header) {
        let contentDispostion = header.split(';');
        const fileNameToken = `filename*=UTF-8''`;

        let fileName = 'Export.' + excelFileExtension;
        for (let thisValue of contentDispostion) {
            if (thisValue.trim().indexOf(fileNameToken) === 0) {
                fileName = decodeURIComponent(thisValue.trim().replace(fileNameToken, ''));
                break;
            }
        }

        return fileName;
    };

    getXlsFile = async (base, query, forceDownloadPdf) => {
        return new Promise(async function (resolve, reject) {
            await GlRequest.get(`${base}?${query}`)
                .then(async res => ({
                    filename: typeof res.headers === "object" && Object.keys(res.headers).length > 0 ? this.fnGetFileNameFromContentDispostionHeader(res.headers.get('Content-Disposition')) : `Export-${moment("YYYY-MM-DD")}.${excelFileExtension}`,
                    blob: await res.blob()
                }))
                .then(r => {
                    const newBlob = new Blob([r.blob], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'});
                    resolve(newBlob)
                })
                .catch(r => reject(r))
            reject("Error");
        });
    }

    render() {
        let self = this
        let buttontext = <i className={" icon-line-awesome-file-pdf-o"}/>
        if (typeof this.props.buttontext !== "undefined")
            buttontext = this.props.buttontext

        return (
            <Button
                color={"primary"} size={"sm"} className={"mr-1"}
                disabled={this.state.loading}
                {...this.props}
                onClick={async () => {
                    this.setState({
                        loading: true
                    })
                    await this.getXlsFile(self.props.base, self.props.query).then(blob => {
                        let forceDownloadPdf = true
                        if (forceDownloadPdf) {
                            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                window.navigator.msSaveOrOpenBlob(blob);
                            } else {
                                // For other browsers: create a link pointing to the ObjectURL containing the blob.
                                const objUrl = window.URL.createObjectURL(blob);
                                let link = document.createElement('a');
                                link.href = objUrl;
                                link.download = `Export-${moment().format('DD-MM-YYYY')}.${excelFileExtension}`;
                                link.click();
                                link.remove()
                                // For Firefox it is necessary to delay revoking the ObjectURL.
                                setTimeout(() => {
                                    window.URL.revokeObjectURL(objUrl);
                                }, 250);
                            }
                        } else {
                            let blobUrl = URL.createObjectURL(blob);
                            window.open(blobUrl);
                        }
                        this.setState({loading: false})
                    }).catch(r => {
                        this.setState({loading: false})
                    })
                }}
                {...this.props}
            >
                {
                    this.state.loading ? <Spinner size={"sm"}/> :
                        buttontext
                }
            </Button>
        );
    }
}

export default ExcelExportButton
import React from "react"
import {Row, Col, Label, FormGroup, Card, CardHeader, CardBody, CardFooter, Button} from "reactstrap"
import InputWithIcon from "../../Elements/InputWithIcon";
import ClientModel from "../../Models/ClientModel";
import {withSnackbar} from "react-simple-snackbar";
import {defaultSnackbarOptions} from "../../Config";
import {ClientAuthorizationObject, GlobalAuthorizationObject} from "../../Lib/Authorization";
import {GetTitle} from "../../Routes/AdminRoute";
import Carousel from "../../Components/CarouselReady";
import CarouselReady from "../../Components/CarouselReady";
import ButtonLoad from "../../Elements/ButtonLoad";
import PasswordReset from "../../Components/PasswordReset";
import {loadProfile} from "../Admin/Blocks/AdminSidebarProfile";
import {get_main_config, react_intl} from "../../index";
import Footer from "./Footer";
import {FormattedMessage, injectIntl} from "react-intl";
import {FaArrowCircleRight, FaPlus, FaPlusCircle} from "react-icons/all";

class ClientLogin extends React.Component {
    state = {
        email: "",
        password: "",
        loading: false
    }

    componentDidMount() {
        document.title = GetTitle(react_intl.formatMessage({id:"client.client_login.login_txt"}))
    }

    render() {
        let {openSnackbar, history} = this.props
        if (GlobalAuthorizationObject.isLoggedIn()) {
            history.push('/cp')
            return false
        }

        return <div className={"padding-top-50 padding-bottom-50 margin-top-25"}>
            <Row>
                <Col sm={12} lg={{size: 8, offset: 2}} className={""}>
                    <form className={"w-75 mr-auto ml-auto"} onSubmit={(e) => {
                        e.preventDefault()
                        this.setState({
                            loading: true
                        })

                        if (this.state.email === "" || this.state.password === "") {
                            openSnackbar(<FormattedMessage id={"client.client_login.invalid_login_info_msg"}/>)
                            this.setState({
                                loading: false
                            })
                            return false
                        }

                        ClientAuthorizationObject.doLogin(this.state.email, this.state.password)
                            .then(result => {
                                let role, self = this
                                loadProfile().then(r => {
                                    role = typeof r.roles === "object" ? r.roles.indexOf("ROLE_ADMIN") > -1 ? "admin" : "client" : "client"
                                    self.setState({
                                        loading: false
                                    })
                                    self.props.history.push(role === "admin" ? "/admin" : "/cp");
                                })
                                return false
                            }).catch(error => {
                            this.setState({loading: false})
                            if (!error)
                                error = <FormattedMessage id={"client.client_login.error_login_msg"}/>
                            openSnackbar(error)
                            return false
                        })
                    }}>

                        <div className={"text-center mb-3"}>
                            <img
                                width={175}
                                src={get_main_config("system_general", "logo_url")} alt={""}
                            />
                        </div>

                        <Card>
                            <CardBody>
                                <FormGroup>
                                    <Label><FormattedMessage id={"client.client_login.e_mail_address_txt"}/></Label>
                                    <InputWithIcon icon={"icon-feather-user"} name={"email"}
                                                   type={"email"}
                                                   required={true}
                                                   value={this.state.email}
                                                   onChange={(e) => {
                                                       this.setState({
                                                           email: e.target.value
                                                       })
                                                   }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label><FormattedMessage id={"client.client_login.password_txt"}/></Label>
                                    <InputWithIcon
                                        type={"password"} icon={"icon-feather-lock"}
                                        name={"password"}
                                        required={true}
                                        onChange={(e) => {
                                            this.setState({
                                                password: e.target.value
                                            })
                                        }}
                                    />
                                </FormGroup>
                            </CardBody>
                            <CardFooter className={"text-right"}>
                                <Row>
                                    <Col sm={12} lg={6} className={"mb-3 mb-lg-0"}>
                                        <PasswordReset className={"float-left btn-block"}/>
                                    </Col>
                                    <Col sm={12} lg={6} className={"text-right"}>
                                        <ButtonLoad loading={this.state.loading}
                                                    block={true}
                                                    color={"dark"} size={"sm"} text={react_intl.formatMessage({id:"client.client_login.login_txt"})}/>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>

                        { typeof get_main_config("system_general", "deny_registers") === "string" && get_main_config("system_general", "deny_registers") === "no" ? <div className={"mt-2 text-center"}>
                            <Button
                                onClick={() => {
                                    history.push('/register')
                                }}
                                color={"primary"}><FormattedMessage id={"client.login.go_register"} /> <FaPlus size={11} className={"ml-1 mt-n1"} /></Button>
                        </div>: "" }

                        { typeof get_main_config("anonymous_payment", "allow_payment") === "string" && get_main_config("anonymous_payment", "allow_payment") === "yes" ? <div className={"mt-2 text-center"}>
                            <Button
                                onClick={() => {
                                    history.push('/open-payment')
                                }}
                                color={"primary"}><FormattedMessage id={"client.open_payment_button"} /> <FaArrowCircleRight /></Button>
                        </div>: "" }

                    </form>
                </Col>
            </Row>
            <Footer shadow={false} border={true} marginBottom={true} />
        </div>;
    }
}

export default injectIntl(withSnackbar(ClientLogin, defaultSnackbarOptions))
import React from "react"
import {ApiErrorThrow, debounce, defaultSnackbarOptions, frontendBaseUrl} from "../../../Config";
import {Input, Button, Card, CardBody, CardFooter, CardHeader, Col, Row, Spinner, Label} from "reactstrap";
import ContentModel from "../../../Models/ContentModel";
import {
    Avatar,
    FormControl, Grid, IconButton,
    InputAdornment,
    InputLabel,
    List,
    ListItem, ListItemAvatar, ListItemSecondaryAction,
    ListItemText,
    TextField
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import {ArrowRightAlt, SearchRounded} from "@material-ui/icons";
import DeleteIcon from '@material-ui/icons/Delete';
import {Container, Draggable} from "react-smooth-dnd";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import arrayMove from "array-move";
import {withSnackbar} from "react-simple-snackbar";
import {makeStyles} from "@material-ui/core/styles";
import CustomCheckbox from "../../../Elements/CustomCheckbox";
import {bool} from "prop-types";
import classnames from "classnames";
import {FaRegTimesCircle, RiEmotionUnhappyLine, RiPhoneFindLine} from "react-icons/all";
import {GetTitle} from "../../../Routes/AdminRoute";
import Select from "react-select";
import {Select38Px} from "../../../Conts";
import StaticPanelTopBar from "../../../Components/StaticPanelTopBar";
import {react_intl} from "../../../index";
import {FormattedMessage} from "react-intl";

export const menus = () => [
    {
        label: react_intl.formatMessage({id: "admin.menu_manage.footer"}),
        value: "footer-menu"
    }
]

class MenuManagement extends React.Component {
    state = {
        pages: null,
        menu: null,
        pageSearch: "",
        pageSearching: false,
        menuId: null,
        menuEditing: [],
        menuSaving: true,
        customMenuLabel: "",
        customMenuUrl: "",
        customMenuTarget: false,
        activeMenuSlug: menus()[0].value,
        activeMenuLabel: menus()[0].label
    }

    constructor(props) {
        super(props);
    }

    menuChanged = () => {
        this.loadMenu()
    }

    loadMenu = () => {
        ContentModel.getMenus(this.state.activeMenuSlug).then(r => r.json())
            .then(r => {
                this.setState({menu: r.data}, () => {
                    if (r.data.length === 0) {
                        ContentModel.create({
                            type: "menu",
                            name: this.state.activeMenuLabel,
                            content: "",
                            content_order: 1,
                            slug: this.state.activeMenuSlug,
                            status: 1
                        }).then(r => {
                            window.location.reload()
                        })
                    } else {
                        let first = r.data[0]
                        this.setState({
                            menuId: first.id,
                            menuEditing: (JSON.parse(first.attributes.content || "[]")),
                            menuSaving: false
                        })
                    }
                })
            })
    }

    loadPages = () => {
        this.setState({pageSearching: true})
        ContentModel.getPages(this.state.pageSearch).then(r => r.json())
            .then(r => {
                this.setState({
                    pages: r.data,
                    pageSearching: false
                })
            })
    }

    componentDidMount() {
        this.loadPages()
        this.loadMenu()
        document.title = GetTitle(react_intl.formatMessage({id: "admin.menu_manage.title"}))
    }

    pageSearchChange = debounce(() => {
        this.loadPages()
    }, 500);

    generate(element) {
        return [0, 1, 2].map((value) =>
            React.cloneElement(element, {
                key: value,
            }),
        );
    }

    onDrop = ({removedIndex, addedIndex}) => {
        let {menuEditing} = this.state
        let OrderedMenu = [...menuEditing]
        OrderedMenu = arrayMove(OrderedMenu, removedIndex, addedIndex)
        this.setState({
            menuEditing: OrderedMenu
        }, this.saveMenu)
    }

    render() {
        let {pages, menu, pageSearching, menuEditing, menuSaving} = this.state

        if (pages === null || menu === null)
            return <div className={"text-center padding-top-50"}>
                <Spinner color={"dark"}/>
            </div>

        return <>
            <StaticPanelTopBar
                title={react_intl.formatMessage({id: "admin.menu_manage.top_title"})}
                extra={<>
                    <Row>
                        <Col sm={12} lg={{size: 4, offset: 8}}>
                            <div className={"padding-top-25"}>
                                <Select
                                    styles={Select38Px}
                                    options={menus()} defaultValue={menus()[0]}
                                    onChange={(val) => {
                                        this.setState({
                                            activeMenuLabel: val.label,
                                            activeMenuSlug: val.value
                                        }, this.loadMenu)
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </>}
            />

            <Row className={"padding-top-100"}>
                <Col sm={4}>
                    <Card className={"mb-3 mt-3"}>
                        <CardHeader><i className={"icon-feather-paperclip"}/> <FormattedMessage
                            id={"admin.menu_manage.add_page"}/>
                            {pageSearching ? <Spinner size={"sm"} color={"dark"} className={"float-right"}/> : ""}
                        </CardHeader>
                        <div className={"d-flex bg-white w-100 border-bottom"}>
                            <span
                                className={"padding-left-25 padding-right-25 pt-1 pb-1 position-relative text-center bg-dark text-white"}
                                style={{lineHeight: "30px", width: 140}}>
                                <i
                                    style={{position: "absolute", marginLeft: 70, opacity: .5, top: 11}}
                                    className={"icon-feather-search text-dark"}/>
                                <FaRegTimesCircle
                                    className={this.state.pageSearch ? "cursor-pointer text-dark" : "display-none"}
                                    style={{position: "absolute", marginLeft: 250, opacity: .75, top: 11}}
                                    onClick={() => {
                                        this.setState({pageSearch: ""}, this.pageSearchChange)
                                    }}
                                />
                                Ara</span>
                            <Input
                                value={this.state.pageSearch}
                                className={"mb-0 padding-left-25"}
                                style={{
                                    borderRadius: 0,
                                    border: 0,
                                    borderLeft: "1px solid #CCC",
                                    backgroundColor: "#fcfcfc"
                                }}
                                onChange={(e) => {
                                    this.setState({
                                        pageSearch: e.target.value,
                                    }, this.pageSearchChange)
                                }}/>
                        </div>
                        <CardBody className={"pb-0 pt-0"}>
                            <List dense={false}>
                                {pages.length ? pages.map((item, i) => <ListItem
                                        key={`MenuListItem-${i}`}
                                        button onClick={() => {
                                        this.addToMenu(item.attributes)
                                    }}>
                                        <ListItemText
                                            primary={item.attributes.name}
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" aria-label="add">
                                                <ArrowRightAlt/>
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>,
                                ) : <div className={"text-center padding-top-15 padding-bottom-25 text-dark"}>
                                    <RiPhoneFindLine size={21}/>
                                    <h4 className={"text-dark"}><FormattedMessage
                                        id={"admin.menu_manage.page_not_found"}/></h4>
                                </div>}
                            </List>
                        </CardBody>
                    </Card>

                    <Card>
                        <CardHeader><i className={"icon-line-awesome-link"}/> <FormattedMessage
                            id={"admin.menu_manage.add_link"}/></CardHeader>
                        <CardBody className={""}>
                            <div className={"mb-1"}>
                                <Label><FormattedMessage id={"admin.menu_manage.add_link.label"}/></Label>
                                <Input
                                    value={this.state.customMenuLabel}
                                    type={"text"} onChange={(e) => {
                                    this.setState({customMenuLabel: e.target.value})
                                }}/>
                            </div>
                            <div className={"mb-1"}>
                                <Label><FormattedMessage id={"admin.menu_manage.add_link.url"}/></Label>
                                <Input type={"text"}
                                       value={this.state.customMenuUrl}
                                       onChange={(e) => {
                                           this.setState({customMenuUrl: e.target.value})
                                       }}
                                />
                            </div>
                            <div>
                                <Label className={"mr-1"}><FormattedMessage
                                    id={"admin.menu_manage.add_link.target"}/></Label>
                                <CustomCheckbox
                                    extraclass={"float-right"}
                                    checked={this.state.customMenuTarget}
                                    onChange={(e) => {
                                        this.setState({customMenuTarget: e.target.checked})
                                    }}
                                />
                            </div>
                        </CardBody>
                        <CardFooter className={"text-center"}>
                            <Button size={"sm"} color={"primary"} onClick={() => {
                                this.addToMenu({
                                    name: this.state.customMenuLabel,
                                    url: this.state.customMenuUrl,
                                    slug: this.generateRandom(10),
                                    newPage: this.state.customMenuTarget
                                }, () => {
                                    this.setState({customMenuLabel: "", customMenuUrl: "", customMenuTarget: false})
                                })
                            }}><FormattedMessage id={"admin.menu_manage.add_link.add"}/></Button>
                        </CardFooter>
                    </Card>
                </Col>
                <Col sm={8}>
                    <Card className={"mt-3"}>
                        <CardHeader><FormattedMessage id={"admin.menu_manage.menu"}/> {menuSaving &&
                        <Spinner size={"sm"} className={"float-right"} color={"dark"}/>}</CardHeader>
                        <CardBody>
                            <List dense={false}>
                                <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={this.onDrop}>
                                    {menuEditing.map((item, index) => {
                                        return <Draggable id={item.slug}>
                                            <ListItem color={"primary"}
                                                      className={index < (menuEditing.length - 1) ? "border-bottom" : ""}>
                                                <ListItemAvatar className={"drag-handle cursor-pointer"}>
                                                    <Avatar>
                                                        <DragHandleIcon/>
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={item.label}
                                                    secondary={<>{item.url}
                                                        <i
                                                            onClick={() => this.toggleNewPage(item.slug)}
                                                            className={classnames("icon-feather-external-link cursor-pointer ml-2", {
                                                                "text-primary": item.newPage
                                                            })}/></>}
                                                />
                                                <ListItemSecondaryAction onClick={() => {
                                                }}>
                                                    <IconButton edge="end" aria-label="delete" onClick={() => {
                                                        this.deleteFromMenu(item.slug)
                                                    }}>
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        </Draggable>
                                    })}

                                </Container>
                            </List>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>;
    }

    addToMenu(attributes, callback) {
        let {
            menuEditing
        }

            = this.state
        let {type, id} = attributes
        menuEditing.push({
            label: attributes.name,
            url: typeof attributes.url === "undefined" ? "/page/" + attributes.slug : attributes.url,
            slug: this.generateRandom(10),
            newPage: typeof attributes.newPage === "undefined" ? false : !!(attributes.newPage),
            type: type || "custom",
            pageId: id || ""

        })
        this.setState({menuEditing}, () => {
            if (typeof callback === "function")
                callback()
            this.saveMenu()
        })
    }

    toggleNewPage(slug) {
        let {menuEditing} = this.state
        let i = menuEditing.findIndex(item => item.slug === slug)
        menuEditing[i]['newPage'] = !menuEditing[i]['newPage']
        this.setState({menuEditing}, this.saveMenu)
    }

    deleteFromMenu(slug) {
        let {menuEditing} = this.state
        let i = menuEditing.findIndex(item => item.slug === slug)
        if (i > -1)
            menuEditing.splice(i, 1)
        this.setState({menuEditing}, this.saveMenu)
    }

    generateRandom = (l) => {
        if (typeof l !== "number")
            l = 8
        l *= -1
        return Math.random()
            .toString(36)
            .slice(l)
    }

    saveMenu = () => {
        this.setState({menuSaving: true})
        let {openSnackbar} = this.props
        ContentModel.modify(this.state.menuId, {
            content: JSON.stringify(this.state.menuEditing)
        }).then(r => r.json())
            .then(r => {
                this.setState({menuSaving: false})
                openSnackbar(react_intl.formatMessage({id: "admin.menu_manage.saved"}))
            })
    }

}

export default withSnackbar(MenuManagement)
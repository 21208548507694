import EditPost from "./../MainType/EditPost";
import {defaultSnackbarOptions} from "../../../../Config";
import {withSnackbar} from "react-simple-snackbar";
import {matchPath} from "react-router";
import {react_intl} from "../../../../index";

class Edit extends EditPost {
    title = react_intl.formatMessage({id:"admin.content.announcements.edit.text"})
    back_url = "/admin/announcements"
    pathScheme = "/admin/announcements/:id"
    back_text = react_intl.formatMessage({id:"admin.content.announcements.edit.back_text"})
    action = "edit"
    thumbnail = true
    content_type = "announcement"

    getParams = (pathname) => {
        const matchProfile = matchPath(pathname, {
            path: this.pathScheme,
        });
        return (matchProfile && matchProfile.params) || {};
    }

    constructor(props) {
        super(props);
        let params = this.getParams(props.location.pathname)
        this.postId = params.id
    }
}
export default withSnackbar(Edit, defaultSnackbarOptions)
import React from "react"
import {FaArrowDown, FaChartBar, FaChartPie} from "react-icons/all";
import UnpaidInvoices from "./CpWidget/UnpaidInvoices";
import {Row} from "reactstrap";
import PaidInvoices from "./CpWidget/PaidInvoices";

class StatBoxes extends React.Component{
    render() {
        let {unpaid, unpaidTotal, paid, paidTotal} = this.props.data
        return <div className={"cp-stat-boxes"}>
            <Row>

            </Row>
        </div>;
    }
}
export default StatBoxes
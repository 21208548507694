import {frontendBaseUrl} from "../../../Config";
import {AiFillDatabase, FaCogs, FaUserAlt, HiTemplate} from "react-icons/all";
import moment from "moment";
import StaticPanelTopBar from "../../../Components/StaticPanelTopBar";
import {Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import DataTable from "../../../Components/DataTable";
import SmsTemplates from "./../Settings/SmsTemplates/SmsTemplates";
import SettingsSeparated from "../../../Components/SettingsSeparated";
import {email_settings, order_settings, sms_settings} from "../Settings/SystemSettings";
import React from "react";
import EmailTemplates from "./../Settings/EmailTemplates/EmailTemplates";
import {react_intl} from "../../../index";
import {FormattedMessage} from "react-intl";


class Notifications extends React.Component {
    tableRef = null
    columns = []

    state = {
        activeTab: "1"
    }

    setActiveTab = (v) => {
        this.setState({activeTab: v})
    }

    toggle = tab => {
        let {activeTab} = this.state
        if (activeTab !== tab) this.setActiveTab(tab);
    }

    title = react_intl.formatMessage({id:"admin.notifications.title"})
    logsEnabled = true
    templatesEnabled = true
    typeSlug = ""
    typeName = ""
    settingsEnabled = true

    render() {
        let {activeTab} = this.state
        let {title, logsEnabled, templatesEnabled, typeSlug, typeName, settingsEnabled} = this
        return (
            <div>
                <StaticPanelTopBar
                    title={title}
                    extra={<div className={"padding-top-25"}>
                        <Nav pills className={"float-right"}>
                            {logsEnabled && <NavItem>
                                <NavLink
                                    href={"#"}
                                    className={classnames({active: activeTab === '1'})}
                                    onClick={() => {
                                        this.toggle('1');
                                    }}
                                >
                                    <AiFillDatabase/> <FormattedMessage id={"admin.notifications.tabs.log"} values={{type:typeName}} />
                                </NavLink>
                            </NavItem>}
                            {templatesEnabled && <NavItem>
                                <NavLink
                                    href={"#"}
                                    className={classnames({active: activeTab === '2'})}
                                    onClick={() => {
                                        this.toggle('2');
                                    }}
                                >
                                    <HiTemplate/> <FormattedMessage id={"admin.notifications.tabs.templates"} values={{type:typeName}} />
                                </NavLink>
                            </NavItem>}
                            {settingsEnabled && <NavItem>
                                <NavLink
                                    href={"#"}
                                    className={classnames({active: activeTab === '3'})}
                                    onClick={() => {
                                        this.toggle('3');
                                    }}
                                >
                                    <FaCogs/> <FormattedMessage id={"admin.notifications.tabs.settings"} values={{type:typeName}} />
                                </NavLink>
                            </NavItem>}
                        </Nav>
                    </div>}
                />
                <div className={"margin-top-100 bg-white shadow p-3"}>
                    <TabContent activeTab={activeTab}>
                        {logsEnabled && <TabPane tabId="1">
                            <Row>
                                <Col sm="12">
                                    <DataTable
                                        pageSize={25}
                                        order={"desc"}
                                        orderBy={"id"}
                                        ref={this.tableRef}
                                        columns={this.columns}
                                        rowClick={(row) => {
                                            row = row.original
                                            row = row.attributes

                                            if (typeof row.user_id !== "object")
                                                return false

                                            this.props.history.push(`/admin/clients/${row.user_id.id}`)
                                        }}
                                        dataQuery={{
                                            url: "/api/v2/mail-sms-history/" + typeSlug,
                                            filters: {}
                                        }}/>
                                </Col>
                            </Row>
                        </TabPane>}
                        {templatesEnabled && <TabPane tabId="2">
                            <Row>
                                <Col sm="12" className={"position-relative"}>
                                    {typeSlug === "sms" && <SmsTemplates hide_filter={true} inline_filter={true}/>}
                                    {typeSlug === "email" && <EmailTemplates hide_filter={true} inline_filter={true}/>}
                                </Col>
                            </Row>
                        </TabPane>}
                        {settingsEnabled && <TabPane tabId={"3"}>
                            <Row>
                                <Col sm={12}>
                                    <div className={"p-3 mb-3"}>
                                        <h3><FormattedMessage id={"admin.notifications.tabs.settings.title"} values={{type:typeName}} /></h3>
                                        <p>&nbsp;</p>

                                        {typeSlug === "sms" && <>
                                            <h4 className={"font-weight-bold"}><FormattedMessage id={"admin.notifications.tabs.settings.sms_operator"} /></h4>
                                            <SettingsSeparated viewType={"inline"} settings={sms_settings}
                                                               group={"sms_config"}/></>}

                                        {typeSlug === "sms" && <>
                                            <h4 className={"font-weight-bold"}><FormattedMessage id={"admin.notifications.tabs.settings.sms_notifications"} /></h4>
                                            <SettingsSeparated viewType={"inline"} settings={order_settings}
                                                               group={"payment_remind_sms"}
                                            />
                                        </>}


                                        {typeSlug === "email" && <>
                                            <h4 className={"font-weight-bold"}><FormattedMessage id={"admin.notifications.tabs.settings.email_options"} /></h4>
                                            <SettingsSeparated viewType={"inline"} settings={email_settings}
                                                               group={"mailer_config"}/>
                                        </>}

                                        {typeSlug === "email" && <>
                                            <h4 className={"font-weight-bold"}><FormattedMessage id={"admin.notifications.tabs.settings.email_templates"} /></h4>
                                            <SettingsSeparated viewType={"inline"} settings={email_settings}
                                                               group={"mail_template"}/>
                                        </>}

                                        {typeSlug === "email" && <>
                                            <h4 className={"font-weight-bold"}><FormattedMessage id={"admin.notifications.tabs.settings.email_smtp"} /></h4>
                                            <SettingsSeparated viewType={"inline"} settings={email_settings}
                                                               group={"smtp_config"}/>
                                        </>}
                                        {typeSlug === "email" && <>
                                            <h4 className={"font-weight-bold"}><FormattedMessage id={"admin.notifications.tabs.settings.email_notifications"} /></h4>
                                            <SettingsSeparated viewType={"inline"} settings={order_settings}
                                                               group={"payment_remind_mail"}
                                            />
                                        </>}
                                    </div>
                                </Col>
                            </Row>
                        </TabPane>}
                    </TabContent>
                </div>
            </div>
        );
    }
}

export default Notifications
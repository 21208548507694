import React from "react";
import {Badge, Button, ButtonGroup, Col, Form, Label, Spinner} from "reactstrap";
import {debounce} from "../../../../Config";
import DataTable from "../../../../Components/DataTable";
import moment from "moment";
import Select from "react-select";
import {Select38Px} from "../../../../Conts";
import ConfirmationModalAlert from "../../../../Components/ModalAlerts/ConfirmationModalAlert";
import InputWithIcon from "../../../../Elements/InputWithIcon";
import ContentModel from "../../../../Models/ContentModel";
import TablePageFilterBar from "../../../../Components/TablePageFilterBar";
import {Link} from "react-router-dom";
import {GetTitle} from "../../../../Routes/AdminRoute";
import {react_intl} from "../../../../index";
import {FormattedMessage} from "react-intl";

export const PostStatuses = () => {
    return  [
        {
            "value": "",
            "label": react_intl.formatMessage({id:"admin.content.main.all.all_status"})
        },
        {
            "value": true,
            "label": react_intl.formatMessage({id:"admin.content.main.all.only_active"})
        },
        {
            "value": false,
            "label": react_intl.formatMessage({id:"admin.content.main.all.only_passive"})
        }
    ]
}

class ListAll extends React.Component {
    title = react_intl.formatMessage({id:"admin.content.main.all.title"})
    description = react_intl.formatMessage({id:"admin.content.main.all.description"})
    content_type = "announcement"
    create_link = "/admin/new-announcement"
    detail_link = "/admin/announcements/:id"

    tableRef = React.createRef()
    state = {
        titleFilter: "",
        statusFilter: "",
        pageActive: {},
        deleteModal: false,
        statusChanging: false
    }

    constructor(props) {
        super(props);
        this.postDeleteToggle = this.postDeleteToggle.bind(this)
    }

    toggleStatus = (pageId, newStatus) => {
        let {openSnackbar} = this.props
        this.setState({
            statusChanging: pageId
        })
        ContentModel.modify(pageId, {
            status: newStatus,
        }).then(() => {
            setTimeout(function () {
                let status_text = newStatus ? react_intl.formatMessage({id: "admin.content.main.all.status_active"}) : react_intl.formatMessage({id: "admin.content.main.all.status_passive"})
                openSnackbar(react_intl.formatMessage({id: "admin.content.main.all.status_changed"}, {
                    status: status_text
                }))
            }, 300)
            this.onFilterChanged()
        })
    }

    columns = [
        {
            Header: react_intl.formatMessage({id: "admin.content.main.all.table_cols.id"}),
            accessor: 'id',
            sortKey: 'id',
            width: "10%",
            Cell: row => {
                return <span className="id-cell">{"#" + row.value}</span>
            }
        },
        {
            Header: react_intl.formatMessage({id: "admin.content.main.all.table_cols.title"}),
            accessor: 'attributes.name',
            sortKey: 'name',
            width: "35%",
            Cell: row => {
                return row.value
            }
        },
        {
            Header: react_intl.formatMessage({id: "admin.content.main.all.table_cols.status"}),
            sortKey: 'status',
            width: "10%",
            Cell: data => {
                let row = data.row.original
                row = row.attributes
                return <>
                    {(
                        (this.state.statusChanging === row.id) ?
                            <Spinner size={"sm"}/> :
                            (
                                row.status ? <Badge
                                    className={"cursor-pointer"}
                                    onClick={() => this.toggleStatus(row.id, !row.status)}
                                    color={"success"}><FormattedMessage
                                    id={"admin.content.main.all.table_cols.status.active"}/></Badge> : <Badge
                                    className={"cursor-pointer"}
                                    onClick={() => this.toggleStatus(row.id, !row.status)}
                                    color={"danger"}><FormattedMessage
                                    id={"admin.content.main.all.table_cols.status.passive"}/></Badge>)
                    )}
                </>
            }
        },
        {
            Header: react_intl.formatMessage({id: "admin.content.main.all.table_cols.date"}),
            accessor: 'attributes.created',
            sortKey: 'created',
            width: "15%",
            Cell: row => {
                return moment(row.value).format('DD-MM-YYYY')
            }
        },
        {
            Header: react_intl.formatMessage({id: "admin.content.main.all.table_cols.actions"}),
            width: "30%",
            Cell: (data) => {
                let row = data.row.original
                row = row.attributes
                let url = this.detail_link
                url = url.replaceAll(':id', row.id)
                return <ButtonGroup>
                    <Link
                        to={url}
                        className={"btn btn-sm btn-primary"}
                    ><FormattedMessage id={"admin.content.main.all.table_cols.actions.edit"}/></Link>
                    <Button size={"sm"} color={"danger"} onClick={() => {
                        this.setState({
                            pageActive: row
                        }, this.postDeleteToggle)
                    }}><FormattedMessage id={"admin.content.main.all.table_cols.actions.delete"}/></Button>
                </ButtonGroup>
            }
        }
    ]

    onFilterChanged = debounce(() => {
        let self = this
        setTimeout(function () {
            self.setState({
                statusChanging: null
            })
        }, 500)
        this.tableRef.current.loadData()
    }, 500);

    componentDidMount() {
        document.title = GetTitle(this.title)
    }

    render() {
        let {dateFilterStart, dateFilterEnd, pageActive} = this.state
        const {openSnackbar} = this.props
        return <div>
            <ConfirmationModalAlert
                title={react_intl.formatMessage({id: "admin.content.main.all.delete_title"})}
                description={react_intl.formatMessage({
                    id: "admin.content.main.all.delete_message"
                }, {
                    title: pageActive.name
                })}
                isOpen={this.state.deleteModal}
                icon={"icon-line-awesome-question"}
                toggle={this.postDeleteToggle}
                buttonAction={() => {
                    ContentModel.delete(pageActive.id).then(() => {
                        openSnackbar(react_intl.formatMessage({id:"admin.content.main.all.deleted"}))
                        this.postDeleteToggle()
                        this.onFilterChanged()
                    })
                }}
            />

            <TablePageFilterBar
                title={this.title}
                description={this.description}
            >
                <Col sm={6} lg={4}>
                    <Label><FormattedMessage id={"admin.content.main.all.filter.title"} /></Label>
                    <InputWithIcon
                        onChange={(e) => {
                            this.setState({titleFilter: e.target.value}, this.onFilterChanged)
                        }}
                    />
                </Col>

                <Col sm={6} lg={4}>
                    <Label><FormattedMessage id={"admin.content.main.all.filter.status"} /></Label>
                    <Select
                        styles={Select38Px}
                        placeholder={react_intl.formatMessage({id:"admin.content.main.all.filter.status_placeholder"})}
                        onChange={(value) => {
                            this.setState({
                                statusFilter: value.value
                            }, this.onFilterChanged)
                        }}
                        defaultValue={null}
                        options={PostStatuses()}
                    />
                </Col>
                <Col sm={6} lg={4} className={"text-right"}>
                    <Link to={this.create_link} className={"btn btn-primary"}><FormattedMessage id={"admin.content.main.all.filter.add_new"} /> <i
                        className={"icon-feather-plus"}/></Link>
                </Col>
            </TablePageFilterBar>

            <div className={"container"}>
                <DataTable
                    pageSize={25}
                    order={"desc"}
                    orderBy={"id"}
                    ref={this.tableRef}
                    columns={this.columns}
                    dataQuery={{
                        url: "/content",
                        filters: {
                            name: this.state.titleFilter,
                            type: this.content_type,
                            status: typeof this.state.statusFilter === "boolean" ? this.state.statusFilter ? "1" : "0" : ""
                        }
                    }}/>
            </div>

        </div>;
    }

    postDeleteToggle() {
        this.setState({
            deleteModal: !this.state.deleteModal
        })
    }
}

export default ListAll
import {get_main_config} from "../../index";
import React from "react";
import {Link} from "react-router-dom";
import moment from "moment";
import classnames from "classnames";
import LangSwitcher from "../../Components/LangSwitcher";

const ReactLink = (props) => {
    let {label, url, newPage} = props
    return <Link to={url} target={newPage ? "_blank" : "_self"}>{label}</Link>
}
const StandardLink = (props) => {
    let {label, url, newPage} = props
    return <a href={url} target={newPage ? "_blank" : "_self"}>{label}</a>
}

class Footer extends React.Component {
    render() {
        return <div className={classnames("user-panel-footer bg-white", {
            shadow: typeof this.props.shadow !== "boolean" || this.props.shadow,
            border: typeof this.props.border === "boolean" && this.props.border,
            "mb-3": typeof this.props.marginBottom !== "boolean" || this.props.marginBottom,
        })}>
            <div className={"position-relative"}>
                <p className={"float-left text-muted font-italic font-weight-normal pl-2"} style={{
                    lineHeight: "50px"
                }}>Powered by gri with Paymendo</p> <LangSwitcher
                boxStyle={{top: 8, right: -110, position: "absolute"}}
            />
                <ul className={"list-unstyled footer-menu mb-0"}>
                    <li>&copy; {moment().format('Y')}</li>
                    {(get_main_config("menus", "footer-menu") || []).map((item, number) => {
                        return <li key={`topmenu-${number}`}>
                            {(item.url || "").indexOf("http") > -1 ? <StandardLink {...item} /> :
                                <ReactLink {...item} />}
                        </li>
                    })}
                </ul>
            </div>
        </div>;
    }
}

export default Footer
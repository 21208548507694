import React from "react"
import {Button, Modal, Spinner} from "reactstrap";

class ConfirmationModalAlert extends React.Component {
    render() {
        let {canCancel} = this.props
        return <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} centered={true} returnFocusAfterClose={false}>
            <button onClick={this.props.toggle} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-body">
                <div className="px-3 pt-3 text-center">
                    <div className={"event-type " + (this.props.color ? this.props.color : "warning")}>
                        <div className="event-indicator ">
                            <i className={"mdi text-white " + (this.props.icon ? this.props.icon : " icon-line-awesome-question-circle")}
                               style={{
                                   fontSize: "60px"
                               }}/>
                        </div>
                    </div>
                    <h3 className="pt-3">{this.props.title}</h3>
                    <p className="text-muted">
                        {this.props.description}
                    </p>
                    {this.props.children}
                </div>
            </div>
            <div className="modal-footer">
                {this.props.extraButtons || ""}
                {typeof this.props.buttonLoading === "boolean" && this.props.buttonLoading ?
                    <Spinner size={"sm"} color={"primary"}/> : <>
                        <Button type={typeof this.props.formName === "string" ? "submit" : "button"}
                                form={typeof this.props.formName === "string" ? this.props.formName : ""}
                                onClick={() => {
                                    if (typeof this.props.buttonAction === "function")
                                        this.props.buttonAction();
                                    else
                                        this.props.toggle()
                                }} color={"dark"}>{this.props.buttontext || 'Devam Et'}</Button>
                        {typeof canCancel === "undefined" || canCancel === null || canCancel === true &&
                        <Button onClick={() => {
                            if (typeof this.props.buttonActionCancel === "function")
                                this.props.buttonActionCancel();
                            else
                                this.props.toggle()
                        }} color={"light"}>{this.props.cancelButtonText || 'Vazgeç'}</Button>}
                    </>}
            </div>
        </Modal>
    }
}

export default ConfirmationModalAlert
import React from "react"
import {Select38Px} from "../Conts";
import GlSelect from "../Components/GlSelect";

class ClientSelect extends React.Component {
    render() {
        let {extrastyle} = this.props
        return <GlSelect
            isMulti={true}
            styles={Object.assign(Select38Px, extrastyle)}
            url={'/api/v2/user?role=ROLE_USER'}
            placeholder={"Seçiniz"}
            isClearable={true}
            defaultValue={null}
            searchKey={"concat"}
            closeMenuOnSelect={typeof this.props.isMulti !== "boolean" || !this.props.isMulti}
            noOptionsMessage={() => "Müşteri bulunamadı"}
            {...this.props}
        />;
    }
}

export default ClientSelect
import React from "react"
import {FormattedMessage} from "react-intl";

class ProfileWidget extends React.Component {
    render() {
        let {user} = this.props
        if (typeof user !== "object")
            user = {}

        return <div className={"profile-widget"}>
            <ul className={"list-unstyled pl-3 pr-3"}>
                <li className={"bg-light p-1"}>
                    <strong><i className={"icon-line-awesome-bookmark"}/> <FormattedMessage id={"client.profile_widget.costumer_no"}/></strong>
                    <span>: #{user.id}</span>
                </li>
                <li className={"bg-white  p-1"}>
                    <strong><i className={"icon-material-outline-account-circle"}/> <FormattedMessage id={"client.profile_widget.full_name"}/></strong>
                    <span>: {`${user.firstname} ${user.lastname}`}</span>
                </li>
                <li className={"bg-light p-1"}>
                    <strong><i className={"icon-material-outline-email"}/> <FormattedMessage id={"client.profile_widget.e_mail"}/></strong>
                    <span>: {user.email}</span>
                </li>
                <li className={"bg-white p-1"}>
                    <strong><i className={"icon-feather-smartphone"}/> <FormattedMessage id={"client.profile_widget.tel_no"}/></strong>
                    <span>: {user.mobile_phone}</span>
                </li>
            </ul>
        </div>;
    }

}

export default ProfileWidget
import GlRequest from "../Lib/GlRequest";

class SettingModel {
    getPublicSettings = () => {
        return GlRequest.get('/config')
    }

    getAll = () => {
        return GlRequest.get('/api/v2/config')
    }
    getByGroup = (groupName) => {
        return GlRequest.get('/api/v2/config?page[size]=999&title=' + groupName)
    }
    create = (detail) => {
        return GlRequest.post('/api/v2/config')
    }

    reset2Default = (title) => {
        return GlRequest.get('/api/v2/config/restore-defaults/' + title)
    }

    bulkEdit = (detail) => {
        detail = {
            data: {
                attributes: detail
            }
        }
        return GlRequest.patch('/api/v2/config', detail, 'json')
    }

    addGlobalShortcode = (name,value) => {
        let data = {
            data: {
                attributes: {
                    name: name,
                    value: value
                }
            }
        }
        return GlRequest.post('/api/v2/config/add-global-shortcode', data, 'json')
    }

    deleteGlobalShortcode = (id) => {
        if(typeof id === "object")
            id = id.join(",")
        return GlRequest.delete('/api/v2/config/delete-global-shortcode/' + id)
    }

}

export default SettingModel = new SettingModel()
import React from "react"
import {withSnackbar} from "react-simple-snackbar";
import {ApiErrorThrow, defaultSnackbarOptions} from "../../../Config";
import {
    Badge,
    Button,
    ButtonGroup,
    Col,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row, Spinner
} from "reactstrap";
import {Link} from "react-router-dom";
import DataTable from "../../../Components/DataTable";
import {Switch} from "@material-ui/core";
import PaymentRuleModel from "../../../Models/PaymentRuleModel";
import {bool} from "prop-types";
import Select from "react-select";
import PaymentRuleSingle from "./PaymentRuleSingle";
import PaymentMethodModel from "../../../Models/PaymentMethodModel";
import {GetTitle} from "../../../Routes/AdminRoute";
import ConfirmationModalAlert from "../../../Components/ModalAlerts/ConfirmationModalAlert";
import InputWithIcon from "../../../Elements/InputWithIcon";
import PaymentMethodSelect from "../../../Elements/PaymentMethodSelect";
import {getBankObject} from "../../../Banks";
import StaticPanelTopBar from "../../../Components/StaticPanelTopBar";
import {react_intl} from "../../../index";
import {FormattedMessage} from "react-intl";
import ButtonLoad from "../../../Elements/ButtonLoad";

export const conditionTypes = () => [
    {
        value: "All",
        label: react_intl.formatMessage({id: "admin.payment.payment_rules.conditions.All"})
    },
    {
        value: "InBinList",
        label: react_intl.formatMessage({id: "admin.payment.payment_rules.conditions.InBinList"})
    },
    {
        value: "BiggerThanAmount",
        label: react_intl.formatMessage({id: "admin.payment.payment_rules.conditions.BiggerThanAmount"})
    },
    {
        value: "LesserThanAmount",
        label: react_intl.formatMessage({id: "admin.payment.payment_rules.conditions.LesserThanAmount"})
    },
    {
        value: "BiggerThanInstallment",
        label: react_intl.formatMessage({id: "admin.payment.payment_rules.conditions.BiggerThanInstallment"})
    },
    {
        value: "LesserThanInstallment",
        label: react_intl.formatMessage({id: "admin.payment.payment_rules.conditions.LesserThanInstallment"})
    },
    {
        value: "Country",
        label: react_intl.formatMessage({id: "admin.payment.payment_rules.conditions.Country"})
    },
    {
        value: "Currency",
        label: react_intl.formatMessage({id: "admin.payment.payment_rules.conditions.Currency"})
    }
]
const defaultRule = {
    "condition_name": "",
    "status": 0,
    "payment_method": {},
    "priority": 0,
    "details": []
}

class PaymentRules extends React.Component {
    tableRef = React.createRef()

    state = {
        ruleModal: false,
        editRule: defaultRule,
        activeRuleDetails: {
            name: "",
            status: false
        },
        statusChanging: null,
        createRuleName: "",
        createRuleMethod: "",
        createRule: false,
        deleteRule: false,
        ruleSaving: false,
        deleteLoading: false,
        createLoading: false
    }

    createRuleToggle = () => {
        this.setState({
            createRule: !this.state.createRule,
            createRuleName: ""
        }, () => {
            this.setState({createLoading: false})
        })
    }
    deleteRuleToggle = () => {
        this.setState({
            deleteRule: !this.state.deleteRule,
            deleteLoading: false
        })
    }

    constructor(props) {
        super(props);
        this.ruleModalToggle = this.ruleModalToggle.bind(this)
        this.addRow = this.addRow.bind(this)
        this.removeRow = this.removeRow.bind(this)
        this.modifyRow = this.modifyRow.bind(this)
        this.createRuleToggle = this.createRuleToggle.bind(this)
        this.deleteRuleToggle = this.deleteRuleToggle.bind(this)
    }

    resetChanging = () => {
        this.setState({statusChanging: null})
    }

    ruleModalToggle = () => {
        this.setState({
            ruleModal: !this.state.ruleModal
        }, () => {
            this.setState({ruleSaving: false})
        })
    }

    priorityChange(id, priority) {
        let {openSnackbar} = this.props
        PaymentRuleModel.modify(id, {
            priority: priority
        })
            .then(r => r.json())
            .then(r => {
                this.tableRef.current.loadData();
            })
            .catch(r => ApiErrorThrow(r, openSnackbar))
    }

    columns = [
        {
            Header: react_intl.formatMessage({id: "admin.payment.payment_rules.table_cols.priority"}),
            accessor: null,
            sortKey: 'priority',
            width: "15%",
            Cell: data => {
                let row = data.row.original
                row = row.attributes
                return <div>
                    <ButtonGroup size={"sm"}>
                        <Button
                            onClick={() => {
                                this.priorityChange(row.id, row.priority - 1)
                            }}
                            className={"btn-circle"} color={"primary"}><i
                            className={"icon-feather-arrow-up"}/></Button>
                        <Button
                            onClick={() => {
                                this.priorityChange(row.id, row.priority + 1)
                            }}
                            color={"danger"}><i className={"icon-feather-arrow-down"}/></Button>
                    </ButtonGroup>
                </div>
            }
        },
        {
            Header: react_intl.formatMessage({id: "admin.payment.payment_rules.table_cols.rule"}),
            accessor: 'attributes.condition_name',
            sortKey: 'condition_name',
            width: "20%",
            Cell: row => {
                return row.value
            }
        },
        {
            Header: react_intl.formatMessage({id: "admin.payment.payment_rules.table_cols.status"}),
            accessor: null,
            width: "15%",
            Cell: data => {
                let row = data.row.original
                row = row.attributes
                return this.state.statusChanging !== row.id ? <Switch
                    onClick={() => {
                        this.setState({
                            statusChanging: row.id
                        })
                        PaymentRuleModel.modify(row.id, {
                            status: !row.status ? 1 : 0
                        }).then(r => r.json()).then(r => {
                            let self = this
                            this.tableRef.current.loadData();
                            setTimeout(function () {
                                self.setState({statusChanging: null})
                            }, 750)
                        })
                    }}
                    checked={row.status == 1}/> : <Spinner size={"sm"}/>
            }
        },
        {
            Header: react_intl.formatMessage({id: "admin.payment.payment_rules.table_cols.payment_gateway"}),
            accessor: 'attributes.payment_method',
            width: "15%",
            Cell: row => {
                let bankDetails = getBankObject(row.value.class) || {}
                return <div className={"text-center"}>
                    <div style={{
                        width: 110,
                        height: 30,
                        backgroundImage: `url(${(bankDetails.image || {}).default})`,
                        backgroundSize: "90%",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat"
                    }} height={75}/>
                </div>
            }
        },
        {
            Header: react_intl.formatMessage({id: "admin.payment.payment_rules.table_cols.actions"}),
            accessor: null,
            width: "20%",
            Cell: data => {
                let row = data.row.original
                row = row.attributes
                return <ButtonGroup size={"sm"}>
                    <Button color={"info"} onClick={() => {
                        this.setState({
                            editRule: row
                        })
                        this.ruleModalToggle()
                    }}>{react_intl.formatMessage({id:"admin.payment.payment_rules.edit"})} <i className={"icon-line-awesome-pencil"}/></Button>
                    <Button
                        onClick={() => {
                            this.setState({
                                editRule: row
                            }, this.deleteRuleToggle)
                        }}
                        color={"danger"}>{react_intl.formatMessage({id:"admin.payment.payment_rules.delete"})} <i className={"icon-line-awesome-close"} /></Button>
                </ButtonGroup>
            }
        }
    ]

    modifyRow = (ruleKey, name, value) => {
        let {editRule} = this.state
        let rows = [...editRule.details]
        let relatedRow = rows[ruleKey]
        relatedRow[name] = value
        rows[ruleKey] = relatedRow
        editRule.details = rows

        this.setState({
            editRule
        })
    }

    removeRow = (ruleKey) => {
        let {editRule} = this.state
        let rows = [...editRule.details]
        if (ruleKey > -1) {
            rows = rows.filter((item, key) => key !== ruleKey)
        }
        editRule.details = rows
        this.setState({editRule})
    }

    addRow = () => {
        let {editRule} = this.state
        let rows = [...editRule.details]
        rows.push({name: "All", "value": ""})
        editRule.details = rows
        this.setState({editRule})
    }

    componentDidMount() {
        document.title = GetTitle(react_intl.formatMessage({id: "admin.payment.payment_rules.title"}))
    }

    render() {
        let {editRule} = this.state
        let editRuleConditions = []
        if (typeof editRule === "object" && Object.keys(editRule).length)
            editRuleConditions = editRule.details || []
        if (editRuleConditions.length === 0) {
            editRuleConditions = [{
                "name": "All", "value": ""
            }]
        }

        let {openSnackbar} = this.props
        return <div>

            <StaticPanelTopBar
                title={react_intl.formatMessage({id: "admin.payment.payment_rules.title"})}
                extra={<div className={"padding-top-25 text-right"}>
                    <Link
                        className={"btn btn-info"}
                        to={"/admin/payment-methods"}
                    ><FormattedMessage id={"admin.payment.payment_rules.edit_methods"}/> <i
                        className={"icon-line-awesome-exchange"}/></Link>
                    {" "}
                    <Button color={"primary"} onClick={this.createRuleToggle}><FormattedMessage
                        id={"admin.payment.payment_rules.add_new"}/> <i className={"icon-feather-plus"}/></Button>
                </div>}
            />

            <div className="dashboard-box p-3 margin-top-100">

                <DataTable
                    afterDraw={() => {
                        this.resetChanging()
                    }}
                    sortable={true}
                    pageSize={25}
                    order={"asc"}
                    orderBy={"priority"}
                    ref={this.tableRef}
                    dataQuery={{
                        url: "/api/v2/payment-forwarding",
                        include: ["details"],
                        filters: {}
                    }}
                    columns={this.columns}
                />

                <ConfirmationModalAlert
                    isOpen={this.state.deleteRule}
                    toggle={this.deleteRuleToggle}
                    title={react_intl.formatMessage({id: "admin.payment.payment_rules.delete_title"})}
                    description={react_intl.formatMessage({id: "admin.payment.payment_rules.delete_desc"}, {
                        name: this.state.editRule.condition_name
                    })}
                    buttonLoading={this.state.deleteLoading}
                    buttonAction={() => {
                        this.setState({deleteLoading: true})
                        PaymentRuleModel.delete(this.state.editRule.id)
                            .then(r => {
                                this.setState({
                                    editRule: defaultRule
                                }, () => {
                                    this.deleteRuleToggle()
                                    this.tableRef.current.loadData()
                                })
                            }).catch(r => ApiErrorThrow(r, openSnackbar))
                    }}
                />

                <ConfirmationModalAlert
                    buttonLoading={this.state.createLoading}
                    toggle={this.createRuleToggle}
                    isOpen={this.state.createRule}
                    buttonAction={() => {
                        this.setState({createLoading: true})
                        PaymentRuleModel.create(Object.assign(defaultRule, {
                            condition_name: this.state.createRuleName,
                            payment_method: this.state.createRuleMethod,
                            details: [{
                                name: "All",
                                value: ""
                            }]
                        })).then(r => r.json()).then(r => {
                            this.createRuleToggle()
                            this.tableRef.current.loadData()
                        }).catch(r => ApiErrorThrow(r, openSnackbar))
                    }}
                >
                    <Row className={"text-left"}>
                        <Col>
                            <Label className={"w-100"}>
                                <FormattedMessage id={"admin.payment.payment_rules.create.rule_name"}/>
                                <InputWithIcon onChange={(e) => {
                                    this.setState({
                                        createRuleName: e.target.value
                                    })
                                }}/>
                            </Label>
                        </Col>
                    </Row>
                    <Row className={"text-left"}>
                        <Col>
                            <Label className={"w-100"}>
                                <FormattedMessage id={"admin.payment.payment_rules.create.method"}/>
                                <PaymentMethodSelect
                                    isMulti={false}
                                    onChange={(e) => {
                                        this.setState({createRuleMethod: e.value})
                                    }}
                                />
                            </Label>
                        </Col>
                    </Row>
                </ConfirmationModalAlert>

                <Modal isOpen={this.state.ruleModal}
                       toggle={this.ruleModalToggle}
                       returnFocusAfterClose={false}
                       size={"xl"}
                >
                    <ModalHeader toggle={this.ruleModalToggle}><FormattedMessage
                        id={"admin.payment.payment_rules.edit.title"}/></ModalHeader>
                    <ModalBody>
                        <Row className={"bg-light border-top border-bottom pt-2 mb-3"}>
                            <Col>
                                <Label><FormattedMessage id={"admin.payment.payment_rules.edit.rule_name"}/></Label>
                                <Input name={"name"} value={this.state.editRule.condition_name}
                                       onChange={(e) => {
                                           let editRule = this.state.editRule
                                           editRule.condition_name = e.target.value
                                           this.setState({editRule})
                                       }}
                                />
                            </Col>
                            <Col>
                                <Label><FormattedMessage id={"admin.payment.payment_rules.edit.status"}/></Label>
                                <div className={"d-block"}>
                                    <Switch checked={this.state.editRule.status} onClick={() => {
                                        let editRule = this.state.editRule
                                        editRule.status = !editRule.status
                                        this.setState({editRule})
                                    }} name={"status"}/>
                                </div>
                            </Col>
                            <Col>
                                <Label><FormattedMessage
                                    id={"admin.payment.payment_rules.edit.payment_method"}/></Label>
                                <PaymentMethodSelect
                                    isMulti={false}
                                    onChange={(e) => {
                                        let editRule = this.state.editRule
                                        editRule.payment_method = e.value
                                        this.setState({editRule})
                                    }}
                                    defaultValue={{
                                        label: this.state.editRule.payment_method.name,
                                        value: this.state.editRule.payment_method.id
                                    }}
                                />
                            </Col>
                        </Row>

                        <div className={"d-inline"}>
                            <h4><FormattedMessage id={"admin.payment.payment_rules.edit.rule_details"}/></h4>
                        </div>

                        <table className={"table"}>
                            <tbody>
                            {
                                this.state.editRule ? editRuleConditions.map(
                                    (item, key) => {
                                        return <PaymentRuleSingle
                                            ruleKey={key}
                                            removeRow={this.removeRow}
                                            addRow={this.addRow}
                                            modifyRow={this.modifyRow}
                                            details={item.value}
                                            type={item.name}
                                        />
                                    }
                                ) : ""}
                            </tbody>
                        </table>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color={"danger"}
                            loading={this.state.ruleSaving}
                            onClick={() => {
                                this.setState({
                                    editRule: defaultRule
                                }, this.ruleModalToggle)
                            }}
                            size={"sm"}><FormattedMessage id={"admin.payment.payment_rules.edit.cancel"}/></Button>
                        <ButtonLoad
                            loading={this.state.ruleSaving}
                            onClick={() => {
                                let cloneRule = {...editRule}
                                delete cloneRule.id
                                if (typeof cloneRule.payment_method === "object")
                                    cloneRule.payment_method = cloneRule.payment_method.id
                                this.setState({ruleSaving: true})
                                PaymentRuleModel.modify(this.state.editRule.id, cloneRule).then(r => r.json())
                                    .then(r => {
                                        this.ruleModalToggle()
                                        openSnackbar(react_intl.formatMessage({id: "admin.payment.payment_rules.edit.saved"}))
                                        this.tableRef.current.loadData()
                                    })
                            }}
                            color={"primary"} size={"sm"}
                            text={react_intl.formatMessage({id: "admin.payment.payment_rules.edit.save"})}
                        />
                    </ModalFooter>
                </Modal>

            </div>
        </div>;
    }
}

export default withSnackbar(PaymentRules, defaultSnackbarOptions)
import React from "react"
import {Spinner} from "reactstrap";
class VisitorIPAddress extends React.Component {
    state ={
        ip: ""
    }

    loadIp = () => {
        fetch('https://ipinfo.io/ip').then(r => r.text()).then(r=>this.setState({ip:r}))
    }

    componentDidMount() {
        this.loadIp()
    }

    render() {
        return this.state.ip ? <strong>{this.state.ip}</strong> : <Spinner size={"sm"} />;
    }
}

export default VisitorIPAddress
import React from "react"
import {Button, ButtonGroup, Modal, ModalBody, ModalHeader, TabContent, Table, TabPane} from "reactstrap";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {withSnackbar} from "react-simple-snackbar";

class TemplateShortCodes extends React.Component {
    state = {
        active_tab: 1,
        modal: false
    }

    toggle = () => {
        this.setState({modal: !this.state.modal})
    }

    toggleTab = (key) => {
        this.setState({
            active_tab: key
        })
    }

    render() {
        let shortCodes = this.props.codes || []
        let {active_tab} = this.state
        let {openSnackbar} = this.props
        return <>
            <Modal centered={true} size={"lg"} isOpen={this.state.modal} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle}>
                    <ButtonGroup size={"sm"}>
                        {typeof shortCodes === "object" && Object.keys(shortCodes).length && shortCodes.map((item, key) => {
                            if (typeof item.codes === "undefined" || (typeof item.codes === "object" && Object.keys(item.codes).length === 0))
                                return ""
                            return <Button
                                color={active_tab === key ? "primary" : "secondary"}
                                onClick={() => this.toggleTab(key)}>
                                {item.icon && item.icon} {item.label}
                            </Button>
                        })}
                    </ButtonGroup>
                </ModalHeader>
                <ModalBody>
                    <TabContent activeTab={this.state.active_tab}>
                        {shortCodes.map((group, key) => {
                            return <TabPane
                                tabId={key}>
                                <Table>
                                    <tbody>
                                    {typeof group.codes === "object" && Object.keys(group.codes).length && group.codes.map(item => {
                                        return <CopyToClipboard text={`{{${item.slug}}}`} onCopy={() => {
                                            openSnackbar(<div>
                                                <i className={"icon-line-awesome-check-circle-o"}/> Kısa kod
                                                kopyalandı.
                                            </div>)
                                            this.toggle()
                                        }}>
                                            <tr className={"d-inline-block p-0 pr-2 pl-2 shortcode-single"}>
                                                <th className={"w-100 border-0 p-0"}>
                                                    <i className={"icon-feather-hash"} /> {item.title}
                                                </th>
                                                <td className={"border-0 p-0"}>
                                                    <small
                                                        className={"p-1 opacity-50 font-italic rounded"}>{'{{'}{item.slug}{'}}'}</small>
                                                </td>
                                            </tr>
                                        </CopyToClipboard>
                                    })}
                                    </tbody>
                                </Table>
                            </TabPane>
                        })}
                    </TabContent>
                </ModalBody>
            </Modal>
            <Button onClick={this.toggle} size={"sm"} color={"info"}>Kısa Kodlar <i className={"icon-feather-hash"}/></Button>
        </>;
    }
}

export default withSnackbar(TemplateShortCodes)
import React, {useEffect} from 'react';
import {createIntl, createIntlCache, FormattedMessage, IntlProvider} from "react-intl";
import ReactDOM from 'react-dom';
import './assets/css/Main.css';
import "./assets/3rd_party/animate.css/animate.min.css"
import reportWebVitals from './reportWebVitals';
import Routes from "./Routes";
import {createBrowserHistory} from "history";
import {Router} from "react-router-dom";
import SnackbarProvider from 'react-simple-snackbar'
import SettingModel from "./Models/SettingModel";
import {backendBaseUrl, default_lang, frontendBaseUrl, get_profile_info} from "./Config";
import {GlobalAuthorizationObject} from "./Lib/Authorization";
import ClientModel from "./Models/ClientModel";

const admin_tr_lang = require('./translations/admin/tr.json')
const admin_en_lang = require('./translations/admin/en.json')

const messages = {
    'en': admin_en_lang,
    'tr': admin_tr_lang
}
const available_lang = Object.keys(messages)

require('dotenv').config()
const history = createBrowserHistory();

const App = (props) => {
    return <>
        <Router history={history}>
            <SnackbarProvider>
                <Routes history={history} onEnter={() => alert('0, 0')}/>
            </SnackbarProvider>
        </Router>
    </>
}

const default_settings = {
    "owner_business_details": {
        "company_name": "",
        "address": "",
        "tax_office": "",
        "tax_number": "",
        "email": "",
        "phone": ""
    },
    "owner_social_media": {
        "facebook": "",
        "twitter": "",
        "linkedin": "",
        "youtube": ""
    },
    "system_general": {
        "deny_registers": "no",
        "site_name": "",
        "system_url": frontendBaseUrl,
        "system_api_base_url": backendBaseUrl,
        "logo_url": "https://tahsilat.grilabs.net/uploads/paymendologo-60548d87e2296.png",
        "default_language": default_lang
    },
    "verification_config": {
        "expiry": "180",
        "code_length": "6"
    },
    "system_preferences": {
        "password_reset_config": "180"
    },
    "menus": {
        "top-menu": []
    },
    "pages": {
        "default": {
            title: "Sayfa bulunamadı",
            content: "<></>"
        }
    },
    "anonymous_payment": {
        "allow_payment": "no"
    },
    "payment_methods": [],
    "default_currency": {
        code: "TRY",
        prefix: "₺",
        suffix: "",
        currency_decimal: 2
    }
}

export const save_main_config = (config_name, values) => {
    localStorage.setItem(`config_${config_name}`, JSON.stringify(values))
}

export const get_main_config = (config_name, setting_name) => {
    let config = localStorage.getItem(`config_${config_name}`)
    if (typeof config === "string") {
        config = JSON.parse(config)
    }
    if (config_name === "top-menu")
        return JSON.parse(config);

    if (typeof setting_name === "undefined")
        return config;

    let result = config !== null && typeof config[setting_name] !== "undefined" && typeof default_settings[config_name] !== "undefined" ? config[setting_name] : default_settings[config_name][setting_name]

    if (config_name === "pages" && typeof result === "undefined") {
        result = default_settings["pages"]["default"];
        window.location.href = '/'
    }

    if (config_name === "menus" && typeof result === "object" && typeof result.content === "string")
        result = result.content

    if (typeof result === "string" && ((result.substr(0, 1) === "[") || (result.substr(0, 1) === "{")))
        return JSON.parse(result)
    return result
}


// ReactDOM.render(<div style={{padding: 50}}>Yükleniyor</div>, document.getElementById('root'));
const loadFirst = function () {
    return new Promise(resolve => {
        SettingModel.getPublicSettings().then(r => r.json())
            .then(r => {
                let isInstalled = false
                if (typeof r === "object") {
                    Object.keys(r).forEach(key => {
                        if (key === "owner_business_details")
                            isInstalled = true
                        save_main_config(key, r[key])
                    })
                    if (isInstalled)
                        resolve(true)
                    else
                        resolve("not-installed")
                }
            })
    })
}
export const get_app_lang = () => {
    let language = get_main_config("system_general", "default_language")
    let langInStorage = localStorage.getItem('language')

    if (typeof langInStorage === "string" && langInStorage !== "")
        language = langInStorage

    if (language === "" && ["tr", "en"].indexOf(navigator.language.split(/[-_]/)[0].toLowerCase()) !== -1)
        language = navigator.language.split(/[-_]/)[0].toLowerCase()

    if (language !== "" && available_lang.indexOf(language) === -1)
        language = get_main_config("system_general", "default_language") || default_lang;

    return language
}

export const set_app_lang = (lang, stop_api_call) => {
    localStorage.setItem("language", lang)
    if ((typeof stop_api_call === "undefined" || !stop_api_call) && GlobalAuthorizationObject.isLoggedIn()) {
        return ClientModel.edit(get_profile_info("id"), {
            language: lang
        })
    } else {
        return new Promise(function (resolve) {
            resolve(true);
        })
    }
}

export const react_intl = createIntl({
    locale: get_app_lang(),
    messages: messages[get_app_lang()]
});

const getApp = () => {
    let language = get_app_lang()
    // let langInStorage = localStorage.getItem('language')

    // if ((typeof langInStorage === "string" && langInStorage !== "") || langInStorage === null)
    // set_app_lang(language)

    let root = document.documentElement
    // root.style.setProperty("--paymendo-primary-color", "#416f8e")
    // root.style.setProperty("--paymendo-primary-hover-color", "#216f8e")
    // root.style.setProperty("--paymendo-dark-color", "#000")
    // root.style.setProperty("--paymendo-dark-hover-color", "#333")
    // root.style.setProperty("--paymendo-info-hover-color", "#333")

    ReactDOM.render(
        <IntlProvider locale={language} messages={messages[language]}
                      defaultRichTextElements={{
                          strong: (chunks) => <strong>{chunks}</strong>,
                          span: (chunks) => <span>{chunks}</span>,
                          b: (chunks) => <b>{chunks}</b>,
                          u: (chunks) => <u>{chunks}</u>,
                          br: () => <br/>,
                          p: (chunks) => <p>{chunks}</p>
                      }}
        >
            <App/>
        </IntlProvider>,
        document.getElementById('root')
    );
}

if (window.location.pathname === "/setup") {
    getApp()
} else {
    loadFirst().then(r => {
        if (typeof r === "string" && r === "not-installed")
            window.location.href = frontendBaseUrl + '/setup'
        else
            getApp()
    }).catch(r => getApp())
}
reportWebVitals();
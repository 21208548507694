import React from "react"
import {Button, Col, Label, Row, Table} from "reactstrap";
import ConfirmationModalAlert from "./ModalAlerts/ConfirmationModalAlert";
import InputWithIcon from "../Elements/InputWithIcon";
import GlSelect from "./GlSelect";
import CurrencyModel from "../Models/CurrencyModel";
import OrderStatus, {OrderStatuses} from "../Elements/OrderStatus";
import MultiRadio from "../Elements/MultiRadio";
import ShowPrice from "../Elements/ShowPrice";
import {Alert} from "@material-ui/lab";
import OrderModel from "../Models/OrderModel";
import ClientSelect from "../Elements/ClientSelect";
import {Select38Px} from "../Conts";
import {react_intl} from "../index";
import {FormattedMessage} from "react-intl";
import CustomCheckbox from "../Elements/CustomCheckbox";
import {feature__use_balance} from "../Config";

const accountBalanceFeature = feature__use_balance;
class CreateNewOrder extends React.Component {
    state = {
        use_balance: false,
        modalOpened: false,
        activeStep: 1,
        order_details: {
            user_id: this.props.client || {},
            status: "draft"
        },
        currencyWaiting: false,
        selectedCurrency: {},
        step1Error: false
    }

    toggle = () => {
        let state = {}
        state.modalOpened = !this.state.modalOpened
        this.setState(state)
    }

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this)
    }

    step1ErrorClose = () => {
        this.setState({
            step1Error: false
        })
    }

    handleStep1Error = (message) => {
        this.setState({step1Error: message})
        let self = this
        setTimeout(function () {
            self.setState({
                step1Error: false
            })
        }, 10 * 1000)
    }

    stepNext = () => {
        let {order_details} = this.state
        let user_id = this.getUserId();
        if (typeof user_id !== "object" || isNaN(parseInt(user_id.value)) || parseInt(user_id.value) <= 0)
            return this.handleStep1Error(react_intl.formatMessage({id: "components.create_new_order_customer_choice"}));

        if (typeof order_details.amount === "undefined" || parseFloat(order_details.amount) <= 0)
            return this.handleStep1Error(react_intl.formatMessage({id: "components.create_new_order_proper_amount"}));

        this.setState({
            activeStep: 2
        })
    }

    getUserId = () => {
        let user_id = this.state.order_details.user_id;
        if (user_id === null)
            return false

        if (typeof user_id.value === "undefined")
            user_id = this.props.client
        return user_id
    }

    render() {
        let {activeStep, currencyWaiting, step1Error} = this.state,
            user_id = this.getUserId()
        let spacing = typeof this.props.spacing !== "boolean" || (typeof this.props.spacing === "boolean" && this.props.spacing !== false)

        return <>
            <ConfirmationModalAlert
                color={"success"}
                toggle={this.toggle}
                isOpen={this.state.modalOpened}
                title={react_intl.formatMessage({id: "components.create_new_order_new_invoice"})}
                description={activeStep === 1 ? react_intl.formatMessage({id: "components.create_new_order_enter_new_invoice"}) : <>
                    <i
                        className={"icon-feather-alert-triangle"}/> <FormattedMessage
                    id={"components.create_new_order_created_invoice"}/></>}
                icon={"icon-feather-file-plus"}
                buttonAction={() => {
                    if (activeStep === 1) {
                        this.stepNext()
                    } else {
                        this.create()
                    }
                }}
                extraButtons={activeStep === 2 && <Button
                    onClick={() => this.stepPrev()}
                    color={"danger"}>
                    <i className={"icon-feather-chevron-left"}/> Değiştir
                </Button>}
            >
                {activeStep === 1 && step1Error &&
                <Alert className={"mb-2"} icon={<i className={"icon-feather-alert-triangle"}/>} color={"error"}
                       action={<Button color={"inherit"} size={"sm"} onClick={() => this.step1ErrorClose()}><i
                           className={"icon-line-awesome-times"}/></Button>}>{step1Error}</Alert>}
                {activeStep === 1 && <div className={"text-left"}>
                    {!this.props.client && <>
                        <Label><FormattedMessage id={"components.create_new_order_customer"}/></Label>
                        <ClientSelect
                            extrastyle={{
                                container: (provided, state) => ({
                                    ...provided,
                                    zIndex: 999
                                })
                            }}
                            isMulti={false}
                            onChange={(e) => {
                                let order_details = {...this.state.order_details}
                                order_details.user_id = e
                                this.setState({order_details})
                            }}
                        />
                    </>}
                    <Row>
                        <Col sm={12} className={"mt-2"}>
                            <Label><FormattedMessage id={"components.create_new_order_amount"}/></Label>
                        </Col>
                        <Col sm={8}>
                            <InputWithIcon
                                value={this.state.order_details.amount}
                                icon={currencyWaiting ? "icon-line-awesome-spinner" : "icon-line-awesome-money"}
                                input_type={"number"}
                                styles={Select38Px}
                                onValueChange={(e) => {
                                    let order_details = {...this.state.order_details}
                                    order_details.amount = e.floatValue
                                    this.setState({order_details})
                                }}
                                currency={this.state.selectedCurrency}
                            />
                        </Col>
                        <Col sm={4}>
                            <GlSelect
                                url={'/currency'}
                                firstSelected={true}
                                placeholder={react_intl.formatMessage({id: "components.create_new_order_select"})}
                                closeMenuOnSelect={true}
                                value={{
                                    value: this.state.selectedCurrency.id,
                                    label: this.state.selectedCurrency.code
                                }}
                                defaultValue={this.state.selectedCurrency.id ? {
                                    value: this.state.selectedCurrency.id,
                                    label: this.state.selectedCurrency.code
                                } : false}
                                onChange={async (value) => {
                                    this.setState({currencyWaiting: true})
                                    await CurrencyModel.getById(value.value).then(r => r.json()).then(item => {
                                        item = item.data.attributes
                                        this.setState({
                                            currencyWaiting: false,
                                            selectedCurrency: {
                                                prefix: item.prefix,
                                                suffix: item.suffix,
                                                decimal: item.decimal,
                                                id: item.id,
                                                code: item.code
                                            }
                                        })
                                    })
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} className={"mt-2"}>
                            <Label><FormattedMessage id={"components.create_new_order_situation"}/></Label>
                            <div className={"d-block"}>
                                <MultiRadio
                                    name={"invoice_status"}
                                    value={this.state.order_details.status}
                                    options={OrderStatuses().slice(2)}
                                    onChange={(e) => {
                                        let order_details = {...this.state.order_details}
                                        order_details.status = e.target.value
                                        this.setState({order_details})
                                    }}
                                />
                            </div>
                        </Col>
                        <Col sm={12} className={"mt-2"}>
                            <Label><FormattedMessage id={"components.create_new_order_note"}/></Label>
                            <div className={"d-block"}>
                                <InputWithIcon
                                    icon={"icon-line-awesome-sticky-note-o"}
                                    value={this.state.order_details.notes}
                                    name={"notes"} onChange={(e) => {
                                    let order_details = {...this.state.order_details}
                                    order_details.notes = e.target.value
                                    this.setState({order_details})
                                }}
                                />
                            </div>
                        </Col>
                    </Row>
                    {
                        accountBalanceFeature ? <div className={"my-2 p-2 bg-light cursor-pointer"} onClick={() => {
                            let order_details = {...this.state.order_details}
                            if (!this.state.use_balance)
                                order_details.status = this.state.use_balance ? 'draft' : 'unpaid'
                            this.setState({
                                use_balance: !this.state.use_balance,
                                order_details
                            })
                        }
                        }>
                            <Row>
                                <Col sm={8}>
                                    <Label>Bakiyeden kullan?</Label>
                                </Col>
                                <Col sm={4} className={"text-right"}>
                                    <CustomCheckbox
                                        checked={this.state.use_balance}
                                        onClick={(e) => {
                                            this.setState({
                                                use_balance: e.target.checked
                                            });
                                        }}/>
                                </Col>
                            </Row>
                        </div> : ""
                    }
                </div>}
                {activeStep === 2 && <div>

                    <Table>
                        <tbody>
                        <tr>
                            <th width={"35%"} className={"text-right"}><FormattedMessage
                                id={"components.create_new_order_second_customer"}/></th>
                            <td className={"text-left"}>{user_id.label}</td>
                        </tr>
                        <tr>
                            <th width={"35%"} className={"text-right"}><FormattedMessage
                                id={"components.create_new_order_second_amount"}/></th>
                            <td className={"text-left"}>
                                <ShowPrice amount={this.state.order_details.amount}
                                           currencyprefix={this.state.selectedCurrency.prefix}
                                           currencysuffix={this.state.selectedCurrency.suffix}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th width={"35%"} className={"text-right"}>Durum:</th>
                            <td className={"text-left"}>
                                <OrderStatus status={this.state.order_details.status}/>
                            </td>
                        </tr>
                        <tr>
                            <th width={"35%"} className={"text-right"}><FormattedMessage
                                id={"components.create_new_order_order_note"}/></th>
                            <td className={"text-left"}>
                                {this.state.order_details.notes || " - "}
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </div>}
            </ConfirmationModalAlert>
            {spacing ? <Label className={"w-100 d-none d-md-block"}>&nbsp;</Label> : ""}
            <Button
                onClick={this.toggle}
                color={"dark"} {...this.props}>{this.props.text || react_intl.formatMessage({id: "components.create_new_order_create_invoice"})}</Button>
        </>;
    }

    stepPrev() {
        this.setState({
            activeStep: 1
        })
    }

    create = () => {
        let details = {...this.state.order_details}
        let user_id = this.getUserId()
        details.user_id = user_id.value + ''
        details.currency_code = this.state.selectedCurrency.code;

        let meta = [];
        if(this.state.use_balance)
        {
            meta.push({
                key: "usebalance",
                value: "1"
            })
        }

        OrderModel.create(details, meta).then(r => r.json()).then(item => {
            let _id = item.data.id
            if (typeof this.props.onCreated === "function") {
                this.props.onCreated(item)
            } else {
                window.location.href = `/admin/orders/${_id}`
                return false;
            }
        })
    }
}

export default CreateNewOrder

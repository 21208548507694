import GlRequest from "../Lib/GlRequest";

class TransactionModel {
    create = (orderId, details) => {
        if (details.isRefund)
            details.total_amount = details.total_amount * -1
        details = {
            data: {
                attributes: details
            }
        }
        return GlRequest.post('/api/v2/transaction', details, 'json')
    }
    delete = (transactionId) => {
        return GlRequest.delete('/api/v2/transaction/' + transactionId)
    }
    getById = (id, include) => {
        let inc = ""
        if (typeof include === "object")
            inc = include.join(",")

        return GlRequest.get('/api/v2/transaction/' + id, {
            params: {
                include: inc
            }
        })
    }
    modify = (transactionId, details) => {
        details = {
            data: {
                attributes: details
            }
        }
        return GlRequest.patch('/api/v2/transaction/' + transactionId, details, "json")
    }

    getOrderTrans(orderId) {
        return GlRequest.get('/api/v2/transaction', {
            params: {
                order_id: orderId,
                include: "currency"
            }
        })
    }

    refund(transactionId, amount) {
        let data = {
            data: {
                attributes: {
                    transaction_id: transactionId,
                    amount: amount
                }
            }
        }
        return GlRequest.post('/api/v2/payment/refund', data, "json")
    }
}

export default TransactionModel = new TransactionModel()
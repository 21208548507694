import React from "react"
import {GetTitle} from "../../Routes/AdminRoute";
import {Badge, Button, Card, Col, Row} from "reactstrap";
import ProfileWidget from "./Home/ProfileWidget";
import ClientWidget from "./Home/ClientWidget";
import {
    FaEnvelopeOpenText,
    FaFileInvoice, FaSms,
    GiCheckMark, GiEnvelope,
    GiMoneyStack,
    GiPhone, GrAnnounce,
    GrContactInfo,
    HiDeviceMobile,
    HiIdentification, ImPriceTags
} from "react-icons/all";
import {ArrowForward, Edit} from "@material-ui/icons";
import InvoiceWidget from "./Home/InvoiceWidget";
import ClientModel from "../../Models/ClientModel";
import {ApiErrorThrow, get_profile_info} from "../../Config";
import {Link} from "react-router-dom";
import InvoiceInformation from "./Home/InvoiceInformation";
import StatBoxes from "../../Components/StatBoxes";
import UnpaidInvoices from "../../Components/CpWidget/UnpaidInvoices";
import PaidInvoices from "../../Components/CpWidget/PaidInvoices";
import VerificationBox from "../../Components/CpWidget/VerificationBox";
import Announcements from "./Home/Announcements";
import {load} from "dotenv";
import {Skeleton} from "@material-ui/lab";
import {injectIntl} from "react-intl";
import {FormattedMessage} from "react-intl";

class ClientHome extends React.Component {
    componentDidMount() {
        let {formatMessage} = this.props.intl
        document.title = GetTitle(formatMessage({id:"client.client_home.home_txt"}))
        this.loadStat()
    }

    state = {
        unpaidInvoices: 0,
        paidTotal: 0,
        phoneVerification: false,
        emailVerification: false,
        profile_stats: {},
        loading: true,
        user_fields: []
    }

    loadStat = () => {
        ClientModel.getClientStats(get_profile_info("id")).then(r => r.json())
            .then(r => {
                this.setState({
                    profile_stats: r.data,
                    user_fields: r.data.user_fields
                }, () => {
                    let self = this
                    setTimeout(function () {
                        self.setState({loading: false})
                    }, 250)
                })
            }).catch(r => {
            this.setState({loading: false})
            ApiErrorThrow(r)
        })
    }

    render() {
        let {profile_stats, loading, user_fields} = this.state
        let {formatMessage} = this.props.intl

        if (typeof user_fields !== "object")
            user_fields = []

        if (typeof profile_stats !== "object")
            profile_stats = {}

        let {invoice_stats} = profile_stats
        if (typeof invoice_stats !== "object")
            invoice_stats = {}

        let phoneVerified = user_fields.filter(item => {
            return item.field_name === "sms_verification" && item.field_content === "yes"
        }).length
        let emailVerified = user_fields.filter(item => {
            return item.field_name === "email_verification" && item.field_content === "yes"
        }).length


        return <div className={"w-100 pl-3 pr-3 pt-3"}>
            <Row> {loading}
                <UnpaidInvoices
                    loading={loading}
                    onClick={() => {
                        this.props.history.push('/cp/orders')
                    }}
                    total={(invoice_stats.unpaid || {}).amount}
                    count={(invoice_stats.unpaid || {}).count}/>
                <PaidInvoices
                    loading={loading}
                    onClick={() => {
                        this.props.history.push('/cp/orders')
                    }}
                    total={(invoice_stats.paid || {}).amount} count={(invoice_stats.paid || {}).count}/>

                <Col sm={12} lg={6}>
                    <Row>
                        <Col sm={12} lg={4}>
                            {loading ? <Skeleton variant={"rect"} width={"100%"} height={105} className={"rounded"} /> :
                                <Link to={"/cp/quick-payment"}
                                      className={"paymendo-grad btn btn-block ml-0 mt-0 mb-3 pt-3 pb-3"}
                                ><FormattedMessage id={"client.client_home.pay_quickly"}/></Link>}
                        </Col>
                        <Col sm={12} lg={4} className={"text-center"}>
                            <VerificationBox
                                loading={loading}
                                title={formatMessage({id:"client.client_home.verification_tel"})}
                                icon={<FaSms size={36}/>}
                                status={phoneVerified}
                            />
                        </Col>
                        <Col sm={12} lg={4} className={"text-center"}>
                            <VerificationBox
                                loading={loading}
                                title={formatMessage({id:"client.client_home.verification_e_mail"})}
                                icon={<FaEnvelopeOpenText size={36}/>}
                                status={emailVerified}
                            />
                        </Col>
                    </Row>
                </Col>

                <Col sm={12} md={6} className={"mt-3"}>
                    <ClientWidget
                        title={<><HiIdentification/> <FormattedMessage id={"client.client_home.my_account_txt"}/></>}
                        link={'/cp/settings'}
                        icon={<Edit/>}
                        loading={loading}
                        actionText={formatMessage({id:"client.client_home.update_txt"})}
                    >
                        <ProfileWidget user={profile_stats.user}/>
                    </ClientWidget>
                </Col>

                <Col sm={12} md={6} className={"mt-3 client-home-slider"}>
                    <ClientWidget
                        title={<><GrAnnounce/> <FormattedMessage id={"client.client_home.campaign_announcements_txt"}/></>}
                        loading={loading}
                        link={"/cp/announcements"}
                        icon={<ArrowForward/>}
                    >
                        <Announcements history={this.props.history}/>
                    </ClientWidget>
                </Col>
                <Col sm={12} md={6} className={"pt-3"}>
                    <ClientWidget
                        loading={loading}
                        title={<><GrContactInfo/> <FormattedMessage id={"client.client_home.invoice_info"}/></>}
                        link={'/cp/billing-information'}
                        icon={<ArrowForward/>}
                    >
                        <InvoiceInformation addresses={profile_stats.addresses || []}/>
                    </ClientWidget>
                </Col>
            </Row>
            <p>&nbsp;</p>
        </div>;
    }
}

export default injectIntl(ClientHome)
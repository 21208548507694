import React from "react"
import {Input, Label} from "reactstrap";
import CustomCheckbox from "../../../../Elements/CustomCheckbox";
import Select from "react-select";
import {Select38Px} from "../../../../Conts";
import GlSelect from "../../../../Components/GlSelect";
import ContentModel from "../../../../Models/ContentModel";

class SystemSettingItem extends React.Component {
    state = {
        asyncValue: ""
    }

    load_data = () => {
        let {type, value} = this.props
        if (type === "page_select" && parseInt(value)) {
            ContentModel.getById(value).then(r => r.json())
                .then(r => {
                    this.setState({
                        asyncValue: {
                            label: r.data.attributes.name,
                            value: r.data.attributes.id
                        }
                    })
                })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.load_data()
        }
    }


    componentDidMount() {
        this.load_data()
    }

    render() {
        let {group, slug, label, handler, description, placeholder, type, value, options, min, max, column} = this.props
        if (typeof value === "undefined")
            value = ""

        if (typeof this.state.asyncValue === "object")
            value = this.state.asyncValue

        return <tr className={column === 2 ? "setting-row" : "setting-row d-inline-block mt-2 mb-2"}>
            <th className={column === 2 ? "" : "w-100 d-inline-block"}
                width={column === 2 ? "40%" : "100%"}>{label}</th>
            <td className={column === 2 ? "" : "w-100 d-inline-block"} width={column === 2 ? "60%" : "100%"}>
                {(type === "text" || type === "password") &&
                <Input value={value} data-group={group} type={type} name={slug} onChange={handler}
                       placeholder={placeholder}/>}
                {
                    (type === "textarea") &&
                    <textarea className={"form-control"} data-group={group} defaultValue={value} name={slug}
                              onChange={handler}
                              placeholder={placeholder}/>
                }
                {(type === "number") &&
                <Input value={value} data-group={group} type={type} name={slug} onChange={handler}
                       placeholder={placeholder} min={min} max={max}/>}
                {type === "checkbox" &&
                <CustomCheckbox data-group={group} checked={value === "yes"} name={slug} onChange={handler}/>}
                {type === "select" && <Select
                    styles={Select38Px}
                    data-group={group} onChange={(v) => {
                    handler({
                        target: {name: slug, value: v.value, dataset: {group: group}}
                    })
                }} options={options} value={options.find(item => item.value === value)}/>}
                {type === "page_select" && <GlSelect
                    isClearable={true}
                    searchKey={"name"}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    url={'/content?type=page&status=true'}
                    styles={Select38Px}
                    data-group={group} onChange={(v) => {
                    let n = ""
                    if (typeof v === "object" && v !== null)
                        n = {
                            label: v.label,
                            value: v.value
                        }

                    this.setState({
                        asyncValue: n
                    })
                    handler({
                        target: {name: slug, value: v !== null ? v.value : "", dataset: {group: group}}
                    })
                }}
                    value={value}
                />}
                {description && <><i className={"icon-feather-info"}/> {description}</>}
            </td>
        </tr>;
    }
}

export default SystemSettingItem
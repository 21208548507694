import {backendBaseUrl} from "../Config";
import {GlobalAuthorizationObject} from "./Authorization";

class GlRequest {
    isClient = false
    noRedirect = false

    setNoRedirect = () => {
        this.noRedirect = true
        return this
    }

    setClient = () => {
        this.isClient = true
        return this
    }

    request_main = (url, options) => {
        return fetch(url, options).then(result => {
            if (result.status === 401 && !this.noRedirect) {
                GlobalAuthorizationObject.doLogout().then(r => {
                    window.location.href = '/cp'
                })
                return result;
            }
            return result
        }).catch( (er) => {
            return new Promise((resolve, reject) => {
                resolve({})
            })
        } )
    }

    request_then = (result) => {
        if (!result.ok) {
            throw result;
        }
        return result;
    }

    create = (url, options) => {
        let self = this
        let no_auth = false
        const noAuthList = [
            "/config",
            "/login",
            "/request-password-reset",
            "/password-change",
            "/content",
            "/order",
            "/payment",
            "/install",
            '/currency'
        ]

        let auth
        auth = GlobalAuthorizationObject

        if (auth.tokenRefreshing) {
            return new Promise((res, rej) => {
                setTimeout(function () {
                    console.info("Token waiting - Token refresh during...", url)
                    res(self.create(url, options))
                }, 500)
            })
        }

        url = url.replace(backendBaseUrl, "")
        no_auth = noAuthList.filter(item => url.indexOf(item) === 0).length

        if (
            (url.indexOf("/payment") === 0 && auth.isLoggedIn()) ||
            (url.indexOf("/content") === 0 && auth.isLoggedIn()) ||
            (url.indexOf("/install") === 0 && auth.isLoggedIn())
        ) {
            no_auth = 0
        }

        if (url.indexOf("http") === -1)
            url = backendBaseUrl + url;

        let _headers = {};
        if (no_auth) {
            return this.request_main(url, options).then(result => this.request_then(result))
        }

        return auth.getAccessToken().then(token => {
            _headers['Authorization'] = token
            if (typeof options.headers === "object") {
                _headers = Object.assign(_headers, options.headers)
            }
            options.headers = _headers
            return this.request_main(url, options).then(result => this.request_then(result));
        })
    }

    post = (url, post_data, data_type, extra_options) => {
        if (typeof extra_options === "undefined")
            extra_options = {
                headers: {}
            }

        if (typeof extra_options.headers === "undefined")
            extra_options.headers = {}

        if (typeof data_type === "string" && data_type === "json") {
            post_data = JSON.stringify(post_data)
            extra_options.headers['Content-Type'] = "application/json";
        }

        let options = Object.assign({
            body: post_data,
            method: 'POST'
        }, extra_options)

        return this.create(url, options)
    }

    put = (url, post_data, data_type, extra_options) => {
        if (typeof extra_options !== "object")
            extra_options = {}
        extra_options.method = "PUT"
        return this.post(url, post_data, data_type, extra_options)
    }

    patch = (url, post_data, data_type, extra_options) => {
        if (typeof extra_options !== "object")
            extra_options = {}
        extra_options.method = "PATCH"
        return this.post(url, post_data, data_type, extra_options)
    }

    get = (url, extra_options) => {
        let options = Object.assign({
            method: 'GET',
            mode: 'cors'
        }, extra_options)

        if (typeof extra_options !== "undefined" && typeof extra_options.params === "object" && Object.keys(extra_options.params).length) {
            if (url.indexOf("?") === -1)
                url += '?'
            Object.keys(extra_options.params).forEach((key, number) => {
                if (number)
                    url += '&'
                if (typeof extra_options.params[key] === "string") {
                    url += `${key}=${extra_options.params[key]}`
                } else if (typeof extra_options.params[key] === "object") {
                    let group = extra_options.params[key];
                    Object.keys(extra_options.params[key]).forEach((key2, number2) => {
                        if (number2)
                            url += '&'
                        url += `${key}[${key2}]=${group[key2]}`
                    })
                }
            })
        }
        return this.create(url, options)
    }
    delete = (url, extra_options) => {
        let options = Object.assign({
            method: 'DELETE'
        }, extra_options)

        return this.create(url, options)
    }
}

const request = new GlRequest();

export function getErrorMessage(result) {
    if (typeof result !== "object" || result === null || typeof result.detail !== "string")
        return "Bir hata oluştu."
    return result.detail
}

export default request

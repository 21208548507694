import React from "react"
import {useRowSelect, useTable} from 'react-table'
import request from "../Lib/GlRequest";
import {
    Button,
    Col,
    Dropdown, DropdownItem,
    DropdownMenu, DropdownToggle,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Spinner, UncontrolledDropdown
} from "reactstrap";
import Select from "react-select";
import classnames from "classnames";
import moment from "moment";
import {ApiErrorThrow, dateonly_format} from "../Config";
import {AiFillFileExcel} from "react-icons/all";
import ExcelExportButton from "./ExcelExportButton";
import {react_intl} from "../index";

const pageSizes = () => [
    {label: react_intl.formatMessage({id: "components.data_table_five_page"}), value: 5},
    {label: react_intl.formatMessage({id: "components.data_table_ten_page"}), value: 10},
    {label: react_intl.formatMessage({id: "components.data_table_twenty_five_page"}), value: 25},
    {label: react_intl.formatMessage({id: "components.data_table_fifty_page"}), value: 50},
    {label: react_intl.formatMessage({id: "components.data_table_one_hundred_page"}), value: 100},
    {label: react_intl.formatMessage({id: "components.data_table_two_hundred_and_fifty_page"}), value: 250}
]

function Table({columns, data, orderChange, orderBy, order, noDataText, rowClick, noCheckbox}) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setSortBy,
        selectedFlatRows,
        state: {selectedRowIds},
    } = useTable(
        {
            noCheckbox,
            columns,
            data,
            manualSortBy: true
        },
        useRowSelect,
        useRowSelect,
        hooks => {
            const checkCell = window.innerWidth < 720 || noCheckbox ? null : {
                id: 'selection',
                width: "5%",
                // The header can use the table's getToggleAllRowsSelectedProps method
                // to render a checkbox
                Header: ({getToggleAllRowsSelectedProps}) => (
                    <div>
                        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                    </div>
                ),
                // The cell can use the individual row's getToggleRowSelectedProps method
                // to the render a checkbox
                Cell: ({row}) => (
                    <div>
                        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                    </div>
                ),
            }
            if (checkCell !== null) {
                hooks.visibleColumns.push(columns => [
                    checkCell,
                    ...columns,
                ])
            }
        }
    )

    // Render the UI for your table
    let props = getTableProps()
    return (
        <table className={"table gl-datatable table-striped"} {...props}>
            <thead className={"gl-table-header"}>
            {headerGroups.map(headerGroup => {
                return <tr {...headerGroup.getHeaderGroupProps()}
                >
                    {
                        headerGroup.headers.map(column => {
                                const sortable = typeof column.sortKey === "string" && column.sortKey !== ""
                                return <th
                                    {...column.getHeaderProps({
                                        style: {width: column.width}
                                    })}
                                    className={sortable ? "cursor-pointer" : ""}
                                    onClick={() => {
                                        if (!sortable)
                                            return
                                        orderChange(column.sortKey)
                                    }}>{column.render('Header')}
                                    {' '}{sortable && orderBy === column.sortKey ?
                                        <Button className={"p-0 ml-1 btn mt-n1"} style={{width: 20, height: 20}}
                                                size={"sm"} color={""}>
                                            {order === 'asc' ? <i className={"icon-line-awesome-angle-up"}/> :
                                                <i className={"icon-line-awesome-angle-down"}/>}
                                        </Button> : ""}
                                </th>
                            }
                        )
                    }
                </tr>
            })}
            </thead>
            <tbody {...getTableBodyProps({
                className: "gl-table-body"
            })}>

            {rows.length > 0 ? !props.loading && rows.map((row, i) => {
                prepareRow(row)
                return (
                    <tr className={"table-row"} {...row.getRowProps({
                        style: {
                            opacity: typeof row.original.hiddenRow === "boolean" && row.original.hiddenRow ? 0 : 1
                        }
                    })}>
                        {row.cells.map((cell, i_cell) => {
                            return <td
                                onClick={() => {
                                    if (i_cell > 0 && ((row.cells.length - 1) > i_cell) && typeof rowClick === "function") {
                                        rowClick(row)
                                    }
                                }}
                                className={classnames({
                                    "cursor-pointer": i_cell && typeof rowClick === "function"
                                })} {...cell.getCellProps({
                                style: {width: cell.column.width}
                            })}>{cell.render('Cell')}</td>
                        })}
                    </tr>
                )
            }) : <tr>
                <td style={{width: "100%"}} colSpan={columns.length + 1}>
                    <div className={"alert alert-light text-center margin-top-100 margin-bottom-100 w-100"}>
                        <h1><i className={"mdi mdi-cloud-search-outline"}/></h1>
                        <h5>{noDataText}</h5>
                    </div>
                </td>
            </tr>}
            </tbody>
        </table>
    )
}

const customSelectStyles = {
    container: (provided, state) => ({
        ...provided,
        color: '#333'
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        display: "block",
        height: "38px",
        paddingTop: 0,
        paddingBottom: 0
    }),
    input: (provided, state) => ({
        ...provided,
        margin: 0,
        padding: 0,
        height: "40px"
    }),
    singleValue: (provided, state) => ({
        ...provided,
        lineHeight: "40px",
        paddingTop: 0,
        paddingBottom: 0
    })
}

const IndeterminateCheckbox = React.forwardRef(
    ({indeterminate, ...rest}, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])
        let randInt = (Math.random() * 10000).toFixed(0)
        return (
            <div className="checkbox">
                <input type="checkbox" ref={resolvedRef} {...rest} id={"inputCheckbox-" + randInt}/>
                <label htmlFor={`inputCheckbox-${randInt}`}>
                    <span className="checkbox-icon"></span>
                    <span className="checkbox-indeterminate"></span>
                </label>
            </div>
        )
    }
)

class DataTablePagination extends React.Component {
    getPages = () => {
        let pages = []
        for (let a = 1; a <= this.props.maxPage; a++)
            pages.push(a)
        return pages
    }

    render() {
        const pages = this.getPages();
        return <Row className={"w-100"}>
            <Col sm={6} md={5} className={"align-self-start"}>
                <div style={{width: "100px"}}>
                    <div className={"d-flex"}>
                        <div className={"gl-datatable-pagination-select-div"} style={{flex: "0 0 auto", width: 200}}>
                            <Select
                                styles={customSelectStyles}
                                menuPlacement={"top"}
                                className={"my-cool-select-top"}
                                defaultValue={pageSizes().find(item => item.value === this.props.pageSize)}
                                onChange={(e) => {
                                    this.props.changesize(e.value)
                                }}
                                options={pageSizes()}
                            />
                        </div>
                        <span style={{
                            width: "100px",
                            flex: "0 0 auto",
                            textAlign: "center",
                            lineHeight: "40px"
                        }}>
                        <i className={"mdi mdi-format-list-bulleted"}/>
                        Toplam: <strong>{this.props.total}</strong></span>
                        <div>
                            {(typeof this.props.exportBase === "string" && this.props.exportBase !== "") && (typeof this.props.exportPosition === "string" && this.props.exportPosition === "bottom") && <>
                                <ExcelExportButton
                                    base={this.props.exportBase}
                                    query={this.props.queryUrl}
                                    buttontext={<><AiFillFileExcel/> Excel</>}
                                    color={"dark"}
                                    style={{width: 75, marginTop: "7px"}}
                                />
                            </>}
                        </div>
                    </div>
                </div>
            </Col>

            <Col sm={2} md={2} className={"gl-preloader"}>
                <Spinner color={"dark"} size={"sm"} className={classnames("mt-1", {"d-none": !this.props.loading})}/>
            </Col>

            <Col sm={6} md={5} className={"align-self-top gl-pagination-numbers"}>
                <Pagination className={classnames("pr-2", {
                    "d-none": this.props.maxPage <= 1
                })} listClassName={"justify-content-end"}>
                    <PaginationItem disabled={this.props.page === 1} key={`PaginationItem-First`}>
                        <PaginationLink onClick={() => {
                            this.props.change(this.props.page - 1)
                        }}><i className={"icon-material-outline-arrow-back"}/></PaginationLink>
                    </PaginationItem>
                    {pages.map((num, i) => {
                        let out = []

                        if ((num + 5 === (this.props.page)) && this.props.maxPage > 6 && this.props.page > 1) {
                            out.push(<PaginationItem key={`PaginationItem-${i}-First`}>
                                <PaginationLink onClick={() => this.props.change(1)}>{1}</PaginationLink>
                            </PaginationItem>)
                            out.push(<PaginationItem><PaginationLink>...</PaginationLink></PaginationItem>)
                        }

                        out.push(
                            (pages.length < 10) ||
                            num === this.props.page ||
                            (
                                num - 5 < (this.props.page) &&
                                num > this.props.page
                            ) ||
                            (
                                num + 5 > (this.props.page) &&
                                num < this.props.page
                            ) ? (<PaginationItem active={this.props.page === num ? true : false}
                                                 key={`PaginationItem-${i}-Num`}>
                                <PaginationLink onClick={() => this.props.change(num)}>{num}</PaginationLink>
                            </PaginationItem>) : '')

                        if ((num - 5 === (this.props.page)) && this.props.maxPage > 6 && this.props.page !== pages.length) {
                            out.push(<PaginationItem
                                key={`PaginationItem-${i}-Dots`}><PaginationLink>...</PaginationLink></PaginationItem>)
                            out.push(<PaginationItem key={`PaginationItem-${i}-Last`}>
                                <PaginationLink
                                    onClick={() => this.props.change(pages.length)}>{pages.length}</PaginationLink>
                            </PaginationItem>)
                        }


                        return out;
                    })}
                    <PaginationItem disabled={this.props.page === this.props.maxPage} key={`PaginationItem-Last`}>
                        <PaginationLink onClick={() => {
                            this.props.change(this.props.page + 1)
                        }}><i className={"icon-material-outline-arrow-forward"}/></PaginationLink>
                    </PaginationItem>
                </Pagination>
            </Col>
        </Row>;
    }
}

class DataTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            page: 1,
            pageSize: this.props.pageSize || 10,
            maxPage: 1,
            loading: true,
            total: 0,
            orderBy: props.orderBy,
            order: props.order || "desc",
            queryUrl: ""
        }
    }

    backup_filters = null

    componentDidMount() {
        if (typeof this.props.data !== "undefined")
            this.setState({data: this.props.data})
        else
            this.loadData()
    }

    changePage = (new_page) => {
        if (new_page === this.state.page)
            return;
        this.setState({page: new_page}, () => {
            this.loadData()
        })
    }
    changePageSize = (new_pagesize) => {
        if (new_pagesize === this.state.pageSize)
            return;
        this.setState({pageSize: new_pagesize}, () => {
            this.loadData()
        })
    }

    loadData = async () => {
        let self = this
        let {dataQuery} = this.props

        let {order, orderBy} = this.state

        this.setState({loading: true})
        if (typeof self.props.showSpinner === "function")
            self.props.showSpinner()

        let url_params = []

        if (typeof dataQuery.filters === "object") {
            Object.keys(dataQuery.filters).forEach(item => {
                let itemVal = dataQuery.filters[item];
                if (itemVal) {
                    if (typeof itemVal === "object" && itemVal instanceof Date) {
                        if (item.indexOf("DateStart"))
                            itemVal = moment(itemVal).startOf('day').format(dateonly_format)
                        else if (item.indexOf("DateEnd"))
                            itemVal = moment(itemVal).endOf('day').format(dateonly_format)
                        else
                            itemVal = moment(itemVal).format(dateonly_format)

                    }
                    url_params.push(item + '=' + itemVal)
                }
            })
        }

        url_params.push('page[size]=' + this.state.pageSize)
        let page = this.state.page;

        if (this.backup_filters !== null && JSON.stringify(this.backup_filters) !== JSON.stringify(url_params)) {
            page = 1;
            this.setState({page: 1})
        }
        this.backup_filters = Object.assign([], url_params);

        url_params.push('page[number]=' + (page - 1))

        let url = dataQuery.url;
        if (url_params.length)
            url += '?' + url_params.join("&");

        if (orderBy) {
            if (order === "desc")
                orderBy = `-${orderBy}`
            url += '&' + 'sort=' + orderBy
        }

        if (typeof dataQuery.include === "object" && dataQuery.include.length) {
            url += `&include=${dataQuery.include.join(",")}`
        }

        let _request = request
        if (typeof this.props.isClient === "boolean" && this.props.isClient)
            _request = _request.setClient()

        if (typeof url === "string" && url.indexOf("?") > -1) {
            let _url = url + ""
            _url = _url.split("?")
            if (_url.length === 2)
                this.setState({queryUrl: _url[1]})
        }

        _request.get(url)
            .then(result => result.json())
            .then(response => {
                let result = response.data || []
                let maxPage = response.meta.total_page
                let total = response.meta.total_result
                let include = response.included;

                if (page > maxPage) {
                    return this.setState({
                        page: maxPage
                    }, () => {
                        self.loadData();
                    });
                }

                if (typeof include.currencies === "object" && include.currencies.length) {
                    result.map(item => {
                        if (item.type === "Order" || item.type === "Transaction") {
                            item.currency = (include.currencies).find(currency => currency.data.code === item.attributes.currency_code)
                            if (typeof item.currency !== "undefined")
                                item.currency = item.currency.data
                        }
                    })
                }
                if (typeof include.details === "object" && Object.keys(include.details).length) {
                    result.map(item => {
                        if (item.type === "PaymentForwarding" && typeof item.attributes === "object") {
                            item.attributes.details = typeof include.details[item.id] !== "undefined" ? include.details[item.id] : []
                        }
                    })
                }

                if (typeof result === "object" && result.length) {
                    let data = [], itemCloned = null
                    result.forEach((item) => {
                        itemCloned = item
                        data.push(item)
                    })
                    if (typeof this.props.noFakeRow !== "boolean" || !this.props.noFakeRow) {
                        data.push(Object.assign({hiddenRow: true}, itemCloned))
                    }
                    this.setState({data, maxPage, total}, () => {
                        if (typeof self.props.hideSpinner === "function")
                            self.props.hideSpinner()
                        this.setState({loading: false})
                    })
                } else {
                    this.setState({
                        data: [],
                        maxPage: 0,
                        total: 0,
                        loading: false
                    }, () => {
                        if (typeof self.props.hideSpinner === "function")
                            self.props.hideSpinner()
                    })
                }
            })
            .catch(r => ApiErrorThrow(r))
    }

    setOrderBy = (orderBy, dir) => {
        if (dir) dir = 'desc'
        this.setState({
            orderBy: orderBy,
            order: dir
        }, () => {
            this.loadData()
        })
    }

    orderChange = (slug) => {
        let dir = this.state.order + ''
        if (slug === this.state.orderBy)
            dir = dir === 'asc' ? 'desc' : 'asc';

        this.setState({
            orderBy: slug,
            order: dir
        }, () => {
            this.loadData()
        })
    }

    render() {
        let {data, loading} = this.state;
        let {container_class, columns} = this.props
        if (typeof container_class === "undefined" || container_class === null)
            container_class = "bg-white datatable-container text-left";
        else
            container_class += ' datatable-container'

        if (window.innerWidth < 720) {
            columns = columns.filter(item => typeof item.hideMobile !== "boolean" || item.hideMobile !== true)
            columns = columns.filter(item => {
                if (typeof item.mobileWidth === "string")
                    item.width = item.mobileWidth

                return item;
            })
        }

        return <div className={container_class}>
            {(typeof this.props.exportBase === "string" && this.props.exportBase !== "") && (typeof this.props.exportPosition === "string" && this.props.exportPosition === "top") && <>
                <div className={"text-right"}>
                    <ExcelExportButton
                        color={"dark"}
                        base={this.props.exportBase}
                        query={this.state.queryUrl}
                        buttontext={<><AiFillFileExcel/> EXCEL</>}
                    />
                </div>
            </>}
            {!loading && <Table
                data={data}
                columns={columns}
                orderChange={this.orderChange}
                orderBy={this.state.orderBy}
                order={this.state.order}
                noDataText={this.props.noDataText || react_intl.formatMessage({id: "components.data_table_no_data"})}
                rowClick={typeof this.props.rowClick === "function" && this.props.rowClick}
                noCheckbox={typeof this.props.noCheckbox !== "boolean" || !this.props.noCheckbox}
            />}
            {loading && <div className={"p-3 text-center w-100 table-page-loading-div"}>
                <Spinner color={"dark"}/>
            </div>}
            <div className={classnames("p-1 bg-light border-top gl-table-footer", {
                "d-none": loading
            })}>
                <DataTablePagination
                    {...this.props}
                    queryUrl={this.state.queryUrl}
                    loading={this.state.loading}
                    change={this.changePage}
                    changesize={this.changePageSize}
                    page={this.state.page}
                    total={this.state.total}
                    pageSize={this.state.pageSize} maxPage={this.state.maxPage}/>
            </div>
        </div>
    }

}

export default DataTable
import NotificationModel from "../Models/NotificationModel";
import {ApiErrorThrow, defaultSnackbarOptions, notificationTypes} from "../Config";
import {Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import React from "react";
import InputWithIcon from "../Elements/InputWithIcon";
import Select from "react-select";
import ClientSelect from "../Elements/ClientSelect";
import ButtonLoad from "../Elements/ButtonLoad";
import {withSnackbar} from "react-simple-snackbar";
import {react_select_zIndex2_style} from "./GlSelect";
import {react_intl} from "../index";
import {FormattedMessage} from "react-intl";

const notification_default = {
    title: "",
    type: "announcement",
    details: "",
    user_id: null
}

class NotificationCreate extends React.Component {
    state = {
        createNotifyModal: false,
        notificationInfo: notification_default
    }
    createNotification = () => {
        let {openSnackbar, user_selected} = this.props

        let details = {...this.state.notificationInfo}
        delete details.user_id
        this.setState({createLoading: true})

        NotificationModel.create(user_selected || this.state.notificationInfo.user_id, details).then(r => {
            this.setState({
                notificationInfo: notification_default,
                createNotifyModal: false,
                createLoading: false
            }, () => {
                if(typeof this.props.callback === "function") {
                    this.props.callback()
                }
                openSnackbar(react_intl.formatMessage({id:"components.notification_create_notification_created"}))
            })
        }).catch(r => {
            this.setState({createLoading: false})
            ApiErrorThrow(r, openSnackbar)
        })
    }


    createNotifyModalToggle = () => {
        this.setState({
            createNotifyModal: !this.state.createNotifyModal
        })
    }

    constructor(props) {
        super(props);
        this.createNotifyModalToggle = this.createNotifyModalToggle.bind(this)
    }

    notificationHandle = (e) => {
        let notificationInfo = {...this.state.notificationInfo}
        notificationInfo[e.target.name] = e.target.value
        this.setState({
            notificationInfo
        })
    }

    render() {
        let {user_selected, label, size, className} = this.props
        let {notificationInfo} = this.state
        return <>
            <Modal isOpen={this.state.createNotifyModal} toggle={this.createNotifyModalToggle} centered={true}>
                <ModalHeader toggle={this.createNotifyModalToggle}><FormattedMessage id={"components.notification_create_new_notification"} /></ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label><FormattedMessage id={"components.notification_create_notification_title"} /></Label>
                        <InputWithIcon name={"title"} onChange={this.notificationHandle}
                                       value={notificationInfo.title}/>
                    </FormGroup>
                    <FormGroup>
                        <Label><FormattedMessage id={"components.notification_bar_notification_content"} /></Label>
                        <textarea className={"border"} name={"details"} onChange={this.notificationHandle}
                                  value={notificationInfo.details}></textarea>
                    </FormGroup>
                    <FormGroup>
                        <Label><FormattedMessage id={"components.notification_bar_notification_type"} /></Label>
                        <Select
                            styles={react_select_zIndex2_style}
                            onChange={(value) => {
                                this.notificationHandle({
                                    target: {
                                        name: "type",
                                        value: value.value
                                    }
                                })
                            }}
                            options={notificationTypes()}
                            value={notificationTypes().find(item => item.value === notificationInfo.type)}/>
                    </FormGroup>
                    <FormGroup className={user_selected ? "d-none" : ""}>
                        <Label><FormattedMessage id={"components.notification_bar_client_account"} /></Label>
                        <ClientSelect
                            isMulti={false}
                            onChange={(value) => {
                                this.notificationHandle({
                                    target: {
                                        name: "user_id",
                                        value: value.value
                                    }
                                })
                            }}
                            options={notificationTypes()}
                            value={notificationTypes().find(item => item.value === notificationInfo.type)}/>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <ButtonLoad
                        text={react_intl.formatMessage({id:"components.notification_bar_create"})}
                        style={{minWidth: "200px"}}
                        loading={this.state.createLoading}
                        color={"dark"} onClick={this.createNotification}
                    ><FormattedMessage id={"components.notification_bar_second_create"} /></ButtonLoad>
                </ModalFooter>
            </Modal>
            <div>
                <Button color={"dark"} className={className} onClick={this.createNotifyModalToggle} size={size||"md"} style={this.props.style}>{label||react_intl.formatMessage({id:"components.notification_bar_third_create"})}</Button>
            </div>
        </>;
    }
}

export default withSnackbar(NotificationCreate, defaultSnackbarOptions)
import React from "react"
import SettingModel from "../../../Models/SettingModel";
import {Badge, Chip} from "@material-ui/core";
import ConfirmationModalAlert from "../../../Components/ModalAlerts/ConfirmationModalAlert";
import InputWithIcon from "../../../Elements/InputWithIcon";
import {Button} from "reactstrap";
import {ApiErrorThrow, defaultSnackbarOptions} from "../../../Config";
import {withSnackbar} from "react-simple-snackbar";
import {FormattedMessage} from "react-intl";
import {react_intl} from "../../../index";

const NoGlobalShortcode = (props) => {
    return <div className={"text-center"}>
        <h3><FormattedMessage id={"admin.global_shortcodes_manager.no_code_msg"}/></h3>
        <p><FormattedMessage id={"admin.global_shortcodes_manager.can_add_msg"}/></p>
        <Button onClick={props.create} size={"sm"}><FormattedMessage id={"admin.global_shortcodes_manager.define_first_code"}/> <i className={"icon-feather-plus"}/></Button>
    </div>
}

const GlobalShortcode = (props) => {
    return <Chip className={"mr-2"}
                 label={`[${props.name}]`}
                 color={"secondary"}
                 onClick={props.onClick}
                 onDelete={props.onDelete}
    />
}

class GlobalShortcodesManager extends React.Component {
    state = {
        shortcodes: [],
        editCodeId: null,
        deleteCodeId: null,
        editCodeValue: "",
        editCodeModal: false,
        deleteCodeModal: false,
        createCodeModal: false,
        deleteModalToggle: false,
        editCodeName: "",
        createCodeName: "",
        deleteCodeName: ""
    }
    _isMounted = false

    constructor(props) {
        super(props);
    }

    loadData = () => {
        SettingModel.getByGroup('mail_global_short_codes')
            .then(r => r.json())
            .then(r => {
                this.setState({shortcodes: r.data}, () => {
                    if (typeof this.props.afterLoad === "function") {
                        this.props.afterLoad()
                    }
                })
            })
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    componentDidMount() {
        this._isMounted = true
        this.loadData()
        if (typeof this.props.setRef === "function") {
            this.props.setRef(this)
        }
        this.editCodeModalToggle = this.editCodeModalToggle.bind(this)
        this.createCodeModalToggle = this.createCodeModalToggle.bind(this)
    }

    editCodeModalToggle = () => {
        this.setState({
            editCodeModal: !this.state.editCodeModal,
            editCodeId: "",
            editCodeName: ""
        })
    }
    createCodeModalToggle = () => {
        this.setState({
            createCodeModal: !this.state.createCodeModal,
            createCodeName: "",
        })
    }
    deleteModalToggle = () => {
        let data = {}
        if (this.state.deleteModal) {
            data['deleteCodeId'] = ""
            data['deleteCodeName'] = ""
        }
        data['deleteModal'] = !this.state.deleteModal
        this.setState(data)
    }

    render() {
        let {shortcodes, editCodeName, deleteCodeName} = this.state
        let {openSnackbar} = this.props

        return <div className={this.props.classNames}>

            <ConfirmationModalAlert
                toggle={this.deleteModalToggle}
                isOpen={this.state.deleteModal}
                color={"error"}
                icon={" icon-line-awesome-trash-o"}
                title={react_intl.formatMessage({id:"admin.global_shortcodes_manager.delete_shortcode"},{delete_code_name:deleteCodeName})}
                description={react_intl.formatMessage({id:"admin.global_shortcodes_manager.delete_shortcode_msg"})}
                buttontext={react_intl.formatMessage({id:"admin.global_shortcodes_manager.delete"})}
                buttonAction={() => {
                    SettingModel.deleteGlobalShortcode(this.state.deleteCodeId).then(r => {
                        this.deleteModalToggle()
                        this.loadData()
                    })
                }}
            />

            <ConfirmationModalAlert
                toggle={this.editCodeModalToggle}
                icon={` icon-feather-edit`}
                isOpen={this.state.editCodeModal}
                title={react_intl.formatMessage({id:"admin.global_shortcodes_manager.manage_shortcode"},{edit_code_name:editCodeName})}
                description={react_intl.formatMessage({id:"admin.global_shortcodes_manager.unchangeable_msg"})}
                buttontext={react_intl.formatMessage({id:"admin.global_shortcodes_manager.save"})}
                buttonAction={() => {
                    let data = {}
                    data['mail_global_short_codes'] = {}
                    data['mail_global_short_codes'][this.state.editCodeName] = this.state.editCodeValue
                    SettingModel.bulkEdit(data).then(r => {
                        this.editCodeModalToggle()
                        this.loadData()
                    });
                }}
            >
                <InputWithIcon value={this.state.editCodeValue} onChange={(e) => {
                    this.setState({editCodeValue: e.target.value})
                }}/>
            </ConfirmationModalAlert>

            <ConfirmationModalAlert
                toggle={this.createCodeModalToggle}
                isOpen={this.state.createCodeModal}
                title={react_intl.formatMessage({id:"admin.global_shortcodes_manager.add_shortcode"})}
                icon={`icon-line-awesome-plus`}
                color={"info"}
                description={react_intl.formatMessage({id:"admin.global_shortcodes_manager.adding_shortcode_msg"})}
                buttontext={react_intl.formatMessage({id:"admin.global_shortcodes_manager.continue"})}
                buttonAction={() => {
                    SettingModel.addGlobalShortcode(this.state.createCodeName, "").then(r => {
                        this.createCodeModalToggle()
                        this.loadData()
                    }).catch(r => ApiErrorThrow(r,openSnackbar));
                }}
            >
                <InputWithIcon value={this.state.createCodeName} onChange={(e) => {
                    this.setState({createCodeName: e.target.value})
                }}/>
            </ConfirmationModalAlert>

            {shortcodes.length === 0 ? <NoGlobalShortcode create={this.createCodeModalToggle}/> : <>
                {
                    shortcodes.map(code => {
                        return <GlobalShortcode {...code.attributes}
                                                onClick={() => {
                                                    this.setState({
                                                        editCodeId: code.id,
                                                        editCodeValue: code.attributes.value,
                                                        editCodeModal: true,
                                                        editCodeName: code.attributes.name
                                                    })
                                                }}
                                                onDelete={() => {

                                                    this.setState({
                                                        deleteCodeId: code.id,
                                                        deleteCodeName: code.attributes.name
                                                    }, this.deleteModalToggle)
                                                }}
                        />
                    })
                }
                <Chip label={react_intl.formatMessage({id:"admin.global_shortcodes_manager.add_new"})}
                      onClick={this.createCodeModalToggle}
                      icon={<i className={"icon-line-awesome-plus"}/>} color={"primary"}/>
            </>}
        </div>;
    }
}

export default withSnackbar(GlobalShortcodesManager, defaultSnackbarOptions)
import GlRequest from "../Lib/GlRequest";

class ClientGroupModel{
    create(details) {
        details = {
            data: {
                attributes: details
            }
        }
        return GlRequest.post('/api/v2/user-group', details, 'json')
    }
    modify(id, data)
    {
        data = {
            data: {attributes: data}
        }
        return GlRequest.patch('/api/v2/user-group/' + id, data, 'json')
    }
    delete(id)
    {
        return GlRequest.delete('/api/v2/user-group/' + id)
    }
    getById(id)
    {
        return GlRequest.get('/api/v2/user-group/' + id)
    }
}
export default ClientGroupModel = new ClientGroupModel()
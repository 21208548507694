import React from "react"
import {Button, Nav} from "reactstrap";
import {GrClose} from "react-icons/all";
import {Scrollbars} from "react-custom-scrollbars";
import AdminMenuComp from "./AdminMenuComp";
import classnames from "classnames";
import {get_main_config} from "../../../index";
import {Link} from "react-router-dom";

class AdminMobileMenu extends React.Component {
    render() {
        let {changeMenu, changeSelectedMenu} = this.props

        return (
            <div className={classnames("admin-mobile-menu p-3 animated", {
                "fadeIn": this.props.open,
                "d-block": this.props.open
            })}>
                <div className={"text-right top-bar d-flex"}>
                    <Link
                        classNames={"w-100"}
                        to={"/admin"} onClick={() => {
                        changeMenu("home")
                        changeSelectedMenu({})
                    }}><img src={get_main_config("system_general", "logo_url")} height={60} alt={""}/></Link>
                    <Button
                        className={"rounded-circle"} style={{width: 48, height: 48}}
                        onClick={this.props.toggle} color={"light"}><GrClose/></Button>
                </div>
                <Nav>
                    <Scrollbars style={{width: "100%", height: "calc(100vh - 150px)"}}>
                        <AdminMenuComp {...this.props} menuChanged={() => {
                            if (typeof this.props.toggle === "function")
                                this.props.toggle()
                        }}/>
                    </Scrollbars>
                </Nav>
            </div>
        );
    }
}

export default AdminMobileMenu
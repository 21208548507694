import React from "react"
import bkmLogo from '../../../assets/images/banks/bkmexpress-logo.png'
import {Button} from "reactstrap";
import {Alert} from "@material-ui/lab";
import {FormattedMessage} from "react-intl";

class BkmExpressTab extends React.Component {
    render() {
        return <div className={"text-center padding-top-25 padding-bottom-25"}>
            <div className="ml-auto mr-auto" style={{width: "75%"}}>
                <p>
                    <img src={bkmLogo} width={125} alt=""/>
                </p>
                <Alert color={"info"} icon={<i className={" icon-line-awesome-info"}/>}>
                    <FormattedMessage id={"client.bkm_express_tab.info"}/>
                </Alert>
            </div>
        </div>;
    }
}

export default BkmExpressTab
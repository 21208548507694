import GlRequest from "../Lib/GlRequest";

class StatModel {
    orderStats = () => {
        return GlRequest.get('/api/v2/order-stats')
    }

    transactionStats = (start, end) => {
        let querystr = '', query = []
        if (start)
            query.push('start=' + start)
        if (end)
            query.push('end=' + end)
        if (query.length)
            querystr = '?' + query.join("&")
        return GlRequest.get('/api/v2/transaction-stats' + querystr)
    }
}

export default StatModel = new StatModel()
import {Button, Modal, ModalBody, ModalFooter, Spinner} from "reactstrap";
import React from "react";
import classnames from "classnames";
import {FormattedMessage} from "react-intl";

class ThreeDModal extends React.Component {
    render() {
        let {paymentDuring, popupShow, iframeDoc, popupToggle, methodSelected} = this.props
        let modalHeight = paymentDuring && methodSelected !== "BkmExpress" ? 400 : "100%"
        let iframeHeight = paymentDuring && methodSelected !== "BkmExpress" ? 400 : "100vh"
        return <>
            <Modal isOpen={popupShow} onClosed={this.props.onClosed} centered={methodSelected !== "BkmExpress"} className={classnames("bg-transparent", {
                "mt-0 mb-0": methodSelected === "BkmExpress",
                "BkmModal": methodSelected === "BkmExpress"
            })}>
                <ModalBody className={classnames("p-0 rounded overflow-hidden", {
                    "bg-white": !paymentDuring || methodSelected !== "BkmExpress"
                })} style={{height: modalHeight}}>
                    <div className={classnames("iframe-loading text-center padding-top-100", {
                        "d-none": methodSelected === "BkmExpress"
                    })}>
                        <FormattedMessage id={"client.three_d_modal.contact_msg"}/>
                        <Spinner color={"dark"} size={"sm"}/>
                        <p className={"mt-3"}><FormattedMessage id={"client.three_d_modal.wait_msg"}/></p>
                    </div>
                    <iframe
                        frameBorder={0}
                        className={classnames("payment-iframe", {
                            border: methodSelected !== "BkmExpress"
                        })}
                        srcDoc={iframeDoc}
                        style={{width: "100%", height: iframeHeight}}></iframe>
                </ModalBody>
                <ModalFooter className={classnames("pt-2", {
                    "d-none": methodSelected === "BkmExpress"
                })}>
                    <Button onClick={popupToggle} color={"danger"}><FormattedMessage id={"client.three_d_modal.close"}/> <i
                        className={"icon-line-awesome-close"}/></Button>
                </ModalFooter>
            </Modal>
        </>;
    }
}

export default ThreeDModal
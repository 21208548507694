
import {defaultSnackbarOptions} from "../../../../Config";
import {withSnackbar} from "react-simple-snackbar";
import ListAll from "../MainType/ListAll";
import {react_intl} from "../../../../index";

class List extends ListAll {
    title = react_intl.formatMessage({id:"admin.content.pages.list.text"})
    description = react_intl.formatMessage({id:"admin.content.pages.list.description"})
    content_type = "page"
    create_link = "/admin/new-page"
    detail_link = "/admin/pages/:id"
}

export default withSnackbar(List, defaultSnackbarOptions)
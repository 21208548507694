import React from "react"
import {Card, CardHeader, IconButton} from "@material-ui/core";
import {Link} from "react-router-dom";
import {Edit} from "@material-ui/icons";
import {Spinner} from "reactstrap";
class ClientWidget extends React.Component{
    render() {
        let {loading} = this.props
        return <div className={"admin-widget"}>
            <Card className={"bg-gray-100"}>
                <CardHeader
                    titleTypographyProps={{variant:"h6"}}
                    title={this.props.title}
                    className={"mb-3 border-bottom"}
                    action={<Link to={this.props.link}><IconButton>{this.props.icon}</IconButton></Link>}
                />
                {loading ? <div className={"text-center pt-3 pb-5"}>
                    <Spinner size={"sm"} color={"dark"}/>
                </div> : this.props.children}
            </Card>
        </div>;
    }
}
export default ClientWidget
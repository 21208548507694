import {withSnackbar} from "react-simple-snackbar";
import React from "react"
import {Button, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import InputWithIcon from "../Elements/InputWithIcon";
import {ApiErrorThrow, getParameterByName} from "../Config";
import AdminAccountModel from "../Models/AdminAccountModel";
import ConfirmationModalAlert from "./ModalAlerts/ConfirmationModalAlert";
import {react_intl} from "../index";
import {FormattedMessage} from "react-intl";

class PasswordReset extends React.Component {
    constructor(props) {
        super(props);
        this.passwordModalToggle = this.passwordModalToggle.bind(this)
    }

    state = {
        passwordModal: false,
        resetEmail: '',
        password_reset_complete: '',
        password_reset_login: '',
        pass_new: '',
        pass_new2: ''
    }

    sendResetEmail(email) {
        return AdminAccountModel.sendResetEmail(email).then();
    }

    componentDidMount() {
        if (getParameterByName("reset-token")) {
            this.setState({
                password_reset_complete: getParameterByName("reset-token"),
                password_reset_login: getParameterByName("email")
            })
        }
    }

    completePasswordReset = () => {
        let {pass_new1, pass_new2, password_reset_complete, password_reset_login} = this.state
        let {openSnackbar} = this.props
        if (pass_new1 !== pass_new2) {
            openSnackbar(react_intl.formatMessage({id:"components.password_reset_enter_same_password"}))
            return false
        }
        AdminAccountModel.completeResetPassword(password_reset_login, password_reset_complete, pass_new1, pass_new2).then(response => {
            let _credentials = this.state.credentials
            _credentials.email = password_reset_login
            this.setState({
                password_reset_complete: '',
                credentials: _credentials
            }, () => {
            })
        }).catch(r => ApiErrorThrow(r, openSnackbar))
    }

    passwordModalToggle = () => {
        if( typeof this.props.reset_toggle === "function")
            this.props.reset_toggle(this.state.passwordModal)

        this.setState({passwordModal: !this.state.passwordModal})
    }

    render() {
        let {openSnackbar} = this.props
        return <>

            <ConfirmationModalAlert
                isOpen={this.state.password_reset_complete !== ""}
                color={"success"}
                toggle={() => {

                }}
                icon={"icon-feather-lock"}
                title={react_intl.formatMessage({id:"components.password_reset_new_password"})}
                buttonAction={() => this.completePasswordReset()}
                buttonActionCancel={() => {
                    this.setState({
                        password_reset_complete: ''
                    })
                }}
            >
                <Label>
                    <FormattedMessage id={"components.password_reset_password"} />
                    <InputWithIcon type={"password"} icon={" icon-material-outline-lock"}
                                   onChange={(e) => this.setState({pass_new1: e.target.value})}/>
                </Label>
                <Label>
                    <FormattedMessage id={"components.password_reset_password_again"} />
                    <InputWithIcon type={"password"} icon={" icon-material-outline-lock"}
                                   onChange={(e) => this.setState({pass_new2: e.target.value})}/>
                </Label>
            </ConfirmationModalAlert>

            <Modal toggle={this.passwordModalToggle} isOpen={this.state.passwordModal}>
                <ModalHeader><FormattedMessage id={"components.password_reset_password_reset"} /></ModalHeader>
                <ModalBody>
                    <Label><FormattedMessage id={"components.password_reset_email"} /></Label>
                    <InputWithIcon
                        onChange={(e) => {
                            this.setState({
                                resetEmail: e.target.value
                            })
                        }}
                        icon={" icon-line-awesome-envelope"}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color={"primary"} type={"button"} onClick={(e) => {
                        e.preventDefault();
                        this.sendResetEmail(this.state.resetEmail).then(r => {
                            this.passwordModalToggle()
                            openSnackbar(react_intl.formatMessage({id:"components.password_reset_email_related_account"}))
                        }).catch(error => ApiErrorThrow(error, openSnackbar));
                    }}><FormattedMessage id={"components.password_reset_send"} /></Button>
                    <Button onClick={this.passwordModalToggle}><FormattedMessage id={"components.password_reset_close"} /></Button>
                </ModalFooter>
            </Modal>

            <Button color={"info"} size={"sm"} onClick={this.passwordModalToggle}
                    className="forgot-password"
                    {...this.props}
            ><FormattedMessage id={"components.password_reset_forgot_password"} /></Button>

        </>;
    }
}

export default withSnackbar(PasswordReset)
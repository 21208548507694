import React from "react"
import CountBox from "./CountBox";
import {RiBillLine} from "react-icons/all";
import {react_intl} from "../../index";

class UnpaidInvoices extends CountBox {
    title = react_intl.formatMessage({id:"client_dashboard.unpaid"})
    color = "danger"
    icon = <RiBillLine size={27}/>
}

export default UnpaidInvoices
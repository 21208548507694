import GlRequest from "../Lib/GlRequest";

class CurrencyModel {
    getById(id) {
        return GlRequest.get('/currency/' + id)
    }

    getByCode(code) {
        return GlRequest.get('/currency/' + code)
    }

    create(details) {
        if (typeof details.rate === "string")
            details.rate = parseFloat(details.rate)
        if (typeof details.currency_decimal === "string")
            details.currency_decimal = parseFloat(details.currency_decimal)
        details = {
            data: {
                attributes: details
            }
        }
        return GlRequest.post('/api/v2/currency', details, 'json')
    }

    delete(id) {
        return GlRequest.delete('/api/v2/currency/' + id)
    }

    modify(id, details) {
        if (typeof details.rate === "string")
            details.rate = parseFloat(details.rate)
        details = {
            data: {
                attributes: details
            }
        }
        return GlRequest.patch('/api/v2/currency/' + id, details, 'json')
    }

    updateRates() {
        return GlRequest.get('/api/v2/currency/update')
    }

    getAll(params) {
        return GlRequest.get('/currency', {params})
    }
}

export default CurrencyModel = new CurrencyModel()
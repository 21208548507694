import React from "react"
import {Button, Spinner} from "reactstrap";
import classnames from "classnames";

class ButtonLoad extends React.Component {
    render() {
        let {className, text, loading, type, sliding, icon} = this.props
        if(typeof loading !== "boolean")
            loading = false

        if (typeof icon === "undefined")
            icon = "icon-material-outline-arrow-right-alt"

        return <Button
            {...this.props}
            disabled={loading}
            className={classnames(className, {
                // "button": true,
                "ripple-effect": true,
                "button-sliding-icon": !loading
            })}
        >
            {loading ? <Spinner size={"sm"} color={"white"}/> : <>{text} <i className={icon}/></>}

        </Button>;
    }
}

export default ButtonLoad
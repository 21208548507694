import React from "react"
import {Link} from "react-router-dom";
import image_not_found from "../assets/svg/not_found.svg"
import {get_main_config} from "../index";
import {FormattedMessage} from "react-intl";

class PageNotFound extends React.Component{
    render() {
        return <>
            <div className={"ml-auto mr-auto padding-top-100 page-not-found"}>
                <img src={image_not_found} />
                <div className="message-box text-center">
                    <div className={"margin-bottom-25 bg-light rounded p-3"}>
                        <img src={get_main_config("system_general", "logo_url")} height={60} alt={""}/>
                    </div>
                    <h1><FormattedMessage id={"client.page_not_found.code"}/></h1>
                    <h3><FormattedMessage id={"client.page_not_found.not_found_msg"}/></h3>
                    <div className="buttons-con">
                        <div className="action-link-wrap">
                            <Link to={"/"} className="link-button"><FormattedMessage id={"client.page_not_found.homepage"}/></Link>
                        </div>
                    </div>
                </div>
            </div>
        </>;
    }
}

export default PageNotFound
import React from "react"
import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Col,
    Input,
    Label,
    Row
} from "reactstrap";
import {matchPath} from "react-router";
import ContentModel from "../../../../Models/ContentModel";
import {Link} from "react-router-dom";
import Editor from "ckeditor-custom/build/ckeditor";
import {ApiErrorThrow} from "../../../../Config";
import Switch from "@material-ui/core/Switch";
import {FormControlLabel} from "@material-ui/core";
import {GetTitle} from "../../../../Routes/AdminRoute";
import UploadZone from "../../MultiMedia/UploadZone";
import ButtonLoad from "../../../../Elements/ButtonLoad";
// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import {CKEditor} from 'ckeditor4-react';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css'
import FroalaEditor from 'react-froala-wysiwyg';
import {Skeleton} from "@material-ui/lab";
import {FormattedMessage} from "react-intl";
import {react_intl} from "../../../../index";

export class ExistImagePreview extends React.Component {
    render() {
        let {url, removeHandle, loading} = this.props
        return <Card className={"text-center"}>
            <CardBody>
                <img src={url}/>
            </CardBody>
            <CardFooter>
                <ButtonLoad
                    loading={loading}
                    color={"danger"}
                    onClick={() => {
                        if (typeof removeHandle === "function")
                            removeHandle()
                    }} className={"btn-rounded-circle"}
                    text={<><FormattedMessage id={"admin.content.main.detail.delete"}/></>}
                />
            </CardFooter>
        </Card>;
    }
}

const getParams = (pathname) => {
    const matchProfile = matchPath(pathname, {
        path: `/admin/pages/:postId`,
    });
    return (matchProfile && matchProfile.params) || {};
};

export const default_content_data = {
    type: "",
    name: "",
    content: "",
    content_order: 0,
    slug: "",
    status: false
}

class Detail extends React.Component {
    title = ""
    thumbnail = false
    canFeature = false
    back_text = ""
    back_url = ""
    action = ""
    content_type = ""
    redirect_url = "/admin/:id"
    postId = null
    save_button = react_intl.formatMessage({id: "admin.content.main.detail.save_button"})
    post_loading = true

    editorConfig = {
        height: 400,
        extra_liners: "['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'pre', 'ul', 'ol', 'table', 'dl']",
        charCounterCount: true
    }

    constructor(props) {
        super(props);
        this.toggleStatus = this.toggleStatus.bind(this)
        this.toggleFeatured = this.toggleFeatured.bind(this)
        this.handleModelChange = this.handleModelChange.bind(this);
        this.inputHandler = this.inputHandler.bind(this);
    }

    handleModelChange = (model) => {
        let edited = {...this.state.edited}
        edited.content = model
        this.setState({
            edited
        });
    }

    state = {
        pageDetail: {},
        edited: {
            name: "",
            content: "",
            status: false
        },
        thumbnail_delete_active: false,
        thumbnailUrl: "",
        thumbnailExist: false,
        loadingSave: false,
        featured: false,
        post_loading: this.post_loading
    }

    componentDidMount() {
        document.title = GetTitle(this.title)
        this.loadPostData()
    }

    saveContentFields = () => {
        if (this.postId === null)
            return false

        let {openSnackbar} = this.props
        let {thumbnailUrl, featured} = this.state

        let datas = [
            {field_name: "thumbnail", value: thumbnailUrl || ""},
            {field_name: "featured", value: featured ? "yes" : "no"}
        ]

        let post_edit = null
        post_edit = ContentModel.editFieldMulti(this.postId, datas)


        post_edit.then(r => r.json())
            .then(r => {
                let content_fields = r.included[this.postId]
            }).catch(r => ApiErrorThrow(r, openSnackbar))
    }

    removeImageHandle = () => {
        this.setState({thumbnail_delete_active: true})

        const field_deleted = (self) => {
            self.setState({
                thumbnailUrl: "",
                thumbnail_delete_active: false
            })
        }

        field_deleted(this)
    }

    inputHandler = () => {
        if (this.editor === null)
            return false

        let editedCopy = Object.assign({}, this.state.edited)
        editedCopy.content = this.editor.getData()
        this.setState({
            edited: editedCopy
        })
    }

    editor = null

    render() {
        let {pageDetail, edited, featured} = this.state
        let {save_button} = this

        return <div className={"mt-3"}>
            <Row className={"mb-3"}>
                <Col>
                    <Link className={"btn btn-sm btn-dark"} to={this.back_url}><i
                        className={"icon-feather-arrow-left"}/> {this.back_text}</Link>
                </Col>
            </Row>

            <Card>
                <CardHeader>
                    <Row>
                        <Col>
                            <CardTitle>{this.title}</CardTitle>
                        </Col>
                        <Col>
                            <ButtonLoad
                                className={"float-right"}
                                size={"sm"}
                                color={"primary"} onClick={() => this.save()}
                                text={save_button}
                                loading={this.state.loadingSave}
                            />
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col sm={12} lg={6}>
                            <Label><FormattedMessage id={"admin.content.main.detail.title"}/></Label>
                            <Input name={"name"} defaultValue={edited.name || ""} onChange={(e) => {
                                let edited = {...this.state.edited};
                                edited.name = e.target.value
                                this.setState({edited})
                            }}
                                   placeholder={react_intl.formatMessage({id: "admin.content.main.detail.title_placeholder"})}
                            />
                        </Col>
                        <Col sm={12} md={3}>
                            <Label><FormattedMessage id={"admin.content.main.detail.status"}/></Label>
                            <div>
                                <FormControlLabel
                                    color={"primary"}
                                    label={edited.status ? react_intl.formatMessage({id: "admin.content.main.detail.status.active"}) : react_intl.formatMessage({id: "admin.content.main.detail.status.passive"})}
                                    control={<Switch
                                        checked={edited.status}
                                        onChange={this.toggleStatus}
                                        inputProps={{'aria-label': 'secondary checkbox'}}
                                    />}
                                />
                            </div>
                            {this.canFeature && <div>
                                <hr/>
                                <Label><FormattedMessage id={"admin.content.main.detail.featured"}/></Label>
                                <div>
                                    <FormControlLabel
                                        color={"primary"}
                                        label={featured ? react_intl.formatMessage({id: "admin.content.main.detail.featured.yes"}) : react_intl.formatMessage({id: "admin.content.main.detail.featured.no"})}
                                        control={<Switch
                                            checked={featured}
                                            onChange={this.toggleFeatured}
                                            inputProps={{'aria-label': 'secondary checkbox'}}
                                        />}
                                    />
                                </div>
                            </div>}
                        </Col>
                        {this.thumbnail && <Col sm={12} md={3} className={""}>
                            <Label><FormattedMessage id={"admin.content.main.detail.image"}/></Label>
                            {this.state.thumbnailUrl ? <ExistImagePreview
                                loading={this.state.thumbnail_delete_active}
                                url={this.state.thumbnailUrl}
                                removeHandle={this.removeImageHandle}
                            /> : <UploadZone
                                onUpload={(response) => {
                                    let url = response.data.attributes.public_url
                                    this.setState({
                                        thumbnailUrl: url
                                    })
                                }}
                            />}
                        </Col>}
                        <Col sm={12} className={"page-content-editor"}>
                            <Label><FormattedMessage id={"admin.content.main.detail.content"}/></Label>
                            {this.state.post_loading ? <>
                                <Skeleton width={"100%"} height={300}/>
                            </> : <CKEditor
                                onChange={this.inputHandler}
                                initData={edited.content}
                                onInstanceReady={ckThis => {
                                    this.editor = ckThis.editor
                                }}
                            />}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>;
    }


    loadPostData() {
        if (typeof this.postId === "undefined" || this.postId === null)
            return false;

        let {openSnackbar} = this.props
        ContentModel.getById(this.postId, ["content-field"]).then(r => r.json())
            .then(r => {
                if (typeof r.data !== "object") {
                    this.props.history.push(this.back_url)
                    return;
                }
                let details = r.data.attributes

                let includes = typeof r.included === "object" ? r.included : {}
                if (typeof includes[this.postId] === "object")
                    includes = includes[this.postId]

                let thumb = includes.find(item => {
                    return item.data.field_name === "thumbnail"
                })
                let featured = includes.find(item => {
                    return item.data.field_name === "featured"
                })

                if (typeof featured === "object" && typeof featured.data === "object")
                    featured = featured.data.value

                this.setState({
                    post_loading: false,
                    pageDetail: (details || {}),
                    edited: {
                        name: details.name,
                        content: details.content,
                        status: details.status
                    },
                    thumbnailUrl: typeof thumb === "object" && thumb !== null ? thumb.data.value : null,
                    featured: typeof featured === "string" && featured === "yes"
                })
            })
            .catch(r => ApiErrorThrow(r, openSnackbar))
    }

    save = () => {
        const {openSnackbar} = this.props
        let page_data = Object.assign(default_content_data, this.state.edited)
        page_data.type = this.content_type
        delete page_data.slug
        this.setState({
            loadingSave: true
        })
        if (this.action === "edit") {

            this.saveContentFields()

            ContentModel.modify(this.postId, page_data).then(r => r.json())
                .then(r => {
                    openSnackbar(react_intl.formatMessage({id: "admin.content.main.detail.saved"}))
                    this.setState({
                        loadingSave: false
                    })
                })
                .catch(r => {
                    this.setState({
                        loadingSave: false
                    })
                    ApiErrorThrow(r, openSnackbar)
                })
        } else if (this.action === "create") {
            ContentModel.create(page_data)
                .then(r => r.json())
                .then(r => {
                    r = r.data.attributes
                    let id = r.id
                    this.setState({
                        loadingSave: false
                    })
                    openSnackbar(react_intl.formatMessage({id: "admin.content.main.detail.content_created"}))
                    this.props.history.push(this.redirect_url.replaceAll(":id", id))
                })
                .catch(r => {
                    this.setState({
                        loadingSave: false
                    })
                    ApiErrorThrow(r, openSnackbar)
                })
        }
    }

    toggleStatus = () => {
        let edited = this.state.edited
        edited.status = !edited.status
        this.setState({
            edited
        })
    }

    toggleFeatured = () => {
        let featured = this.state.featured
        featured = !featured
        this.setState({
            featured
        })
    }
}

export default Detail
import React from "react"

class ShowEmail extends React.Component{
    render() {
        let {email} = this.props
        return <div className={"email-tag"}>
            {/*<a href={"mailto:" + email}>*/}
            {email}
            {/*</a>*/}
        </div>;
    }
}
export default ShowEmail
import {Button, Modal, ModalBody} from "reactstrap";
import React from "react"
import {BiCommentCheck, BiCommentError} from "react-icons/all";
import {FormattedMessage} from "react-intl";

class PaymentSuccessModal extends React.Component{
    constructor(props) {
        super(props);
    }
    render() {
        let {isOpen, toggle, message} = this.props
        return <Modal size={"md"} isOpen={isOpen} centered={true}>
            <ModalBody className={"text-center"}>
                <BiCommentCheck style={{fontSize:50}} className={"mb-3 text-success"} />
                <h2 className={"mb-3"}><FormattedMessage id={"client.payment_success_modal.pay_success_msg"}/></h2>
                <Button color={"success"} onClick={toggle}><FormattedMessage id={"client.payment_success_modal.continue"}/></Button>
            </ModalBody>
        </Modal>;
    }
}
export default PaymentSuccessModal
import React from "react"
import {Button, Card, CardBody, CardFooter, CardHeader, CardSubtitle, CardTitle} from "reactstrap";
import {FormattedMessage} from "react-intl";

class SmallAddressBox extends React.Component {
    render() {
        let {
            id,
            title, address1, address2,
            company_title, tax_office,
            tax_number, state, city,
            email, phone, corporate,
            delete_toggle, edit_toggle,
            onChange
        } = this.props

        if (typeof address2 === "string" && address2 !== "")
            address1 += " " + address2;

        return <Card className={"border rounded w-100 p-2"}>
            <CardHeader>
                <CardTitle>{corporate ? <i className={" icon-line-awesome-building-o"}/> :
                    <i className={" icon-material-outline-person-pin"}/>} {title}</CardTitle>
            </CardHeader>
            <CardBody>
                <CardSubtitle className={"mb-2 d-block"}>
                    <strong
                        className={"w-100 d-block"}>{company_title}</strong>{tax_number} {tax_office ? `- ${tax_office}` : ""}
                </CardSubtitle>
                <div className={"text-excepted"}>{address1} {`${state}/${city}`}</div>
                <div><i className={" icon-feather-phone"}/> {phone}</div>
                <div><i className={" icon-material-outline-email"}/> {email}</div>
            </CardBody>
            <CardFooter className={"text-right"}>
                {typeof onChange === "function" && typeof this.props.useButton === "boolean" && this.props.useButton && <Button
                    className={"button-sliding-icon"}
                    onClick={() => {
                        onChange({
                            value: id,
                            details: this.props
                        })
                    }}
                    size={"sm"} color={"dark"}><FormattedMessage id={"components.small_address_box_use"} /> <i className={"icon-feather-check"}/></Button>}
                {" "}
                <Button
                    onClick={() => {
                        edit_toggle(this.props)
                    }}
                    size={"sm"} color={"info"}><FormattedMessage id={"components.small_address_box_edit"} /></Button>
                {" "}
                <Button size={"sm"} onClick={() => {
                    delete_toggle(this.props)
                }} color={"danger"}><FormattedMessage id={"components.small_address_box_delete"} /></Button>
            </CardFooter>
        </Card>;
    }
}

export default SmallAddressBox
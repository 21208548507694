import React from "react"
import {Button, Input, Modal, ModalBody, ModalHeader} from "reactstrap";
import ConfirmationModalAlert from "./ModalAlerts/ConfirmationModalAlert";
import {AiOutlineWarning} from "react-icons/all";
import GlRequest from "../Lib/GlRequest";
import {ApiErrorThrow, frontendBaseUrl} from "../Config";
import {FormattedMessage} from "react-intl";
import {react_intl} from "../index";

class ReInstallButton extends React.Component {
    timeoutTime = 10000

    state = {
        modal: false,
        acceptText: "",
        buttonLoading: false,
        buttontext: react_intl.formatMessage({id:"components.reinstall_button_reset"}),
        timeRemaining: this.timeoutTime
    }
    inputRef = React.createRef()

    handle = (e) => {
        this.setState({
            acceptText: e.target.value
        })
    }

    modalToggle = () => {
        this.setState({
            modal: !this.state.modal
        })
    }

    timeout = null
    interval = null

    constructor(props) {
        super(props);
        this.modalToggle = this.modalToggle.bind(this)
        this.handle = this.handle.bind(this)
    }

    reset = () => {
        this.setState({
            buttonLoading: true
        })
        return GlRequest.get('/install/start-over').then(r => r.json())
            .then(r => {
                window.location.href = frontendBaseUrl + '/setup'
                return false
            }).catch(r => ApiErrorThrow(r))
    }

    countDown = () => {
        let maxTime = this.timeoutTime
        let time = 0
        let self = this
        return new Promise((resolve, reject) => {
            self.interval = setInterval(function () {
                time += 1000;
                self.setState({
                    timeRemaining: (maxTime - time) / 1000
                })
                if (time >= maxTime) {
                    clearInterval(self.interval)
                    resolve(true)
                }
            }, 1000)
        })
    }

    render() {
        let {modal, timeRemaining} = this.state
        let started = timeRemaining !== this.timeoutTime && timeRemaining !== 0

        return <>
            <ConfirmationModalAlert
                color={"error"}
                isOpen={modal}
                toggle={this.modalToggle}
                icon={"icon-line-awesome-warning"}
                title={react_intl.formatMessage({id:"components.reinstall_button_reset_system"})}
                buttontext={<>
                    {!started && <><FormattedMessage id={"components.reinstall_button_second_reset"} /></>}
                    {started && <><FormattedMessage id={"components.reinstall_button_give_up"} values={ { time: timeRemaining} } /></>}
                </>}
                buttonLoading={this.state.buttonLoading}
                buttonAction={() => {

                    if (started) {
                        clearInterval(this.interval)
                        clearTimeout(this.timeout)
                        this.setState({
                            acceptText: "",
                            timeRemaining: this.timeoutTime
                        })
                        return false;
                    }

                    if (this.state.acceptText !== "ONAYLIYORUM" && this.state.acceptText !== "I CONFIRM") {
                        let input = document.getElementById('accept-input')
                        input.focus()
                        input.classList.add('animated')
                        input.classList.add("headShake")
                        input.classList.add("border-danger")
                        input.classList.add("shadow")
                        setTimeout(function () {
                            input.classList.remove('animated')
                            input.classList.remove('headShake')
                            input.classList.remove('border-danger')
                            input.classList.remove('shadow')
                        }, 1000)
                        return false;
                    }

                    let time = this.timeoutTime
                    let self = this

                    self.countDown().then(r => {
                        self.reset()
                    })

                    this.timeout = setTimeout(function () {

                    }, time)
                }}
            >
                <h3 className={"pt-2 pb-2 text-danger"}><FormattedMessage id={"components.reinstall_button_all_data_deleted"} /></h3>
                <h5 className={"pt-2 pb-2"}><FormattedMessage id={"components.reinstall_description"} /></h5>
                <Input ref={this.inputRef} id={"accept-input"} type={"text"} onChange={this.handle} value={this.state.acceptText} />
            </ConfirmationModalAlert>
            <Button color={"danger"} onClick={this.modalToggle}>
                <FormattedMessage id={"components.reinstall_button_second_reset_system"} />
            </Button>
        </>;
    }
}

export default ReInstallButton
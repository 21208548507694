import React from "react"
import ConfirmationModalAlert from "../../../Components/ModalAlerts/ConfirmationModalAlert";
import {ApiErrorThrow, debounce, defaultSnackbarOptions, languages} from "../../../Config";
import InputWithIcon from "../../../Elements/InputWithIcon";
import {Button, ButtonGroup, Col, Label, Row, Spinner} from "reactstrap";
import Select from "react-select";
import classnames from "classnames";
import {Alert} from "@material-ui/lab";
import TablePageFilterBar from "../../../Components/TablePageFilterBar";
import MultiRadio from "../../../Elements/MultiRadio";
import DataTable from "../../../Components/DataTable";
import {GetTitle} from "../../../Routes/AdminRoute";
import {Switch} from "@material-ui/core";
import SettingModel from "../../../Models/SettingModel";
import {Link} from "react-router-dom";
import {Select38Px} from "../../../Conts";
import {react_intl} from "../../../index";
import {FormattedMessage} from "react-intl";


class EmailSmsTemplates extends React.Component {
    tableRef = React.createRef()

    configurable_templates = null
    config_group = ""
    url_slug = ""
    template_model = ""
    templates = null
    template_type = ""
    titleMultiple = ""
    titleSingle = ""

    defaultTemplate = {
        title: "",
        language: languages()[0].value,
        type: "custom",
        status: false
    }

    constructor(props) {
        super(props);
        this.deleteModalToggle = this.deleteModalToggle.bind(this)
        this.createModalToggle = this.createModalToggle.bind(this)
        this.createTemplateHandle = this.createTemplateHandle.bind(this)
        this.restoreModalToggle = this.restoreModalToggle.bind(this)
    }

    deleteModalToggle = () => {
        this.setState({deleteModal: !this.state.deleteModal})
    }

    restoreModalToggle = () => {
        this.setState({restoreModal: !this.state.restoreModal})
    }

    createModalToggle = () => {
        this.setState({createModal: !this.state.createModal})
        if (this.state.createModal)
            this.setState({new_template: this.defaultTemplate, showCreateError: false})
    }

    state = {
        searchStatus: "1",
        createModal: false,
        titleFilter: '',
        langFilter: languages()[0].value,
        deleteModal: false,
        restoreModal: false,
        deleteTemplate: {},
        restoreTemplate: {},
        new_template: this.defaultTemplate,
        showCreateError: false,
        auto_send_config: [],
        statusChanging: null
    }

    columns = [
        {
            Header: react_intl.formatMessage({id:"admin.email_sms_templates.id"}),
            accessor: 'id',
            sortKey: 'id',
            width: "10%",
            Cell: row => {
                return <span className="id-cell">{"#" + row.value}</span>
            }
        },
        {
            Header: react_intl.formatMessage({id:"admin.email_sms_templates.title_header"}),
            accessor: "attributes.title",
            width: "30%"
        },
        {
            Header: react_intl.formatMessage({id:"admin.email_sms_templates.active"}),
            // <>Aktif <i className={"icon-feather-info"} /><UncontrolledTooltip>Sistem tarafından otomatik gönderilen e-postaları aktif/pasif hale getirebilirsiniz.</UncontrolledTooltip></>
            accessor: "null",
            width: "10%",
            Cell: data => {
                let row = data.row.original
                let configs = this.state.auto_send_config
                if (this.configurable_templates.filter(item => item.key === row.attributes.type).length === 0)
                    return <div className={"text-left p-2 pl-3"}>
                        <i className={"icon-feather-check-circle"}/>
                    </div>;

                let _config = configs.find(item => item.attributes.name === row.attributes.type)
                if (typeof _config === "object" && typeof _config.attributes === "object")
                    _config = _config.attributes
                let enabled = typeof _config === "object" && _config.value === "yes"
                return <div className={"text-left"}>
                    {this.state.statusChanging === row.id ?
                        <div className={"p-2 pl-3"}><Spinner color={"dark"} size={"sm"}/></div> : <Switch
                            onClick={() => {
                                this.setState({statusChanging: row.id})
                                let edit_obj = {}
                                edit_obj[row.attributes.type] = enabled ? "no" : "yes"
                                let edit_obj2 = {}
                                edit_obj2[this.config_group] = edit_obj
                                SettingModel.bulkEdit(edit_obj2).then(() => {
                                    this.loadConfigs()
                                })
                            }}
                            checked={enabled}/>}
                </div>
            }
        },
        {
            Header: react_intl.formatMessage({id:"admin.email_sms_templates.transactions"}),
            accessor: null,
            width: "50%",
            Cell: (data) => {
                let row = data.row.original
                row = row.attributes
                return <ButtonGroup size={"sm"}>
                    <Link to={`/admin/${this.url_slug}/${row.id}`}
                          class={"btn btn-secondary"}>
                        <i className={"icon-feather-edit-2"}/> <FormattedMessage id={"admin.email_sms_templates.edits"}/>
                    </Link>
                    <Button color={"danger"} onClick={() => {
                        this.setState({
                            deleteTemplate: row
                        }, this.deleteModalToggle)
                    }}><i className={"icon-feather-trash-2"}/> <FormattedMessage id={"admin.email_sms_templates.delete"}/></Button>
                    <Button color={"info"} onClick={() => {
                        let {openSnackbar} = this.props
                        this.template_model.modify(row.id, {
                            status: !row.status ? 1 : 0
                        }).then(r => r.json()).then(r => {
                            this.tableRef.current.loadData();
                        }).catch(r => ApiErrorThrow(r, openSnackbar))
                    }
                    }>{row.status ? <><i className={"icon-line-awesome-sticky-note"}/> <FormattedMessage id={"admin.email_sms_templates.hide"}/></> : <><i
                        className={"icon-feather-check"}/> <FormattedMessage id={"admin.email_sms_templates.publish"}/></>}</Button>
                    <Button color={"secondary"} onClick={() => {
                        this.setState({restoreTemplate: row}, this.restoreModalToggle)
                    }}>
                        <i className={"icon-material-outline-undo"}></i> <FormattedMessage id={"admin.email_sms_templates.reset"}/>
                    </Button>
                </ButtonGroup>
            }
        }
    ]

    createTemplateHandle = (e) => {
        let new_template = this.state.new_template
        new_template[e.target.name] = e.target.value
        this.setState({
            new_template
        })
    }

    loadConfigs = () => {
        SettingModel.getByGroup(this.config_group)
            .then(r => r.json())
            .then(result => {
                let data = result.data
                this.setState({
                    auto_send_config: data,
                    statusChanging: null
                })
            })
    }

    componentDidMount() {
        document.title = GetTitle(this.titleMultiple)
        this.loadConfigs()
    }

    onFilterChanged = debounce(() => {
        this.tableRef.current.loadData()
    }, 500);

    render() {
        let {openSnackbar, hide_filter, inline_filter} = this.props
        let {deleteTemplate, createModal, restoreTemplate} = this.state
        const filterCols = <>
            <Col sm={3}>
                <Label><FormattedMessage id={"admin.email_sms_templates.title"}/></Label>
                <InputWithIcon
                    icon={"icon-line-awesome-ellipsis-h"}
                    onChange={(e) => {
                        this.setState({titleFilter: e.target.value}, this.onFilterChanged)
                    }}/>
            </Col>
            <Col sm={3}>
                <Label><FormattedMessage id={"admin.email_sms_templates.status"}/></Label>
                <MultiRadio
                    value={this.state.searchStatus}
                    onChange={(e) => {
                        this.setState({searchStatus: e.target.value}, this.tableRef.current.loadData)
                    }}
                    options={[
                        {slug: "0", label: react_intl.formatMessage({id:"admin.email_sms_templates.hidden"}), color: "info"},
                        {slug: "1", label: react_intl.formatMessage({id:"admin.email_sms_templates.on_the_air"}), color: "primary"}
                    ]}
                />
            </Col>
            <Col sm={3}>
                <Label><FormattedMessage id={"admin.email_sms_templates.language"}/></Label>
                <Select
                    styles={Select38Px}
                    options={languages()}
                    defaultValue={languages()[0]}
                    onChange={(item) => {
                        this.setState({
                            langFilter: item.value
                        }, this.onFilterChanged)
                    }}
                />
            </Col>
            <Col sm={3} className={"text-right"}>
                <p className={"p-0 m-0"}>&nbsp;</p>
                <Button color={"primary"} onClick={this.createModalToggle}><FormattedMessage id={"admin.email_sms_templates.add_new_template"}/></Button>
            </Col>
        </>

        return <div>
            <ConfirmationModalAlert
                isOpen={createModal} toggle={this.createModalToggle}
                icon={"icon-material-outline-library-add"}
                title={react_intl.formatMessage({id:"admin.email_sms_templates.create_new_title"}, {title: this.titleSingle})}
                buttonAction={() => {
                    let details = {...this.state.new_template}
                    if (typeof details.title !== "string" || details.title.length === 0) {
                        this.setState({
                            showCreateError: true
                        })
                        return false
                    }
                    if (this.template_type === "email") {
                        details.mail_body = react_intl.formatMessage({id:"admin.email_sms_templates.e_mail_title_content"}, {details_title: details.title, title_single: this.titleSingle})
                        details.subject = react_intl.formatMessage({id:"admin.email_sms_templates.title_subject"}, {details_title: details.title, title_single: this.titleSingle})
                    } else
                        details.message = react_intl.formatMessage({id:"admin.email_sms_templates.title_content"}, {details_title: details.title, title_single: this.titleSingle})

                    this.template_model.create(details)
                        .then(r => r.json())
                        .then(r => {
                            let id = r.data.id
                            this.props.history.push(`/admin/${this.url_slug}/${id}`)
                        }).catch(r => ApiErrorThrow(r, openSnackbar))
                }}
            >
                <InputWithIcon
                    icon={"icon-material-outline-keyboard-arrow-right"}
                    placeholder={react_intl.formatMessage({id:"admin.email_sms_templates.enter_title"})}
                    type={"text"} onChange={this.createTemplateHandle} name={"title"}/>
                <div className={"text-left mt-1"}>
                    <Label><FormattedMessage id={"admin.email_sms_templates.choose_template_type"}/></Label>
                    <Select
                        onChange={(val) => {
                            this.createTemplateHandle({
                                target: {
                                    name: "type",
                                    value: val.value
                                }
                            })
                        }}
                        options={this.templates.map(item => {
                            return {
                                value: item.key,
                                label: item.title
                            }
                        })}
                    />
                </div>
                <div className={"text-left mt-2"}>
                    <Label><FormattedMessage id={"admin.email_sms_templates.language2"}/></Label>
                    <Select
                        options={languages()}
                        defaultValue={languages()[0]}
                        onChange={(val) => {
                            this.createTemplateHandle({
                                target: {
                                    name: "language",
                                    value: val.value
                                }
                            })
                        }}
                    />
                </div>
                <div className={classnames("text-center", {
                    "d-none": !this.state.showCreateError
                })}>
                    <Alert color={"error"} icon={<i className={"icon-line-awesome-times"}/>}><FormattedMessage id={"admin.email_sms_templates.invalid_value_msg"}/></Alert>
                </div>
            </ConfirmationModalAlert>

            <ConfirmationModalAlert
                icon={' icon-feather-trash-2'}
                isOpen={this.state.deleteModal}
                toggle={this.deleteModalToggle}
                title={react_intl.formatMessage({id:"admin.email_sms_templates.sure_ask"})}
                description={react_intl.formatMessage({id:"admin.email_sms_templates.e_mail_template_deleted_question"},{delete_template_title:deleteTemplate.title})}
                buttonAction={() => {
                    this.template_model.delete(deleteTemplate.id)
                        .then(r => {
                            this.setState({deleteTemplate: this.defaultTemplate})
                            openSnackbar(<FormattedMessage id={"admin.email_sms_templates.e_mail_template_deleted"}/>)
                            this.deleteModalToggle()
                            this.onFilterChanged()
                        }).catch(r => ApiErrorThrow(r, openSnackbar))
                }}
            />

            <ConfirmationModalAlert
                color={"danger"}
                icon={'icon-material-outline-undo'}
                isOpen={this.state.restoreModal}
                toggle={this.restoreModalToggle}
                title={react_intl.formatMessage({id:"admin.email_sms_templates.sure_ask2"})}
                description={react_intl.formatMessage({id:"admin.email_sms_templates.e_mail_template_changed_question"},{delete_template_title:restoreTemplate.title})}
                buttonAction={() => {
                    this.template_model.restore(restoreTemplate.type)
                        .then(r => {
                            this.setState({restoreTemplate: this.defaultTemplate})
                            openSnackbar(<FormattedMessage id={"admin.email_sms_templates.e_mail_template_changed"}/>)
                            this.restoreModalToggle()
                            this.onFilterChanged()
                        }).catch(r => ApiErrorThrow(r, openSnackbar))
                }}
            />
            {!hide_filter && <TablePageFilterBar
                title={this.titleMultiple}
                description={react_intl.formatMessage({id:"admin.email_sms_templates.manage_title"},{title_multiple:this.titleMultiple.toLowerCase()})}
            >
                {filterCols}
            </TablePageFilterBar>}

            <div className={"container"}>
                {inline_filter && <Row className={"border-bottom pb-3 mb-3"}>{filterCols}</Row>}
                <DataTable
                    pageSize={25}
                    order={"desc"}
                    orderBy={"id"}
                    ref={this.tableRef}
                    columns={this.columns} dataQuery={{
                    url: `/api/v2/template/${this.template_type}`,
                    filters: {
                        language: this.state.langFilter,
                        title: this.state.titleFilter,
                        status: this.state.searchStatus
                    }
                }}/>
            </div>
        </div>;
    }
}

export default EmailSmsTemplates
import React from "react";
import {
    Button, ButtonGroup,
    Col, FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    UncontrolledTooltip
} from "reactstrap";
import {ApiErrorThrow, debounce, notificationTypes} from "../../../Config";
import DataTable from "../../../Components/DataTable";
import moment from "moment";
import GlSelect from "../../../Components/GlSelect";
import Select from "react-select";
import {Link} from "react-router-dom";
import InputWithIcon from "../../../Elements/InputWithIcon";
import TablePageFilterBar from "../../../Components/TablePageFilterBar";
import {GetTitle} from "../../../Routes/AdminRoute";
import ClientSelect from "../../../Elements/ClientSelect";
import CustomCheckbox from "../../../Elements/CustomCheckbox";
import NotificationModel from "../../../Models/NotificationModel";
import classnames from "classnames";
import {Table} from "@material-ui/core";
import {withSnackbar} from "react-simple-snackbar";
import ButtonLoad from "../../../Elements/ButtonLoad";
import NotificationCreate from "../../../Components/NotificationCreate";
import ConfirmationModalAlert from "../../../Components/ModalAlerts/ConfirmationModalAlert";
import UserLink from "../../../Elements/UserLink";
import {Select38Px} from "../../../Conts";
import {react_intl} from "../../../index";
import {FormattedMessage} from "react-intl";

export const get_notification_icon = (type) => {
    let t = ""
    let i = notificationTypes().find(item => item.value === type)
    if (typeof i === "undefined")
        i = notificationTypes().find(item => item.value === "other")
    t = i.label
    i = i.icon
    return <i className={i} title={t}/>
}


class WebNotifications extends React.Component {
    tableRef = React.createRef()
    state = {
        clientFilter: "",
        clientGroupFilter: "",
        typeFilter: "",
        titleFilter: "",
        onlyUnread: false,
        showDeleted: false,
        createNotifyModal: false,
        notificationInfo: {},
        notificationDetails: {
            user_id: {}
        },
        createLoading: false,
        deleteModal: false,
        deleteItem: {},
        deleteLoading: false
    }

    createNotifyModalToggle = () => {
        this.setState({
            createNotifyModal: !this.state.createNotifyModal
        })
    }
    deleteModalToggle = () => {
        this.setState({
            deleteModal: !this.state.deleteModal
        }, () => this.setState({deleteLoading: false}))
    }

    notificationDetailsModalToggle = () => {
        this.setState({
            notificationDetailsModal: !this.state.notificationDetailsModal
        })
    }

    showDetails = (details) => {
        this.setState({
            notificationDetails: details,
            notificationDetailsModal: typeof details === "object" && details !== null
        })
    }

    constructor(props) {
        super(props);
        this.createNotifyModalToggle = this.createNotifyModalToggle.bind(this)
        this.notificationDetailsModalToggle = this.notificationDetailsModalToggle.bind(this)
        this.deleteModalToggle = this.deleteModalToggle.bind(this)
    }

    columns = [
        {
            Header: react_intl.formatMessage({id: "admin.notifications.web.table_cols.id"}),
            accessor: 'id',
            sortKey: 'id',
            width: "10%",
            mobileWidth: "25%",
            Cell: row => {
                return <span className={"id-cell"}>{"#" + row.value}</span>
            }
        },
        {
            Header: react_intl.formatMessage({id: "admin.notifications.web.table_cols.type"}),
            accessor: null,
            hideMobile: true,
            width: "10%",
            Cell: row => {
                let data = row.row.original
                row = data.attributes
                return get_notification_icon(row.type)
            }
        }, {
            Header: react_intl.formatMessage({id: "admin.notifications.web.table_cols.status"}),
            accessor: null,
            width: "10%",
            hideMobile: true,
            Cell: row => {
                let data = row.row.original
                data = data.attributes
                return <>
                    <i
                        onClick={() => {
                            let self = this
                            let user = data.user_id
                            NotificationModel.updateById(user.id, data.id, {
                                is_read: !data.is_read
                            }).then(r => {
                                self.tableRef.current.loadData()
                            })
                        }}
                        title={data.is_read ? react_intl.formatMessage({id: "admin.notifications.web.table_cols.status.read"}) : react_intl.formatMessage({id: "admin.notifications.web.table_cols.status.unread"})}
                        className={classnames("cursor-pointer", {
                            "icon-feather-eye": data.is_read,
                            "icon-feather-eye-off": !data.is_read
                        })}
                    />
                    {data.deleted && <i
                        onClick={() => {
                            let self = this
                            let user = data.user_id
                            NotificationModel.updateById(user.id, data.id, {
                                deleted: !data.deleted
                            }).then(r => {
                                self.tableRef.current.loadData()
                            })
                        }}
                        title={react_intl.formatMessage({id: "admin.notifications.web.table_cols.status.deleted"})}
                        className={" icon-material-outline-delete cursor-pointer"}/>}
                </>
            }
        },
        {
            Header: react_intl.formatMessage({id: "admin.notifications.web.table_cols.client"}),
            accessor: 'attributes.user_id',
            width: "20%",
            mobileWidth: "30%",
            hideMobile: true,
            Cell: row => {
                return <UserLink user={row.value}/>
            }
        },
        {
            Header: react_intl.formatMessage({id: "admin.notifications.web.table_cols.title"}),
            accessor: 'attributes.title',
            sortKey: 'title',
            width: "20%",
            mobileWidth: "45%",
            Cell: row => {
                let limit = 25;
                return (row.value).length < (limit) ? row.value : (row.value).substr(0, limit - 3) + '...'
            }
        },
        {
            Header: react_intl.formatMessage({id: "admin.notifications.web.table_cols.date"}),
            accessor: 'attributes.created',
            width: "25%",
            hideMobile: true,
            Cell: row => {
                return moment(row.value).format("DD-MM-YYYY HH:mm")
            }
        },
        {
            Header: react_intl.formatMessage({id: "admin.notifications.web.table_cols.actions"}),
            width: "15%",
            mobileWidth: "30%",
            Cell: (data) => {
                let row = data.row.original
                row = row.attributes
                return <ButtonGroup size={"sm"}>
                    <Button
                        color={"info"}
                        onClick={() => this.showDetails(row)}
                    >
                        <span className={"d-none d-md-inline-block"}>
                            <FormattedMessage id={"admin.notifications.web.table_cols.actions.details"}/>
                        </span>
                        <span className="d-block d-md-none">
                            <i className={"icon-feather-eye"}/>
                        </span>
                    </Button>
                    <Button
                        onClick={() => {
                            this.setState({
                                deleteItem: row
                            }, this.deleteModalToggle)
                        }}
                        color={"danger"}>
                        <span className={"d-none d-md-inline-block"}>
                            <FormattedMessage id={"admin.notifications.web.table_cols.actions.delete"}/> <i
                            className={"icon-feather-trash"}/>
                        </span>
                        <span className="d-block d-md-none">
                            <i className={"icon-feather-trash"}/>
                        </span>

                    </Button>
                </ButtonGroup>
            }
        }
    ]
    onNotificationFilterChanged = debounce(() => {
        this.tableRef.current.loadData()
    }, 500);

    componentDidMount() {
        document.title = GetTitle(react_intl.formatMessage({id: "admin.notifications.web.title"}))
    }

    notificationHandle = (e) => {
        let notificationInfo = {...this.state.notificationInfo}
        notificationInfo[e.target.name] = e.target.value
        this.setState({
            notificationInfo
        })
    }

    render() {
        let includes = []
        let {openSnackbar} = this.props
        let {notificationInfo, notificationDetailsModal, notificationDetails, deleteItem} = this.state
        if (notificationDetails === null)
            notificationDetails = {}
        let notificationDetailsTitle = ""
        let notificationType = notificationTypes().find(item => item.value === notificationDetails.type)
        if (typeof notificationType === "undefined")
            notificationType = notificationTypes().find(item => item.value === "other")
        notificationDetailsTitle = notificationType.label

        if (this.state.showDeleted)
            includes.push("deleted")
        if (!this.state.onlyUnread)
            includes.push("read")

        return <div className={"filter-160"}>

            <ConfirmationModalAlert
                toggle={this.deleteModalToggle}
                isOpen={this.state.deleteModal}
                color={"error"}
                icon={"icon-feather-trash"}
                title={react_intl.formatMessage({id: "admin.notifications.web.are_you_sure"})}
                description={react_intl.formatMessage({id: "admin.notifications.web.delete_message"}, {title: deleteItem.title})}
                buttonLoading={this.state.deleteLoading}
                buttonAction={() => {
                    this.setState({deleteLoading: true})
                    NotificationModel.delete(deleteItem.id).then(r => {
                        this.setState({deleteItem: {}}, () => {
                            this.tableRef.current.loadData()
                            this.deleteModalToggle()
                        })
                    }).catch(r => ApiErrorThrow(r, openSnackbar))
                }}
            />

            <Modal isOpen={notificationDetailsModal} toggle={this.notificationDetailsModalToggle}>
                <ModalHeader toggle={this.notificationDetailsModalToggle}><FormattedMessage
                    id={"admin.notifications.web.detail_title"} values={{id: notificationDetails.id}}/></ModalHeader>
                <ModalBody>
                    <Table cellPadding={10} className={"notification-detail-table text-left"}>
                        <tbody>
                        <tr>
                            <th className={"text-right"}><FormattedMessage
                                id={"admin.notifications.web.detail_title.title"}/></th>
                            <td>{notificationDetails.title}</td>
                        </tr>
                        <tr>
                            <th className={"text-right"}><FormattedMessage
                                id={"admin.notifications.web.detail_title.detail"}/></th>
                            <td>{notificationDetails.details}</td>
                        </tr>
                        <tr>
                            <th className={"text-right"}><FormattedMessage
                                id={"admin.notifications.web.detail_title.date"}/></th>
                            <td>{moment(notificationDetails.created).format("DD/MM/YYYY HH:mm")}</td>
                        </tr>
                        <tr>
                            <th className={"text-right"}><FormattedMessage
                                id={"admin.notifications.web.detail_title.type"}/></th>
                            <td>{get_notification_icon(notificationDetails.type)} {notificationDetailsTitle}</td>
                        </tr>
                        <tr>
                            <th className={"text-right"}><FormattedMessage
                                id={"admin.notifications.web.detail_title.user"}/></th>
                            <td><Link
                                target={"_blank"}
                                to={`/admin/clients/${notificationDetails.user_id.id}`}>
                                {notificationDetails.user_id.firstname} {notificationDetails.user_id.lastname}
                                {" "}
                                <i className={"icon-feather-external-link"}/>
                            </Link>
                            </td>
                        </tr>
                        <tr>
                            <th className={"text-right"}>
                                <FormattedMessage id={"admin.notifications.web.detail_title.is_read"}/>
                            </th>
                            <td>{notificationDetails.is_read ? react_intl.formatMessage({id: "admin.notifications.web.detail_title.is_read.yes"}) : react_intl.formatMessage({id: "admin.notifications.web.detail_title.is_read.no"})}</td>
                        </tr>
                        <tr>
                            <th className={"text-right"}>
                                <FormattedMessage id={"admin.notifications.web.detail_title.is_deleted"}/>
                            </th>
                            <td>{notificationDetails.deleted ? react_intl.formatMessage({id: "admin.notifications.web.detail_title.is_deleted.yes"}) : react_intl.formatMessage({id: "admin.notifications.web.detail_title.is_deleted.no"})}</td>
                        </tr>
                        </tbody>
                    </Table>
                </ModalBody>
            </Modal>

            <TablePageFilterBar
                title={react_intl.formatMessage({id: "admin.notifications.web.title_top"})}
                description={react_intl.formatMessage({id: "admin.notifications.web.description"})}
            >
                <Col sm={2}>
                    <Label><FormattedMessage id={"admin.notifications.web.filter.client"}/></Label>
                    <ClientSelect
                        onChange={(value) => {
                            this.setState({clientFilter: value.map(item => item.value)}, this.onNotificationFilterChanged)
                        }}
                    />
                </Col>
                <Col sm={6} lg={2}>
                    <Label><FormattedMessage id={"admin.notifications.web.filter.client_group"}/></Label>
                    <GlSelect
                        isMulti={true}
                        url={'/api/v2/user-group'}
                        placeholder={react_intl.formatMessage({id: "admin.notifications.web.filter.client_group_placeholder"})}
                        onChange={(value) => {
                            this.setState({clientGroupFilter: value.map(item => item.value)}, this.onNotificationFilterChanged)
                        }}
                        isClearable={true}
                        searchKey={"name"}
                    />
                </Col>
                <Col sm={3} lg={2}>
                    <Label><FormattedMessage id={"admin.notifications.web.filter.title"}/></Label>
                    <InputWithIcon
                        icon={"icon-feather-more-horizontal"}
                        type={"text"}
                        onChange={(e) => {
                            this.setState({titleFilter: e.target.value}, this.onNotificationFilterChanged)
                        }}
                    />
                </Col>
                <Col sm={3} lg={2}>
                    <Label><FormattedMessage id={"admin.notifications.web.filter.type"}/></Label>
                    <Select
                        styles={Select38Px}
                        placeholder={react_intl.formatMessage({id: "admin.notifications.web.filter.type.placeholder"})}
                        onChange={(value) => {
                            this.setState({
                                typeFilter: value.value
                            }, this.onNotificationFilterChanged)
                        }}
                        defaultValue={notificationTypes()[0]}
                        options={notificationTypes()}
                    />
                </Col>
                <Col sm={3} lg={3}>
                    <Label><FormattedMessage id={"admin.notifications.web.filter.status"}/></Label>
                    <p className={"mb-1 cursor-pointer"}
                       onClick={(e) => {
                           this.setState({onlyUnread: !this.state.onlyUnread}, this.onNotificationFilterChanged)
                       }}
                    >
                        <CustomCheckbox checked={this.state.onlyUnread} style={{pointerEvents: "none"}}/>
                        <FormattedMessage id={"admin.notifications.web.filter.only_unread"}/></p>
                    <p className={"cursor-pointer"} onClick={(e) => {
                        this.setState({showDeleted: !this.state.showDeleted}, this.onNotificationFilterChanged)
                    }}>
                        <CustomCheckbox checked={this.state.showDeleted} style={{pointerEvents: "none"}}/>
                        <FormattedMessage id={"admin.notifications.web.filter.show_deleted"}/>
                    </p>
                </Col>
                <Col sm={12} lg={1}>
                    {/*<p>&nbsp;</p>*/}
                    {/*<NotificationCreate callback={() => {*/}
                    {/*    this.tableRef.current.loadData()*/}
                    {/*}}/>*/}
                </Col>
            </TablePageFilterBar>


            <div className={"container"}>
                <DataTable
                    pageSize={25}
                    order={"desc"}
                    orderBy={"id"}
                    ref={this.tableRef}
                    columns={this.columns} dataQuery={{
                    url: "/api/v2/notification",
                    filters: {
                        user_id: typeof this.state.clientFilter === "object" && Object.keys(this.state.clientFilter).length ? (this.state.clientFilter).join(",") : "",
                        user_group: typeof this.state.clientGroupFilter === "object" && Object.keys(this.state.clientGroupFilter).length ? (this.state.clientGroupFilter).join(",") : "",
                        type: this.state.typeFilter,
                        title: this.state.titleFilter,
                        include: includes.join(",")
                    }
                }}/>
            </div>

        </div>;
    }
}


export default withSnackbar(WebNotifications)
import React from "react"
import NumberFormat from "react-number-format";
import classnames from "classnames";
import {get_default_currency} from "../Config";

class InputWithIcon extends React.Component {
    render() {
        let {icon, input_type, currency} = this.props
        if(currency === null)
            currency = get_default_currency()

        if (typeof icon !== "string" || icon === "")
            icon = "icon-material-outline-info"

        if(typeof currency !== "object")
            currency = get_default_currency()

        return (<div
            className={classnames("input-with-icon-left", {
                "size-x2": this.props.size === 2
            })}
        >
            <i className={icon}/>
            {input_type === "number" ?
                <NumberFormat
                    {...this.props}
                    displayType={'input'}
                    className={classnames("input-text", {
                        "size-x2": this.props.size === 2
                    })}
                    allowedDecimalSeparators={[".",","]}
                    decimalSeparator={","}
                    thousandSeparator={"."}
                    allowNegative={false}
                    prefix={currency.prefix || ""}
                    suffix={currency.suffix || ""}
                    decimalScale={currency.currency_decimal || 2}
                /> :
                <input
                    className={`input-text ${this.props.className}`}
                    {...this.props}
                />
            }
        </div>);
    }
}

export default InputWithIcon
import React, {Fragment} from "react"
const {Route} = require("react-router-dom");

export class EmptyRoute extends Route {
    render() {
        const {component: Component}: EmptyRoute = this.props;

        const containerInnerClass = "container"
        const wrapperClass = "w-100 fullh"

        return <Fragment>
            <div id="wrapper" className={wrapperClass}>
                <div className="installer-container dashboard-container">
                    <div className="w-50 ml-auto mr-auto page-container">
                        <div className={containerInnerClass}>
                            <Component {...this.props} />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    }
}
import React from "react";
import {Badge, Button, ButtonGroup} from "reactstrap";
import Select from "react-select";
import {conditionTypes} from "./PaymentRules";
import {Select38Px} from "../../../Conts";
import {react_intl} from "../../../index";

class PaymentRuleSingle extends React.Component<{}> {
    render() {
        let rand = parseInt(Math.random() * 1000);
        let {
            addRow, removeRow, modifyRow,
            type, details, ruleKey
        } = this.props
        return <tr key={`row-${ruleKey}-${rand}`}>
            <td width={"15%"} className={"text-center"}>
                <ButtonGroup size={"sm"}>
                    <Button onClick={() => addRow(ruleKey)} color={"primary"}><i
                        className={"icon-feather-plus"}/></Button>
                    <Button onClick={() => removeRow(ruleKey)} color={"danger"}><i
                        className={"icon-feather-minus"}/></Button>
                </ButtonGroup>
            </td>
            <th width={"5%"}><Badge color={"primary"}>
                {ruleKey ? react_intl.formatMessage({id:"admin.payment.payment_rules.single.and"}) : react_intl.formatMessage({id:"admin.payment.payment_rules.single.if"})}
            </Badge></th>
            <td colSpan={type === "All" ? 2 : 1} width={`${type === "All" ? 100 : 40}%`}>
                <Select
                    styles={Select38Px}
                    onChange={(e) => {
                        modifyRow(ruleKey, "name", e.value)
                    }}
                    defaultValue={conditionTypes().find(item => item.value === type)}
                    options={conditionTypes()}
                />
            </td>
            {type !== "All" && <td width={"40%"}>
                <textarea
                    style={{height: 120, minHeight: "auto"}} onBlur={(e) => {
                    modifyRow(ruleKey, "value", e.target.value)
                }} defaultValue={details}
                ></textarea>
            </td>}
        </tr>;
    }
}

export default PaymentRuleSingle
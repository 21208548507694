import GlRequest from "../Lib/GlRequest";

class TemplateModel {
    type = "email"

    constructor(type) {
        if (!type)
            throw new TypeError("Please specify template type")
        this.type = type
    }

    create(details) {
        details = {
            data: {attributes: details}
        }
        return GlRequest.post('/api/v2/template/' + this.type, details, 'json')
    }

    modify(id, data) {
        data = {
            data: {
                attributes: data
            }
        }
        return GlRequest.patch('/api/v2/template/' + this.type + '/' + id, data, 'json')
    }

    get(params) {
        return GlRequest.get(`/api/v2/template/${this.type}`, {params:params})
    }

    delete(id) {
        return GlRequest.delete('/api/v2/template/' + this.type + '/' + id)
    }

    getById(id) {
        return GlRequest.get('/api/v2/template/' + this.type + '/' + id)
    }

    history(details) {
        let query = ""
        Object.keys(details).forEach(function (key, num) {
            if(num)
                query+='&'
            query+=key+'='+details[key]
        })

        if(query)
            query+="&"
        query += "sort=-id"

        let url = '/api/v2/mail-sms-history/' + this.type + '?' + query
        return GlRequest.get(url)
    }

    repeat(id)
    {
        let url = `/api/v2/mail-sms-history/${this.type}/${id}/repeat`
        return GlRequest.get(url)

    }

    restore(type) {
        let url = `/api/v2/restore-template/${this.type}/${type}`
        return GlRequest.get(url)
    }
}

export default TemplateModel
import React from "react"
import InputWithIcon from "../../../Elements/InputWithIcon";
import {Col, Input, Label, Row} from "reactstrap";
import GlSelect from "../../../Components/GlSelect";
import {Alert} from "@material-ui/lab";
import NotesIcon from '@material-ui/icons/Notes';
import {ApiErrorThrow, defaultSnackbarOptions} from "../../../Config";
import {withSnackbar} from "react-simple-snackbar";
import classnames from "classnames";
import CustomCheckbox from "../../../Elements/CustomCheckbox";
import OrderModel from "../../../Models/OrderModel";
import {Select38Px} from "../../../Conts";
import {FormattedMessage} from "react-intl";
import PhoneNumberInput from "../../../Elements/PhoneNumberInput";
import {injectIntl} from "react-intl";
import LangSwitcher from "../../../Components/LangSwitcher";

class QuickPayment_Step1 extends React.Component {
    state = {
        selectedCurrency: {},
        order_details: {
            amount: "0,00"
        },
        error: false,
        currencyWaiting: false,
        addNote: false,
        name: "",
        email: "",
        phone: "",
        note: ""
    }

    amountTemp = "0,00"

    genOrderNote = () => {
        let _Name = this.state.name,
            _Phone = this.state.phone,
            _Email = this.state.email,
            _Note = this.state.note,
            _NoteStr = "";

        if (_Note)
            _NoteStr = _Note;
        if (_Name)
            _NoteStr += "\n" + "NAME= " + _Name;
        if (_Phone)
            _NoteStr += "\n" + "PHONE= " + _Phone;
        if (_Email)
            _NoteStr += "\n" + "EMAIL= " + _Email;
        return _NoteStr;
    }

    next = async () => {
        let {selectedCurrency, order_details, name, phone} = this.state
        let {payment_type} = this.props
        return new Promise((resolve, reject) => {
            let _detailsForAPI = {
                currency_code: selectedCurrency.code,
                status: "quick_checkout",
                amount: order_details.amount,
                notes: this.genOrderNote()
            };
            let _CREATE;

            if (payment_type === "quick_payment")
                _CREATE = OrderModel.create(_detailsForAPI);
            else if (payment_type === "open_payment")
                _CREATE = OrderModel.createAnonymous(_detailsForAPI);

            return _CREATE.then(r => r.json())
                .then(r => {
                    let _resolveData = {};
                    if (typeof r.data.id === "undefined")
                        reject("No order");
                    _resolveData.orderId = r.data.id;

                    if (payment_type === "open_payment" && (typeof r.included === "undefined" || typeof r.included[_resolveData.orderId] !== "object"))
                        reject("");

                    if (payment_type === "open_payment" && (typeof r.included === "undefined" || typeof r.included[_resolveData.orderId] !== "object"))
                        reject("No Token");

                    if (typeof r.included === "object" && typeof r.included[_resolveData.orderId] !== "undefined")
                        _resolveData.token = r.included[_resolveData.orderId].data.meta_value;

                    resolve(_resolveData);
                    return _resolveData;
                }).catch(r => {
                    ApiErrorThrow(r);
                    reject();
                })
        })
    }

    isVerified = () => {
        let {selectedCurrency, order_details} = this.state
        let {openSnackbar} = this.props

        let _amount = 0;
        if (typeof (order_details.amount) === "string" && (order_details.amount).indexOf(",") > -1)
            _amount = (order_details.amount).replaceAll(',', '.');

        if (typeof order_details.amount === "string")
            order_details.amount = parseFloat(_amount)

        if (typeof order_details.amount !== "number" || parseFloat(order_details.amount) <= 0) {
            openSnackbar(<FormattedMessage id={"client.quick_payment_step1.valid_amount_msg"}/>)
            return false
        }

        if (typeof selectedCurrency !== "object" || typeof selectedCurrency.id !== "number") {
            openSnackbar(<FormattedMessage id={"client.quick_payment_step1.select_currency_msg"}/>)
            return false
        }
        return true
    }

    onBlur = (cb) => {
        let {order_details} = this.state
        let {amountTemp} = this
        let order_details_ = {...order_details}
        if (amountTemp === '' || amountTemp === '0,00' || parseFloat(amountTemp) === 0) {
            order_details_.amount = '0,00'
        } else {
            order_details_.amount = (parseFloat(amountTemp).toFixed(2)).toString().replace('.', ',')
        }

        this.setState({
            order_details: order_details_
        }, () => {
            if (typeof cb === "function")
                cb()
        })
    }

    onFocus = () => {
        let {order_details} = this.state
        if (parseFloat(order_details.amount) === 0) {
            let order_details_ = {...order_details}
            order_details_.amount = ''
            this.setState({order_details: order_details_})
        }
    }

    render() {
        let {currencyWaiting, error, order_details} = this.state
        let {formatMessage} = this.props.intl
        return <>
            <Row>
                {error && <Col sm={12}>
                    <Alert
                        icon={<i className={"icon-line-awesome-times"}/>}
                        action={() => this.setState({error: false})}>{error}</Alert>
                </Col>}
                <Col sm={12}>
                    <h3 className={"mb-2"}><FormattedMessage id={"client.quick_payment_step1.amount"}/></h3>
                    <InputWithIcon
                        size={2}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                let next = this.props.next;
                                if (typeof next === "function") {
                                    this.onBlur(function () {
                                        next();
                                    });
                                    // to fix enter spam
                                    document.activeElement.blur();
                                }
                            }
                        }}
                        value={order_details.amount}
                        icon={currencyWaiting ? "icon-line-awesome-spinner" : "icon-line-awesome-money"}
                        input_type={"number"}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onValueChange={(e) => {
                            if (typeof e.floatValue === "undefined")
                                e.floatValue = 0
                            if (typeof e.floatValue === "number")
                                this.amountTemp = (e.floatValue).toFixed(2)
                        }}
                        currency={this.state.selectedCurrency}
                    />
                </Col>
                <Col sm={12}>
                    <h4 className={"mb-2 mt-1 pt-1"}><FormattedMessage id={"client.quick_payment_step1.currency"}/></h4>
                    <GlSelect
                        styles={Object.assign(Select38Px, {
                            container: (provided, state) => ({
                                ...provided,
                                zIndex: 99
                            })
                        })}
                        url={'/currency'}
                        closeMenuOnSelect={true}
                        placeholder={formatMessage({id: "client.quick_payment_step1.select"})}
                        value={{
                            value: this.state.selectedCurrency.id,
                            label: this.state.selectedCurrency.code
                        }}
                        firstSelected={true}
                        defaultValue={this.state.selectedCurrency.id ? {
                            value: this.state.selectedCurrency.id,
                            label: this.state.selectedCurrency.code
                        } : false}
                        onChange={(value) => {
                            this.setState({
                                currencyWaiting: false,
                                selectedCurrency: value.details
                            })
                            if (typeof this.props.currencyChanged === "function") {
                                this.props.currencyChanged(value.details)
                            }
                        }}
                        isClient={true}
                    />
                </Col>
            </Row>

            <Row className={classnames("pt-2 pb-1 mt-3 rounded", {
                "bg-light": this.state.addNote
            })}>
                <Col>
                    <Alert
                        color={"info"}
                        icon={<NotesIcon/>}
                        onClick={() => {
                            this.setState({
                                addNote: !this.state.addNote
                            })
                        }} className={"cursor-pointer float-left w-auto border"}>
                        <FormattedMessage id={"client.quick_payment_step1.add_note"}/>{" "}
                        <CustomCheckbox checked={this.state.addNote}
                                        style={{
                                            pointerEvents: "none",
                                            float: "right",
                                            marginTop: "-2px",
                                            marginLeft: "10px"
                                        }}
                        />
                    </Alert>
                </Col>
                <Col sm={12} className={classnames("mt-2", {
                    "d-none": !this.state.addNote
                })}>
                    <Label className={"text-dark mt-2"}><FormattedMessage
                        id={"client.quick_payment_step1.invoice_note"}/></Label>
                    <textarea className={"form-control"} onChange={(e) => {
                        this.setState({note: e.target.value})
                    }}></textarea>
                </Col>
            </Row>

            {this.props.payment_type === "open_payment" ? <Row className={"pt-2 pb-1 mt-3"}>
                <Col sm={12}>
                    <Label><FormattedMessage id={"client.open_payment.namesurname"}/></Label>
                    <InputWithIcon type={"text"} name={"name"} onChange={(e) => {
                        this.setState({
                            name: e.target.value
                        })
                    }}/>
                </Col>
                <Col sm={12} lg={6}>
                    <Label><FormattedMessage id={"client.open_payment.phone"}/></Label>
                    <PhoneNumberInput
                        onChange={(val) => {
                            if (typeof val !== "object")
                                val = {}
                            this.setState({
                                phone: val.number
                            });
                        }}
                    />
                </Col>
                <Col sm={12} lg={6}>
                    <Label><FormattedMessage id={"client.open_payment.email"}/></Label>
                    <InputWithIcon type={"email"} name={"email"} onChange={(e) => {
                        this.setState({
                            email: e.target.value
                        })
                    }}/>
                </Col>
            </Row> : ""}
        </>;
    }

    componentDidMount() {
        if (this.props.onRef != null) {
            this.props.onRef(this)
        }
    }
}

export default injectIntl(withSnackbar(QuickPayment_Step1, defaultSnackbarOptions))

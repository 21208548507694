import React from "react"
import {debounce, defaultSnackbarOptions, languages, log_types} from "../../../Config";
import {withSnackbar} from "react-simple-snackbar";
import {Button, Col, Input, Label, Row} from "reactstrap";
import Select from "react-select";
import DataTable from "../../../Components/DataTable";
import moment from "moment";
import TablePageFilterBar from "../../../Components/TablePageFilterBar";
import {Select38Px} from "../../../Conts";
import DateRangePickerWrapper from "../../../Components/react-dates/DateRangePickerWrapper";
import {GetTitle} from "../../../Routes/AdminRoute";
import {Link} from "react-router-dom";
import InputWithIcon from "../../../Elements/InputWithIcon";
import ConfirmationModalAlert from "../../../Components/ModalAlerts/ConfirmationModalAlert";
import PurgeLog from "../../../Components/PurgeLog";
import {react_intl} from "../../../index";
import {FormattedMessage} from "react-intl";
import LogSettings from "../../../Components/LogSettings";

class SystemLogs extends React.Component {
    tableRef = React.createRef()
    state = {
        typeFilter: typeof this.props.filterType === "string" ? this.props.filterType : log_types()[0].value,
        dateFilterStart: "",
        dateFilterEnd: "",
        ipFilter: "",
        concat: ""
    }

    componentDidMount() {
        document.title = GetTitle(react_intl.formatMessage({id:"admin.system_logs.system_records_title"}))
    }

    onFilterChanged = debounce(() => {
        this.tableRef.current.loadData()
    }, 500);




    columns = [
        {
            Header: react_intl.formatMessage({id:"admin.system_logs.record_no"}),
            accessor: 'id',
            sortKey: 'id',
            width: "10%",
            Cell: row => {
                return <span className="id-cell">{"#" + row.value}</span>
            }
        },
        {
            Header: react_intl.formatMessage({id:"admin.system_logs.record_type_header"}),
            accessor: 'attributes.type',
            sortKey: 'type',
            width: "20%",
            Cell: row => {
                return (log_types().find(item => item.value === row.value) || {}).label || ""
            }
        },
        {
            Header: react_intl.formatMessage({id:"admin.system_logs.ip_address_header"}),
            accessor: 'attributes.ip_address',
            width: "15%",
            Cell: row => {
                return <div>
                    {row.value}
                    {" "}
                    <a href={"https://ipinfo.io/" + row.value} target={"_blank"}><i
                        className={"icon-feather-globe"}/></a>
                </div>
            }
        },
        {
            Header: react_intl.formatMessage({id:"admin.system_logs.request"}),
            accessor: null,
            width: "20%",
            Cell: data => {
                let row = data.row.original
                row = row.attributes
                let request = Object.assign(row.request, {
                    Headers: row.headers
                })
                return <textarea className={"form-control"} defaultValue={JSON.stringify(request, null, " ")}/>
            }
        },
        {
            Header: react_intl.formatMessage({id:"admin.system_logs.response"}),
            accessor: 'attributes.response',
            width: "20%",
            Cell: row => {
                return <textarea className={"form-control"} defaultValue={JSON.stringify(row.value, null, " ")}/>
            }
        },
        {
            Header: react_intl.formatMessage({id:"admin.system_logs.date"}),
            accessor: 'attributes.created',
            sortKey: 'created',
            width: "15%",
            Cell: row => {
                return moment(row.value).format("DD-MM-YYYY HH:mm")
            }
        }
    ]

    reload = () => {
        if (typeof this.tableRef === "undefined" || this.tableRef === null)
            return false
        this.tableRef.current.loadData();
    }

    render() {
        let {dateFilterStart, dateFilterEnd} = this.state
        let {filterType, filterIp, filterDate, purge, viewType} = this.props
        let columns = this.columns
        if(typeof this.props.typeColumn === "boolean" && !this.props.typeColumn)
            columns = columns.filter(item => item.sortKey !== "type")

        if (typeof viewType === "undefined")
            viewType = "pageTop"

        if (typeof filterType === "undefined")
            filterType = true
        if (typeof filterIp === "undefined")
            filterIp = true
        if (typeof filterDate === "undefined")
            filterDate = true
        if (typeof purge === "undefined")
            purge = true


        const filters = <>
            <Col lg={3}>
                <Label><FormattedMessage id={"admin.system_logs.keyword"}/></Label>
                <Input type={"text"} placeholder={react_intl.formatMessage({id:"admin.system_logs.keyword_for_search"})} onChange={(e) => { this.setState({concat: e.target.value}, this.onFilterChanged) }} />
            </Col>
            {typeof filterType !== "string" && <Col lg={2}>
                <Label><FormattedMessage id={"admin.system_logs.record_type"}/></Label>
                <Select
                    styles={Select38Px}
                    options={log_types()}
                    defaultValue={log_types()[0]}
                    onChange={(item) => {
                        this.setState({
                            typeFilter: item.value
                        }, this.onFilterChanged)
                    }}
                />
            </Col>}
            {filterIp && <Col lg={2}>
                <Label><FormattedMessage id={"admin.system_logs.ip_address"}/></Label>
                <InputWithIcon value={this.state.ipFilter} onChange={(e) => {
                    this.setState({
                        ipFilter: e.target.value
                    }, this.onFilterChanged)
                }}/>
            </Col>}
            {filterDate && <Col sm={typeof filterType === "string" && !filterIp ? 7 : 3}>
                <Label><FormattedMessage id={"admin.system_logs.date_range"}/></Label>
                <DateRangePickerWrapper
                    showClearDates={true}
                    onChange={(start, end) => {
                        this.setState({
                            dateFilterStart: start ? start.toDate() : null,
                            dateFilterEnd: end ? end.toDate() : null
                        }, this.onFilterChanged)
                    }}
                />
            </Col>}
            {purge && <Col lg={2} className={"float-right text-right"}>
                <PurgeLog reload={this.reload}/>
                <LogSettings />
            </Col>}
        </>

        return <div>
            {viewType === "pageTop" && <TablePageFilterBar
                title={react_intl.formatMessage({id:"admin.system_logs.system_records"})}
                description={react_intl.formatMessage({id:"admin.system_logs.review_records"})}
            >
                {filters}
            </TablePageFilterBar>}

            <div className={"container"}>
                {viewType === "inline" && <Row className={"pb-3 mb-3 border-bottom"}>
                    {filters}
                </Row>}
                <DataTable
                    pageSize={25}
                    order={"desc"}
                    orderBy={"created"}
                    ref={this.tableRef}
                    columns={columns} dataQuery={{
                    url: "/api/v2/log",
                    filters: {
                        type: this.state.typeFilter,
                        ip_address: this.state.ipFilter,
                        start: dateFilterStart ? moment(dateFilterStart).format("YYYY-MM-DD") : "",
                        end: dateFilterEnd ? moment(dateFilterEnd).format("YYYY-MM-DD") : "",
                        concat: this.state.concat
                    }
                }}/>
            </div>
        </div>;
    }
}

export default withSnackbar(SystemLogs, defaultSnackbarOptions)
import React from "react"
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle, Col,
    Row, TabContent, TabPane
} from "reactstrap";
import {withSnackbar} from "react-simple-snackbar";
import {ApiErrorThrow, defaultSnackbarOptions} from "../../../Config";
import QuickPayment_Step1 from "./QuickPayment_Step1";
import classnames from "classnames";
import {GetTitle} from "../../../Routes/AdminRoute";
import CreditCardTab from "../Payment/CreditCardTab";
import ButtonLoad from "../../../Elements/ButtonLoad";
import PaymentModel from "../../../Models/PaymentModel";
import CustomCheckbox from "../../../Elements/CustomCheckbox";
import ThreeDModal from "../Payment/ThreeDModal";
import PaymentFailedModal from "../Payment/PaymentFailedModal";
import PaymentSuccessModal from "../Payment/PaymentSuccessModal";
import MultiRadio from "../../../Elements/MultiRadio";
import {get_payment_method_id} from "../PayPageBase";
// import BkmExpressTab from "../Payment/BkmExpressTab";
import {injectIntl} from "react-intl";
import {FormattedMessage} from "react-intl/lib";
import {get_main_config, react_intl} from "../../../index";
import TermsModal from "../TermsModal";
import GarantiPayTab from "../Payment/GarantiPayTab";
import LangSwitcher from "../../../Components/LangSwitcher";

class QPaymentTab extends React.Component {
    state = {
        paymentType: "CC",
        numberValid: false,
        cvvValid: false,
        expValid: false,
        expValidNotChanged: true,
        holderValid: true
    }

    paymentMethods = (getItem) => {
        let {formatMessage} = this.props.intl
        let methods = []
        methods.push({
            label: formatMessage({id: "client.quick_payment.pay_with_credit_card"}),
            slug: "CC"
        })

        // let bkmId = get_payment_method_id("BkmExpress")
        // if (bkmId !== -1) {
        //     methods.push({
        //         label: "BKM Express",
        //         slug: "BkmExpress",
        //         methodId: bkmId
        //     })
        // }

        let garantiPayId = get_payment_method_id("GarantiPay")
        if (garantiPayId !== -1) {
            methods.push({
                label: "GarantiPay",
                slug: "GarantiPay",
                methodId: garantiPayId
            })
        }

        if (typeof getItem !== "undefined" && getItem)
            return methods.find(item => item.slug === getItem)
        return methods
    }

    render() {
        let {self} = this.props
        return (
            <div>
                <div className="bg-light p-2 rounded margin-bottom-15">
                    <MultiRadio
                        value={this.state.paymentType}
                        onChange={(e) => {
                            let v = e.target.value
                            this.setState({
                                paymentType: v
                            }, () => {
                                if (typeof this.props.onChange === "function")
                                    this.props.onChange(v)
                            })
                        }}
                        options={this.paymentMethods()}
                    />
                </div>

                <TabContent activeTab={this.state.paymentType}>
                    <TabPane tabId={"CC"}>
                        <CreditCardTab
                            auth={this.props.payment_type !== "open_payment"}
                            token={this.state.token}
                            theme={"quick"}
                            setRef={(r) => self.refArray[2] = r}
                            installment_changed={self.installment_changed}
                            installment={self.state.installment}
                            orderId={self.state.orderId}
                            onChange={self.ccOnChange}
                            orderDetails={self.state.orderDetails}
                            details={self.state.cc_details}

                            isValidNumber={this.state.numberValid}
                            isValidExp={this.state.expValid}
                            expValidNotChanged={this.state.expValidNotChanged}
                            isValidCVV={this.state.cvvValid}
                            isValidHolder={this.state.holderValid}

                            numberValidation={(result) => {
                                if (result !== this.state.numberValid) this.setState({numberValid: result})
                            }}
                            expiryValidation={(result) => {
                                if (result !== this.state.expValid) this.setState({
                                    expValid: result,
                                    expValidNotChanged: false
                                })
                            }}
                            cvvValidation={(result) => {
                                if (result !== this.state.cvvValid) this.setState({cvvValid: result})
                            }}
                            holderValidation={(result) => {
                                if (result !== this.state.holderValid) this.setState({holderValid: result})
                            }}
                        />
                    </TabPane>
                    {/*<TabPane tabId={"BkmExpress"}>*/}
                    {/*    <BkmExpressTab/>*/}
                    {/*</TabPane>*/}
                    <TabPane tabId={"GarantiPay"}>
                        <GarantiPayTab/>
                    </TabPane>
                </TabContent>
            </div>
        );
    }
}

class QuickPaymentBase extends React.Component {
    payment_type = "quick_payment";
    toggle = tab => {
        this.setState({
            activeTab: tab
        })
    }

    paymentCompleted = (data) => {

        let Token = this.state.token
        let OrderId = this.state.orderId
        let redirect_url = `/cp/orders/${OrderId}`
        if (Token)
            redirect_url = `/show-invoice/${OrderId}/${Token}`
        this.props.history.push(redirect_url)
    }

    paymentFailed = (data) => {
        let self = this
        self.setState({
            popupShow: false,
            errorShow: true,
            errorMessage: data.message || ""
        })
    }

    popupToggle = () => {
        this.setState({popupShow: !this.state.popupShow})
    }

    errorToggle = () => {
        this.setState({errorShow: !this.state.errorShow})
    }

    successToggle = () => {
        this.setState({successShow: !this.state.successShow})
    }

    constructor(props) {
        super(props);
        this.ccOnChange = this.ccOnChange.bind(this)
        this.popupToggle = this.popupToggle.bind(this)

        let self = this
        window.onmessage = function (e) {
            let {data} = e
            self.setState({
                iframeDoc: ""
            })
            switch (data.event) {
                case 'payment_completed':
                    self.paymentCompleted(data)
                    break;
                case 'payment_failed':
                    self.paymentFailed(data)
                    break;

            }
        }

        if ( this.payment_type === "open_payment" && (typeof get_main_config("anonymous_payment", "allow_payment") !== "string" || get_main_config("anonymous_payment", "allow_payment") !== "yes")) {
            props.history.push('/');
        }
    }

    componentDidMount() {
        let {formatMessage} = this.props.intl
        document.title = GetTitle(formatMessage({id: "client.quick_payment.quick_pay"}))
    }

    state = {
        nextWaiting: false,
        activeStep: 0,
        paymentModal: false,
        cc_details: {
            number: '',
            holder: '',
            expiry_month: '',
            expiry_year: '',
            cvv: '',
        },
        orderDetails: {
            currency: {}
        },
        orderId: "",
        token: "",
        installment: 1,
        accepted: !true,
        activeMethod: null
    }

    step2Ref = React.createRef()
    refArray = [
        0, this.step2Ref
    ]

    installment_changed = (installment) => {
        this.setState({
            installment
        })
    }

    ccOnChange = (name, value) => {
        let cc_details = {...this.state.cc_details}
        if (name === "holder") {
            if (value.match(/[^a-zA-Z' 'wığüşöçĞÜŞÖÇİ]/g)) {
                value = value.replace(/[^a-zA-ZwığüşöçĞÜŞÖÇİ]/g, '');
            }
        }
        cc_details[name] = value
        this.setState({cc_details})
    }

    startPayment() {
        let {openSnackbar} = this.props
        let {cc_details, installment} = this.state

        if (!this.state.accepted) {
            openSnackbar(<FormattedMessage id={"client.quick_payment.accept_terms_msg"}/>)
            return false
        }

        this.setState({
            popupShow: true
        })

        let payment_object = {
            cc_number: cc_details.number,
            cc_cvv: cc_details.cvv,
            cc_exp: `${cc_details.expiry_month}/${cc_details.expiry_year}`,
            cc_holder: cc_details.holder,
            order_id: this.state.orderId,
            installment: installment
        }

        if (this.state.activeMethod !== null)
            payment_object.use_method = get_payment_method_id(this.state.activeMethod)

        let make;
        if (this.payment_type === "open_payment")
            make = PaymentModel.makeUnauth(payment_object, this.state.token)
        else
            make = PaymentModel.make(payment_object)

        make
            .then(r => r.json())
            .then(resp => {
                let status = resp.status
                if (status) {
                    let {form} = resp.data.attributes
                    this.setState({
                        iframeDoc: form
                    })
                } else {
                    let message = ""
                    openSnackbar(message)
                }
            }).catch(r => {
            this.popupToggle()
            ApiErrorThrow(r, openSnackbar)
        })
        return "";
    }

    steps = () => {
        return [
            {
                slug: "createOrder",
                isDefault: true,
                content: <QuickPayment_Step1
                    payment_type={this.payment_type}
                    next={this.next}
                    {...this.props}
                    onRef={(item) => {
                        this.refArray[0] = item
                    }}
                    currencyChanged={(val) => {
                        let orderDetails = {...this.state.orderDetails}
                        orderDetails['currency'] = val
                        this.setState({orderDetails})
                    }}
                />,
                title: <div><i className={"icon-feather-list"}/> <FormattedMessage
                    id={"client.quick_payment.payment_details"}/></div>
            },
            {
                slug: "completePayment",
                isDefault: false,
                content: <div ref={this.refArray[1]}>
                    {<QPaymentTab
                        payment_type={this.payment_type}
                        intl={this.props.intl}
                        self={this} onChange={(val) => {
                        this.setState({
                            activeMethod: val
                        })
                    }}/>}
                    <div className={"text-right padding-top-25"}>
                        <ul className={"list-unstyled"}>
                            <li>
                                <CustomCheckbox
                                    checked={this.state.accepted}
                                    onChange={(e) => {
                                        this.setState({
                                            accepted: e.target.checked
                                        })
                                    }}
                                />
                                <TermsModal onAccept={() => {
                                    this.setState({
                                        accepted: true
                                    })
                                }} onCancel={() => {
                                    this.setState({
                                        accepted: false
                                    })
                                }}/>
                            </li>
                        </ul>
                    </div>
                </div>,
                title: <div><i className={"icon-feather-check"}/> <FormattedMessage
                    id={"client.quick_payment.accept_payment"}/></div>
            }
        ]
    }

    next = async () => {
        let {activeStep} = this.state
        let self = this;
        let activeRef = this.refArray[activeStep]

        this.setState({nextWaiting: true})

        if (typeof activeRef.current !== "undefined")
            activeRef = activeRef.current

        if (typeof activeRef.isVerified !== "function" || activeRef.isVerified()) {
            if (typeof activeRef.next === "function") {
                await activeRef.next().then(callback => {
                    let _NextStep = activeStep + 1,
                        _NextStepColClass = `.qc-step-` + _NextStep;
                    if (document.querySelector(_NextStepColClass) !== null)
                        document.querySelector(_NextStepColClass).scrollIntoView();
                    let new_state_object = {
                        orderId: callback.orderId
                    };
                    if (typeof callback.token === "string")
                        new_state_object.token = callback.token;

                    this.setState(new_state_object, () => {
                        if (typeof callback.token === "string") {
                            window.dispatchEvent(new CustomEvent("orderTokenChanged", {
                                detail: {token: callback.token}
                            }));
                        }
                        self.setState({activeStep: ++activeStep, nextWaiting: false}, () => {
                            if (activeStep === 1) {
                                if (typeof self.refArray[2].resetState === "function")
                                    self.refArray[2].resetState()
                            }

                        })
                    })
                }).catch(e => {
                    this.setState({nextWaiting: false});
                });
            }
        } else {
            this.setState({nextWaiting: false})
        }
    }

    prev = () => this.setState({activeStep: --this.state.activeStep})

    complete = () => {
        this.setState({paymentModal: true})
    }

    paymentModalToggle = () => {
        let state = {
            paymentModal: !this.state.paymentModal
        }
        if (this.state.paymentModal) {
            state['iframeDoc'] = null
        }
        this.setState(state)
    }

    render() {
        let {payment_type} = this
        let {activeStep} = this.state
        let steps = this.steps()
        let {formatMessage} = this.props.intl
        let outer_class = "w-100 pl-3 pr-3 pt-3";
        if (payment_type === "open_payment")
            outer_class += "w-100 padding-top-50 padding-bottom-50 margin-top-25"
        return <div className={outer_class}>

            {payment_type === "open_payment" ? <div className={"text-center mb-3 cursor-pointer"} onClick={() => {
                this.props.history.push('/');
            }}>
                <img
                    height={64}
                    src={get_main_config("system_general", "logo_url")} alt={""}
                />
            </div> : ""}
            <Row>
                {steps.map((item, number) => {
                    return <Col sm={12} lg={payment_type === "open_payment" ? 12 : ((number + 1) * 4)}
                                className={classnames("qc-step qc-step-" + (number), {
                                    "disabled": activeStep !== number,
                                })}><Card className={classnames("mb-3", {
                        "shadow": activeStep === number
                    })}>
                        <CardHeader className={classnames("cursor-pointer", {
                            "font-weight-bold": activeStep === number,
                            "text-dark": activeStep === number
                        })} onClick={() => {
                            if (number > activeStep) {
                                this.next()
                            } else {
                                this.setState({activeStep: number})
                            }
                        }}>
                            <CardTitle>
                                <div className={"float-left font-size-18"}>
                                    {item.title}
                                </div>
                                <Badge className={"float-right font-weight-bold"}
                                       color={number === activeStep ? "dark" : "light"}
                                       style={{fontSize: 18, marginTop: -5}}>{`${number + 1}/${steps.length}`}</Badge>
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            {item.content}
                        </CardBody>
                        <CardFooter className={"text-right"}>
                            <Button
                                size={"sm"}
                                onClick={() => this.prev()}
                                color={"danger"}
                                className={classnames("float-left", {
                                    "d-none": activeStep === 0
                                })}><i className={" icon-material-outline-arrow-back"}/> <FormattedMessage
                                id={"client.quick_payment.previous_btn_txt"}/></Button>
                            <ButtonLoad
                                loading={this.state.nextWaiting}
                                icon={"icon-material-outline-arrow-forward"}
                                text={formatMessage({id: "client.quick_payment.continue"})}
                                className={classnames({
                                    "d-none": number === (steps.length) - 1
                                })}
                                onClick={() => this.next()}
                                color={"primary"}
                            />
                            <Button
                                className={classnames({
                                    "d-none": number !== (steps.length) - 1
                                })}
                                onClick={() => this.startPayment()}
                                color={"primary"}><FormattedMessage id={"client.quick_payment.complete"}/> <i
                                className={" icon-material-outline-arrow-forward"}/></Button>
                        </CardFooter>
                    </Card>
                    </Col>
                })}

                <ThreeDModal
                    methodSelected={"CC"}
                    popupShow={this.state.popupShow}
                    iframeDoc={this.state.iframeDoc}
                    popupToggle={this.popupToggle}
                    paymentDuring={this.state.popupShow}
                />
                <PaymentFailedModal
                    message={this.state.errorMessage}
                    isOpen={this.state.errorShow}
                    toggle={this.errorToggle}
                />
                <PaymentSuccessModal
                    isOpen={this.state.successShow}
                    toggle={this.successToggle}
                />
                {payment_type === "open_payment" ? <div className={"language-switch-blank-template"}>
                    <LangSwitcher
                        bgTransparent={true}
                        boxStyle={{
                            width: 200,
                            bottom: 20,
                            right: 20,
                            padding: "7.5px 0",
                            position: "fixed",
                            backgroundColor: "transparent !important"
                        }}/>
                </div> : ""}
            </Row>
        </div>;
    }
}

export default QuickPaymentBase

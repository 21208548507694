import React from "react"
import {Col, Row} from "reactstrap";
import CustomCheckbox from "../../../Elements/CustomCheckbox";

class FilterResult extends React.Component {
    render() {
        let {email, selected, firstname, lastname, mobile_phone, onSelect, preview} = this.props
        return (
            <Row className={"cursor-pointer pt-1 pb-1 result-line"} onClick={() => onSelect({
                target: {
                    checked: !selected
                }
            })}>{preview ? "" : <Col>
                <CustomCheckbox
                    style={{pointerEvents: "none"}}
                    checked={selected}
                />
            </Col>}
                <Col>{firstname} {lastname}</Col>
                <Col>{mobile_phone}</Col>
                <Col>{email}</Col>
            </Row>
        );
    }
}

export default FilterResult
import React from "react"
import {Badge} from "reactstrap";
import {FormattedMessage} from "react-intl";

class SmsTextarea extends React.Component {

    smsSize = () => {
        return /[^\u0000-\u007f]/.test(this.state.content) ? 70 : 160
    }

    state = {
        content: ""
    }
    change = (e) => {
        this.setState({
            content: e.target.value
        })
        if (typeof this.props.onChange === "function")
            this.props.onChange(e)
    }

    render() {
        let {content} = this.state
        let limit = this.smsSize()
        let length = content.length
        let smsCount = Math.ceil(length / limit)
        return <>
            <textarea
                className={"form-content border"}
                onChange={this.change}
            ></textarea>
            <span className={"mt-2 d-block w-100 text-center"} style={{fontSize: 16}}><FormattedMessage id={"components.sms_text_area_total_characters"} /> <Badge color={"danger"}>{length}</Badge> - <FormattedMessage id={"components.sms_text_area_message_number"} /> <Badge color={"primary"}>{smsCount}</Badge></span>
        </>;
    }
}

export default SmsTextarea
import React from "react"
import {withSnackbar} from "react-simple-snackbar";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown} from "reactstrap";
import ConfirmationModalAlert from "./ModalAlerts/ConfirmationModalAlert";
import SendSmsEmailModel from "../Models/SendSmsEmailModel";
import Editor from "ckeditor-custom/build/ckeditor";
import {CKEditor} from "@ckeditor/ckeditor5-react/dist/ckeditor";
import InputWithIcon from "../Elements/InputWithIcon";
import {ApiErrorThrow} from "../Config";
import SmsTextarea from "./SmsTextarea";
import AdminAccountModel from "../Models/AdminAccountModel";
import {react_intl} from "../index";
import {FormattedMessage} from "react-intl";

class SendNotificationGroup extends React.Component {
    smsContentRef = null
    emailSubjectRef = null

    constructor(props) {
        super(props);
        this.invoice_details_modal_toggle = this.invoice_details_modal_toggle.bind(this)
        this.smsContentRef = React.createRef()
        this.emailSubjectRef = React.createRef()
    }

    isEditorActive = (slug) => {
        let actives = ["custom_message"]
        return actives.indexOf(this.state.notification_subject) > -1
    }

    state = {
        notification_modal: false,
        notification_type: 'sms',
        notification_subject: '',
        loading: false,
        editor_content: "",
        email_subject: ""
    }

    invoice_details_modal_toggle = () => {
        this.setState({invoice_details_modal: !this.state.invoice_details_modal})
    }

    notification_modal_toggle = (notification_type = false, notification_subject = false) => {
        let state = {}
        state['notification_modal'] = !this.state.notification_modal
        if (typeof notification_type === "string") {
            state['notification_type'] = notification_type
        }
        if (typeof notification_subject === "string")
            state['notification_subject'] = notification_subject
        state['loading'] = false
        this.setState(state)
    }

    sendNotification = async () => {
        let {orderId, transactionId, clientId, openSnackbar} = this.props
        let {notification_type, notification_subject, editor_content, email_subject} = this.state
        this.setState({loading: true})
        const callback = (response) => {
            this.setState({loading: false, email_subject: "", editor_content: ""})
            if (typeof response.status === "boolean" && !response.status) {
                openSnackbar(
                    react_intl.formatMessage({
                        id: "components.send_notification_group_error_occurred"
                    }, {error: response.message || react_intl.formatMessage({id: "components.send_notification_group_unknown_error"})})
                )
                return false
            } else {
                openSnackbar(notification_type === "sms" ? react_intl.formatMessage({id: "components.send_notification_group_notification_sent_sms"}) : react_intl.formatMessage({id: "components.send_notification_group_notification_sent_email"}))
                this.notification_modal_toggle()
            }
        }

        let req = SendSmsEmailModel

        if (notification_type === "sms") {
            if (notification_subject === "custom_message") {
                if (editor_content !== "") {
                    req = req.sendSmsWithoutTemplate(clientId, editor_content)
                } else {
                    return callback({
                        status: false,
                        message: react_intl.formatMessage({id: "components.send_notification_group_enter_text"})
                    })
                }
            } else {
                req = req.sendSms(clientId, orderId, '', notification_subject)
            }
        } else {
            if (notification_subject === "password_reset_request") {
                await AdminAccountModel.getById(clientId).then(r => r.json())
                    .then(r => {
                        let attributes = r.data.attributes
                        req = AdminAccountModel.sendResetEmail(attributes.email)
                    })
            } else if (notification_subject === "custom_message") {
                req = req.sendEmailWithoutTemplate(clientId, email_subject, editor_content)
            } else {
                req = req.sendEmail(clientId, orderId, '', notification_subject)
            }
        }
        req
            .then(r => r.json())
            .then(callback)
            .catch(r => {
                this.setState({loading: false})
                ApiErrorThrow(r, openSnackbar)
            })
    }

    render() {
        let {editor_content} = this.state
        return <>
            <ConfirmationModalAlert
                isOpen={this.state.notification_modal}
                toggle={this.notification_modal_toggle}
                icon={this.state.notification_type === "sms" ? "icon-material-outline-textsms" : "icon-material-baseline-mail-outline"}
                title={this.props.type === "sms" ? react_intl.formatMessage({id: "components.send_notification_group_send_notification_sms"}) : react_intl.formatMessage({id: "components.send_notification_group_send_notification_email"})}
                buttontext={react_intl.formatMessage({id: "components.send_notification_group_send"})}
                description={
                    react_intl.formatMessage({id: "components.send_notification_group_continue"}, {
                        subject: this.getButtonLabel(this.state.notification_subject)
                    })
                }
                buttonLoading={this.state.loading}
                buttonAction={() => {
                    this.sendNotification()
                }}
            >
                <div
                    className={"quick-mail-editor"}>
                    {
                        this.isEditorActive(this.state.notification_subject) && this.state.notification_type === "email" && <>
                            <InputWithIcon
                                placeholder={react_intl.formatMessage({id: "components.send_notification_group_subject"})}
                                ref={this.emailSubjectRef}
                                onChange={(e) => {
                                    this.setState({
                                        email_subject: e.target.value
                                    })
                                }}/>
                            <CKEditor
                                editor={Editor}
                                data={editor_content}
                                onChange={(event, editor) => {
                                    let editor_content = editor.getData();
                                    this.setState({editor_content})
                                }}
                            /></>
                    }
                    {
                        this.isEditorActive(this.state.notification_subject) && this.state.notification_type === "sms" &&
                        <SmsTextarea ref={this.smsContentRef} onChange={(e) => {
                            this.setState({editor_content: e.target.value})
                        }}/>
                    }
                </div>
            </ConfirmationModalAlert>

            <UncontrolledButtonDropdown size={
                this.props.size || "sm"
            }
            >
                <
                    DropdownToggle
                    caret
                    color={this.props.color}>
                    {
                        this
                            .props.type === "sms" ? "SMS " : react_intl.formatMessage({id: "components.send_notification_group_third_email"}) + " "
                    }
                    <FormattedMessage id={"components.send_notification_group_second_send"}/>
                </DropdownToggle>
                <DropdownMenu>
                    {
                        this.props.groups.map(item => {
                            return <DropdownItem
                                onClick={() => this.notification_modal_toggle(this.props.type, item)}
                            >{this.getButtonLabel(item)}</DropdownItem>
                        })
                    }
                </DropdownMenu>
            </UncontrolledButtonDropdown>
        </>;
    }

    getButtonLabel(type) {
        switch (type) {
            case "custom_message":
                return react_intl.formatMessage({id: "components.send_notification_group_new_message"})
                break;
            case 'new_register':
                return react_intl.formatMessage({id: "components.send_notification_group_welcome"})
                break;
            case 'remind_mail':
            case 'remind_sms':
                return react_intl.formatMessage({id: "components.send_notification_group_reminder"})
                break;
            case 'email_verification':
                return react_intl.formatMessage({id: "components.send_notification_group_email_verification"})
                break;
            case 'new_order_user':
                return react_intl.formatMessage({id: "components.send_notification_group_invoice_created"})
                break;
            case 'order_completed_user':
                return react_intl.formatMessage({id: "components.send_notification_group_paid_invoice"})
                break;
            case 'phone_verification':
                return react_intl.formatMessage({id: "components.send_notification_group_phone_verification"})
                break;
            case 'password_reset_request':
                return react_intl.formatMessage({id: "components.send_notification_group_password_request"})
                break;
        }
    }

}

export default withSnackbar(SendNotificationGroup)
import GlRequest from "../Lib/GlRequest";

class MediaModel {
    base = "/api/v2/media"

    get(details) {
        return GlRequest.get(this.base, {
            params: details
        })
    }

    getById(id) {
        return GlRequest.get(this.base + '/' + id)
    }

    delete(id) {
        if (typeof id === "object")
            id = id.join(",")
        return GlRequest.delete(this.base + '/' + id)
    }
}

export default MediaModel = new MediaModel()
import React from "react"
import {ApiErrorThrow, debounce, defaultSnackbarOptions} from "../../../Config";
import {withSnackbar} from "react-simple-snackbar";
import {Button, ButtonGroup, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import InputWithIcon from "../../../Elements/InputWithIcon";
import DataTable from "../../../Components/DataTable";
import AdminAccountModel from "../../../Models/AdminAccountModel";
import ConfirmationModalAlert from "../../../Components/ModalAlerts/ConfirmationModalAlert";
import TablePageFilterBar from "../../../Components/TablePageFilterBar";
import {GetTitle} from "../../../Routes/AdminRoute";
import CustomCheckbox from "../../../Elements/CustomCheckbox";
import PhoneNumberInput from "../../../Elements/PhoneNumberInput";
import {react_intl} from "../../../index";
import {FormattedMessage} from "react-intl";

class AdminAccounts extends React.Component {
    state = {
        nameFilter: '',
        emailFilter: '',
        accountEditModal: false,
        editingAccount: {},
        deleteModal: false,
        resetPasswordModal: false,
        newPassword: "",
        newPassword2: "",
        showPassiveAccounts: false
    }
    tableRef = React.createRef()

    accountEditModalToggle = () => {
        this.setState({accountEditModal: !this.state.accountEditModal})
        if (this.state.accountEditModal) {
            let self = this
            setTimeout(function () {
                self.setState({editingAccount: {}})
            }, 500)
        }
    }

    constructor(props) {
        super(props);
        this.accountEditModalToggle = this.accountEditModalToggle.bind(this)
        this.adminAccountHandler = this.adminAccountHandler.bind(this)
        this.deleteModalToggle = this.deleteModalToggle.bind(this)
        this.resetPasswordModalToggle = this.resetPasswordModalToggle.bind(this)
    }

    columns = [
        {
            Header: react_intl.formatMessage({id:"admin.admin_account.id"}),
            accessor: 'id',
            sortKey: 'id',
            width: "10%",
            Cell: row => {
                return <span className={"id-cell"}>{"#" + row.value}</span>
            }
        },
        {
            Header: react_intl.formatMessage({id:"admin.admin_account.full_name"}),
            accessor: null,
            width: "20%",
            Cell: data => {
                let row = data.row.original
                row = row.attributes
                return `${row.firstname} ${row.lastname}`
            }
        },
        {
            Header: react_intl.formatMessage({id:"admin.admin_account.e_mail_header"}),
            accessor: "attributes.email",
            width: "20%"
        },
        {
            Header: react_intl.formatMessage({id:"admin.admin_account.username_header"}),
            accessor: "attributes.username",
            width: "20%"
        }, {
            Header: react_intl.formatMessage({id:"admin.admin_account.transactions"}),
            accessor: null,
            width: "30%",
            Cell: (data) => {
                let row = data.row.original
                row = row.attributes
                return <ButtonGroup size={"sm"}>
                    <Button
                        onClick={() => {
                            this.setState({
                                editingAccount: {...row}
                            }, this.resetPasswordModalToggle)
                        }}
                        color={"primary"}><FormattedMessage id={"admin.admin_account.change_password_btn_txt"}/></Button>
                    <Button
                        onClick={() => {
                            this.setState({
                                editingAccount: {...row}
                            }, this.accountEditModalToggle)
                        }}
                        color={"secondary"}><FormattedMessage id={"admin.admin_account.edit"}/></Button>
                    <Button color={"danger"} onClick={() => {
                        this.setState({
                            editingAccount: row
                        }, this.deleteModalToggle)
                    }}>{row.enabled ? <>
                        <i className={"icon-line-awesome-trash-o"} /> <FormattedMessage id={"admin.admin_account.delete"}/>
                    </> : <>
                        <i className={"icon-line-awesome-undo"} /> <FormattedMessage id={"admin.admin_account.undo"}/>
                    </>}</Button>
                </ButtonGroup>
            }
        }
    ]

    adminAccountHandler = (e) => {
        let {editingAccount} = this.state
        editingAccount[e.target.name] = e.target.value
        this.setState({
            editingAccount
        })
    }

    onFilterChanged = debounce(() => {
        this.tableRef.current.loadData()
    }, 500);

    componentDidMount() {
        document.title = GetTitle(react_intl.formatMessage({id:"admin.admin_account.admin_accounts_title"}))
    }

    render() {
        let {editingAccount, showPassiveAccounts} = this.state
        let {openSnackbar} = this.props
        return <div>

            <ConfirmationModalAlert
                icon={'icon-feather-trash-2'}
                isOpen={this.state.deleteModal}
                toggle={this.deleteModalToggle}
                title={react_intl.formatMessage({id:"admin.admin_account.sure_ask"})}
                description={react_intl.formatMessage({id:"admin.admin_account.admin_deleted_msg"}, {name: editingAccount.firstname + " " + editingAccount.lastname})}
                buttonAction={() => {
                    AdminAccountModel.switchStatus(editingAccount.id)
                        .then(r => {
                            this.setState({editingAccount: {}})
                            this.deleteModalToggle()
                            this.onFilterChanged()
                        })
                        .catch(error => ApiErrorThrow(error, openSnackbar))
                }}
            />

            <TablePageFilterBar
                title={react_intl.formatMessage({id:"admin.admin_account.admin_accounts"})}
                description={react_intl.formatMessage({id:"admin.admin_account.edit_authorized_users"})}
            >
                <Col sm={3}>
                    <Label><FormattedMessage id={"admin.admin_account.admin_name"}/></Label>
                    <InputWithIcon icon={"icon-line-awesome-ellipsis-h"} onChange={(e) => {
                        this.setState({nameFilter: e.target.value}, this.onFilterChanged)
                    }}/>
                </Col>
                <Col sm={4}>
                    <Label><FormattedMessage id={"admin.admin_account.e_mail"}/></Label>
                    <InputWithIcon icon={"icon-line-awesome-envelope"} onChange={(e) => {
                        this.setState({emailFilter: e.target.value}, this.onFilterChanged)
                    }}/>
                </Col>
                <Col sm={2}>
                    <Label><FormattedMessage id={"admin.admin_account.show_deleted"}/></Label>
                    <div className={"w-100"}>
                        <CustomCheckbox onChange={(e) => {
                            this.setState({
                                showPassiveAccounts: e.target.checked
                            }, this.onFilterChanged)
                        }}/>
                    </div>
                </Col>
                <Col sm={3} className={"text-right"}>
                    <Button color={"facebook"} onClick={this.accountEditModalToggle}><FormattedMessage id={"admin.admin_account.add_admin"}/></Button>
                </Col>
            </TablePageFilterBar>

            <div className={"container"}>
                <DataTable
                    pageSize={25}
                    order={"desc"}
                    orderBy={"id"}
                    ref={this.tableRef}
                    columns={this.columns} dataQuery={{
                    url: "/api/v2/user",
                    filters: {
                        role: "ROLE_ADMIN",
                        namesurname: this.state.nameFilter,
                        email: this.state.emailFilter,
                        include: (showPassiveAccounts ? "suspended" : "")
                    }
                }}/>
            </div>

            {/*TODO:Custom Modaller ile değişecek*/}
            <Modal isOpen={this.state.resetPasswordModal} toggle={this.resetPasswordModalToggle} centered={true}>
                <ModalHeader toggle={this.resetPasswordModalToggle}>
                    <FormattedMessage id={"admin.admin_account.change_password"}/>
                </ModalHeader>
                <ModalBody>
                    <div className={"d-block"}>
                        <Label>
                            <FormattedMessage id={"admin.admin_account.new_password"}/>
                        </Label>
                        <InputWithIcon
                            icon={"icon-feather-lock"}
                            type={"password"}
                            onChange={(e) => this.setState({newPassword: e.target.value})}
                            value={this.state.newPassword}
                            name={"password"}
                        />
                    </div>
                    <div className={"d-block"}>
                        <Label>
                            <FormattedMessage id={"admin.admin_account.new_password_again"}/>
                        </Label>
                        <InputWithIcon
                            icon={"icon-feather-lock"}
                            type={"password"}
                            onChange={(e) => this.setState({newPassword2: e.target.value})}
                            value={this.state.newPassword2}
                            name={"password"}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={() => {
                            AdminAccountModel.password_reset(this.state.editingAccount.id, {
                                password: this.state.newPassword,
                                password2: this.state.newPassword2
                            })
                                .then(r => r.json())
                                .then(r => {
                                    this.setState({newPassword: '', newPassword2: ''})
                                    openSnackbar(<FormattedMessage id={"admin.admin_account.admin_password_changed"}/>)
                                    this.resetPasswordModalToggle()
                                })
                                .catch(r => ApiErrorThrow(r, openSnackbar))
                        }}
                        color={"primary"}><FormattedMessage id={"admin.admin_account.save_btn_txt"}/></Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={this.state.accountEditModal} toggle={this.accountEditModalToggle}
                   centered={true}
            >
                <ModalHeader toggle={this.accountEditModalToggle}>
                    {this.state.editingAccount.id ? react_intl.formatMessage({id:"admin.admin_account.edit_admin_account"}) : react_intl.formatMessage({id:"admin.admin_account.add_admin_account"})}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={6}>
                            <InputWithIcon name={"firstname"}
                                           autocomplete={"given-name"}
                                           value={this.state.editingAccount.firstname}
                                           placeholder={react_intl.formatMessage({id:"admin.admin_account.name"})}
                                           onChange={this.adminAccountHandler}
                            />
                        </Col>
                        <Col sm={6}>
                            <InputWithIcon name={"lastname"}
                                           autocomplete={"family-name"}
                                           value={this.state.editingAccount.lastname}
                                           placeholder={react_intl.formatMessage({id:"admin.admin_account.surname"})}
                                           onChange={this.adminAccountHandler}
                            />
                        </Col>
                        <Col sm={12}>
                            {!this.state.editingAccount.id && <InputWithIcon
                                icon={"icon-feather-lock"}
                                autocomplete={"new-password"}
                                name={"password"}
                                onChange={this.adminAccountHandler}
                                type={"password"}
                                placeholder={react_intl.formatMessage({id:"admin.admin_account.password"})}
                            />}
                        </Col>
                        <Col sm={12}>
                            <InputWithIcon
                                icon={"icon-material-outline-code"}
                                name={"username"}
                                autocomplete={"username"}
                                value={this.state.editingAccount.username}
                                placeholder={react_intl.formatMessage({id:"admin.admin_account.username"})}
                                onChange={this.adminAccountHandler}
                            />
                        </Col>
                        <Col sm={12}>
                            <InputWithIcon
                                icon={"icon-material-outline-email"}
                                name={"email"}
                                autocomplete={"email"}
                                value={this.state.editingAccount.email}
                                placeholder={react_intl.formatMessage({id:"admin.admin_account.e_mail_placeholder"})}
                                onChange={this.adminAccountHandler}
                            />
                        </Col>
                        <Col sm={12} md={6}>
                            <Label><FormattedMessage id={"admin.admin_account.phone_num"}/></Label>
                            <PhoneNumberInput
                                afterLoad={(e) => {
                                    let country;
                                    if (typeof e === "object" && typeof e.getCountryData === "function")
                                        country = e.getCountryData()
                                    this.adminAccountHandler({
                                        target: {
                                            name: "mobile_phone_country_code",
                                            value: (country.countryCode || "TR").toUpperCase()
                                        }
                                    });
                                }}
                                onChange={(val) => {
                                    this.adminAccountHandler({
                                        target: {
                                            name: "mobile_phone",
                                            value: val.number
                                        }
                                    }, function () {
                                        this.adminAccountHandler({
                                            target: {
                                                name: "mobile_phone_country_code",
                                                value: val.country
                                            }
                                        })
                                    })

                                }}
                                value={this.state.editingAccount.mobile_phone}
                                name={"mobile_phone"}
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color={"primary"} onClick={() => this.saveAccount()}>
                        {this.state.editingAccount.id ? react_intl.formatMessage({id:"admin.admin_account.save"}) : react_intl.formatMessage({id:"admin.admin_account.add"})}
                    </Button>
                </ModalFooter>
            </Modal>

        </div>;
    }

    saveAccount() {
        let {openSnackbar} = this.props
        let save_data = {...this.state.editingAccount}
        let id = this.state.editingAccount.id
        delete save_data.id

        if (id) {
            AdminAccountModel.modify(id, save_data).then(r => r.json())
                .then(r => {
                    openSnackbar(<FormattedMessage id={"admin.admin_account.edited_account_details_msg"}/>)
                    this.accountEditModalToggle()
                    this.setState({editingAccount: {}})
                    this.tableRef.current.loadData()
                }).catch(response => ApiErrorThrow(response, openSnackbar))
        } else {
            if (typeof save_data.language === "undefined")
                save_data.language = 'tr'
            save_data.role = "ROLE_ADMIN"
            AdminAccountModel.create(save_data)
                .then(r => r.json())
                .then(r => {
                    openSnackbar(<FormattedMessage id={"admin.admin_account.added_new_account_msg"}/>)
                    this.accountEditModalToggle()
                    this.setState({editingAccount: {}})
                    this.tableRef.current.loadData()
                })
                .catch(error => {
                    ApiErrorThrow(error, openSnackbar)
                })

        }
    }

    deleteModalToggle = () => {
        this.setState({deleteModal: !this.state.deleteModal})
    }
    resetPasswordModalToggle = () => {
        this.setState({resetPasswordModal: !this.state.resetPasswordModal})
        if (this.state.resetPasswordModal)
            this.setState({
                newPassword: '',
                newPassword2: ''
            })
    }
}

export default withSnackbar(AdminAccounts, defaultSnackbarOptions)
import React from "react"
import OrderModel from "../Models/OrderModel";
import ConfirmationModalAlert from "./ModalAlerts/ConfirmationModalAlert";
import {Button, ButtonGroup, UncontrolledTooltip} from "reactstrap";
import {OrderStatuses} from "../Elements/OrderStatus";
import {
    FaCheck,
    FiCheck,
    GoCheck, GoX,
    GrDocument,
    HiDocumentText,
    RiArrowGoBackFill,
    RiCloseFill,
    TiCancel
} from "react-icons/all";
import {react_intl} from "../index";
import {FormattedMessage} from "react-intl";
class StatusChangeModel extends React.Component{
    constructor(props) {
        super(props);
        this.status_modal_toggle = this.status_modal_toggle.bind(this)
    }
    state = {
        status_modal: false
    }
    status_modal_toggle = () => {
        this.setState({
            status_modal: !this.state.status_modal
        })
    }

    start_status_change(slug) {
        this.setState({
            new_status: slug,
            status_modal: true
        })
    }

    render() {
        let orderId = this.props.orderId
        let {new_status} = this.state
        let selectedStatus = OrderStatuses().find(item => item.slug === new_status)
        return <div>
            <ConfirmationModalAlert
                isOpen={this.state.status_modal}
                icon={"icon-line-awesome-question"}
                title={react_intl.formatMessage({id:"components.status_change_model_are_you_sure"})}
                toggle={this.status_modal_toggle}
                description={react_intl.formatMessage({id:"components.status_change_model_invoice_status"}, {
                    status: selectedStatus ? selectedStatus.label : ""
                })}
                buttonAction={() => {
                    OrderModel.modify(orderId, {
                        status: new_status
                    }).then( () => {
                        this.status_modal_toggle()
                        this.props.reload();
                    } )
                }}
            />
            <ButtonGroup className={"float-right bg-white order-mark-group"}>
                <UncontrolledTooltip target={`mark-paid-${orderId}`}><FormattedMessage id={"components.status_change_model_select_as_paid"} /></UncontrolledTooltip>
                <Button
                    className={"border-left"}
                    onClick={() => this.start_status_change('fully_paid')}
                    size={"sm"} color={"dark"} id={`mark-paid-${orderId}`}>
                    <GoCheck />
                </Button>
                {" "}
                <UncontrolledTooltip target={`mark-unpaid-${orderId}`}><FormattedMessage id={"components.status_change_model_select_as_not_paid"} /></UncontrolledTooltip>
                <Button
                    className={"border-left"}
                    onClick={() => this.start_status_change('unpaid')}
                    size={"sm"} color={"dark"} id={`mark-unpaid-${orderId}`}>
                    <GoX />
                </Button>
                {" "}
                <UncontrolledTooltip target={`mark-draft-${orderId}`}><FormattedMessage id={"components.status_change_model_mark_as_draft"} /></UncontrolledTooltip>
                <Button
                    className={"border-left"}
                    onClick={() => this.start_status_change('draft')}
                    size={"sm"} color={"dark"} id={`mark-draft-${orderId}`}>
                    <HiDocumentText />
                </Button>
                {" "}
                <UncontrolledTooltip target={`mark-canceled-${orderId}`}><FormattedMessage id={"components.status_change_model_mark_as_cancelled"} /></UncontrolledTooltip>
                <Button
                    className={"border-left"}
                    onClick={() => this.start_status_change('canceled')}
                    size={"sm"} color={"dark"} id={`mark-canceled-${orderId}`}>
                    <TiCancel />
                </Button>
                {" "}
                <UncontrolledTooltip target={`mark-refunded-${orderId}`}><FormattedMessage id={"components.status_change_model_mark_as_refunded"} /></UncontrolledTooltip>
                <Button
                    className={"border-left"}
                    onClick={() => this.start_status_change('refunded')}
                    size={"sm"} color={"dark"} id={`mark-refunded-${orderId}`}>
                    <RiArrowGoBackFill />
                </Button>
            </ButtonGroup>
        </div>;
    }
}
export default StatusChangeModel
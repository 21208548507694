import React from "react"
import {Card, CardBody, Modal, ModalBody, ModalHeader, Table, UncontrolledTooltip} from "reactstrap";
import moment from "moment"
import {withSnackbar} from "react-simple-snackbar";
import NotificationModel from "../../../../Models/NotificationModel";
import {SingleSmsLog} from "./SmsHistoryWidget";

export const SingleNotificationLog = props => {
    let {icon, attributes, show, repeat, msgprop} = props
    if(typeof msgprop === "undefined")
        msgprop = "message"
    let msg = attributes[msgprop]
    let l = 70
    if (msg.length > l)
        msg = msg.substr(0, l - 3) + '...'

    return <>
        <Card
            onClick={() => {
                props.show(attributes)
            }}
            className={"mb-1 single-sms-history cursor-pointer"}>
            <CardBody className={"p-2"}>
                <div className={"d-flex"}>
                    <i className={icon + " text-dark rounded text-center p-2"}
                       style={{fontSize: 27}}/>
                    <div className={"w-100 pl-1 ml-1 border-left"}>
                        <span className={"text-muted d-inline-block w-100"} style={{height: 40}}>{msg}</span>
                        <p className={"mb-0"} style={{opacity: 0.5, fontSize: 11}}>
                            <span className={"pr-1"}>
                                <i className={"icon-feather-calendar"}/> {moment(attributes.sent_date).format("DD/MM/YYYY HH:mm")}
                            </span>
                            <span className={"pr-1"}>
                                {attributes.is_read? <><i className={"icon-feather-circle"}/> Okundu</> : <><i className={"icon-feather-x-circle"} /> Okunmadı</>}
                            </span>
                            {attributes.is_deleted && <span className={"text-danger"}><i className={"icon-feather-trash"}/> Silindi</span>}
                        </p>
                    </div>
                    <small className={"pt-3 text-info text-center"}>
                        {typeof show === "function" && <>
                            <UncontrolledTooltip target={`message-${props.id}-show`}>Detaylar</UncontrolledTooltip>
                            <a onClick={() => {
                                props.show(attributes)
                            }} id={`message-${props.id}-show`}
                               className={"rounded mr-2 bg-dark text-white p-1"}><i
                                className={"icon-feather-zoom-in"}/></a>
                        </>}
                        {typeof repeat === "function" && <>
                            <UncontrolledTooltip target={`message-${props.id}-reply`}>Tekrar
                                Gönder</UncontrolledTooltip>
                            <a onClick={() => props.repeat(attributes)} id={`message-${props.id}-reply`}
                               className={"rounded mr-2 bg-info text-white p-1"}><i className={" icon-feather-repeat"}/></a>
                        </>}
                    </small>
                </div>
            </CardBody>
        </Card>
    </>
}

const NotificationNotFound = () => {
    return <div className={"text-center"}>
        <i style={{fontSize: 21}} className={"icon-material-outline-notifications-off"}></i>
        <span className={"d-block"} style={{fontSize: 18}}>Bildirim Bulunmuyor</span>
    </div>
}

class NotificationHistoryWidget extends React.Component {
    constructor(props) {
        super(props);
        this.showMessage = this.showMessage.bind(this)
    }

    state = {
        notification_history: [],
        active_history: {},
        show_modal: false
    }


    loadData = () => {
        let user_id = this.props.user_id
        NotificationModel.loadUser(user_id)
            .then(r => r.json())
            .then(r => {
                this.setState({
                    notification_history: r.data
                })
            })
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let {notification_history, active_history, show_modal} = this.state
        if (typeof active_history === "undefined")
            active_history = {}
        return <>
            <Modal isOpen={show_modal} toggle={() => this.showMessage()}>
                <ModalHeader toggle={() => this.showMessage()}>Bildirim Görüntüleme</ModalHeader>
                <ModalBody>
                    <Table>
                        <tbody>
                        <tr>
                            <th>Gönderilme Tarihi:</th>
                            <td>{typeof active_history.created === "string" && moment(active_history.created).format("DD/MM/YYYY HH:mm")}</td>
                        </tr>
                        <tr>
                            <th>Bildirim Başlığı:</th>
                            <td>{active_history.title}</td>
                        </tr>
                        <tr>
                            <th>Bildirim İçeriği:</th>
                            <td>{active_history.details}</td>
                        </tr>
                        </tbody>
                    </Table>
                </ModalBody>
            </Modal>

            <div style={{backgroundColor: "#e4f2f0"}} className={"pt-2"}>
                <div className={"pl-2 pr-2 pb-2 w-100"}>
                    {notification_history.length === 0 ? <NotificationNotFound/> : notification_history.map(item =>
                        <SingleNotificationLog
                            icon={"icon-material-outline-notifications"}
                            show={this.showMessage}
                            msgprop={"title"}
                            {...item}
                        />)}
                </div>
            </div>
        </>;
    }

    showMessage(attributes) {
        this.setState({
            active_history: attributes,
            show_modal: typeof attributes === "object"
        })
    }
}

export default withSnackbar(NotificationHistoryWidget)
import React from "react"
import {GetTitle} from "../../Routes/AdminRoute";
import DataTable from "../../Components/DataTable";
import {Alert, Col, Label, Row, UncontrolledTooltip} from "reactstrap";
import moment from "moment";
import {Link} from "react-router-dom";
import TablePageFilterBar from "../../Components/TablePageFilterBar";
import InputWithIcon from "../../Elements/InputWithIcon";
import DateRangePickerWrapper from "../../Components/react-dates/DateRangePickerWrapper";
import {debounce, get_profile_info, transactionTypes} from "../../Config";
import UserLink from "../../Elements/UserLink";
import OrderLink from "../../Elements/OrderLink";
import ShowPrice from "../../Elements/ShowPrice";
import TransactionPdfButton from "../../Components/TransactionPdfButton";
import {filter2String} from "../Admin/Orders/Orders";
import {AiFillFileExcel} from "react-icons/all";
import ExcelExportButton from "../../Components/ExcelExportButton";
import {react_intl} from "../../index";
import {FormattedMessage} from "react-intl";


class Transactions extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef()
    }

    state = {
        orderNumFilter: ""
    }

    columns = [
        {
            Header: react_intl.formatMessage({id:"client.transactions.transaction_number"}),
            accessor: "attributes.transaction_id",
            width: "15%",
            Cell: row => {
                return row.value
            }
        },
        {
            Header: react_intl.formatMessage({id:"client.transactions.amount"}),
            accessor: null,
            sortKey: 'total_amount',
            width: "10%",
            Cell: data => {
                let row = data.row.original
                let currency = row.currency
                if(typeof currency === "undefined") return "-"
                row = row.attributes
                return <ShowPrice
                    amount={row.total_amount}
                    currencyprefix={currency.prefix}
                    currencysuffix={currency.suffix}
                />
            }
        },
        {
            Header: react_intl.formatMessage({id:"client.transactions.invoice"}),
            accessor: null,
            width: "15%",
            Cell: data => {
                let row = data.row.original
                row = row.attributes
                let order = row.order_id
                return <OrderLink order={order}/>
            }
        },
        {
            Header: react_intl.formatMessage({id:"client.transactions.date"}),
            accessor: 'attributes.date',
            width: "15%",
            sortKey: 'date',
            Cell: row => {
                return moment(row.value).format("DD-MM-YYYY HH:mm")
            }
        },
        {
            Header: react_intl.formatMessage({id:"client.transactions.payment_method"}),
            width: "25%",
            Cell: data => {
                let row = data.row.original
                row = row.attributes
                return row.payment_method.name
            }
        },
        {
            Header: react_intl.formatMessage({id:"client.transactions.operations"}),
            width: "15%",
            Cell: (data) => {
                let row = data.row.original
                row = row.attributes
                return <div className={"d-flex"}>
                    <TransactionPdfButton
                        color={"dark"} id={row.id} text={react_intl.formatMessage({id:"client.transactions.receipt"})}
                    />
                </div>
            }
        }
    ]

    getFilters = () => {
        return {
            concat: this.state.orderNumFilter,
            date_start: this.state.dateFilterStart,
            date_end: this.state.dateFilterEnd,
            status: this.state.statusFilter,
            user_id: get_profile_info('id')
        }
    }

    componentDidMount() {
        document.title = GetTitle(react_intl.formatMessage({id:"client.transactions.old_payment_title"}))
    }

    onFilterChanged = debounce(() => {
        this.tableRef.current.loadData()
    }, 500);

    render() {
        return <>
            <div className={"w-100 pl-3 pr-3"}>
                <Alert color={"light"} className={"border"}>
                    <Row>
                        <Col sm={12} md={3}>
                            <h3><FormattedMessage id={"client.transactions.old_payment"}/></h3>
                            <p className={"font-italic text-muted"}><FormattedMessage id={"client.transactions.see_old_payment_msg"}/></p>
                        </Col>
                        <Col sm={12} md={3}>
                            <Label><FormattedMessage id={"client.transactions.transaction_no"}/></Label>
                            <InputWithIcon
                                onChange={(e) => {
                                    this.setState({orderNumFilter: e.target.value}, this.onFilterChanged)
                                }}
                            />
                        </Col>
                        <Col sm={12} md={4}>
                            <Label><i className={"icon-feather-calendar"}/> <FormattedMessage id={"client.transactions.date_range"}/></Label>
                            <DateRangePickerWrapper
                                showClearDates={true}
                                onChange={(start, end) => {
                                    this.setState({
                                        dateFilterStart: start ? start.toDate() : null,
                                        dateFilterEnd: end ? end.toDate() : null
                                    }, this.onFilterChanged)
                                }}
                            />
                        </Col>
                        <Col sm={12} md={2} className={"text-right"}>
                            <p>&nbsp;</p>
                            <ExcelExportButton
                                color={"dark"}
                                base={"/api/v2/transaction-excel"}
                                query={filter2String(this.getFilters())}
                                buttontext={<><AiFillFileExcel/> Excel</>}
                            />
                        </Col>
                    </Row>
                </Alert>

                <div className={"container"}>
                    <DataTable
                        isClient={true}
                        pageSize={25}
                        order={"desc"}
                        orderBy={"id"}
                        ref={this.tableRef}
                        columns={this.columns} dataQuery={{
                        url: "/api/v2/transaction",
                        filters: this.getFilters(),
                        include: ["currency"]
                    }}/>
                </div>
            </div>
        </>
    }
}

export default Transactions

import {Button, Modal, ModalBody} from "reactstrap";
import React from "react"
import {BiCommentError} from "react-icons/all";
import {Alert} from "@material-ui/lab";
import {FormattedMessage} from "react-intl";

class PaymentFailedModal extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {isOpen, toggle, message} = this.props
        return <Modal size={"md"} isOpen={isOpen} centered={true}>
            <ModalBody className={"bg-white text-center"}>
                <BiCommentError style={{fontSize: 50}} className={"mb-3"}/>
                <h2 className={"mb-3"}><FormattedMessage id={"client.payment_failed_modal.pay_error_msg"}/></h2>
                <FormattedMessage id={"client.payment_failed_modal.pay_error_return_msg"}/>
                <Alert className={"mb-3"} severity={"error"}
                       icon={<i className={" icon-line-awesome-warning"}/>}>{message}</Alert>
                <Button color={"danger"} onClick={toggle}><FormattedMessage id={"client.payment_failed_modal.close"}/></Button>
            </ModalBody>
        </Modal>;
    }
}

export default PaymentFailedModal
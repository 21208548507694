import React from "react"
import {defaultSnackbarOptions} from "../../../../Config";
import {withSnackbar} from "react-simple-snackbar";
import EmailTemplateModel from "../../../../Models/EmailTemplateModel";
import {configurable_email_templates, email_templates} from "../../../../Conts";
import EmailSmsTemplates from "../EmailSmsTemplates";
import {react_intl} from "../../../../index";

class EmailTemplates extends EmailSmsTemplates {
    configurable_templates = configurable_email_templates
    config_group = "auto_mail_config"
    url_slug = "email-templates"
    template_model = EmailTemplateModel
    templates = email_templates
    template_type = "email"
    titleMultiple = react_intl.formatMessage({id:"admin.email_templates.e_mail_templates_multiple_title"})
    titleSingle = react_intl.formatMessage({id:"admin.email_templates.e_mail_templates_single_title"})
}

export default withSnackbar(EmailTemplates, defaultSnackbarOptions)
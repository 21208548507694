import {matchPath} from "react-router";
import Detail from "./Detail";
import {react_intl} from "../../../../index";

const getParams = (pathname, pathScheme) => {
    const matchProfile = matchPath(pathname, {
        path: pathScheme,
    });
    return (matchProfile && matchProfile.params) || {};
}

class EditPost extends Detail {
    title = react_intl.formatMessage({id:"admin.content.main.detail.edit_title"})
    back_url = "/admin/announcements"
    back_text = react_intl.formatMessage({id:"admin.content.main.detail.back_text"})
    pathScheme = ""
    action = "edit"
    thumbnail = true
    content_type = "announcement"
    canFeature = true

    componentDidUpdate(prevProps, prevState) {
        let prevPathname = prevProps.location.pathname
        let pathname = this.props.location.pathname
        if (prevPathname !== pathname) {
            let params = getParams(pathname, this.pathScheme)
            this.postId = params.id;
            this.loadOrderData()
        }
    }


}
export default EditPost
import GlRequest from "../Lib/GlRequest";

class ClientCustomFieldModel {
    getClientFields = (clientId) => {
        return GlRequest.get('/api/v2/client-fields/' + clientId)
    }
    bulkUpdate = (clientId, data) => {
        data = {
            data: {
                attributes: data
            }
        }
        return GlRequest.patch(`/api/v2/user-field/${clientId}`, data, 'json')
    }
}
export default ClientCustomFieldModel = new ClientCustomFieldModel()
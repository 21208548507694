import React from "react"

class BoxedWidget extends React.Component {
    render() {
        return <div className={"boxed-widget border margin-bottom-20 bg-white summary margin-top-0"}{...this.props} title={""}>
            <div className={"boxed-widget-headline"}>
                <h3>{this.props.title}</h3>
            </div>
            <div className="boxed-widget-inner">
                {this.props.content}
            </div>
        </div>;
    }
}

export default BoxedWidget
import React from "react"
import StaticPanelTopBar from "../../../Components/StaticPanelTopBar";
import SettingsSeparated from "../../../Components/SettingsSeparated";
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import {security_settings, sms_settings} from "./SystemSettings";
import AccessControl from "./AccessControl";
import {react_intl} from "../../../index";
import {FormattedMessage} from "react-intl";

class SecuritySettings extends React.Component {

    state = {
        activeTab: "1"
    }

    tabChange = (v) => {
        this.setState({
            activeTab: v
        })
    }

    render() {
        return <>
            <StaticPanelTopBar title={react_intl.formatMessage({id:"admin.security_settings.security_settings"})}
                               extra={<div className={"text-right padding-top-25 float-right"}>
                                   <Nav pills>
                                       <NavItem>
                                           <NavLink
                                               active={this.state.activeTab === "1"}
                                               onClick={() => this.tabChange("1")}
                                               href={"#"}><FormattedMessage id={"admin.security_settings.options"}/></NavLink>
                                       </NavItem>
                                       <NavItem>
                                           <NavLink
                                               active={this.state.activeTab === "2"}
                                               onClick={() => this.tabChange("2")}
                                               href={"#"}><FormattedMessage id={"admin.security_settings.access_permissions"}/></NavLink>
                                       </NavItem>
                                   </Nav>
                               </div>}
            />
            <div className={"margin-top-100 bg-white p-3"}>
                <TabContent activeTab={this.state.activeTab} className={"mt-3 bg-light p-2 rounded"}>
                    <TabPane tabId={"1"}>
                        <SettingsSeparated viewType={"inline"} settings={security_settings}
                                           group={"ip_access"}/>
                    </TabPane>
                    <TabPane tabId={"2"}>
                        <AccessControl filterView={"inline"}/>
                    </TabPane>
                </TabContent>
            </div>
        </>;
    }
}

export default SecuritySettings
import React from "react"
import InputWithIcon from "../../../Elements/InputWithIcon";
import {Col, Label, Row} from "reactstrap";
import Select from "react-select";
import {rangeOptions} from "../../../Config";
import moment from "moment";
import {Alert, AlertTitle} from "@material-ui/lab";
import {Number, Cvc, Expiration} from "react-credit-card-primitives";

import visa from '../../../assets/svg/cards/visa.svg'
import maestro from '../../../assets/svg/cards/maestro.svg'
import mastercard from '../../../assets/svg/cards/mastercard.svg'
import jcb from '../../../assets/svg/cards/jcb.svg'
import discover from '../../../assets/svg/cards/discover.svg'
import amex from '../../../assets/svg/cards/amex.svg'
import diners from '../../../assets/svg/cards/diners.svg'
import CreditCardTab from "../Payment/CreditCardTab";
import {FormattedMessage} from "react-intl";

export const getTypeIcon = (s, size) => {
    if (typeof s === "string")
        s = s.toLowerCase()

    if(typeof size !== "number")
        size = 50

    let logo = null
    switch (s) {
        case 'mastercard':
            logo = mastercard
            break;
        case 'visa':
            logo = visa
            break;
        case 'diners club':
            logo = diners
            break;
        case 'amex':
        case 'american express':
            logo = amex
            break;
        case 'discover':
            logo = discover
            break;
        case 'jcb':
            logo = jcb
            break;
        case 'maestro':
            logo = maestro
            break;
    }
    if (logo)
        return <img className={"mt-n3"} src={logo} width={size}/>
    return s;
}

const years = () => {
    let options = []
    let currentYear = new Date().getFullYear()
    for (let a = 0; a < 15; a++) {
        options.push({
            value: currentYear + a,
            label: currentYear + a
        })
    }
    return options
}


class QuickCreditCard extends React.Component {
    state = {
        restrictAmex: false,
        maskedCvc: false,
        cc_details: {}
    }
    toggleAmex = () => this.setState({restrictAmex: !this.state.restrictAmex})

    render() {
        return <div>
            <Alert severity={"success"} icon={<i className={" icon-material-outline-lock"}/>}>
                <AlertTitle><FormattedMessage id={"client.quick_credit_card.security_msg"}/></AlertTitle>
                <FormattedMessage id={"client.quick_credit_card.security_info"}/>
            </Alert>
            <p>&nbsp;</p>
        </div>
    }
}

export default QuickCreditCard
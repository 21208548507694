import React from "react"
import {Alert, Button, Modal, ModalBody, ModalHeader, Table} from "reactstrap";
import moment from "moment"
import EmailTemplateModel from "../../../../Models/EmailTemplateModel";
import ConfirmationModalAlert from "../../../../Components/ModalAlerts/ConfirmationModalAlert";
import {withSnackbar} from "react-simple-snackbar";
import {SingleSmsLog} from "./SmsHistoryWidget";

const EmailHistoryNotFound = () => {
    return <div className={"text-center"}>
        <i style={{fontSize: 21}} className={"icon-material-outline-highlight-off"}></i>
        <span className={"d-block"} style={{fontSize: 18}}>E-posta Bulunmuyor</span>
    </div>
}

class EmailHistoryWidget extends React.Component {
    constructor(props) {
        super(props);
        this.showMessage = this.showMessage.bind(this)
    }

    state = {
        sms_history: [],
        active_history: {},
        repeat_modal: false,
        show_modal: false
    }

    repeatToggle = (details) => {
        let _state = {}

        if (typeof details === "object")
            _state['active_history'] = details
        else _state['active_history'] = {}
        _state['repeat_modal'] = typeof details === "object" && Object.keys(details).length
        this.setState(_state)
    }


    loadData = () => {
        let user_id = this.props.user_id
        EmailTemplateModel.history({
            user_id
        })
            .then(r => r.json())
            .then(r => {
                this.setState({
                    sms_history: r.data
                })
            })
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let {sms_history, active_history, repeat_modal, show_modal} = this.state
        if (typeof active_history === "undefined")
            active_history = {}
        let {openSnackbar} = this.props
        return <>
            <Modal isOpen={show_modal} toggle={() => this.showMessage()} size={"lg"}>
                <ModalHeader toggle={() => this.showMessage()}>Mesaj Görüntüleme</ModalHeader>
                <ModalBody>
                    <Table>
                        <tbody>
                        <tr>
                            <th>Alıcı:</th>
                            <td>{active_history.mail_to}</td>
                        </tr>
                        <tr>
                            <th>Gönderilme Tarihi:</th>
                            <td>{typeof active_history.sent_date === "string" && moment(active_history.sent_date).format("DD/MM/YYYY HH:mm")}</td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <strong>Mesaj İçeriği:</strong>
                                <div
                                    style={{height: 300, overflowY: "scroll"}}
                                    className="content p-2 border w-100 message-content"
                                    dangerouslySetInnerHTML={{__html: typeof active_history.message === "string" ? active_history.message : ""}}></div>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                    <div className={"text-center"}>
                        <Button size={"sm"} onClick={() => {
                            this.repeatToggle(active_history)
                            this.setState({show_modal: false})
                        }}>Tekrar Gönder</Button>
                    </div>
                </ModalBody>
            </Modal>

            <ConfirmationModalAlert
                isOpen={repeat_modal}
                toggle={() => this.repeatToggle()}
                title={`E-postayı Tekrar Gönder`}
                color={"warning"}
                buttonAction={() => {
                    EmailTemplateModel.repeat(this.state.active_history.id)
                        .then(r => r.json())
                        .then(r => {
                            openSnackbar("E-posta gönderildi!")
                            this.repeatToggle()
                            this.loadData()
                        })
                }}
            >
                {`E-posta tekrar gönderilecektir. Onaylıyor musunuz?`}
                <Alert color={"primary"}>
                    <i className={"icon-feather-info"}/> <strong>BAŞLIK:</strong>{" "}
                    {active_history.subject}
                </Alert>
            </ConfirmationModalAlert>

            <div style={{backgroundColor: "#e4f2f0"}} className={"pt-2"}>
                <div className={"pl-2 pr-2 pb-2 w-100"}>
                    {sms_history.length === 0 ? <EmailHistoryNotFound/> : sms_history.map(item => <SingleSmsLog
                        show={this.showMessage}
                        repeat={this.repeatToggle}
                        msgprop={"subject"}
                        icon={"icon-line-awesome-envelope"}
                        {...item}
                    />)}
                </div>
            </div>
        </>;
    }

    showMessage(attributes) {
        this.setState({
            active_history: attributes,
            show_modal: typeof attributes === "object"
        })
    }
}

export default withSnackbar(EmailHistoryWidget)
import React from 'react';
import {Alert} from "@material-ui/lab";
import {Button} from "@material-ui/core";
import {FormattedMessage} from "react-intl";

class InvoiceInformation extends React.Component {
    render() {
        let {addresses} = this.props

        return <div className={"p-3"}>
            {addresses.length === 0 && <Alert severity={"warning"}><FormattedMessage id={"client.invoice_information.undefined_invoice_info"}/></Alert>}
            {addresses.length > 0 && <div>
                <Alert severity={"info"}><FormattedMessage id={"client.invoice_information.defined_invoice_info"} values={{addresses_length:addresses.length}}/></Alert>
            </div>}
        </div>
    }

}

export default InvoiceInformation
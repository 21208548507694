import Dropzone from "react-dropzone-uploader";
import React from "react";
import {GlobalAuthorizationObject} from "../../../Lib/Authorization";
import {backendBaseUrl} from "../../../Config";
import 'react-dropzone-uploader/dist/styles.css'
import {react_intl} from "../../../index";

class UploadZone extends React.Component{
    constructor(props) {
        super(props);

    }

    handleChangeStatus = ({meta, file, xhr}, status) => {
        const {openSnackbar} = this.props
        if (status === "rejected_max_files") {
            openSnackbar(react_intl.formatMessage({id:"admin.multimedia.max_file_count"}))
        }
        if (status === "done") {
            let response = JSON.parse(xhr.response)
            if(typeof this.props.onUpload === "function")
            {
                this.props.onUpload(response)
            }
        }
    }

    getUploadParams = async ({meta, file}) => {
        let token;
        await GlobalAuthorizationObject.getAccessToken().then(txt => {
            token = txt
        })
        let form_data = new FormData()
        let title = "Untitled Media"
        form_data.append("upload", file)
        form_data.append("title", title)
        return {
            url: backendBaseUrl + '/api/v2/media',
            headers: {
                // "Content-Type": "multipart/form-data",
                Authorization: token
            },
            body: form_data
        }
    }

    handleSubmit = (files, allFiles) => {
    }

    render() {
        return <div>
            <Dropzone
                inputWithFilesContent={react_intl.formatMessage({id:"admin.multimedia.please_delete_first"})}
                inputContent={react_intl.formatMessage({id:"admin.multimedia.drop_here"})}
                input={false}
                maxFiles={1}
                multiple={false}
                canCancel={false}
                // submitButton={""}
                getUploadParams={this.getUploadParams}
                onChangeStatus={this.handleChangeStatus}
                onSubmit={this.handleSubmit}
                accept="image/*"
            />
        </div>;
    }
}
export default UploadZone
import React from "react"
import {ApiErrorThrow, CurrencyConvertTypes, defaultSnackbarOptions, languages} from "../../../Config";
import {withSnackbar} from "react-simple-snackbar";
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    Col, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink,
    Row,
    Spinner,
    TabContent,
    TabPane
} from "reactstrap";
import SystemSettingGroup from "./SystemSettings/SystemSettingGroup";
import SettingModel from "../../../Models/SettingModel";
import {GetTitle} from "../../../Routes/AdminRoute";
import ConfirmationModalAlert from "../../../Components/ModalAlerts/ConfirmationModalAlert";
import VisitorIPAddress from "../../../Elements/VisitorIPAddress";
import GlobalShortcodesManager from "./GlobalShortcodesManager";
import classnames from "classnames";
import ReInstallButton from "../../../Components/ReInstallButton";
import StaticPanelTopBar from "../../../Components/StaticPanelTopBar";
import {react_intl} from "../../../index";
import {FormattedMessage} from "react-intl";
import {F} from "react-select/dist/index-4bd03571.esm";

export const general_settings = () => {
    return {
        label: react_intl.formatMessage({id: "admin.system_settings.general"}),
        icon: <i className={"icon-line-awesome-cog"}/>,
        slug: "system_general",
        type: "group",
        settings: [
            {
                type: "subgroup",
                label: react_intl.formatMessage({id: "admin.system_settings.general_settings"}),
                slug: "system_general",
                description: react_intl.formatMessage({id: "admin.system_settings.general_settings_desc"}),
                settings: [
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.site_title"}),
                        type: "text",
                        slug: "site_name"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.system_url"}),
                        type: "text",
                        slug: "system_url"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.logo_url"}),
                        type: "text",
                        slug: "logo_url"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.default_language"}),
                        type: "select",
                        slug: "default_language",
                        options: [
                            {
                                label: react_intl.formatMessage({id: "languages.tr"}),
                                value: "tr"
                            },
                            {
                                label: react_intl.formatMessage({id: "languages.en"}),
                                value: "en"
                            }
                        ]
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.close_membership"}),
                        type: "checkbox",
                        slug: "deny_registers",
                        description: react_intl.formatMessage({id: "admin.system_settings.select_for_block"})
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.refund_policy_content"}),
                        slug: "terms_content",
                        type: "page_select"
                    }
                ]
            },
            {
                type: "subgroup",
                label: react_intl.formatMessage({id: "admin.system_settings.open_payment_settings"}),
                slug: "anonymous_payment",
                description: react_intl.formatMessage({id: "admin.system_settings.open_payment_desc"}),
                settings: [
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.enable_open_payment"}),
                        type: "checkbox",
                        slug: "allow_payment"
                    }
                ]
            },
            {
                type: "subgroup",
                label: react_intl.formatMessage({id: "admin.system_settings.system_preferences"}),
                slug: "system_preferences",
                description: react_intl.formatMessage({id: "admin.system_settings.system_optional_msg"}),
                settings: [
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.activate_debug_mode"}),
                        type: "checkbox",
                        slug: "debug_mode",
                        description: react_intl.formatMessage({id: "admin.system_settings.transactions_log"})
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.password_reset_time"}),
                        type: "number",
                        slug: "password_reset_config",
                        description: react_intl.formatMessage({id: "admin.system_settings.password_reset_second"})
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.system_admin_e_mail"}),
                        type: "text",
                        slug: "system_admin",
                        description: react_intl.formatMessage({id: "admin.system_settings.e_mail_for_system_notifications"})
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.notify_scheduled_missions"}),
                        type: "checkbox",
                        slug: "send_cron_details_to_admin",
                        description: react_intl.formatMessage({id: "admin.system_settings.select_for_block2"})
                    }
                ]
            },
            {
                type: "subgroup",
                label: react_intl.formatMessage({id: "admin.system_settings.corporate_info"}),
                slug: "owner_business_details",
                description: react_intl.formatMessage({id: "admin.system_settings.corporate_info_for_site"}),
                settings: [
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.company_title"}),
                        type: "text",
                        slug: "company_name"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.address"}),
                        type: "text",
                        slug: "address"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.tax_office"}),
                        type: "text",
                        slug: "tax_office"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.tax_number"}),
                        type: "text",
                        slug: "tax_number"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.tax_e_mail"}),
                        type: "text",
                        slug: "email"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.tax_phone_num"}),
                        type: "text",
                        slug: "phone"
                    }
                ]
            },
            {
                type: "subgroup",
                label: react_intl.formatMessage({id: "admin.system_settings.pdf_config"}),
                slug: "pdf_config",
                description: react_intl.formatMessage({id: "admin.system_settings.corporate_info_for_site"}),
                settings: [
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.company_title"}),
                        type: "textarea",
                        slug: "transaction_footer_text_tr"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.address"}),
                        type: "textarea",
                        slug: "transaction_footer_text_en"
                    }
                ]
            }, {
                type: "subgroup",
                label: react_intl.formatMessage({id: "admin.system_settings.social_media_accounts"}),
                slug: "owner_social_media",
                description: react_intl.formatMessage({id: "admin.system_settings.social_media_accounts_for_site"}),
                settings: [
                    {
                        label: "Facebook",
                        slug: "facebook",
                        type: "text"
                    },
                    {
                        label: "Twitter",
                        slug: "twitter",
                        type: "text"
                    },
                    {
                        label: "Linkedin",
                        slug: "linkedin",
                        type: "text"
                    },
                    {
                        label: "YouTube",
                        slug: "youtube",
                        type: "text"
                    }
                ]
            }
        ]
    }
}
export const order_settings = () => {
    return {
        label: react_intl.formatMessage({id: "admin.system_settings.order"}),
        slug: "order",
        icon: <i className={"icon-feather-shopping-cart"}/>,
        settings: [
            {
                type: "subgroup",
                label: react_intl.formatMessage({id: "admin.system_settings.basic_order_definitions"}),
                description: react_intl.formatMessage({id: "admin.system_settings.e_mail_for_system_notifications"}),
                slug: "order_globals",
                settings: [
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.order_no_prefix"}),
                        slug: "order_number_prefix",
                        type: "text"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.default_expiry_date"}),
                        slug: "due_date",
                        type: "number"
                    }
                ]
            },
            {
                type: "subgroup",
                label: react_intl.formatMessage({id: "admin.system_settings.pdf_settings"}),
                description: react_intl.formatMessage({id: "admin.system_settings.general_settings_for_order2"}),
                slug: "pdf_config",
                settings: [
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.receipt_sub_text_tr"}),
                        slug: "transaction_footer_text_tr",
                        type: "textarea"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.receipt_sub_text_en"}),
                        slug: "transaction_footer_text_en",
                        type: "textarea"
                    }
                ]
            },
            {
                type: "subgroup",
                label: react_intl.formatMessage({id: "admin.system_settings.currency_settings"}),
                description: react_intl.formatMessage({id: "admin.system_settings.general_settings_for_order3"}),
                slug: "currency_config",
                settings: [
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.automatic_exchange_rate_update"}),
                        slug: "update_automatically",
                        type: "checkbox",
                        description: react_intl.formatMessage({id: "admin.system_settings.automatic_exchange_rate_info"})
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.currency_type_to_use"}),
                        slug: "rate_type",
                        type: "select",
                        description: react_intl.formatMessage({id: "admin.system_settings.exchange_rate_info_type"}),
                        options: CurrencyConvertTypes()
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.updating_period"}),
                        slug: "update_period",
                        type: "number",
                        description: react_intl.formatMessage({id: "admin.system_settings.exchange_rate_info_period_min"})
                    }
                ]
            },
            {
                type: "subgroup",
                label: react_intl.formatMessage({id: "admin.system_settings.billing_reminder_e_mails"}),
                description: react_intl.formatMessage({id: "admin.system_settings.e_mail_reminder_before_expiry_date"}),
                slug: "payment_remind_mail",
                settings: [
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.one_day_left"}),
                        slug: "1day",
                        type: "checkbox"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.three_day_left"}),
                        slug: "3day",
                        type: "checkbox"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.seven_day_left"}),
                        slug: "7day",
                        type: "checkbox"
                    }
                ]
            },
            {
                type: "subgroup",
                label: react_intl.formatMessage({id: "admin.system_settings.billing_reminder_sms"}),
                description: react_intl.formatMessage({id: "admin.system_settings.sms_reminder_before_expiry_date"}),
                slug: "payment_remind_sms",
                settings: [
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.one_day_left2"}),
                        slug: "1day",
                        type: "checkbox"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.three_day_left2"}),
                        slug: "3day",
                        type: "checkbox"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.seven_day_left2"}),
                        slug: "7day",
                        type: "checkbox"
                    }
                ]
            }
        ]
    }
}

export const sms_settings = () => {
    return {
        label: react_intl.formatMessage({id: "admin.system_settings.sms"}),
        slug: "sms",
        icon: <i className={"icon-line-awesome-mobile-phone"}/>,
        settings: [
            {
                type: "subgroup",
                label: react_intl.formatMessage({id: "admin.system_settings.sms_operator_settings"}),
                slug: "sms_config",
                description: react_intl.formatMessage({id: "admin.system_settings.sms_operator_settings_to_send"}),
                settings: [
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.activate_sms_sending"}),
                        slug: "enable_sms",
                        type: "checkbox"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.save_sent_sms"}),
                        slug: "sms_history",
                        type: "checkbox"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.operator"}),
                        slug: "provider",
                        type: "select",
                        description: react_intl.formatMessage({id: "admin.system_settings.sms_operator_choice_to_send"}),
                        options: [
                            {
                                label: react_intl.formatMessage({id: "admin.system_settings.net_gsm"}),
                                value: "NetGSM"
                            },
                            {
                                label: react_intl.formatMessage({id: "admin.system_settings.grisms"}),
                                value: "Gri"
                            }
                        ]
                    }, {
                        label: react_intl.formatMessage({id: "admin.system_settings.username"}),
                        description: react_intl.formatMessage({id: "admin.system_settings.operator_username"}),
                        slug: "usercode",
                        type: "text"
                    }, {
                        label: react_intl.formatMessage({id: "admin.system_settings.user_password"}),
                        description: react_intl.formatMessage({id: "admin.system_settings.operator_user_password"}),
                        slug: "userpass",
                        type: "password"
                    }, {
                        label: react_intl.formatMessage({id: "admin.system_settings.msg_title"}),
                        description: react_intl.formatMessage({id: "admin.system_settings.operator_sms_title"}),
                        slug: "header",
                        type: "text"
                    }
                ]
            },
            {
                type: "subgroup",
                label: react_intl.formatMessage({id: "admin.system_settings.verification_settings"}),
                slug: "verification_config",
                description: react_intl.formatMessage({id: "admin.system_settings.verification_settings_sms_email"}),
                settings: [
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.enforcement_sms_verification"}),
                        description: react_intl.formatMessage({id: "admin.system_settings.enforcement_sms_verification_for_all_users"}),
                        slug: "force_sms_verification",
                        type: "checkbox"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.enforcement_email_verification"}),
                        description: react_intl.formatMessage({id: "admin.system_settings.enforcement_email_verification_for_all_users"}),
                        slug: "force_email_verification",
                        type: "checkbox"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.code_length"}),
                        description: react_intl.formatMessage({id: "admin.system_settings.code_char_length_question"}),
                        slug: "code_lenght",
                        type: "number"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.code_validity_period"}),
                        description: react_intl.formatMessage({id: "admin.system_settings.code_validity_period_second"}),
                        slug: "expiry",
                        type: "number"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.block_same_number"}),
                        description: react_intl.formatMessage({id: "admin.system_settings.use_unique_number"}),
                        slug: "unique_number_requirement",
                        type: "checkbox"
                    }
                ]
            }
        ]
    }
}
export const email_settings = () => {
    return {
        label: react_intl.formatMessage({id: "admin.system_settings.e_mail"}),
        slug: "email",
        icon: <i className={"icon-line-awesome-envelope"}/>,
        settings: [
            {
                type: "subgroup",
                label: react_intl.formatMessage({id: "admin.system_settings.e_mail_settings"}),
                slug: "mailer_config",
                description: react_intl.formatMessage({id: "admin.system_settings.e_mail_cases_msg"}),
                settings: [
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.save_sent_email"}),
                        slug: "mail_history",
                        type: "checkbox"
                    },
                    {
                        label: "enable_mailing?",
                        slug: "enable_mailing",
                        type: "checkbox"
                    }
                ]
            },
            {
                type: "subgroup",
                slug: "mail_template",
                label: react_intl.formatMessage({id: "admin.system_settings.e_mail_template_settings"}),
                description: react_intl.formatMessage({id: "admin.system_settings.e_mail_template_for_all"}),
                settings: [
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.e_mail_header"}),
                        slug: "header",
                        type: "textarea",
                        description: react_intl.formatMessage({id: "admin.system_settings.set_e_mail_header"})
                    }, {
                        label: react_intl.formatMessage({id: "admin.system_settings.e_mail_footer"}),
                        slug: "footer",
                        type: "textarea",
                        description: react_intl.formatMessage({id: "admin.system_settings.set_e_mail_footer"})
                    }
                ]
            },
            {
                type: "subgroup",
                label: react_intl.formatMessage({id: "admin.system_settings.e_mail_smtp_settings"}),
                slug: "smtp_config",
                description: react_intl.formatMessage({id: "admin.system_settings.set_e_mail_smtp_settings"}),
                settings: [
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.use_smtp"}),
                        description: react_intl.formatMessage({id: "admin.system_settings.activation_wrong"}),
                        slug: "enable_smtp",
                        type: "switch"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.smtp_server"}),
                        description: react_intl.formatMessage({id: "admin.system_settings.smtp_msg"}),
                        slug: "host",
                        type: "text"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.smtp_port"}),
                        description: react_intl.formatMessage({id: "admin.system_settings.set_smtp_port"}),
                        slug: "port",
                        type: "number"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.verification_type"}),
                        description: react_intl.formatMessage({id: "admin.system_settings.smtp_server_verification_type"}),
                        slug: "auth_mode",
                        type: "select",
                        options: [
                            {
                                label: react_intl.formatMessage({id: "admin.system_settings.default"}),
                                value: ""
                            }, {
                                label: "login",
                                value: "login"
                            }, {
                                label: "null",
                                value: "null"
                            }
                        ]
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.smtp_username"}),
                        description: react_intl.formatMessage({id: "admin.system_settings.enter_smtp_username"}),
                        slug: "username",
                        type: "text"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.smtp_password"}),
                        description: react_intl.formatMessage({id: "admin.system_settings.smtp_server_user_password"}),
                        slug: "password",
                        type: "password"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.smtp_sender_name"}),
                        description: react_intl.formatMessage({id: "admin.system_settings.enter_smtp_sender_name"}),
                        slug: "from_name",
                        type: "text"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.smtp_sender_address"}),
                        description: react_intl.formatMessage({id: "admin.system_settings.enter_smtp_email"}),
                        slug: "from_email",
                        type: "text"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.smtp_encrypting_method"}),
                        description: react_intl.formatMessage({id: "admin.system_settings.select_encrypting_method_for_smtp"}),
                        slug: "encryption",
                        type: "select",
                        options: [
                            {
                                label: "TLS",
                                value: "tls"
                            },
                            {
                                label: "SSL",
                                value: "ssl"
                            }
                        ]
                    }
                ]
            }
        ]
    }
}
export const log_settings = () => {
    return {
        label: react_intl.formatMessage({id: "admin.system_settings.error_records"}),
        slug: "logs",
        icon: <i className={"icon-line-awesome-cogs"}/>,
        settings: [
            {
                type: "subgroup",
                label: react_intl.formatMessage({id: "admin.system_settings.record_settings"}),
                slug: "log_config",
                description: react_intl.formatMessage({id: "admin.system_settings.determine_areas"}),
                settings: [
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.activation_records"}),
                        slug: "activity",
                        type: "checkbox",
                        description: react_intl.formatMessage({id: "admin.system_settings.check_for_activation"})
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.payment_records"}),
                        slug: "payment",
                        type: "checkbox",
                        description: react_intl.formatMessage({id: "admin.system_settings.check_for_data_exchange_records"})
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.error_entry_records"}),
                        slug: "failed_login",
                        type: "checkbox",
                        description: react_intl.formatMessage({id: "admin.system_settings.check_for_error_entry_records"})
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.system_settings.system_error_records"}),
                        slug: "exception",
                        type: "checkbox",
                        description: react_intl.formatMessage({id: "admin.system_settings.check_for_system_error_records"})
                    }
                ]
            },
        ]
    }
}
export const security_settings = () => {
    return {
        label: react_intl.formatMessage({id: "admin.system_settings.security"}),
        slug: "security",
        icon: <i className={"icon-feather-shield"}/>,
        settings: [
            {
                type: "subgroup",
                label: react_intl.formatMessage({id: "admin.system_settings.access_settings"}),
                slug: "ip_access",
                settings: [
                    {
                        type: "checkbox",
                        slug: "only_whitelist_admin",
                        label: react_intl.formatMessage({id: "admin.system_settings.admin_panel_ip_limitation"}),
                        description: <div className={"d-inline"}><FormattedMessage
                            id={"admin.system_settings.check_for_allowed_ip"}/>.
                        </div>
                    },
                    {
                        type: "checkbox",
                        slug: "enable_blacklist",
                        label: react_intl.formatMessage({id: "admin.system_settings.black_list_protection"}),
                        description: react_intl.formatMessage({id: "admin.system_settings.block_black_list_address"})
                    }
                ]
            }
        ]
    }
}

export let settings = () => [
    general_settings(), order_settings(), sms_settings(), email_settings(), log_settings(), security_settings()
]

class SystemSettings extends React.Component {
    state = {
        settingsTab: 'system_general',
        settings: [],
        loading: true,
        selected_group: '',
        restoreModal: false,
        saving: false,
        globalShortcodesModal: false,
        globalShortcodesLoading: false
    }

    globalShortcodesModalToggle = () => {
        this.setState({
            globalShortcodesModal: !this.state.globalShortcodesModal
        })
    }
    shortcodesRef = null

    constructor(props) {
        super(props);
        this.loadSettings()
        this.settingHandler = this.settingHandler.bind(this)
        this.restoreModalToggle = this.restoreModalToggle.bind(this)
        this.globalShortcodesModalToggle = this.globalShortcodesModalToggle.bind(this)
    }

    loadSettings = async () => {
        let config_groups = []
        let group_slugs = {}

        settings().map(group => {
            group.settings.map(subgroup => {
                group_slugs[group.slug] = {}
                config_groups.push(subgroup.slug)
            })
        })

        let saved_settings = {}
        config_groups.forEach(item => {
            saved_settings[item] = {}
        })

        await SettingModel.getByGroup(config_groups.join(","))
            .then(r => r.json())
            .then(r => {
                let data = r.data
                data.forEach(single => {
                    single = single.attributes
                    saved_settings[single.title][single.name] = single.value
                })
            })

        this.setState({settings: saved_settings, loading: false})
    }

    settingHandler = (e) => {
        let {settings} = this.state
        let editedGroup = e.target.dataset.group
        let {name, value, type, checked} = e.target
        if (type === "checkbox")
            value = checked ? "yes" : "no"
        settings[editedGroup][name] = value
        this.setState({
            settings
        })
    }

    resetSettings = () => {
        let {selected_group} = this.state
        SettingModel.reset2Default(selected_group).then(r => r.json())
            .then(r => {

            })
    }

    selectGroup = (group_slug) => {
        this.setState({
            selected_group: group_slug
        }, () => {
            this.setState({
                restoreModal: true
            })
        })
    }

    componentDidMount() {
        document.title = GetTitle(react_intl.formatMessage({id: "admin.system_settings.system_settings"}))
    }

    render() {
        let {settingsTab, selected_group, globalShortcodesLoading} = this.state
        let selected_groupObj = {}

        let settingsFiltered = Object.assign([], settings()).filter(item => ["sms", "email", "security"].indexOf(item.slug) === -1).map((item) => {
            if (item.slug === "order") {
                item.settings = item.settings.filter(s => ["currency_config", "payment_remind_mail", "payment_remind_sms"].indexOf(s.slug) === -1)
            }
            return item;
        })

        if (selected_group) {
            settingsFiltered.map(tab => {
                let setting
                if (typeof tab !== "undefined" && typeof tab.settings === "object")

                    (tab.settings).map(settingItem => {
                        if (settingItem.slug === selected_group) {
                            selected_groupObj = settingItem
                        }
                    })
            })
        }

        if (typeof selected_groupObj === "undefined")
            selected_groupObj = {}

        return <div>
            <ConfirmationModalAlert
                isOpen={this.state.restoreModal}
                toggle={this.restoreModalToggle}
                icon={"icon-line-awesome-warning"}
                title={react_intl.formatMessage({id: "admin.system_settings.sure_ask"})}
                description={<>
                    <FormattedMessage id={"admin.system_settings.set_e_mail_footer"}
                                      values={{selected_group_obj: selected_groupObj.label}}/>
                </>}
                buttonAction={() => {
                    SettingModel.reset2Default(this.state.selected_group).then(r => r.json())
                        .then(r => {
                            window.location.reload()
                        });
                }}
            />
            <Modal
                className={"border"}
                onOpened={() => {
                    this.shortcodesRef.loadData()
                }}
                onClosed={() => {
                    this.setState({
                        globalShortcodesLoading: true
                    })
                }}
                centered={true} isOpen={this.state.globalShortcodesModal} size={"lg"}
                toggle={this.globalShortcodesModalToggle}>
                <ModalHeader toggle={this.globalShortcodesModalToggle}>
                    <i className={" icon-line-awesome-code-fork"}
                    /> <FormattedMessage id={"admin.system_settings.manage_shortcodes"}/></ModalHeader>
                <ModalBody>
                    <div className={classnames(
                        "text-center",
                        {
                            "d-none": !globalShortcodesLoading
                        }
                    )}>
                        <Spinner color={"primary"} size={"sm"}/>
                        <p><FormattedMessage id={"admin.system_settings.loading"}/></p>
                    </div>

                    <GlobalShortcodesManager
                        classNames={globalShortcodesLoading ? "d-none" : ""}
                        setRef={(item) => {
                            this.shortcodesRef = item;
                        }}
                        afterLoad={() => {
                            this.setState({globalShortcodesLoading: false})
                        }}
                    />

                </ModalBody>
            </Modal>

            <StaticPanelTopBar
                title={<><FormattedMessage id={"admin.system_settings.system_settings2"}/> {this.state.loading &&
                    <Spinner className={"ml-2"} color={"dark"} size={"sm"}/>}</>}
                extra={<div className={"padding-top-25 w-100 text-right"}>
                    <Nav pills className={"float-right"}>
                        {settingsFiltered.map(item => {
                            return <NavItem key={`button-${item.slug}`}><NavLink
                                href={"#"}
                                active={settingsTab === item.slug}
                                onClick={() => this.setState({settingsTab: item.slug})}
                            >{item.icon} {item.label}</NavLink></NavItem>
                        })}

                        <Button
                            className={"ml-2"}
                            onClick={this.save}
                            color={"primary"}>{this.state.saving ? <Spinner size={"sm"}/> :
                            <i className={"icon-line-awesome-save"}/>} <FormattedMessage
                            id={"admin.system_settings.save"}/></Button>
                    </Nav>
                </div>}
            />
            <Card className={"margin-top-100 border-0 shadow system-settings-page"}>
                <CardBody>
                    <TabContent activeTab={settingsTab}>
                        {settingsFiltered.map(item => <TabPane tabId={item.slug} key={`tabPane-${item.slug}`}>
                                {item.settings && item.settings.map(group => <SystemSettingGroup
                                    key={`setting-group-${group.slug}`}
                                    selectHandler={this.selectGroup}
                                    values={this.state.settings[group.slug] || {}}
                                    handler={this.settingHandler}
                                    shortcodeButtonToggle={this.globalShortcodesModalToggle}
                                    {...group}
                                />)}
                                {item.slug === "system_general" && <div className={"text-right"}>
                                    <ReInstallButton/>
                                </div>}
                            </TabPane>
                        )}
                    </TabContent>
                </CardBody>
            </Card>
        </div>;
    }

    save = () => {
        let save_groups = {}, group, settings_saved = this.state.settings, group_enabled = false
        let {openSnackbar} = this.props
        this.setState({
            saving: true
        })
        settings().map(item => {
            if (typeof item.settings === "object") {
                Object.keys(item.settings).forEach(i => {
                    group = item.settings[i]
                    if (typeof save_groups[group.slug] === "undefined")
                        save_groups[group.slug] = {};

                    group_enabled = false;

                    (group.settings).map(item => {
                        if (typeof settings_saved[group.slug][item.slug] !== "undefined") {
                            group_enabled = true
                            save_groups[group.slug][item.slug] = settings_saved[group.slug][item.slug]
                        }
                    })
                    if (!group_enabled) {
                        delete save_groups[group.slug]
                    }
                })
            }
        })

        SettingModel.bulkEdit(save_groups)
            .then(r => r.json())
            .then(r => {
                this.setState({
                    saving: false
                }, openSnackbar(<FormattedMessage id={"admin.system_settings.changes_saved"}/>))
            }).catch(r => ApiErrorThrow(r, openSnackbar))

    }

    restoreModalToggle() {
        this.setState({
            restoreModal: !this.state.restoreModal
        })
    }
}

export default withSnackbar(SystemSettings, defaultSnackbarOptions)
import React from "react"
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import {get_main_config, react_intl} from "../../index";
import ContentModel from "../../Models/ContentModel";
import {FormattedMessage} from "react-intl";

class TermsModal extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this)
    }

    state = {
        open: false,
        content: {}
    }

    toggle = () => {
        if (!this.state.open)
            this.load_data()

        this.setState({open: !this.state.open})
    }

    load_data = () => {
        this.setState({
            content: {}
        })
        let content_id = get_main_config('system_general', 'terms_content')
        ContentModel.getById(content_id).then(r => r.json())
            .then(r => {
                this.setState({
                    content: r.data.attributes
                })
            })
    }

    render() {
        let {open, content} = this.state
        if(typeof content !== "object")
            content = {};

        return <div className={"d-inline"}>

            <Modal isOpen={open} toggle={this.toggle} size={"lg"}>
                {content.name ? <ModalHeader toggle={this.toggle}>{content.name}</ModalHeader> : ""}
                <ModalBody>
                    {content.content ? <div dangerouslySetInnerHTML={{__html: content.content}}/> :
                        <div className={"text-center pt-3 pb-3"}>
                            <Spinner/>
                        </div>}
                </ModalBody>
                {content.name ? <ModalFooter>
                    {typeof this.props.onCancel === "function" ? <Button
                        onClick={() => {
                            if (typeof this.props.onCancel === "function")
                                this.props.onCancel()
                            this.toggle()
                        }}
                        color={"danger"}><FormattedMessage id={"client.terms_modal.close"}/></Button> : ""}
                    {typeof this.props.onAccept === "function" ? <Button onClick={() => {
                        if (typeof this.props.onAccept === "function")
                            this.props.onAccept()
                        this.toggle()
                    }} color={"primary"}><FormattedMessage id={"client.terms_modal.accept"}/></Button> : ""}
                </ModalFooter> : ""}
            </Modal>

            <FormattedMessage
                tagName={"span"}
                className={"d-inline"}
                id={"client.pay_page_base.cancellation_refund_policy_txt"}
                values={{
                    link: <a href={"#"} onClick={(ev) => {
                        ev.preventDefault()
                        this.toggle()
                    }}>{react_intl.formatMessage({id: "client.pay_page_base.cancellation_refund_policy_txt_url_text"})}</a>
                }}
            />
        </div>;
    }
}

export default TermsModal
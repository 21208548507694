import React from "react"
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col, Input,
    Media,
    Modal,
    ModalBody, ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import MediaModel from "../../../Models/MediaModel";
import MultiMediaItem from "./MultiMediaItem";
import ConfirmationModalAlert from "../../../Components/ModalAlerts/ConfirmationModalAlert";
import {withSnackbar} from "react-simple-snackbar";
import {ApiErrorThrow, debounce, defaultSnackbarOptions} from "../../../Config";
import {GetTitle} from "../../../Routes/AdminRoute";
import UploadZone from "./UploadZone";
import ButtonLoad from "../../../Elements/ButtonLoad";
import classnames from "classnames";
import StaticPanelTopBar from "../../../Components/StaticPanelTopBar";
import {Skeleton} from "@material-ui/lab";
import InputWithIcon from "../../../Elements/InputWithIcon";
import {VscSearchStop} from "react-icons/all";
import {react_intl} from "../../../index";
import {FormattedMessage} from "react-intl";

const MultimediaSkeleton = (props) => {
    return <div className={""}>
        <div className="d-flex">
            {[0, 1, 2, 3].map(item => <Skeleton variant={"rect"} width={"100%"} height={130}
                                                className={"rounded mr-3 mb-3"}/>)}
        </div>
        <div className="d-flex">
            {[0, 1, 2, 3, 4].map(item => <Skeleton variant={"rect"} width={"100%"} height={130}
                                                   className={"rounded mr-3 mb-3"}/>)}
        </div>
        <div className="d-flex">
            {[0, 1, 2, 3].map(item => <Skeleton variant={"rect"} width={"100%"} height={130}
                                                className={"rounded mr-3 mb-3"}/>)}
        </div>
    </div>
}

class MultiMedia extends React.Component {
    state = {
        data: [],
        activeMedia: {},
        deleteModal: false,
        uploadModal: false,
        page: 0,
        pageSize: 24,
        maxPage: 1,
        loading: true,
        q: "",
        deleteLoading: false
    }

    constructor(props) {
        super(props);
        this.uploadModalToggle = this.uploadModalToggle.bind(this)
    }

    componentDidMount() {
        this.loadMultiMedia()
        document.title = GetTitle(react_intl.formatMessage({id: "admin.multimedia.title"}))
    }

    loadMultiMedia = (clear) => {
        this.setState({
            loading: true
        })

        let params = {}
        if (this.state.q)
            params['original_name'] = this.state.q

        params['page'] = {
            size: this.state.pageSize,
            number: this.state.page
        }
        params['sort'] = '-created'
        MediaModel
            .get(params).then(r => r.json())
            .then(r => {
                let dataMerged = this.state.data
                if (clear)
                    dataMerged = []
                dataMerged = dataMerged.concat(r.data)

                this.setState({data: dataMerged, maxPage: r.meta.max_page, loading: false})
            })
    }

    ZoomPreview = (details) => {
        window.open(details.public_url, "_blank")
    }

    DeleteMedia = (details) => {
        this.setState({activeMedia: details}, this.deleteModalToggle)
    }

    deleteModalToggle = () => {
        this.setState({deleteModal: !this.state.deleteModal})
    }

    CopyUrl = (details) => {
        let tempElement = document.createElement("textarea")
        tempElement.value = details.public_url
        tempElement.style.opacity = 0
        document.body.appendChild(tempElement)
        tempElement.select()
        document.execCommand("copy")
        document.body.removeChild(tempElement)
        let {openSnackbar} = this.props
        openSnackbar(react_intl.formatMessage({id: "admin.multimedia.url_copied"}))
    }

    uploadModalToggle = () => {
        this.setState({uploadModal: !this.state.uploadModal})
    }

    searchChange = debounce(() => {
        this.loadMultiMedia(true)
    }, 500)

    render() {
        let {data, activeMedia, uploadModal} = this.state
        let {openSnackbar} = this.props
        return <div>
            <Modal isOpen={uploadModal} toggle={this.uploadModalToggle}>
                <ModalHeader toggle={this.uploadModalToggle}><FormattedMessage
                    id={"admin.multimedia.title"}/></ModalHeader>
                <ModalBody>
                    <UploadZone onUpload={(resp) => {
                        this.uploadModalToggle()
                        this.loadMultiMedia()
                    }}/>
                </ModalBody>
            </Modal>
            <ConfirmationModalAlert
                color={"error"}
                icon={'icon-feather-trash-2'}
                toggle={this.deleteModalToggle}
                isOpen={this.state.deleteModal}
                buttonLoading={this.state.deleteLoading}
                title={react_intl.formatMessage({id: "admin.multimedia.delete"})}
                buttonAction={() => {
                    this.setState({deleteLoading: true})
                    MediaModel.delete(activeMedia.id)
                        .then(r => {
                            this.setState({deleteLoading: false})
                            this.deleteModalToggle()
                            this.loadMultiMedia(true)
                            openSnackbar(react_intl.formatMessage({id: "admin.multimedia.file_deleted"}))
                        }).catch(r => {
                        this.setState({deleteLoading: false})
                        ApiErrorThrow(r, openSnackbar)
                    })
                }}
            >
                <p><Media width={125} className={"border rounded"} object src={activeMedia.public_url}></Media></p>
                <FormattedMessage id={"admin.multimedia.delete_message"} values={{name: activeMedia.id}} tagName={"p"}/>

                {/*TODO: id değil, dosya adı kullanılacak*/}
            </ConfirmationModalAlert>

            <StaticPanelTopBar title={react_intl.formatMessage({id: "admin.multimedia.top_title"})}
                               extra={<Row className={"text-right padding-top-25 w-100"}>
                                   <Col className={"text-center"} lg={8}>
                                       <InputWithIcon icon={"icon-material-outline-search"}
                                                      className={"mr-2"}
                                                      onChange={(e) => {
                                                          this.setState({
                                                              q: e.target.value,
                                                          }, this.searchChange)
                                                      }}
                                                      value={this.state.q}
                                       />
                                   </Col>
                                   <Col lg={4} className={"text-center"}>
                                       <Button color={"dark"}
                                               className={"align-self-end margin-top-3"}
                                               onClick={this.uploadModalToggle}><i
                                           className={" icon-feather-upload"}/> <FormattedMessage
                                           id={"admin.multimedia.upload_button"}/></Button>
                                   </Col>
                               </Row>}
            />

            <Card className={"margin-top-100 shadow border-0"}>
                <CardBody className={"border-0"}>
                    <Row>
                        {data ? data.map((item, i) => <Col key={`Media-${i}`} sm={6} md={4} lg={3}
                                                           className={"text-center mb-2"}>
                            <MultiMediaItem
                                {...item}
                                preview={this.ZoomPreview}
                                delete={this.DeleteMedia}
                                copy={this.CopyUrl}
                            /></Col>) : null}
                        {!this.state.loading && data.length === 0 && <div className={"text-center pt-3 w-100"}>
                            <VscSearchStop size={48}/>
                            <h4><FormattedMessage id={"admin.multimedia.not_found"}/></h4>
                            {this.state.q && <Button size={"sm"} color={"danger"} onClick={() => {
                                this.setState({
                                    q: ""
                                }, this.searchChange)
                            }
                            }><FormattedMessage id={"admin.multimedia.reset_filter"}/></Button>}
                        </div>}
                    </Row>

                    {this.state.loading ? <MultimediaSkeleton/> : null}

                    <Row className={classnames("", {
                        "d-none": !this.state.loading && this.state.maxPage <= this.state.page
                    })}>
                        <Col className={"text-center"}>
                            <ButtonLoad
                                loading={this.state.loading}
                                text={react_intl.formatMessage({id: "admin.multimedia.load_more"})}
                                onClick={() => {
                                    this.setState({page: ++this.state.page}, () => {
                                        this.loadMultiMedia()
                                    })
                                }}
                                color={"primary"}/>
                        </Col>
                    </Row>

                </CardBody>
            </Card>
        </div>;
    }
}

export default withSnackbar(MultiMedia, defaultSnackbarOptions)
import {Button, Col, Row} from "reactstrap";
import React from "react";
import classnames from "classnames";
import {BiSearchAlt} from "react-icons/all";

class TablePageFilterBar extends React.Component {
    toggle = () => {
        this.setState(
            {
                show: !this.state.show
            }
        )
    }
    state = {
        show: false
    }

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this)
    }

    render() {
        let {title, description} = this.props
        return <>
            <div className={classnames("page-filter-bar pl-3 pr-3 pt-2", {
                show: this.state.show
            })} {...this.props} title={null}>
                <Row>
                    <Col sm={12} className={"d-block d-md-none text-right"}>
                        <Button
                            onClick={this.toggle}
                            className={"btn-rounded-circle"} color={"danger"} size={"sm"}><i
                            className={"icon-line-awesome-close"}/></Button>
                    </Col>
                    <Col sm={12}>
                        <div className="title-box">
                            <Row>
                                <Col sm={12} lg={6}>
                                    <h2 className={"rounded pt-3"}>{title}</h2>
                                </Col>
                                <Col sm={12} lg={6}>
                                    {typeof description === "string" ?
                                        <small className={"w-100 text-right float-left pr-1"}>{description}</small> :
                                        <div className={"text-right padding-top-12"}>
                                            {description}
                                        </div>
                                    }
                                </Col>
                            </Row>

                        </div>
                    </Col>
                    <Col sm={12}>
                        <div className={"pt-1 pb-1 pl-0 pr-0 ml-0 search-box"}>
                            <Row>
                                {this.props.children}
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
            <Button
                onClick={this.toggle}
                color={"dark"}
                className={"rounded-circle d-block position-fixed d-md-none text-center p-0"}
                style={{bottom: 40, right: 40, width: 48, height: 48, lineHeight: "22px", fontSize: 27, zIndex: 999}}
            >
                <BiSearchAlt/>
            </Button>
        </>;
    }
}

export default TablePageFilterBar
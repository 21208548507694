import React from "react"
import {Card, CardBody, Col, Row} from "reactstrap";
import {RiNumbersLine} from "react-icons/all";
import ShowPrice from "../../Elements/ShowPrice";
import {get_default_currency} from "../../Config";
import classnames from "classnames";
import {Skeleton} from "@material-ui/lab";
import {FormattedMessage} from "react-intl";

const CountBoxLoading = (props) => {
    return <Col xl={3} lg={6} className={"cp-stat-boxes"}>
        <Card className={"card-stats mb-4 mb-xl-0"}>
            <CardBody>
                <Row>
                    <Col>
                        <Skeleton variant={"text"} height={20} width={"100%"}/>
                        <Skeleton variant={"rect"} width={"100%"} height={40}/>
                    </Col>
                    <div className="col-auto">
                        <Skeleton variant={"circle"} width={50} height={50}/>
                    </div>
                </Row>
                <p className="mt-3 mb-0 text-muted text-sm">
                    <Skeleton variant={"text"} height={20} width={"100%"}/>
                </p>
            </CardBody>
        </Card>
    </Col>
}

class CountBox extends React.Component {
    icon = null
    title = ""
    color = "info"

    render() {
        let {total, count, loading} = this.props
        if (count)
            count = parseInt(count)
        else
            count = (0).toFixed(2)

        if (total)
            total = parseFloat(total).toFixed(2)
        else
            total = (0).toFixed(2)

        if (loading)
            return <CountBoxLoading/>

        return <Col onClick={this.props.onClick} xl={3} lg={6}
                    className={classnames("cp-stat-boxes", {"cursor-pointer": typeof this.props.onClick === "function"})}>
            <Card className={"card-stats mb-4 mb-xl-0"}>
                <CardBody>
                    <Row>
                        <Col>
                            <h5 className="card-title text-uppercase text-muted mb-0">{this.title}</h5>
                            <span className="h2 font-weight-bold mb-0"><ShowPrice amount={total}
                                                                                  currencyprefix={get_default_currency("prefix")}
                                                                                  currencysuffix={get_default_currency("suffix")}/></span>
                        </Col>
                        <div className="col-auto">
                            <div className={`icon icon-shape text-white rounded-circle shadow bg-` + this.color}>
                                {this.icon}
                            </div>
                        </div>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                        <FormattedMessage id={"client_dashboard.invoices_count"} values={{count}}>
                            {(msg) => ( <span className="text-success mr-2"><RiNumbersLine/> {msg}</span> )}
                        </FormattedMessage>
                    </p>
                </CardBody>
            </Card>
        </Col>
    }
}

export default CountBox
import React from "react"
import {defaultSnackbarOptions} from "../../../../Config";
import {withSnackbar} from "react-simple-snackbar";
import SmsTemplateModel from "../../../../Models/SmsTemplateModel";
import EmailSmsTemplateSingle from "../EmailSmsTemplateSingle";
import {sms_templates} from "../../../../Conts";
import {react_intl} from "../../../../index";

class SingleSmsTemplate extends EmailSmsTemplateSingle {
    template_type = "sms"
    url_slug = "sms-templates"
    all_link = "sms-notifications"
    single_type_title = react_intl.formatMessage({id:"admin.single_sms_template.sms"})
    template_model = SmsTemplateModel
    templates = sms_templates
}

export default withSnackbar(SingleSmsTemplate, defaultSnackbarOptions)
import GlRequest from "../Lib/GlRequest";

class AdminAccountModel {
    create(details) {
        details = {
            data: {
                attributes: details
            }
        }
        return GlRequest.post('/user', details, 'json')
    }

    modify(id, data) {
        let data_modified = Object.assign({}, data)
        delete data_modified.groups
        delete data_modified.last_login
        delete data_modified.balance
        delete data_modified.enabled
        delete data_modified.roles
        delete data_modified.password
        delete data_modified.email_canonical
        delete data_modified.username_canonical

        data = {
            data: {
                attributes: data_modified
            }
        }
        return GlRequest.patch('/api/v2/user/' + id, data, 'json')
    }

    password_reset(id, data) {
        data = {
            data: {
                attributes: data
            }
        }
        return GlRequest.patch('/api/v2/user/' + id + '/reset-pw', data, 'json')
    }

    switchStatus(id) {
        return GlRequest.get('/api/v2/user/' + id + '/switch-status')
    }

    getById(id) {
        return GlRequest.get('/api/v2/user/' + id)
    }

    sendResetEmail(email) {
        let data = {
            data: {
                attributes: {
                    login: email
                }
            }
        }
        return GlRequest.post('/request-password-reset', data, 'json')
    }

    completeResetPassword(login, token, password, password2) {
        let data = {
            data: {
                attributes: {
                    login, token, password, password2
                }
            }
        }
        return GlRequest.post('/password-change', data, 'json')
    }

    switch(id)
    {
        return GlRequest.get(`/api/v2/user/${id}/switch-role`)
    }

}

export default AdminAccountModel = new AdminAccountModel()
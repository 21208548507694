import React from "react";
import classnames from "classnames";
import {Skeleton} from "@material-ui/lab";
import {Col, Row} from "reactstrap";
import ShowPrice from "./ShowPrice";

const FunFactsSkeleton = (props) => {
    return <>
        <div className="fun-fact border" data-fun-fact-color="#333333">
            <Row className={"w-100"}>
                <Col sm={9}>
                    <Skeleton variant={"text"} width={"calc(100% - 50px)"} height={15} />
                    <Skeleton variant={"text"} width={"60%"} height={35}/>
                    <Skeleton variant={"text"} width={"50%"} height={20}/>
                </Col>
                <Col sm={3}>
                    <Skeleton variant={"rect"} width={80} height={80} className={"rounded"} />
                </Col>
            </Row>
        </div>
    </>
}

class FunFacts extends React.Component {
    render() {
        let {
            title, currency_symbol, count, prev_count, prev_count_symbol,
            icon, icon_color, prev_icon, prev_icon_color, icon_bg_color, prev_text,
            loading
        } = this.props

        if (loading)
            return <FunFactsSkeleton/>

        if (!prev_text)
            prev_text = "Dün"

        return <div className="fun-fact border" data-fun-fact-color="#333333">
            <div className="fun-fact-text">
                    <span
                        onClick={() => {
                            if (typeof this.props.mainLink === "string")
                                this.props.history.push(this.props.mainLink)
                        }}
                        className={classnames("title",
                            {
                                "cursor-pointer": typeof this.props.mainLink === "string"
                            })}>{title}{typeof this.props.mainLink === "string" &&
                    <i className={"icon-feather-arrow-right ml-1"}/>}</span>
                <h4>
                    <ShowPrice
                        currencyprefix={currency_symbol}
                        amount={count.toFixed(2)}
                    />
                </h4>

                {prev_count !== null ? <h5 className="text-info" onClick={() => {
                    if (typeof this.props.subLink === "string")
                        this.props.history.push(this.props.subLink)
                }}>
                    <i className={prev_icon}
                       style={{
                           color: prev_icon_color
                       }}/>{" "}
                    <span
                        onClick={() => {
                            if (typeof this.props.subLink === "string")
                                this.props.history.push(this.props.subLink)
                        }}
                        className={classnames({
                            "cursor-pointer": typeof this.props.subLink === "string"
                        })}>
                    {prev_text}:{" "}
                        <ShowPrice
                            currencyprefix={currency_symbol}
                            amount={prev_count.toFixed(2)}
                        />{typeof this.props.subLink === "string" &&
                    <i className={"icon-feather-arrow-right ml-1"}/>}</span>
                </h5> : <h5 className="text-info">&nbsp;</h5>}
            </div>
            <div className="fun-fact-icon" style={{
                backgroundColor: icon_bg_color
            }}><i
                className={loading ? "cssspin icon-feather-loader" : icon} style={{color: icon_color}}/></div>
        </div>;
    }
}

export default FunFacts
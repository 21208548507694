import React from "react"
import classnames from "classnames";
import {Link} from "react-router-dom";
import {get_main_config} from "../../../index";
import {GrClose} from "react-icons/all";
import {Button} from "reactstrap";
import {loadProfile} from "./AdminSidebarProfile";
import {GlobalAuthorizationObject} from "../../../Lib/Authorization";
import {FormattedMessage} from "react-intl";

const auth = GlobalAuthorizationObject
class AdminMobileProfile extends React.Component{
    componentDidMount() {
        loadProfile().then(r => {
            this.setState({rand: Math.random()})
        })
    }

    getProfileName = () => {
        let admin_profile = localStorage.getItem('admin_profile')
        admin_profile = JSON.parse(admin_profile) || {}
        return `${admin_profile.firstname} ${admin_profile.lastname}`
    }

    render() {
        let {changeMenu, changeSelectedMenu} = this.props
        return <div className={classnames("mobile-profile p-3 animated fadeIn",{
            "d-block": this.props.open
        })}>
            <div className={"d-block padding-bottom-10 border-bottom mobile-profile-div"}>
                <div id="logo" className={"w-100 ml-3 float-left mobile-profile-logo"}>
                    <Link to={"/admin"} onClick={() => {
                        changeMenu("home")
                        changeSelectedMenu({})
                    }}><img src={get_main_config("system_general", "logo_url")} alt={""}/></Link>
                </div>
                <Button
                    className={"rounded-circle d-block mr-3 mt-2 p-0 float-right mobile-profile-close"}
                    onClick={this.props.toggle} color={"light"}><GrClose/></Button>
            </div>

            <p>&nbsp;</p>
            <h3 className={"mb-3"}>{this.getProfileName()}</h3>
            <div className={"text-center"}>
                <Button color={"dark button-sliding-icon"} block={true} onClick={() => {
                    let self = this
                    auth.doLogout().then(function () {
                        self.setState({
                            logout_loading: false
                        })
                        self.props.history.push('/cp/login')
                    })
                }}><FormattedMessage id={"admin.mobile_profile.logout"} /> <i className={"icon-feather-arrow-right"} /></Button>
            </div>

        </div>;
    }
}
export default AdminMobileProfile
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ContentModel from "../../Models/ContentModel";
import {Grid} from "@material-ui/core";
import {matchPath} from "react-router";
import {object} from "prop-types";
import moment from "moment";
import {MdDateRange} from "react-icons/all";
import {Link} from "react-router-dom";
import {ArrowLeft} from "@material-ui/icons";
import {Skeleton} from "@material-ui/lab";
import {Col, Row} from "reactstrap";
import {FormattedMessage} from "react-intl";


const getParams = (pathname) => {
    const matchProfile = matchPath(pathname, {
        path: `/cp/announcements/:id`,
    });
    return (matchProfile && matchProfile.params) || {};
};

const ContentLoading = () => {
    return <>
        <div className="d-flex padding-bottom-10 border-bottom margin-bottom-10">
            <div className="w-100">
                <Skeleton variant={"text"} width={175} height={32}/>
            </div>
            <div className="d-flex meta border rounded p-1">
                <Skeleton variant={"text"} width={120} height={32}/>
            </div>
        </div>
        <Row>
            <Col sm={5}>
                <Skeleton width={"100%"} height={300} className={"rounded"}/>
            </Col>
            <Col sm={7}>
                <Skeleton variant={"text"} width={"70%"} height={32} className={"w-100 mb-3"}/>
                <Skeleton variant={"text"} width={"80%"} height={20}/>
                <Skeleton variant={"text"} width={"70%"} height={20}/>
                <Skeleton variant={"text"} width={"75%"} height={20}/>
                <Skeleton variant={"text"} width={"70%"} height={20}/>
                <Skeleton variant={"text"} width={"80%"} height={20}/>
            </Col>
        </Row>
    </>
}

class CpAnnouncementSingle extends React.Component {
    contentId = null

    constructor(props) {
        super(props);
        let params = getParams(props.location.pathname)
        this.contentId = params.id
    }

    state = {
        data: {},
        image: "",
        loading: true
    }

    loadAnnouncement = (id) => {
        ContentModel.getById(id, ["content-field"]).then(r => r.json())
            .then(r => {
                let data = r.data.attributes
                let loading = false
                let image
                if (typeof r.included === "object") {
                    let fields = r.included[this.contentId]
                    let thumb = fields.find(item => item.data.field_name === "thumbnail")
                    if (typeof thumb === "object")
                        image = thumb.data.value
                }
                if (!image)
                    image = ""
                this.setState({data, loading, image})
            })
    }

    componentDidMount() {
        this.loadAnnouncement(this.contentId)
    }

    render() {
        let {data, loading, image} = this.state
        return <div>
            <div className={"w-100 pl-3 pr-3"}>
                {loading ? <ContentLoading/> :
                    <>
                        <div className="d-flex padding-bottom-10 border-bottom margin-bottom-10">
                            <div className="w-100">
                                <Link className={"btn btn-dark"} to={"/cp/announcements"}><ArrowLeft/> <FormattedMessage id={"client.cp_announcement_detail.campaign_announcements_txt"}/></Link>
                            </div>
                            <div className="d-flex meta border rounded p-1">
                                <MdDateRange className={"margin-top-3"}/>
                                <strong
                                    className={"margin-left-10"}>{moment(data.created).format("DD/MM/YYYY")}</strong>
                            </div>
                        </div>
                        <Row>
                            <Col sm={5}>
                                {image && <img src={image} alt="" className={"mb-3 rounded"}/>}
                            </Col>
                            <Col sm={7}>
                                <h3 className={"w-100 mb-3"}>{data.name}</h3>
                                <div className="content" dangerouslySetInnerHTML={{__html: data.content || "-"}}></div>
                            </Col>
                        </Row>
                    </>}
            </div>
        </div>;
    }
}

export default CpAnnouncementSingle
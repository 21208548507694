import React from "react"
import {GetTitle} from "../../Routes/AdminRoute";
import {Alert, Button, Card, CardBody, CardHeader, Col, Label, Row, Spinner} from "reactstrap";
import InputWithIcon from "../../Elements/InputWithIcon";
import PhoneNumberInput from "../../Elements/PhoneNumberInput";
import {
    AiFillFileExcel,
    BiMessageRoundedCheck,
    BiMessageRoundedX,
    RiMailCheckLine,
    RiMailCloseLine
} from "react-icons/all";
import Switch from "@material-ui/core/Switch";
import {FormControlLabel} from "@material-ui/core";
import classnames from "classnames";
import ButtonLoad from "../../Elements/ButtonLoad";
import ClientCustomFieldModel from "../../Models/ClientCustomFieldModel";
import {get_user_id} from "../../Conts";
import ClientModel from "../../Models/ClientModel";
import {ApiErrorThrow, defaultSnackbarOptions} from "../../Config";
import {withSnackbar} from "react-simple-snackbar";
import PhoneVerification from "../../Components/PhoneVerification";
import EmailVerification from "../../Components/EmailVerification";
import {FormattedMessage} from "react-intl";
import {react_intl} from "../../index";

const MailVerificationNo = (props) => {
    return <>
        <RiMailCloseLine className={"text-danger"} size={40}/>
        <h4 className={"mt-3"}><FormattedMessage id={"client.profile.e_mail_error_msg"}/></h4>
        <div className={"mt-3"}>
            <EmailVerification />
        </div>
    </>
}
const MailVerificationYes = (props) => {
    return <>
        <RiMailCheckLine className={"text-dark"} size={40}/>
        <h4 className={"mt-3"}><FormattedMessage id={"client.profile.e_mail_success_msg"}/></h4>
    </>
}

const PhoneVerificationNo = () => {
    return <>
        <BiMessageRoundedX className={"text-danger"} size={40}/>
        <h4 className={"mt-3"}><FormattedMessage id={"client.profile.tel_no_error_msg"}/></h4>
        <div className={"mt-3"}>
            <PhoneVerification />
        </div>
    </>
}
const PhoneVerificationYes = () => {
    return <>
        <BiMessageRoundedCheck className={"text-dark"} size={40}/>
        <h4 className={"mt-3"}><FormattedMessage id={"client.profile.tel_no_success_msg"}/></h4>
    </>
}

const ProfileData = {
    firstname: "",
    lastname: "",
    mobile_phone: "",
    email: "",
    mobile_phone_country_code: "TR",
}

class Profile extends React.Component {
    state = {
        account_type: "personal",
        user_details: ProfileData,
        company_name: "",
        saving: false,
        loading: true,
        phoneVerification: false,
        emailVerification: false
    }

    phoneRef = React.createRef()

    componentDidMount() {
        document.title = GetTitle(react_intl.formatMessage({id:"client.profile.my_account_title"}))
        this.loadProfile()
    }

    constructor(props) {
        super(props);
        this.toggle_account_type = this.toggle_account_type.bind(this)
        this.profile_handle = this.profile_handle.bind(this)
    }

    toggle_account_type = (e) => {
        let checked = e.target.checked
        this.setState({
            account_type: checked ? "corporate" : "personal"
        })
    }

    loadProfile = () => {
        ClientModel.getById(get_user_id(), ["user-field"]).then(r => r.json())
            .then(r => {
                let data = r.data.attributes
                let included = r.included
                if (typeof included === "object" && included.length) {
                    let fields = {}
                    included.map(item => {
                        fields[item.data.field_name] = item.data.field_content;
                    })
                    this.setState({
                        user_fields: fields,
                        company_name: fields['company_name'] || "",
                        account_type: fields['account_type'] || "personal",
                        phoneVerification: typeof fields['sms_verification'] === "string" && fields['sms_verification'] === "yes",
                        emailVerification: typeof fields['email_verification'] === "string" && fields['email_verification'] === "yes"
                    })
                }

                this.setState({
                    user_details: data,
                    loading: false
                })
            })
    }

    profile_handle = (e, callback) => {
        let user_details = {...this.state.user_details}
        user_details[e.target.name] = e.target.value
        this.setState({
            user_details
        }, () => {
            if (typeof callback === "function")
                callback()
        })
    }

    save = () => {
        let {openSnackbar} = this.props
        this.setState({saving: true})
        let save_data = {}

        // save profile
        let {user_details, account_type, company_name} = this.state
        Object.keys(ProfileData).map(key => {
            save_data[key] = user_details[key]
        })
        ClientModel.edit(get_user_id(), save_data).then(r => r.json()).then(r => {
            this.setState({saving: false})
            openSnackbar(<FormattedMessage id={"client.profile.changes_saved_msg"}/>)
        }).catch(r => {
            this.setState({saving: false})
            ApiErrorThrow(r, openSnackbar)
        })

        if (account_type !== "corporate") {
            account_type = "personal"
            company_name = ""
        }

        // save profile fields
        let save_data2 = [
            {
                field_name: "account_type",
                field_content: account_type
            },
            {
                field_name: "company_name",
                field_content: company_name
            }
        ]
        ClientCustomFieldModel.bulkUpdate(get_user_id(), save_data2).then(r => r.json()).catch(r => ApiErrorThrow(r, openSnackbar))
    }

    render() {
        let {user_details, loading, phoneVerification, emailVerification} = this.state
        let self = this
        return <div className={"w-100 pl-3 pr-3"}>
            {loading ? <div className={"text-center padding-top-100"}>
                <Spinner color={"dark"}/>
            </div> : <div color={"light"}>

                <Alert color={"light"} className={"border"}>
                    <Row>
                        <Col sm={12} md={6}>
                            <h3><FormattedMessage id={"client.profile.account_details"}/></h3>
                            <p className={"font-italic text-muted"}><FormattedMessage id={"client.profile.account_details_msg"}/></p>
                        </Col>

                    </Row>
                </Alert>

                <CardBody className={"bg-white"}>
                    <Row>
                        <Col sm={12} md={8}>
                            <div className={"bg-light border p-3 rounded"}>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <Label><FormattedMessage id={"client.profile.name"}/></Label>
                                        <InputWithIcon
                                            onChange={this.profile_handle}
                                            defaultValue={user_details.firstname}
                                            name={"firstname"}
                                        />
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <Label><FormattedMessage id={"client.profile.surname"}/></Label>
                                        <InputWithIcon
                                            onChange={this.profile_handle}
                                            defaultValue={user_details.lastname}
                                            name={"lastname"}
                                        />
                                    </Col>

                                    <Col sm={12} className={"mt-1"}>
                                        <Label><FormattedMessage id={"client.profile.account_type"}/></Label>
                                        <p className={"pl-1 mb-0"}>
                                            <FormControlLabel
                                                color={"primary"}
                                                label={this.state.account_type === "corporate" ? react_intl.formatMessage({id:"client.profile.corporate"}) : react_intl.formatMessage({id:"client.profile.personal"})}
                                                control={<Switch
                                                    checked={this.state.account_type === "corporate"}
                                                    onChange={this.toggle_account_type}
                                                    inputProps={{'aria-label': 'secondary checkbox'}}
                                                />}
                                            />
                                        </p>
                                    </Col>

                                    <Col sm={12} className={classnames({
                                        "d-none": this.state.account_type !== "corporate"
                                    })}>
                                        <Label><FormattedMessage id={"client.profile.company_title"}/></Label>
                                        <InputWithIcon defaultValue={this.state.company_name} onChange={(e) => {
                                            this.setState({
                                                company_name: e.target.value
                                            })
                                        }}/>
                                    </Col>

                                    <Col sm={12} md={6}>
                                        <Label><FormattedMessage id={"client.profile.tel_no"}/></Label>
                                        <PhoneNumberInput
                                            afterLoad={(e) => {
                                                let country;
                                                if (typeof e === "object" && typeof e.getCountryData === "function")
                                                    country = e.getCountryData()
                                                this.profile_handle({
                                                    target: {
                                                        name: "mobile_phone_country_code",
                                                        value: (country.countryCode || "TR").toUpperCase()
                                                    }
                                                });
                                            }}
                                            onChange={(val) => {
                                                this.profile_handle({
                                                    target: {
                                                        name: "mobile_phone",
                                                        value: val.number
                                                    }
                                                }, function () {
                                                    self.profile_handle({
                                                        target: {
                                                            name: "mobile_phone_country_code",
                                                            value: val.country
                                                        }
                                                    })
                                                })

                                            }}
                                            value={user_details.mobile_phone}
                                            name={"mobile_phone"}
                                        />
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <Label><FormattedMessage id={"client.profile.e_mail"}/></Label>
                                        <InputWithIcon
                                            onChange={this.profile_handle}
                                            defaultValue={user_details.email}
                                            name={"email"}
                                            icon={" icon-material-outline-email"}/>
                                    </Col>
                                </Row>
                            </div>
                            <div className={"text-right mt-2"}>
                                <ButtonLoad
                                    onClick={() => {
                                        this.save()
                                    }}
                                    color={"primary"} text={react_intl.formatMessage({id:"client.profile.save"})} loading={this.state.saving}
                                    icon={" icon-feather-check"}
                                />
                            </div>
                        </Col>
                        <Col sm={12} md={4}>
                            <Card color={"primary"}>
                                <CardHeader className={"text-white text-center"}><FormattedMessage id={"client.profile.account_verification"}/></CardHeader>
                                <CardBody className={"text-center bg-white"}>
                                    <Row>
                                        <Col sm={12}>
                                            {phoneVerification ? <PhoneVerificationYes/> : <PhoneVerificationNo/>}
                                        </Col>
                                        <Col sm={12} className={"pt-3 border-top mt-3"}>
                                            {emailVerification ? <MailVerificationYes/> : <MailVerificationNo/>}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </CardBody>
            </div>}
        </div>
    }
}

export default withSnackbar(Profile, defaultSnackbarOptions)
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import cup from "../../assets/images/cup.png"
import {frontendBaseUrl} from "../../Config";
import {FormattedMessage} from "react-intl";

const useStyles = makeStyles({
    root: {
        maxWidth: 350,
        margin: "0 auto",
        textAlign: "center"
    },
    CardMedia: {
        width: "auto",
        height: 120,
        display: "inline-block",
        marginTop: 20,
        objectFit: 'cover'
    },
    Actions: {
        justifyContent: 'center'
    }
});

export default function SetupCompleted() {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardActionArea
                onClick={() => {
                window.location.href = frontendBaseUrl + '/admin'
            }}>
                    <CardMedia
                        className={classes.CardMedia}
                        component="img"
                        alt="Contemplative Reptile"
                        image={cup}
                        title="Contemplative Reptile"
                    />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        <FormattedMessage id={"client.setup_completed.configuration_okay"}/>
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        <FormattedMessage id={"client.setup_completed.configuration_okay_msg"}/>
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions className={classes.Actions}>
                <a href={frontendBaseUrl + '/cp/login'} size="small" className={"btn btn-primary btn-sm"}>
                    <FormattedMessage id={"client.setup_completed.go_to_admin_panel"}/>
                </a>
            </CardActions>
        </Card>
    );
}
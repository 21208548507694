import {Link} from "react-router-dom";
import React from "react";
import {GlobalAuthorizationObject, ClientAuthorizationObject} from "../../Lib/Authorization";
import {
    Button, Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    Navbar, NavbarBrand, NavbarText, NavbarToggler,
    NavItem,
    NavLink,
    UncontrolledDropdown
} from "reactstrap";
import {frontendBaseUrl} from "../../Config";
import {FaFileInvoiceDollar, RiBillLine, RiDashboardFill, VscAccount, VscChecklist, VscRocket} from "react-icons/all";

class Sidebar extends React.Component {
    render() {
        return <>
        </>
    }
}

export default Sidebar
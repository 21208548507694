import React from "react"
import {defaultSnackbarOptions} from "../../../../Config";
import {withSnackbar} from "react-simple-snackbar";
import EmailTemplateModel from "../../../../Models/EmailTemplateModel";
import EmailSmsTemplateSingle from "../EmailSmsTemplateSingle";
import {email_templates} from "../../../../Conts";
import {react_intl} from "../../../../index";

class SingleEmailTemplate extends EmailSmsTemplateSingle {
    template_type = "email"
    url_slug = "email-templates"
    all_link = "email-notifications"
    single_type_title = react_intl.formatMessage({id:"admin.single_email_template.e_mail"})
    template_model = EmailTemplateModel
    templates = email_templates
}

export default withSnackbar(SingleEmailTemplate, defaultSnackbarOptions)
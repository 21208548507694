import PropTypes from 'prop-types';

import {INFO_POSITION_AFTER, INFO_POSITION_BEFORE, INFO_POSITION_BOTTOM, INFO_POSITION_TOP,} from '../constants';

export default PropTypes.oneOf([
  INFO_POSITION_TOP,
  INFO_POSITION_BOTTOM,
  INFO_POSITION_BEFORE,
  INFO_POSITION_AFTER,
]);

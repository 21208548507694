import React from "react"
import GlRequest from "../Lib/GlRequest";
import moment from "moment";
import {Button, Spinner, UncontrolledTooltip} from "reactstrap";
import {react_intl} from "../index";

class TransactionPdfButton extends React.Component {
    state = {
        invoiceGetting: false
    }
    getPdfTransaction = async (transactionId, forceDownloadPdf) => {
        this.setState({invoiceGetting: transactionId})
        return new Promise(async function (resolve, reject) {
            await GlRequest.get(`/api/v2/transaction-pdf/${transactionId}?force_download=1`)
                .then(async res => ({
                    filename: typeof res.headers === "object" && Object.keys(res.headers).length > 0 ? this.fnGetFileNameFromContentDispostionHeader(res.headers.get('Content-Disposition')) : `Transaction-${transactionId}-${moment("YYYY-MM-DD")}.pdf`,
                    blob: await res.blob()
                }))
                .then(r => {
                    const newBlob = new Blob([r.blob], {type: 'application/pdf'});
                    resolve(newBlob)
                })
            reject("Error");
        });
    }

    render() {
        let {id, color} = this.props
        return <>
            <Button color={color || "info"} size={"sm"} className={"mr-1"} onClick={async () => {
                await this.getPdfTransaction(id).then(blob => {
                    let forceDownloadPdf = false
                    if (forceDownloadPdf) {
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(blob);
                        } else {
                            // For other browsers: create a link pointing to the ObjectURL containing the blob.
                            const objUrl = window.URL.createObjectURL(blob);
                            let link = document.createElement('a');
                            link.href = objUrl;
                            link.download = `Transaction-${id}-${moment().format('DD-MM-YYYY')}.pdf`;
                            link.click();
                            link.remove()
                            // For Firefox it is necessary to delay revoking the ObjectURL.
                            setTimeout(() => {
                                window.URL.revokeObjectURL(objUrl);
                            }, 250);
                        }
                    } else {
                        let blobUrl = URL.createObjectURL(blob);
                        window.open(blobUrl);
                    }
                    this.setState({invoiceGetting: null})
                }).catch(r => {
                    this.setState({invoiceGetting: null})
                })
            }} id={`transaction-receipt-button-${id}`}>
                {
                    this.state.invoiceGetting === id ? <Spinner size={"sm"}/> :
                        this.props.text || <i className={" icon-line-awesome-file-pdf-o"}/>
                }
            </Button>
            <UncontrolledTooltip target={`transaction-receipt-button-${id}`}>
                {react_intl.formatMessage({id:"component.transaction_pdf_button.receipt"})}
            </UncontrolledTooltip>
        </>;
    }
}

export default TransactionPdfButton
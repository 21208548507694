import React from "react"
import {Button, FormControl, FormGroup, Step, StepLabel, Stepper, TextField} from "@material-ui/core";
import logo from "../../assets/images/paymendo-logo.png"
import {count} from "react-table/src/aggregations";
import "../../assets/sass/custom/_installer.scss"
import {BsBuilding, GoSettings, GrNext, GrPrevious, GrUserAdmin, RiListSettingsLine} from "react-icons/all";
import {Card, CardBody, CardHeader, Col, Row, Spinner} from "reactstrap";
import PhoneNumberInput from "../../Elements/PhoneNumberInput";
import CustomCheckbox from "../../Elements/CustomCheckbox";
import GlRequest from "../../Lib/GlRequest";
import {ApiErrorThrow} from "../../Config";
import SetupCompleted from "./SetupCompleted";
import {react_intl} from "../../index";
import {FormattedMessage} from "react-intl";

class Installer extends React.Component {
    state = {
        completed: false,
        loading: false,
        loading_prev: false,
        activeStep: 0,
        data: [
            {
                "name": "site_name",
                "value": "Paymendo",
                "group": "system_general"
            },
            {
                "name": "system_url",
                "value": (window.location.origin),
                "group": "system_general"
            },
            {
                "name": "system_admin",
                "value": "info@gri.net",
                "group": "system_preferences"
            }
        ],
        user_data: {},
        accepted: false
    }

    getData = () => {
        let settings = this.settings()
        let d = this.state.data
        settings.map(g => g.settings.map(i => {
            if (!d.find(item => item.name === i.slug)) {
                d.push({
                    name: i.slug,
                    value: i.value || "",
                    group: g.slug
                })
            }
        }))
        return d
    }

    findElementIndex = (group, name) => {
        let i = -1
        let {data} = this.state
        data.forEach((item, key) => {
            if (item.group === group && item.name === name)
                i = key
        })
        return i
    }

    onChange = (e) => {
        let {data} = this.state
        let {name, value} = e.target
        let {group} = e.target.dataset
        let index = this.findElementIndex(group, name)
        if (index > -1)
            data[index].value = value
        else
            data.push({
                name, value, group
            })

        this.setState({data})
    }

    settings = () => {
        let settings = [
            {
                slug: "system_general",
                label: react_intl.formatMessage({id:"client.installer.site_settings"}),
                icon: <GoSettings/>,
                settings: [
                    {
                        slug: "site_name",
                        label: react_intl.formatMessage({id:"client.installer.site_title"}),
                        value: "",
                        type: "text"
                    },
                    {
                        slug: "system_url",
                        label: react_intl.formatMessage({id:"client.installer.system_url"}),
                        value: window.location.href,
                        type: "text"
                    }
                ]
            },
            {
                slug: "system_preferences",
                label: react_intl.formatMessage({id:"client.installer.system_preferences"}),
                icon: <RiListSettingsLine/>,
                settings: [
                    {
                        slug: "system_admin",
                        label: react_intl.formatMessage({id:"client.installer.system_admin_e_mail"}),
                        description: react_intl.formatMessage({id:"client.installer.e_mail_for_system_notifications_msg"}),
                        value: "",
                        type: "text"
                    }
                ]
            },
            {
                slug: "owner_business_details",
                label: react_intl.formatMessage({id:"client.installer.corporate_info"}),
                icon: <BsBuilding/>,
                settings: [
                    {
                        label: react_intl.formatMessage({id:"client.installer.company_title"}),
                        type: "text",
                        slug: "company_name"
                    },
                    {
                        label: react_intl.formatMessage({id:"client.installer.company_address"}),
                        type: "text",
                        slug: "address"
                    },
                    {
                        label: react_intl.formatMessage({id:"client.installer.company_tax_office"}),
                        type: "text",
                        slug: "tax_office"
                    },
                    {
                        label: react_intl.formatMessage({id:"client.installer.company_tax_number"}),
                        type: "text",
                        slug: "tax_number"
                    },
                    {
                        label: react_intl.formatMessage({id:"client.installer.company_e_mail"}),
                        type: "text",
                        slug: "email"
                    },
                    {
                        label: react_intl.formatMessage({id:"client.installer.company_phone_number"}),
                        type: "text",
                        slug: "phone"
                    }
                ]
            }
        ]
        return settings
    }

    constructor(props) {
        super(props);
        this.next = this.next.bind(this)
        this.prev = this.prev.bind(this)
        this.onChange = this.onChange.bind(this)
        this.complete = this.complete.bind(this)
        this.user_data_handle = this.user_data_handle.bind(this)
    }

    user_data_handle = (e, cb) => {
        let {user_data} = this.state
        let user_data_copy = Object.assign({}, user_data)
        user_data_copy[e.target.name] = e.target.value
        this.setState({
            user_data: user_data_copy
        }, cb)
    }

    steps = [
        {
            label: react_intl.formatMessage({id:"client.installer.basic_settings"}),
            content: () => {
                let settings = this.settings()
                let {data} = this.state
                return <div>
                    <div className={"w-100 ml-auto mr-auto"}>
                        <Row>
                            {settings.map(item_group => {
                                return <Col sm={12} lg={12}>
                                    <h3 className={"text-dark margin-bottom-10"}>{item_group.icon ? <>{item_group.icon} </> : ""}{item_group.label}</h3>
                                    <div className={"bg-white-translucent rounded pt-2 pl-2 pr-2"}>
                                        {typeof item_group.settings === "object" && (item_group.settings).map(item_single => {
                                            let val = (data.find(i => i.name === item_single.slug) || {}).value;
                                            return <div>
                                                <FormControl className={"w-100 margin-bottom-15"}>
                                                    {item_single.type === "text" &&
                                                    <TextField className={"w-100"} label={item_single.label}
                                                               size={"small"}
                                                               onChange={this.onChange}
                                                               inputProps={{
                                                                   "data-group": item_group.slug
                                                               }}
                                                               name={item_single.slug}
                                                               value={val}
                                                               variant="outlined"
                                                        // margin="dense"
                                                    />}
                                                </FormControl>
                                            </div>
                                        })}
                                    </div>
                                    <p>&nbsp;</p>
                                </Col>
                            })}
                        </Row>
                    </div>
                </div>
            },
            process: function (self) {
                self.setState({loading: true})
                return new Promise((resolve, reject) => {
                    let groups = []
                    let {data} = self.state
                    data.forEach(item => {
                        if (groups.indexOf(item.group) === -1) {
                            groups.push(item.group);
                        }
                    })
                    let data_post = {
                        attributes: {}
                    }

                    groups.forEach(gSlug => {
                        data_post.attributes[gSlug] = data.filter(i => i.group == gSlug)
                    })
                    GlRequest.post('/install/step-1', {data: data_post}, "json")
                        .then(r => r.json())
                        .then(r => {
                            self.setState({loading: false})
                            resolve(true)
                        })
                        .catch(r => {
                            self.setState({loading: false})
                            ApiErrorThrow(r, reject)
                        })
                });
            }
        },
        {
            label: react_intl.formatMessage({id:"client.installer.create_user"}),
            content: () => {
                let {user_data} = this.state
                return <div className={"mb-3"}>
                    <div className="text-center margin-bottom-25">
                        <h3 className={"text-dark"}><GrUserAdmin/> <FormattedMessage id={"client.installer.create_admin"}/></h3>
                    </div>
                    <Row>
                        <Col>
                            <TextField className={"w-100"} label={react_intl.formatMessage({id:"client.installer.name"})}
                                       onChange={this.user_data_handle}
                                       name={"firstname"}
                                       value={user_data.firstname}
                                       variant="outlined"
                            />
                        </Col>
                        <Col>
                            <TextField className={"w-100"} label={react_intl.formatMessage({id:"client.installer.surname"})}
                                       onChange={this.user_data_handle}
                                       name={"lastname"}
                                       value={user_data.lastname}
                                       variant="outlined"
                            />
                        </Col>
                    </Row>
                    <Row className={"margin-top-15"}>
                        <Col>
                            <TextField className={"w-100"} label={react_intl.formatMessage({id:"client.installer.e_mail"})}
                                       onChange={this.user_data_handle}
                                       name={"email"}
                                       value={user_data.email}
                                       variant="outlined"
                            />
                        </Col>
                    </Row>
                    <Row className={"margin-top-15"}>
                        <Col>
                            <TextField className={"w-100"} label={react_intl.formatMessage({id:"client.installer.username"})}
                                       onChange={this.user_data_handle}
                                       name={"username"}
                                       value={user_data.username}
                                       variant="outlined"
                            />
                        </Col>
                    </Row>
                    <Row className={"margin-top-15"}>
                        <Col>
                            <TextField className={"w-100"} label={react_intl.formatMessage({id:"client.installer.password"})}
                                       onChange={this.user_data_handle}
                                       type={"password"}
                                       name={"password"}
                                       value={user_data.password}
                                       variant="outlined"
                            />
                        </Col>
                        <Col>
                            <TextField className={"w-100"} label={react_intl.formatMessage({id:"client.installer.password_again"})}
                                       onChange={this.user_data_handle}
                                       name={"password2"}
                                       type={"password"}
                                       value={user_data.password2}
                                       variant="outlined"
                            />
                        </Col>
                    </Row>
                    <Row className={"margin-top-15"}>
                        <Col>
                            <PhoneNumberInput
                                value={user_data.mobile_phone}
                                onChange={(val) => {
                                    let self = this
                                    if (typeof val !== "object")
                                        val = {}
                                    this.user_data_handle({
                                        target: {
                                            name: "mobile_phone_country_code",
                                            value: val.country
                                        }
                                    }, function () {
                                        self.user_data_handle({
                                            target: {
                                                name: "mobile_phone",
                                                value: val.number
                                            }
                                        })
                                    })
                                }}/>
                        </Col>
                    </Row>
                </div>
            },
            process: function (self) {
                return new Promise((resolve, reject) => {
                    let {user_data} = self.state
                    if (user_data.password !== user_data.password2) {
                        reject(<FormattedMessage id={"client.installer.passwords_dont_match_msg"}/>)
                    }
                    resolve(true)
                });
            }
        },
        {
            label: react_intl.formatMessage({id:"client.installer.review"}),
            content: () => {
                let {data, loading} = this.state
                return <div>
                    <Row>
                        <Col>
                            <Card className={"border-0"}>
                                <CardHeader className={"bg-dark text-white"}><FormattedMessage id={"client.installer.settings"}/></CardHeader>
                                <CardBody className={"pl-0 pr-0"}>
                                    {this.settings().map(group => {
                                        return <div className={"margin-bottom-10"}>
                                            <h4>{group.label}</h4>
                                            <div className={"p-2 border rounded"}>
                                                {group.settings.map(item => <div><h5>{item.label}</h5>
                                                    <span
                                                        className={"text-muted"}>{((data || []).find(i => i.name === item.slug) || {}).value || "-"}</span>
                                                </div>)}
                                            </div>
                                        </div>
                                    })}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col onClick={() => {
                            this.setState({accepted: !this.state.accepted})
                        }} className={"cursor-pointer"}>
                            <CustomCheckbox
                                style={{pointerEvents:"none"}}
                                checked={this.state.accepted}/> <FormattedMessage id={"client.installer.accept_terms_txt"}/>
                        </Col>
                        <Col className={"text-right"}>
                            {loading && <Spinner size={"sm"} color={"primary"} />}
                            {!loading && <Button
                                onClick={this.complete}
                                disabled={!this.state.accepted} variant={"contained"} color={"primary"}><FormattedMessage id={"client.installer.complete_configuration"}/></Button>}
                        </Col>
                    </Row>
                </div>
            }
        }
    ]

    complete = () => {
        let self = this

        this.setState({loading:true})

        // create user
        let {user_data} = self.state

        let user_data2 = Object.assign({}, user_data)
        delete user_data2.password2

        let data_post = {
            attributes: user_data2
        }

        GlRequest.post('/install/step-2', {data: data_post}, "json")
            .then(r => r.json())
            .then(r => {
                GlRequest.get('/install/step-3')
                    .then(r => r.json())
                    .then(r => {
                        self.setState({loading: false, completed: true})
                    })
                    .catch(r => {
                        self.setState({loading: false, completed: false})
                        ApiErrorThrow(r)
                        return false
                    })
            })
            .catch(r => {
                self.setState({loading: false})
                ApiErrorThrow(r)
                return false
            })
    }

    next = () => {
        let {activeStep} = this.state
        let self = this
        if ((activeStep + 1) === count(this.steps))
            return false
        let activeStepDetails = this.steps[activeStep]
        if (typeof activeStepDetails.process === "function") {
            activeStepDetails.process(this).then(function () {
                self.setState({
                    activeStep: activeStep + 1
                })
            }).catch(r => {
                alert(r)
            })
        } else {
            self.setState({
                activeStep: activeStep + 1
            })
        }
    }

    prev = () => {

        let {activeStep} = this.state
        if (activeStep === 0)
            return false
        this.setState({loading_prev: true})
        GlRequest.get('/install/start-over').then(r => r.json())
            .then(r => {
                this.setState({
                    activeStep: 0,
                    loading_prev: false
                })
            })
    }

    componentDidMount() {
        this.setState({data: this.getData()})
    }

    render() {
        let {steps} = this
        let {activeStep, completed} = this.state

        return <>

            <div className={"text-center pt-3 pb-3 margin-top-50 margin-bottom-25"}>
                <img src={logo}/>
            </div>

            {completed ? <SetupCompleted/> : <>
                <div className={"border shadow bg-white rounded"}>
                    <div className={"d-flex"}>
                        <Button onClick={this.prev} color={"secondary"} style={{
                            opacity: activeStep === 0 ? ".2" : "1"
                        }} disabled={activeStep === 0}>{this.state.loading_prev ? <Spinner size={"sm"}/> :
                            <GrPrevious/>}</Button>
                        <Stepper alternativeLabel activeStep={activeStep} className={"w-100"}>
                            {steps.map((item) => (
                                <Step key={item.label}>
                                    <StepLabel>{item.label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <Button
                            disabled={this.state.loading}
                            onClick={() => {
                                this.next()
                            }}
                            style={{
                                opacity: (activeStep + 1) === this.steps.length ? ".2" : "1"
                            }}
                            color={"primary"}
                            disabled={(activeStep + 1) === this.steps.length}>{!this.state.loading ? <GrNext/> :
                            <Spinner size={"sm"}/>}</Button>
                    </div>
                </div>

                <div className={"border rounded p-3 mt-5 bg-white shadow"}>
                    {steps[activeStep].content()}
                    {steps.length !== (activeStep + 1) && <>
                        <div className={"text-center"}>
                            <Button
                                disabled={this.state.loading}
                                onClick={this.next}
                                variant={"contained"}
                                color={"primary"}><FormattedMessage id={"client.installer.continue"}/></Button>
                        </div>
                    </>}
                </div>

            </>}
            <p className={"bg-white margin-top-15 p-3 rounded text-center"}>
                &copy; 2021 - gri - <a href={"mailto:info@gri.net"}>info@gri.net</a>
            </p>
        </>;
    }
}

export default Installer
import {
    Col,
    Row,
} from "reactstrap";
import React from "react";
import LeftTable from "./Details/LeftTable";
import RightTable from "./Details/RightTable";
import BottomTable from "./Details/BottomTable";
import OrderModel from "../../../Models/OrderModel";
import {ApiErrorThrow} from "../../../Config";
import {react_intl} from "../../../index";

class OrderDetailsTab extends React.Component {

    state = {
        order_details: {},
        saving: false
    }

    handle = (e) => {
        let order_details = Object.assign({}, this.state.order_details)
        order_details[e.target.name] = e.target.value
        this.setState({order_details})
    }

    save = () => {
        let {openSnackbar} = this.props
        let {order_details} = this.state
        this.setState({saving: true})
        OrderModel.modify(this.props.orderId, order_details).then(r => r.json())
            .then(r => {
                openSnackbar(react_intl.formatMessage({id:"admin.orders.order_details_tab.saved"}));
                this.setState({saving: false})
                this.props.reload()
            })
            .catch(r => {
                this.setState({saving: false})
                ApiErrorThrow(r, openSnackbar)
            })
    }

    render() {
        return <div>
            <div className={"content with-padding bg-light border-bottom"}>
                <Row>
                    <Col sm={12} md={7}>
                        <LeftTable
                            {...this.props}
                            orderDetails={this.props.orderDetails}
                        />
                    </Col>
                    <Col sm={12} md={5} className={"text-left"}>
                        <RightTable {...this.props} />
                    </Col>
                </Row>
            </div>
            <BottomTable
                loading={this.state.saving}
                handle={this.handle}
                save={this.save}
                key={`order-${this.props.orderDetails.id}-bottom`} {...this.props} />
        </div>;
    }
}

export default OrderDetailsTab
import React from 'react';
import {Alert} from "@material-ui/lab";
import {Button} from "reactstrap";
import {FormattedMessage} from "react-intl";


class InvoiceWidget extends React.Component {
    render() {
        let {invoice_stats} = this.props
        if (typeof invoice_stats !== "object")
            invoice_stats = {}
        let {unpaid} = invoice_stats
        if (typeof unpaid !== "object")
            unpaid = {}
        let {amount} = unpaid

        return <div className={"p-3"}>
            {unpaid.count === 0 && <Alert severity={"success"}>
                <FormattedMessage id={"client.invoice_widget.paid_invoice_info"}/>
            </Alert>}
            {unpaid.count > 0 && <>
                <Alert severity={"warning"}>
                    <p><FormattedMessage id={"client.invoice_widget.unpaid_invoice_info"} values={{unpaid_count:unpaid.count}}/></p>
                    <p className={"mb-1"}><strong><FormattedMessage id={"client.invoice_widget.total_amount"}/></strong></p>
                    {Object.keys(unpaid.amount).map(key => {
                        let item = unpaid.amount[key]
                        return <p>{item} {key}</p>
                    })}
                </Alert>

            </>}
        </div>
    }

}

export default InvoiceWidget
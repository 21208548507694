import GlRequest from "../Lib/GlRequest";

class NotificationModel {
    base = "/api/v2"

    get(details) {
        return GlRequest.get(this.base, {
            params: details
        })
    }

    create(user_id, details) {
        details = {
            data: {
                attributes: details
            }
        }
        return GlRequest.post(this.base + '/user/' + user_id + '/notification', details, 'json')
    }

    getById(id) {
        return GlRequest.get(this.base + '/' + id)
    }

    updateById(user_id, id, details) {
        details = {
            data: {
                attributes: details
            }
        }
        return GlRequest.patch(this.base + '/user/' + user_id + '/notification/' + id, details, 'json')
    }

    delete(id) {
        if (typeof id === "object")
            id = id.join(",")
        return GlRequest.delete(this.base + '/notification/' + id)
    }

    loadUser(user_id) {
        let getParams = {
            sort: "-id",
            include: 'read'
        }
        return GlRequest.get(`${this.base}/user/${user_id}/notification`, {
            params: getParams
        })
    }

    markRead(id, user_id) {
        let d = {
            data: {
                attributes: {
                    is_read: true
                }
            }
        }
        return GlRequest.patch(`${this.base}/user/${user_id}/notification/${id}`, d, 'json')
    }

    markDeleted(id, user_id) {
        let d = {
            data: {
                attributes: {
                    deleted: true
                }
            }
        }
        return GlRequest.patch(`${this.base}/user/${user_id}/notification/${id}`, d, 'json')
    }
}

export default NotificationModel = new NotificationModel()
import React from "react"
import {Avatar, Badge, Chip} from "@material-ui/core";
import {ClientAuthorizationObject, GlobalAuthorizationObject} from "../../../Lib/Authorization";
import {Button, Dropdown, DropdownMenu, DropdownToggle, Spinner} from "reactstrap";
import {Notifications} from "@material-ui/icons";
import NotificationBar from "../../../Components/NotificationBar";
import classnames from "classnames";
import {get_app_lang, set_app_lang} from "../../../index";

const auth = GlobalAuthorizationObject
export const loadProfile = () => {

    return new Promise(async function (resolve, reject) {

        let admin_profile = localStorage.getItem('admin_profile')
        admin_profile = JSON.parse(admin_profile) || {}
        if (typeof admin_profile.firstname === "string")
            resolve(admin_profile)

        await ClientAuthorizationObject.getProfile().then(data => {
            localStorage.setItem('admin_profile', JSON.stringify(data))
            if ( typeof data === "object" && typeof data.language === "string" && get_app_lang() !== data.language) {
                set_app_lang(data.language).then(() => {
                    setTimeout(function (){
                        window.location.reload()
                    }, 200);
                });

            }
            resolve(data)
        })
        reject("no profile load");
    });
}

class AdminSidebarProfile extends React.Component {
    state = {
        logout_loading: false
    }

    componentDidMount() {
        loadProfile().then(r => {
            this.setState({rand: Math.random()})
        })
    }

    logout = () => {
        let self = this
        let {type} = this.props

        this.setState({
            logout_loading: true
        }, () => {
            setTimeout(function () {
                auth.doLogout().then(function () {
                    self.setState({
                        logout_loading: false
                    }, () => {
                        window.location.href = '/login'
                    })
                })
            }, 1000)
        })

    }

    render() {
        let {logout_loading} = this.state
        let {type, hidename, hidelogout, hidenotify, chipclass, style} = this.props
        let admin_profile = localStorage.getItem('admin_profile')
        admin_profile = JSON.parse(admin_profile) || {}
        if (typeof admin_profile.firstname !== "string")
            return <div className={"text-center"}>
                <Spinner size={"sm"}/>
            </div>

        let shortName = this.getShortName(`${admin_profile.firstname} ${admin_profile.lastname}`)

        return <div className={`text-center user-type-${type}`} style={style}>
            <div className={"d-inline"}>
                <Chip
                    onClick={() => {
                        if (typeof this.props.onClick === "function")
                            this.props.onClick()
                    }}
                    className={classnames(typeof chipclass !== "string" ? "paymendo-grad text-white " : chipclass, {
                        "m-0": type === "client",
                        "ml-3": type === "client",
                        "float-right": type === "client"
                    })}
                    variant="outlined"
                    avatar={<Avatar>{shortName}</Avatar>}
                    label={typeof hidename === "boolean" && hidename === true ? "" : `${admin_profile.firstname} ${admin_profile.lastname}`}
                    deleteIcon={logout_loading ? <Spinner size={"sm"} color={"white"}/> :
                        <i className={" icon-feather-power"} style={{fontSize: 18, marginTop: 4, color: "#fff"}}/>}
                    onDelete={typeof hidelogout === "boolean" && hidelogout === true ? "" : () => this.logout()}
                /></div>
            {" "}
            <div className={(typeof hidenotify === "boolean" && hidenotify === true) ? "d-none" : "d-inline-block"}>
                <NotificationBar {...this.props} />
            </div>
        </div>;
    }

    getShortName(nameStr) {
        return nameStr.split(" ").map(item => {
            return item.substr(0, 1).toUpperCase()
        }).join("");
    }
}

export default AdminSidebarProfile
import React from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import moment from 'moment';
import "moment/locale/tr";
import omit from 'lodash/omit';
import DateRangePicker from './DateRangePicker';
import 'react-dates/lib/css/_datepicker.css';
import {DateRangePickerPhrases} from './src/defaultPhrases.js';
import DateRangePickerShape from './src/shapes/DateRangePickerShape.js';
import {
    ANCHOR_LEFT,
    END_DATE,
    HORIZONTAL_ORIENTATION,
    NAV_POSITION_TOP,
    OPEN_DOWN,
    START_DATE,
} from './src/constants.js';
import CalendarIcon from "./CalendarIcon";
import {Button, ButtonGroup} from "reactstrap";
import classnames from "classnames";

const propTypes = {
    // example props for the demo
    autoFocus: PropTypes.bool,
    autoFocusEndDate: PropTypes.bool,
    stateDateWrapper: PropTypes.func,
    initialStartDate: momentPropTypes.momentObj,
    initialEndDate: momentPropTypes.momentObj,

    ...omit(DateRangePickerShape, [
        'startDate',
        'endDate',
        'onDatesChange',
        'focusedInput',
        'onFocusChange',
    ]),
};

const defaultProps = {
    // example props for the demo
    autoFocus: false,
    autoFocusEndDate: false,
    initialStartDate: null,
    initialEndDate: null,

    // input related props
    startDateId: START_DATE,
    startDatePlaceholderText: 'Başlangıç',
    endDateId: END_DATE,
    endDatePlaceholderText: 'Bitiş',
    disabled: false,
    required: false,
    screenReaderInputMessage: '',
    showClearDates: true,
    showDefaultInputIcon: false,
    customInputIcon: null,
    customArrowIcon: null,
    customCloseIcon: null,
    block: false,
    small: false,
    regular: false,

    // calendar presentation and interaction related props
    renderMonthText: null,
    orientation: HORIZONTAL_ORIENTATION,
    anchorDirection: ANCHOR_LEFT,
    horizontalMargin: 0,
    withPortal: false,
    withFullScreenPortal: false,
    initialVisibleMonth: null,
    numberOfMonths: 2,
    keepOpenOnDateSelect: false,
    reopenPickerOnClearDates: false,
    isRTL: false,
    openDirection: OPEN_DOWN,

    // navigation related props
    navPosition: NAV_POSITION_TOP,
    navPrev: null,
    navNext: null,
    onPrevMonthClick() {},
    onNextMonthClick() {},
    onClose() {},

    // day presentation and interaction related props
    renderCalendarDay: undefined,
    renderDayContents: null,
    minimumNights: 1,
    enableOutsideDays: false,
    isDayBlocked: () => false,
    isOutsideRange: day => false,
    isDayHighlighted: () => false,

    // internationalization
    displayFormat: () => moment.localeData().longDateFormat('L'),
    monthFormat: 'MMMM YYYY',
    phrases: DateRangePickerPhrases,

    stateDateWrapper: date => date,
};

class DateRangePickerWrapper extends React.Component {
    constructor(props) {
        super(props);

        let focusedInput = null;
        if (props.autoFocus) {
            focusedInput = START_DATE;
        } else if (props.autoFocusEndDate) {
            focusedInput = END_DATE;
        }

        this.state = {
            focusedInput,
            startDate: props.initialStartDate,
            endDate: props.initialEndDate,
            selected_date: ''
        };

        this.onDatesChange = this.onDatesChange.bind(this);
        this.onFocusChange = this.onFocusChange.bind(this);
    }

    onDatesChange({ startDate, endDate }) {
        const { stateDateWrapper, onChange } = this.props;
        this.setState({
            startDate: startDate && stateDateWrapper(startDate),
            endDate: endDate && stateDateWrapper(endDate),
        });
        if(typeof onChange === "function")
            onChange(startDate, endDate);
    }

    onFocusChange(focusedInput) {
        this.setState({ focusedInput });
    }

    onClickDateButton(date_str) {
        let startDate, endDate
        this.setState({
            selected_date: date_str
        })
        switch (date_str)
        {
            case 'today':
                startDate = moment()
                endDate = moment()
                break;
            case 'yesterday':
                startDate = moment().subtract(1,"day")
                endDate = moment().subtract(1,"day")
                break;
            case 'this_week':
                startDate = moment().startOf("week")
                endDate = moment().endOf("week")
                break;
            case 'this_month':
                startDate = moment().startOf("month")
                endDate = moment().endOf("month")
                break;
            case 'last_month':
                startDate = moment().subtract(1,"month").startOf("month")
                endDate = moment().subtract(1,"month").endOf("month")
                break;
            case 'this_year':
                startDate = moment().startOf("year")
                endDate = moment().endOf("year")
                break;
            case 'last_year':
                startDate = moment().subtract(1,"year").startOf("year")
                endDate = moment().subtract(1,"year").endOf("year")
                break;
        }
        this.onDatesChange({startDate,endDate});
        this.onFocusChange({focused: false});
    }

    dates = [
        {
            label: "Bugün",
            slug: "today"
        },
        {
            label: "Dün",
            slug: "yesterday"
        },
        {
            label: "Bu Hafta",
            slug: "this_week"
        },
        {
            label: "Bu Ay",
            slug: "this_month"
        },
        {
            label: "Geçen Ay",
            slug: "last_month"
        },
        {
            label: "Bu Yıl",
            slug: "this_year"
        },
        {
            label: "Geçen Yıl",
            slug: "last_year"
        }
    ]

    renderCalendarFooter() {
        return (
            <div className="date-picker-footer pt-2 pb-2 bg-light text-center">
                <div>
                    {this.dates.map(item => <Button type="button"
                                                    size={"sm"}
                                                    color={this.state.selected_date == item.slug ? "dark" : "default"}
                                                    onClick={() => this.onClickDateButton(item.slug)}>{item.label}</Button>)}
                </div>
            </div>
        );
    }

    render() {
        let {calendarIcon} = this.props
        const { focusedInput, startDate, endDate } = this.state;

        const props = omit(this.props, [
            'autoFocus',
            'autoFocusEndDate',
            'initialStartDate',
            'initialEndDate',
            'stateDateWrapper',
        ]);

        return (
            <div>
                {calendarIcon && <CalendarIcon width={15} height={15} style={{marginRight: 10}}/>}
                <DateRangePicker
                    {...props}
                    hideKeyboardShortcutsPanel={true}
                    onDatesChange={this.onDatesChange}
                    onFocusChange={this.onFocusChange}
                    focusedInput={focusedInput}
                    startDate={startDate}
                    renderCalendarInfo={() => this.renderCalendarFooter()}
                    endDate={endDate}
                />
            </div>
        );
    }
}

DateRangePickerWrapper.propTypes = propTypes;
DateRangePickerWrapper.defaultProps = defaultProps;

export default DateRangePickerWrapper;
import GlRequest from "../Lib/GlRequest";

class ClientModel {
    create = (details) => {
        if (typeof details.username === "string" && details.username === "")
            delete details.username

        details = {
            data: {
                attributes: details
            }
        }
        return GlRequest.post('/user', details, 'json')
    }

    edit = (clientId, clientDetails) => {
        let edit_data = Object.assign({}, clientDetails)
        delete edit_data.password
        edit_data = {
            data: {
                attributes: edit_data
            }
        }
        return GlRequest.patch('/api/v2/user/' + clientId, edit_data, 'json');
    }
    getById = (clientId, include) => {
        let params
        if (typeof include === "object") {
            params = {
                params: {
                    include: include.join(",")
                }
            }
        }
        return GlRequest.get('/api/v2/user/' + clientId, params)
    }
    getClientStats = (clientId) => {
        return GlRequest.get('/api/v2/user/' + clientId + '/stats')
    }
    editClient = (clientId, clientDetails) => {
        return GlRequest.patch('/api/v2/user/' + clientId, clientDetails, 'json')
    }
    toggleStatus = (clientId) => {
        return GlRequest.get(`/api/v2/user/${clientId}/switch-status`)
    }
    delete = (clientId) => {
        return GlRequest.delete(`/api/v1/clients/${clientId}`)
    }

    loginWithEmail = (email, password) => {
        return GlRequest.setNoRedirect().setClient().post('/login', {
            email, password
        }, 'json')
    }

    loginWithUsername = (username, password) => {
        return GlRequest.setNoRedirect().setClient().post('/login', {
            username, password
        }, 'json')
    }

    phoneVerifyRequest = (user_id) => {
        return GlRequest.setClient().get(`/api/v2/user/${user_id}/request-phone-verify`)
    }
    phoneVerifyComplete = (user_id, code) => {
        return GlRequest.setClient().post(`/api/v2/user/${user_id}/phone-verify`, {
            data: {
                attributes: {
                    verification_code: code
                }
            }
        }, 'json')
    }

    emailVerifyRequest = (user_id) => {
        return GlRequest.setClient().get(`/api/v2/user/${user_id}/request-email-verify`)
    }
    emailVerifyComplete = (user_id, code) => {
        return GlRequest.setClient().post(`/api/v2/user/${user_id}/email-verify`, {
            data: {
                attributes: {
                    verification_code: code
                }
            }
        }, 'json')
    }

}

export default ClientModel = new ClientModel()
import GlRequest from "../Lib/GlRequest";

class IpAddressModel {
    create(details) {
        details = {
            data: {
                attributes: details
            }
        }

        return GlRequest.post('/api/v2/ip-rule', details, 'json')
    }

    modify(id, data) {
        data = {
            data: {
                attributes: data
            }
        }
        return GlRequest.patch('/api/v2/ip-rule/' + id, data, 'json')
    }

    delete(id) {
        return GlRequest.delete('/api/v2/ip-rule/' + id)
    }

    getById(id) {
        return GlRequest.get('/api/v2/ip-rule/' + id)
    }
}

export default IpAddressModel = new IpAddressModel()
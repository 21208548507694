import React from "react"
import {defaultSnackbarOptions} from "../../../../Config";
import {withSnackbar} from "react-simple-snackbar";
import {
    configurable_sms_templates,
    sms_templates
} from "../../../../Conts";
import EmailSmsTemplates from "../EmailSmsTemplates";
import SmsTemplateModel from "../../../../Models/SmsTemplateModel";
import {react_intl} from "../../../../index";

class SmsTemplates extends EmailSmsTemplates {
    configurable_templates = configurable_sms_templates
    config_group = "auto_sms_config"
    url_slug = "sms-templates"
    template_model = SmsTemplateModel
    templates = sms_templates
    template_type = "sms"
    titleMultiple = react_intl.formatMessage({id:"admin.sms_template.sms_multiple_title"})
    titleSingle = react_intl.formatMessage({id:"admin.sms_template.sms_single_title"})
}

export default withSnackbar(SmsTemplates, defaultSnackbarOptions)
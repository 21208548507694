import React from "react"
import {ClientFieldDefaults} from "../../../../DefaultValues";
import GlSelect from "../../../../Components/GlSelect";
import ClientModel from "../../../../Models/ClientModel";
import {withSnackbar} from 'react-simple-snackbar'
import {successSnackbarOptions} from "../../../../Config";
import SendNotificationGroup from "../../../../Components/SendNotificationGroup";
import {Select38Px} from "../../../../Conts";
import {Skeleton} from "@material-ui/lab";

class ClientProfileWidget extends React.Component {
    state = {
        client_group: {}
    }

    getCustomFieldValue = (slug) => {
        let fields, found
        fields = this.props.client_fields
        if (typeof fields === "object" && Object.keys(fields).length)
            found = fields.find(item => item.field_name === slug)

        if (typeof found === "object" && found !== null)
            return found.field_content;

        if (typeof ClientFieldDefaults[slug] !== "undefined")
            return ClientFieldDefaults[slug]
        return false;
    }

    render() {
        const {loading, openSnackbar, client} = this.props
        let defaultClientGroup = typeof client.user_group === "object" ? {
            value: client.user_group.id,
            label: client.user_group.name
        } : null;

        if(typeof this.state.client_group === "object" && typeof this.state.client_group.value !== "undefined")
        {
            defaultClientGroup = this.state.client_group
        }

        let count = 4, skeletonGroup = []
        for (let a = 0; a < count; a++)
            skeletonGroup.push(<Skeleton variant="text" height={40}/>)

        return <div className="single-client-box border rounded" style={{backgroundColor: "#e4f2f0"}}>
            <h4 className={"text-center"}>
                <i className="icon-feather-user-check"/> MÜŞTERİ BİLGİLERİ
            </h4>
            {loading && <div className={"pl-2 pr-2"}>
                {skeletonGroup}
                <div className={"d-block text-center"}>
                    <Skeleton variant="text" height={32} width={60} className={"d-inline-block mr-3"} />
                    <Skeleton variant="text" height={32} width={60} className={"d-inline-block"} />
                </div>
            </div>}
            {!loading && <table className={"mb-0"}>
                <tbody>
                <tr>
                    <td>
                        <table width="100%" className={"mb-0"}>
                            <tbody>
                            <tr>
                                <th width="40%"><i className="icon-line-awesome-dot-circle-o"/> Ad Soyad</th>
                                <td id="cp-name">{`${client.firstname || ''} ${client.lastname || ''}`}</td>
                            </tr>
                            <tr>
                                <th width="40%"><i className="icon-line-awesome-dot-circle-o"/> Durumu</th>
                                <td id="cp-status">
                                    {client.enabled === true ?
                                        <span className="float-left verified-badge-with-title">Aktif</span> :
                                        <span className="float-left verified-badge-with-title bg-danger">Pasif</span>}
                                </td>
                            </tr>
                            <tr>
                                <th width="40%"><i className="icon-line-awesome-dot-circle-o"/> Hesap Tipi</th>
                                <td id="cp-type">{this.getCustomFieldValue('account_type') === "corporate" ? "Kurumsal" : "Bireysel"}</td>
                            </tr>

                            {this.getCustomFieldValue('account_type') === "corporate" && <tr>
                                <th width="40%"><i className="icon-line-awesome-dot-circle-o"/> Firma Unvanı</th>
                                <td id="cp-company-name">{this.getCustomFieldValue('company_name')}</td>
                            </tr>}
                            <tr>
                                <th width="40%"><i className="icon-line-awesome-dot-circle-o"/> E-posta</th>
                                <td id="cp-email">{client.email}</td>
                            </tr>
                            <tr>
                                <th width="40%"><i className="icon-line-awesome-dot-circle-o"/> Telefon</th>
                                <td id="cp-phone">{client.mobile_phone}</td>
                            </tr>
                            <tr>
                                <th width={"40%"}><i className="icon-line-awesome-dot-circle-o"/> Müşteri Grubu</th>
                                <td id={"cp-client-group"}>
                                    <GlSelect
                                        url={'/api/v2/user-group'}
                                        placeholder={"Seçiniz"}
                                        onChange={(e) => {
                                            this.setState({
                                                client_group: {
                                                    value: e.value,
                                                    label: e.label
                                                }
                                            })
                                            ClientModel.edit(this.props.id, {
                                                user_group: e.value || ""
                                            }).then(r => r.json())
                                                .then(result => {
                                                    openSnackbar(<div>
                                                        <i className={"icon-line-awesome-check-circle-o"}/> Müşteri
                                                        grubu değiştirildi!
                                                    </div>)
                                                })
                                        }}
                                        isClearable={true}
                                        value={defaultClientGroup}
                                        searchKey={"name"}
                                        styles={Select38Px}
                                        closeMenuOnSelect={true}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <div className={"text-center pt-1 pb-1"}>
                            <SendNotificationGroup
                                type={"sms"}
                                groups={["custom_message", "new_register", "phone_verification"]}
                                clientId={this.props.client.id}
                                openSnackbar={this.props.openSnackbar}
                            /> {" "}
                            <SendNotificationGroup
                                type={"email"}
                                color={"primary"}
                                groups={["custom_message", "new_register", "password_reset_request", "email_verification"]}
                                clientId={this.props.client.id}
                                openSnackbar={this.props.openSnackbar}
                            />
                        </div>

                    </td>
                </tr>
                </tbody>
            </table>}
        </div>;
    }
}

export default withSnackbar(ClientProfileWidget, successSnackbarOptions)
import React from "react"
import InvoiceDetailsSelector from "../../Components/InvoiceDetailsSelector";
import {get_user_id} from "../../Conts";
import {FormattedMessage} from "react-intl";

const NoInformation = <div>
    <p className={"text-muted"}>
        <i className={"icon-feather-info"} /> <FormattedMessage id={"client.invoice_details.invalid_invoice_info_msg"}/>
    </p>
</div>

class InvoiceDetails extends React.Component{
    render() {
        let {details, canChange} = this.props
        return <div>
            {(Object.keys(details).length === 0 && NoInformation)}
            {<div>
                {canChange ? Object.keys(details).length > 1 && <p className="text-info">
                    <i className={"icon-feather-info"}/><FormattedMessage id={"client.invoice_details.changeable_invoice_info_msg"}/>
                </p> : <p className={"text-danger"}><i className={"icon-line-awesome-warning"} /> <FormattedMessage id={"client.invoice_details.unchangeable_invoice_info_msg"}/></p>}
                <InvoiceDetailsSelector
                    canChange
                    user_id={get_user_id()}
                    {...this.props}
                />
            </div>}
        </div>;
    }
}
export default InvoiceDetails
import React, {Fragment} from "react"
import Header from "../Pages/Client/Header";
import ContentModel from "../Models/ContentModel";
import Footer from "../Pages/Client/Footer";


const {Route} = require("react-router-dom");

export class StandardRoute extends Route {
    constructor(props) {
        super(props);
        // ContentModel.getMenu().then()
    }

    render() {
        const {component: Component}: ClientRoute = this.props;

        const containerInnerClass = "container bg-white fullh80 border-left border-right"
        const wrapperClass = ""

        return <Fragment>
            <div id="wrapper" className={wrapperClass}>
                <div className="bg-light dashboard-container">
                    <Header {...this.props} />
                    <div className="ml-auto mr-auto">
                        <div className={containerInnerClass}>
                            <Component {...this.props} />
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        </Fragment>
    }
}
import React from "react"
import {ClientFieldDefaults} from "../../../../DefaultValues";
import Chart from "react-apexcharts";
import {Skeleton} from "@material-ui/lab";

const NoTransaction = (props) => {
    return <div className={"text-center pt-2"}>
        <i style={{fontSize: 38}} className={"  icon-line-awesome-folder-open d-block mb-2"}/>
        <em>Herhangi bir hareket bulunamadı!</em>
    </div>
}

class ClientProfileStats extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: {
                type: 'pie',
                legend: {
                    position: 'bottom',
                    labels: {
                        fontSize: 11,
                        boxWidth: 10
                    }
                },
                labels: [
                    'Ödendi',
                    'Ödenmedi',
                    'İptal',
                    'İade',
                    'Taslak'
                ]
            }
        };
    }


    getCustomFieldValue = (slug) => {
        let fields = this.props.client_fields
        let found = fields.find(item => item.field_name === slug)
        if (typeof found === "object" && found !== null)
            return found.value;

        if (typeof ClientFieldDefaults[slug] !== "undefined")
            return ClientFieldDefaults[slug]
        return false;
    }

    render() {
        let {loading} = this.props
        return <div className="single-client-box rounded border" style={{backgroundColor: "#e4f2f0"}}>
            <h4 className={"text-center"}>
                <i className="icon-feather-pie-chart"/> FİNANSAL DURUM
            </h4>
            {this.props.pieSeries ? <table style={{backgroundColor: "#fff"}}>
                <tbody>
                <tr>
                    <td className={"text-center"}>
                        {!loading && <Chart
                            options={this.state.options}
                            series={this.props.pieSeries}
                            type="pie"
                            width="320"
                            height={230}
                        />}
                        {(loading) && <><Skeleton variant="circle" width={150} height={150}
                                                          className={"d-inline-block mt-2"}/>
                            <div className={"d-block text-center w-100 pb-3"}>
                                <Skeleton variant={"text"} className={"d-inline-block mr-2"} width={40} height={12}/>
                                <Skeleton variant={"text"} className={"d-inline-block mr-2"} width={40} height={12}/>
                                <Skeleton variant={"text"} className={"d-inline-block mr-2"} width={40} height={12}/>
                                <Skeleton variant={"text"} className={"d-inline-block mr-2"} width={40} height={12}/>
                                <Skeleton variant={"text"} className={"d-inline-block"} width={40} height={12}/>
                            </div>
                        </>}
                    </td>
                </tr>
                </tbody>
            </table> : <NoTransaction/>}
        </div>;
    }
}

export default ClientProfileStats
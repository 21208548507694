import React from "react";
import Button from "@material-ui/core/Button";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import {HiTranslate} from "react-icons/all";
import {languages} from "../Config";
import {get_app_lang, set_app_lang} from "../index";
import {Menu, MenuItem} from "@material-ui/core";
import {ReactSVG} from "react-svg";

class LangSwitcher extends React.Component {
    render() {
        let {bgTransparent} = this.props
        if (!bgTransparent) bgTransparent = false;
        let selected_lang = languages().find(a => a.value !== get_app_lang());
        let selected_lang_flag = typeof selected_lang === "object" && typeof selected_lang.flag_code === "string" ? selected_lang.flag_code : "gb";
        let flag_img;
        try{
            flag_img = (require(`./../assets/images/flags/${selected_lang_flag}.svg`) || {});
        } catch (e)
        {
            flag_img = (require(`./../assets/images/flags/gb.svg`) || {});
        }

        if (typeof flag_img === "object")
            flag_img = flag_img.default;
        return <PopupState variant="popover">
            {(popupState) => (
                <React.Fragment>
                    <div className={bgTransparent !== true ? "bg-white" : ""}
                         style={this.props.boxStyle}>
                        <Button variant="contained" size={"small"}
                                className={"position-relative bg-white text-dark d-block ml-auto mr-auto shadow-none border"} {...bindTrigger(popupState)}
                        >
                            {selected_lang.label}
                            <img
                                src={flag_img}
                                className={"ml-1 rounded"} width={24}/>
                        </Button>
                    </div>
                    <Menu {...bindMenu(popupState)}>
                        {languages().map((item, count) => <MenuItem key={`lang-${count}`} onClick={() => {
                            set_app_lang(item.value).then(() => {
                                setTimeout(function () {
                                    popupState.close();
                                    window.location.reload()
                                }, 200);
                            });
                        }}>{item.label}</MenuItem>)}
                    </Menu>
                </React.Fragment>
            )}
        </PopupState>;
    }
}

export default LangSwitcher
import GlRequest from "../Lib/GlRequest";

class PaymentModel {
    make(data) {
        data = {
            data: {attributes: data}
        }
        return GlRequest.post('/api/v2/payment/make', data, 'json')
    }

    checkBin = (bin, orderId) => {
        return GlRequest.get(`/payment/check-bin?cc_number=${bin}&order_id=${orderId}`)
    }
    checkBinWithToken = (bin, token) => {
        let url = `/payment/check-bin?cc_number=${bin}&token=${token}`
        return GlRequest.get(url)
    }

    getBinInfo = (bin) => {
        return GlRequest.get(`/payment/get-bin-info?bin=${bin}`)
    }

    makeUnauth(data, token) {
        data = {
            data: {attributes: data}
        }
        return GlRequest.post('/payment/make/' + token, data, 'json')
    }
}

export default PaymentModel = new PaymentModel()
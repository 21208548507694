import React from "react"
import {GetTitle} from "../../Routes/AdminRoute";
import DataTable from "../../Components/DataTable";
import moment from "moment";
import {Link} from "react-router-dom";
import {Alert, Col, Input, Label, Row, UncontrolledTooltip} from "reactstrap";
import OrderStatus, {OrderStatuses} from "../../Elements/OrderStatus";
import StatusChangeModel from "../../Components/StatusChangeModel";
import ShowPrice from "../../Elements/ShowPrice";
import DateRangePickerWrapper from "../../Components/react-dates/DateRangePickerWrapper";
import Select from "react-select";
import {Select38Px} from "../../Conts";
import InputWithIcon from "../../Elements/InputWithIcon";
import {debounce, get_profile_info} from "../../Config";
import ExcelExportButton from "../../Components/ExcelExportButton";
import {AiFillFileExcel} from "react-icons/all";
import {filter2String} from "../Admin/Orders/Orders";
import {FormattedMessage} from "react-intl";
import {react_intl} from "../../index";

class Orders extends React.Component {

    state = {
        orderNumFilter: "",
        dateFilterStart: "",
        dateFilterEnd: ""
    }

    onFilterChanged = debounce(() => {
        this.tableRef.current.loadData()
    }, 500);

    columns = [
        {
            Header: react_intl.formatMessage({id: "client.orders.invoice_id"}),
            accessor: 'id',
            sortKey: 'id',
            width: "10%",
            Cell: row => {
                return "#" + row.value
            }
        },
        {
            Header: react_intl.formatMessage({id: "client.orders.invoice_no"}),
            accessor: null,
            width: "25%",
            Cell: data => {
                let row = data.row.original
                row = row.attributes
                return <div>
                    {row.order_num}{row.notes ? <div className={"d-inline-block ml-2"}>
                    <UncontrolledTooltip target={`order-${row.id}-note`}>{row.notes}</UncontrolledTooltip>
                    <i id={`order-${row.id}-note`} className={"icon-line-awesome-comment"} style={{fontSize: 18}}/>
                </div> : ""}
                </div>
            }
        },
        {
            Header: react_intl.formatMessage({id: "client.orders.amount"}),
            sortKey: 'amount',
            width: "10%",
            Cell: data => {
                let row = data.row.original
                return <ShowPrice
                    amount={row.attributes.amount}
                    currencyprefix={row.currency.prefix}
                    currencysuffix={row.currency.suffix}
                />
            }
        },
        {
            Header: react_intl.formatMessage({id: "client.orders.date"}),
            accessor: 'attributes.created',
            width: "20%",
            sortKey: "created",
            Cell: row => {
                return <span title={moment(row.value).format("DD-MM-YYYY HH:mm")}>
                    {moment(row.value).format("DD-MM-YYYY")}
                </span>
            }
        },
        {
            Header: react_intl.formatMessage({id: "client.orders.status"}),
            accessor: "attributes.status",
            width: "10%",
            Cell: row => {
                return <OrderStatus status={row.value}/>
            }
        },
        {
            Header: react_intl.formatMessage({id: "client.orders.operations"}),
            width: "20%",
            Cell: (data) => {
                let row = data.row.original
                row = row.attributes
                return <div className={"d-flex table-buttons"} style={{marginTop: 4}}>
                    <Link
                        to={`/cp/orders/${row.id}`}
                        className={"btn btn-sm btn-dark mr-1"}
                    ><FormattedMessage id={"client.orders.details"}/></Link>

                    {row.status === "unpaid" && <Link
                        to={`/cp/orders/${row.id}/pay`}
                        className={"btn btn-sm btn-success mr-1"}
                    ><FormattedMessage id={"client.orders.pay"}/></Link>}
                </div>
            }
        }
    ]

    constructor(props) {
        super(props);
        this.tableRef = React.createRef()
    }

    getFilters = () => {
        let {dateFilterStart, dateFilterEnd} = this.state
        return {
            status: this.state.statusFilter,
            concat: this.state.orderNumFilter,
            created_start: dateFilterStart ? moment(dateFilterStart).format("YYYY-MM-DD") : "",
            created_end: dateFilterEnd ? moment(dateFilterEnd).format("YYYY-MM-DD") : "",
            user_id: get_profile_info('id')
        }
    }

    componentDidMount() {
        document.title = GetTitle(react_intl.formatMessage({id: "client.orders.invoices_title"}))
    }

    render() {

        return <div>
            <div className={"w-100 pl-3 pr-3"}>
                <Alert color={"light"} className={"border"}>
                    <Row>
                        <Col sm={12} md={4}>
                            <h3><FormattedMessage id={"client.orders.invoices"}/></h3>
                            <p className={"font-italic text-muted"}><FormattedMessage
                                id={"client.orders.invoice_listed_msg"}/></p>
                        </Col>
                        <Col sm={12} md={8}>
                            <Row>
                                <Col sm={12} md={5}>
                                    <Label><i className={""}/> <FormattedMessage
                                        id={"client.orders.invoice_no_or_note_txt"}/></Label>
                                    <InputWithIcon
                                        onChange={(e) => {
                                            this.setState({
                                                orderNumFilter: e.target.value
                                            }, this.onFilterChanged)
                                        }}/>
                                    <div className={"mt-2"}>
                                        <Label><FormattedMessage id={"client.orders.status"}/></Label>
                                        <Select
                                            isClearable={true}
                                            placeholder={react_intl.formatMessage({id: "client.orders.select"})}
                                            onChange={(value) => {
                                                if (value === null)
                                                    value = {
                                                        value: ""
                                                    }
                                                this.setState({
                                                    statusFilter: value.value
                                                }, this.onFilterChanged)
                                            }}
                                            styles={Select38Px}
                                            defaultValue={null}
                                            options={OrderStatuses().filter(item => item.slug !== "unknown" && item.slug !== "quick_checkout" && item.slug !== "draft").map(item => {
                                                return {label: item.label, value: item.slug}
                                            })}
                                        />
                                    </div>
                                </Col>
                                <Col sm={12} md={7}>
                                    <Label><i className={"icon-feather-calendar"}/> <FormattedMessage
                                        id={"client.orders.date_range"}/></Label>
                                    <DateRangePickerWrapper
                                        showClearDates={true}
                                        onChange={(start, end) => {
                                            this.setState({
                                                dateFilterStart: start ? start.toDate() : null,
                                                dateFilterEnd: end ? end.toDate() : null
                                            }, this.onFilterChanged)
                                        }}
                                    />

                                    <div className={"text-left"}>
                                        <p>&nbsp;</p>
                                        <ExcelExportButton
                                            color={"dark"}
                                            base={"/api/v2/invoice-excel"}
                                            query={filter2String(this.getFilters())}
                                            buttontext={<><AiFillFileExcel/> Excel</>}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </Alert>

                <div className={"table-style-1"}>
                    <DataTable
                        container_class={" "}
                        isClient={true}
                        pageSize={25}
                        order={"desc"}
                        orderBy={"id"}
                        ref={this.tableRef}
                        styles={Select38Px}
                        noDataText={react_intl.formatMessage({id: "client.orders.no_invoice_msg"})}
                        columns={this.columns} dataQuery={{
                        url: "/api/v2/order",
                        filters: this.getFilters(),
                        include: ["currency"]
                    }}/>
                </div>
            </div>
        </div>;
    }
}

export default Orders
import {get_main_config, react_intl} from "./index";

export const mobileWidthEnd = 720;
export const AdminMenu = () => [
    {
        slug: "start",
        label: "",
        items: [
            {
                slug: "home",
                href: "/admin",
                icon: "icon-feather-home",
                label: react_intl.formatMessage({id: "admin.menu.dashboard"})
            }
        ]
    },
    {
        slug: "sale",
        label: react_intl.formatMessage({id: "admin.menu.payment.label"}),
        items: [
            {
                slug: "clients",
                href: "#",
                icon: "icon-line-awesome-user",
                label: react_intl.formatMessage({id: "admin.menu.payment.clients"}),
                items: [
                    {
                        label: react_intl.formatMessage({id: "admin.menu.payment.clients.clients"}),
                        href: "/admin/clients"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.menu.payment.clients.client_groups"}),
                        href: "/admin/client-groups"
                    },
                    // ,
                    // {
                    //     label: "Bildirimler",
                    //     href: "/admin/notifications"
                    // },
                    {
                        label: react_intl.formatMessage({id: "admin.menu.payment.clients.bulk_sending"}),
                        href: "/admin/mailer"
                    }
                ]
            },
            {
                slug: "orders",
                href: "#",
                icon: "icon-material-outline-monetization-on",
                label: react_intl.formatMessage({id: "admin.menu.payment.payments"}),
                items: [
                    {
                        label: react_intl.formatMessage({id: "admin.menu.payment.payments.orders"}),
                        href: "/admin/orders"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.menu.payment.payments.transactions"}),
                        href: "/admin/transactions"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.menu.payment.payments.bank_transactions"}),
                        href: "/admin/bank-transactions"
                    }
                ]
            }
        ]
    },
    {
        slug: "manage",
        label: react_intl.formatMessage({id: "admin.menu.system"}),
        items: [
            {
                slug: "payment",
                href: "#",
                icon: " icon-feather-credit-card",
                label: react_intl.formatMessage({id: "admin.menu.system.payment_settings"}),
                items: [
                    {
                        label: react_intl.formatMessage({id: "admin.menu.system.payment_settings.payment_methods"}),
                        href: "/admin/payment-methods"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.menu.system.payment_settings.payment_rules"}),
                        href: "/admin/payment-rules"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.menu.system.payment_settings.currencies"}),
                        href: "/admin/currencies"
                    }
                ]
            },
            {
                slug: "content",
                href: "#",
                icon: "icon-line-awesome-newspaper-o",
                label: react_intl.formatMessage({id: "admin.menu.system.contents"}),
                items: [
                    {
                        label: react_intl.formatMessage({id: "admin.menu.system.contents.pages"}),
                        href: "/admin/pages"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.menu.system.contents.announcements"}),
                        href: "/admin/announcements"
                    }, {
                        label: react_intl.formatMessage({id: "admin.menu.system.contents.menu"}),
                        href: "/admin/menu"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.menu.system.contents.multimedia"}),
                        href: "/admin/multimedia"
                    }
                ]
            },
            {
                slug: "notifications",
                href: "#",
                icon: " icon-material-baseline-notifications-none",
                label: react_intl.formatMessage({id: "admin.menu.system.notifications"}),
                items: [
                    {
                        label: react_intl.formatMessage({id: "admin.menu.system.notifications.sms"}),
                        href: "/admin/sms-notifications"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.menu.system.notifications.email"}),
                        href: "/admin/email-notifications"
                    }, {
                        label: react_intl.formatMessage({id: "admin.menu.system.notifications.web"}),
                        href: "/admin/web-notifications"
                    }
                ]
            },
            {
                slug: "logs",
                href: "#",
                icon: " icon-line-awesome-database",
                label: react_intl.formatMessage({id: "admin.menu.system.logs"}),
                items: [
                    {
                        label: react_intl.formatMessage({id: "admin.menu.system.logs.payment"}),
                        href: "/admin/payment-logs"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.menu.system.logs.other"}),
                        href: "/admin/other-logs"
                    }
                ]
            },
            {
                slug: "system",
                href: "#",
                icon: "icon-line-awesome-cogs",
                label: react_intl.formatMessage({id: "admin.menu.system.settings"}),
                items: [
                    {
                        label: react_intl.formatMessage({id: "admin.menu.system.settings.general"}),
                        href: "/admin/general-settings"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.menu.system.settings.security"}),
                        href: "/admin/security-settings"
                    },
                    {
                        label: react_intl.formatMessage({id: "admin.menu.system.settings.admin"}),
                        href: "/admin/admin-accounts"
                    },
                ]
            }
        ]
    }
]
export const pageTemplates = {
    "standard": {
        "containerInnerClass": "dashboard-content-inner padding-top-10 padding-bottom-10",
        "wrapperClass": ""
    },
    "table": {
        "containerInnerClass": "dashboard-content-inner p-0",
        "wrapperClass": "table-page-wrapper"
    }
}

export function debounce(fn, time) {
    let timeoutId
    return wrapper

    function wrapper(...args) {
        if (timeoutId) {
            clearTimeout(timeoutId)
        }
        timeoutId = setTimeout(() => {
            timeoutId = null
            fn(...args)
        }, time)
    }
}

export const defaultSnackbarOptions = {
    position: 'top-right',
    closeStyle: {
        color: 'white',
        fontSize: '16px',
    },
}
export const successSnackbarOptions = {
    style: {
        backgroundColor: "#30ab4a"
    },
    position: 'top-right',
    closeStyle: {
        color: 'white',
        fontSize: '16px',
    },
}
export const dangerSnackbarOptions = {
    style: {
        backgroundColor: "#dc3545"
    },
    position: 'top-right',
    closeStyle: {
        color: 'white',
        fontSize: '16px',
    },
}
export const transactionTypes = () => [
    {
        value: "all",
        label: react_intl.formatMessage({id: "admin.transaction_types.all"})
    }, {
        value: "payment",
        label: react_intl.formatMessage({id: "admin.transaction_types.payment"})
    }, {
        value: "refund",
        label: react_intl.formatMessage({id: "admin.transaction_types.refund"})
    }
]

export const bankTransactionTypes = () => [
    {
        value: "transferred_in,transferred_out",
        label: react_intl.formatMessage({id: "admin.transaction_types.all"})
    }, {
        value: "transferred_in",
        label: react_intl.formatMessage({id: "admin.transaction_types.transferred_in"})
    }, {
        value: "transferred_out",
        label: react_intl.formatMessage({id: "admin.transaction_types.transferred_out"})
    }
]

export const notificationTypes = () => [
    {
        value: "",
        label: react_intl.formatMessage({id: "admin.notification_types.all"})
    }, {
        value: "order",
        label: react_intl.formatMessage({id: "admin.notification_types.order"}),
        icon: "icon-line-awesome-file"
    }, {
        value: "transaction",
        label: react_intl.formatMessage({id: "admin.notification_types.transaction"}),
        icon: "icon-feather-dollar-sign"
    }, {
        value: "user",
        label: react_intl.formatMessage({id: "admin.notification_types.user"}),
        icon: "icon-feather-user"
    }, {
        value: "system",
        label: react_intl.formatMessage({id: "admin.notification_types.system"}),
        icon: "icon-line-awesome-keyboard-o"
    }, {
        value: "announcement",
        label: react_intl.formatMessage({id: "admin.notification_types.announcement"}),
        icon: "icon-material-outline-announcement"
    }, {
        value: "other",
        label: react_intl.formatMessage({id: "admin.notification_types.other"}),
        icon: "icon-material-outline-info"
    }
]
export const languages = () => [
    {
        value: "tr",
        label: react_intl.formatMessage({id: "languages.tr"}),
        flag_code: "tr"
    },
    {
        value: "en",
        label: react_intl.formatMessage({id: "languages.en"}),
        flag_code: "gb"
    }
]
export const ipRuleTypes = () => [
    {
        value: "white",
        label: react_intl.formatMessage({id: "admin.ip_rule_types.white"})
    }, {
        value: "black",
        label: react_intl.formatMessage({id: "admin.ip_rule_types.black"})
    }
]
export const ApiErrorThrow = (error, snackbar) => {
    if (typeof snackbar === "undefined") {
        snackbar = alert
    }
    if (typeof error === "string") {
        snackbar(error)
    }

    if (typeof error.json === "function") {
        error.json().then(r => {
            let errorMessage = ""
            if (typeof r.errors === "object" && (r.errors).length) {
                (r.errors).forEach(item => {
                    errorMessage += item.detail + "\n"
                })
            }

            if (typeof r.error === "object" && (r.error.exception).length) {
                (r.error.exception).forEach(item => {
                    errorMessage += item.message + "\n"
                })
            }

            if (errorMessage)
                snackbar(errorMessage)
        })
    }
}
export const backendBaseUrl = process.env.REACT_APP_BACKEND_BASEURL
export const frontendBaseUrl = process.env.REACT_APP_FRONTNEND_BASEURL
export const dateonly_format = "DD-MM-YYYY"
export const datetime_format = "DD.MM.YYYY HH:mm"
export const default_lang = process.env.REACT_APP_DEFAULT_LANGUAGE||"tr"
export const feature__tax_number_search = parseInt(process.env.REACT_APP_TAX_SEARCH||"0")
export const feature__use_balance = parseInt(process.env.REACT_APP_USEBALANCE||"0")
export const feature__teb_paymendo_merge = parseInt(process.env.REACT_APP_TEB_PAYMENDO_MERGE||"0")
export const log_types = () => [
    {
        label: react_intl.formatMessage({id: "admin.log_types.all"}),
        value: ""
    },
    {
        label: react_intl.formatMessage({id: "admin.log_types.failed_login"}),
        value: "failed_login"
    },
    {
        label: react_intl.formatMessage({id: "admin.log_types.exception"}),
        value: "exception"
    },
    {
        label: react_intl.formatMessage({id: "admin.log_types.activity"}),
        value: "activity"
    },
    {
        label: react_intl.formatMessage({id: "admin.log_types.sms"}),
        value: "sms"
    },
    {
        label: react_intl.formatMessage({id: "admin.log_types.payment"}),
        value: "payment"
    }
]
export const CurrencyConvertTypes = () => [
    {
        label: react_intl.formatMessage({id: "admin.currency_rate_types.ForexBuying"}),
        value: "ForexBuying"
    },
    {
        label: react_intl.formatMessage({id: "admin.currency_rate_types.ForexSelling"}),
        value: "ForexSelling"
    },
    {
        label: react_intl.formatMessage({id: "admin.currency_rate_types.BanknoteBuying"}),
        value: "BanknoteBuying"
    },
    {
        label: react_intl.formatMessage({id: "admin.currency_rate_types.BanknoteSelling"}),
        value: "BanknoteSelling"
    }
]
export const rangeOptions = (start, end) => {
    let options = []
    for (start; start <= end; start++)
        options.push({value: start, label: start})
    return options
}
export const getParameterByName = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
export const limitObjectWithRef = (object, ref) => {
    return Object.keys(object)
        .filter(key => Object.keys(ref).includes(key))
        .reduce((obj, key) => {
            return {
                ...obj,
                [key]: object[key]
            };
        }, {});
}

export const get_profile_info = (slug) => {
    let admin_profile = localStorage.getItem('admin_profile')
    admin_profile = JSON.parse(admin_profile) || {}
    return typeof admin_profile[slug] !== "undefined" ? admin_profile[slug] : ""
}

export const get_default_currency = (detail) => {
    return get_main_config("default_currency", detail)
}
export const is_mobile = () => {
    return mobileWidthEnd >= window.innerWidth
}

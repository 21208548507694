import React from "react"
import {Row, Col, Label, FormGroup, Card, CardHeader, CardBody, CardFooter, Button} from "reactstrap"
import InputWithIcon from "../../Elements/InputWithIcon";
import ClientModel from "../../Models/ClientModel";
import {withSnackbar} from "react-simple-snackbar";
import {defaultSnackbarOptions} from "../../Config";
import {ClientAuthorizationObject, GlobalAuthorizationObject} from "../../Lib/Authorization";
import {GetTitle} from "../../Routes/AdminRoute";
import Carousel from "../../Components/CarouselReady";
import CarouselReady from "../../Components/CarouselReady";
import ButtonLoad from "../../Elements/ButtonLoad";
import PasswordReset from "../../Components/PasswordReset";
import {loadProfile} from "../Admin/Blocks/AdminSidebarProfile";
import {get_main_config, react_intl} from "../../index";
import Footer from "./Footer";
import {FormattedMessage, injectIntl} from "react-intl";
import {FaArrowAltCircleLeft, FaLongArrowAltLeft, FaPlus, FaPlusCircle} from "react-icons/all";
import PhoneNumberInput from "../../Elements/PhoneNumberInput";

class ClientRegister extends React.Component {
    state = {
        user_details: {
            firstname: "",
            lastname: "",
            email: "",
            password: "",
            password2: "",
            mobile_phone: "",
            mobile_phone_country_code: ""
        },
        loading: false
    }

    constructor(props) {
        super(props);
        this.setUserDetails = this.setUserDetails.bind(this);
    }

    componentDidMount() {
        document.title = GetTitle(react_intl.formatMessage({id: "client.client_login.login_txt"}))
    }

    setUserDetails = (d, callback) => {
        let slug = d.target.name,
            val = d.target.value;

        let {user_details} = this.state;
        user_details = Object.assign({}, user_details);
        user_details[slug] = val;
        this.setState({user_details}, () => {
            if (typeof callback === "function") callback()
        })
    }

    render() {
        let {openSnackbar, history} = this.props
        let {user_details} = this.state
        if (GlobalAuthorizationObject.isLoggedIn()) {
            history.push('/cp')
            return false
        }

        return <div className={"padding-top-50 padding-bottom-50 margin-top-25"}>
            <Row>
                <Col sm={12} lg={{size: 8, offset: 2}} className={""}>
                    <form className={"w-100 mr-auto ml-auto"} onSubmit={(e) => {
                        e.preventDefault()
                        this.setState({
                            loading: true
                        })
                        if (Object.keys(user_details).filter(item => user_details[item] === null || (
                            typeof user_details[item] === "string" && user_details[item] === ""
                        )).length > 0) {
                            openSnackbar(<FormattedMessage id={"client.register.fill_all"}/>);
                            this.setState({
                                loading: false
                            })
                            return false
                        }


                        ClientAuthorizationObject.doRegister(user_details)
                            .then(result => {
                                history.push('/login');
                                return false;
                            }).catch(error => {
                            this.setState({loading: false})
                            if (!error)
                                error = <FormattedMessage id={"client.register.register_error"}/>
                            openSnackbar(error);
                            return false
                        })
                    }}>
                        <div className={"text-center mb-3"}>
                            <img
                                height={64}
                                src={get_main_config("system_general", "logo_url")} alt={""}
                            />
                        </div>

                        <Card>
                            <CardBody>
                                <FormGroup>
                                    <Row>
                                        <Col sm={12} lg={6}>
                                            <Label><FormattedMessage id={"client.register.firstname"}/></Label>
                                            <InputWithIcon icon={"icon-feather-user"} name={"firstname"}
                                                           autocomplete={"given-name"}
                                                           type={"text"}
                                                           required={true}
                                                           value={user_details.firstname}
                                                           onChange={this.setUserDetails}
                                            />
                                        </Col>
                                        <Col sm={12} lg={6}>
                                            <Label><FormattedMessage id={"client.register.lastname"}/></Label>
                                            <InputWithIcon icon={"icon-feather-user"} name={"lastname"}
                                                           type={"text"}
                                                           autocomplete={"family-name"}
                                                           required={true}
                                                           value={user_details.lastname}
                                                           onChange={this.setUserDetails}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Label><FormattedMessage id={"client.register.email"}/></Label>
                                    <InputWithIcon icon={"icon-feather-at-sign"} name={"email"}
                                                   type={"email"}
                                                   required={true}
                                                   value={this.state.email}
                                                   onChange={this.setUserDetails}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label><FormattedMessage id={"client.register.phone"}/></Label>
                                    <PhoneNumberInput
                                        required
                                        value={""} onChange={(val) => {
                                        if (typeof val !== "object")
                                            val = {}
                                        let self = this;
                                        this.setUserDetails({
                                            target: {
                                                name: "mobile_phone_country_code",
                                                value: val.country
                                            }
                                        }, function () {
                                            self.setUserDetails({
                                                target: {
                                                    name: "mobile_phone",
                                                    value: val.number
                                                }
                                            })
                                        })
                                    }}/>
                                </FormGroup>
                                <Row>
                                    <Col sm={12} lg={6}>
                                        <FormGroup>
                                            <Label><FormattedMessage id={"client.register.password"}/></Label>
                                            <InputWithIcon
                                                type={"password"} icon={"icon-feather-lock"}
                                                name={"password"}
                                                autocomplete={"new-password"}
                                                required={true}
                                                onChange={this.setUserDetails}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12} lg={6}>
                                        <FormGroup>
                                            <Label><FormattedMessage id={"client.register.password2"}/></Label>
                                            <InputWithIcon
                                                autocomplete={"new-password"}
                                                type={"password"} icon={"icon-feather-lock"}
                                                name={"password2"}
                                                required={true}
                                                onChange={this.setUserDetails}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter className={"text-right"}>
                                <Button
                                    size={"sm"}
                                    className={"float-left"}
                                    onClick={() => {
                                        history.push('/login')
                                    }}
                                    color={"primary"}><FaLongArrowAltLeft size={11} className={"ml-1 mt-n1"}/>
                                    <FormattedMessage id={"client.register.login_back"}/>
                                </Button>

                                <ButtonLoad loading={this.state.loading} color={"dark"} size={"sm"}
                                            text={react_intl.formatMessage({id: "client.register.register_button"})}/>
                            </CardFooter>
                        </Card>

                    </form>
                </Col>
            </Row>
            <Footer shadow={false} border={true} marginBottom={true}/>
        </div>;
    }
}

export default injectIntl(withSnackbar(ClientRegister, defaultSnackbarOptions))